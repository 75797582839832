import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable, { createTheme } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { Collapse, Dropdown, Modal, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import Handsontable from 'handsontable/base';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import { PrivateAxios, url } from '../../environment/AxiosInstance';
import Loader from '../../loader/Loader';
import Form from 'react-bootstrap/Form';
import { AllUser, GetTaskPriority, GetTaskStatus } from '../../environment/GlobalApi';
import { exportExcel, exportPDF, printTable } from '../../environment/ExportData';
import moment from 'moment';
import { ErrorMessage, SuccessMessage, WaringMessage } from '../../environment/ToastMessage';

import HtmlDescription from '../../environment/HtmlDescription ';
import Offcanvas from 'react-bootstrap/Offcanvas';
import TaskMessage from '../../component/TaskMessage';
import { convertLinks, convertLinksNewLine } from '../../environment/ConvertLinks';
import { UserAuth } from '../auth/Auth';
import { formatDate } from '../../environment/DateFormat';
import { isHoliday, isWeekend } from '../../environment/HolidayDateProtect';
import { TaskPriority } from '../../utils/StaticData';
import TaskTrackerDetails from '../taskManager/tsaktracker/TaskTrackerDetails';
import { calculateDelayInHoursEndTime } from '../../environment/Helper';
import AutoModalOn from '../CommonComponent/AutoModalOn';
import SwitchDoerModal from '../CommonComponent/SwitchDoerModal';
import HodTab from './HodTab';

registerAllModules();

function HodTask() {
    const { isLoading, setIsLoading, Logout, User, holidayList, officeTimeList, } = UserAuth()
    const [grid, setGrid] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [data, setData] = useState([]);
    const [inputSearch, setInputSearch] = useState([]);
    const [searchData, setSearchData] = useState({
        "name": "",
        "assigned_by": "",
        "assign_to": "",
        "task_priority_id": "",
        "delegation_status_id": "",
    })
    const [searchDataLabel, setSearchDataLabel] = useState({
        "assign_to": "",
        "task_priority_id": "",
        "delegation_status_id": "",
    })

    //================Delete Bulk Upload============================//
    const [deleteTaskId, setDeleteTaskId] = useState([]);
    const DeleteBulk = (e, row) => {
        setDeleteTaskId((prevSelectedIds) =>
            e.target.checked
                ? [...prevSelectedIds, row.id]
                : prevSelectedIds.filter((selectedId) => selectedId !== row.id)
        );
    }

    // const [User, setUser] = useState([
    //     { value: 'select', label: '-Select-' }
    // ]);
    const [priorityAllData, setPriorityAllData] = useState([
        { value: 'select', label: '-Select-' }
    ]);
    const [taskStatusAllData, setTaskStatusAllData] = useState([
        { value: 'select', label: '-Select-' }
    ]);

    // const TaskData = async () => {
    //     setIsLoading(true)
    //     PrivateAxios.get("task/all-task").then((res) => {
    //         setTableData(res.data.data);
    //         setData(res.data.data)
    //         setInputSearch(res.data.data)
    //         setIsLoading(false)
    //     }).catch((err) => {
    //         setIsLoading(false)
    //         if (err.response.data == 401) {
    //             Logout();
    //         }
    //     })
    // }
    // for task details modal
    const [showScTaskDetails, setShowScTaskDetails] = useState(false);
    const [showScTaskView, setShowScTaskView] = useState('');
    const handleCloseScTaskDetails = () => {
        setShowScTaskDetails(false);
        setShowScTaskView('')
    }
    const handleShowScTaskDetails = (data) => {
        setShowScTaskDetails(true);
        setShowScTaskView(data)
    }
    // for attachment list action

    // for attachment list action end
    // for Doer selection    
    const [showDoer, setShowDoer] = useState(false);
    const handleCloseDoer = () => setShowDoer(false);
    const handleShowDoer = () => setShowDoer(true);
    const [planeDate, setPlaneDate] = useState('')
    //Task Reopen modal
    const [taskReOpenShow, setTaskReOpenShow] = useState(false);
    const [taskReOpenData, setTaskReOpenData] = useState('');
    const taskReOpenModalClose = () => {
        setTaskReOpenData('');
        setTaskReOpenShow(false);
        setPlaneDate('')
    }
    const taskReOpenModalShow = (data) => {
        setTaskReOpenData(data)
        setTaskReOpenShow(true);
        setPlaneDate(new Date(data.planned_date))
    }

    const filterDate = date => {
        return !isHoliday(date, holidayList) && !isWeekend(date, officeTimeList);
    };
    const submitReopen = (e) => {
        e.preventDefault();
        const payload = {
            id: taskReOpenData.id,
            name: taskReOpenData.name,
            assigned_by: taskReOpenData.assigned_by,
            assign_to: taskReOpenData.assign_to,
            task_priority_id: taskReOpenData.task_priority_id,
            message: taskReOpenData.message,
            notify_to: taskReOpenData.notify_to,
            auditor_id: taskReOpenData.auditor_id,
            reminder_mode_id: taskReOpenData.reminder_mode_id,
            reminder_frequency: taskReOpenData.reminder_frequency,
            file_is_require: taskReOpenData.file_is_require,
            before_reminder: taskReOpenData.before_reminder,
            attachment_file: taskReOpenData.attachment_file,
            newPlaneDate: planeDate
        }
        PrivateAxios.post('task/task-reopen', payload)
            .then((res) => {
                SuccessMessage(res.data.message)
                taskReOpenModalClose()
            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }

    //for datepicker
    const [task, setTask] = useState({
        startData: "",
        endDate: ""
    })

    const tableView = () => {
        setGrid(false)
    }
    const gridView = () => {
        setGrid(true);
    }

    const handleKeyUpSearch = (e) => {
        const filteredItems = data.filter((item) => {
            return item && item.name && item.name.toLowerCase().replace(/\s+/g, '').includes(e.target.value.toLowerCase().replace(/\s+/g, ''))
        }
        );
        setTaskData(filteredItems);
    }
    // exportExcel
    const generatePdf = () => {
        exportPDF(selectedColumns, taskData, "Task")
    }
    const generateExcel = () => {
        exportExcel(selectedColumns, taskData, "Task")
    }
    const generatePrint = () => {
        printTable(selectedColumns, taskData, "Task")
    }
    const clearAll = () => {
        setTaskData(data);
        setInputSearch(data)
        document.getElementById('contentId').classList.remove('show');
        setSearchData('');
        setSearchDataLabel('');
        setTask({ ...task, startData: "", endDate: "" })
    }

    //All Modal
    const [taskInfoShow, setTaskInfoShow] = useState(false);
    const [taskInfoValue, setTaskInfoValue] = useState('');
    const [taskCloseShow, setTaskCloseShow] = useState(false);
    const [taskCloseValue, setTaskCloseValue] = useState({
        "remark": "",
        "id": ""
    });
    const [highAlertShow, setHighAlertShow] = useState(false);
    const [highAlertValue, setHighAlertValue] = useState({
        "remark": "",
        "id": ""
    });
    const [switchDoerShow, setSwitchDoerShow] = useState(false);
    const [switchDoerValue, setSwitchDoerValue] = useState({
        "doer_id": "",
        "doer_label": "",
        "remark": "",
        "id": ""
    });

    const TaskInfoView = (e) => {
        setTaskInfoShow(true);
        setTaskInfoValue(e)
    }
    const TaskInfoHide = () => {
        setTaskInfoShow(false);
        setTaskInfoValue('');
    }
    const TaskCloseView = (e) => {
        setTaskCloseShow(true);
        setTaskCloseValue({ ...taskCloseValue, id: e });
    }
    const TaskCloseHide = () => {
        setTaskCloseShow(false);
        setTaskCloseValue({ ...taskCloseValue, id: "", remark: "" })
    }

    const HighAlertView = (e) => {
        setHighAlertShow(true)
        setHighAlertValue({ ...highAlertValue, id: e })
    }
    const HighAlertHide = () => {
        setHighAlertShow(false)
        setHighAlertValue({ ...highAlertValue, id: "", remark: "" })
    }

    const SwitchDoerView = (e) => {
        setSwitchDoerShow(true);
        setSwitchDoerValue({ ...switchDoerValue, id: e })

    }
    const SwitchDoerHide = () => {
        setSwitchDoerShow(false)
        setSwitchDoerValue({ ...switchDoerValue, id: '', remark: "", doer_id: "", doer_label: "" })
    }

    const CloseTask = (e) => {
        e.preventDefault();
        setIsLoading(true)
        PrivateAxios.post("task/task-close", taskCloseValue)
            .then((res) => {
                SuccessMessage(res.data.message);
                setIsLoading(false)
                TaskCloseHide();
                setTaskData(prevent => prevent.filter(data => data.id != taskCloseValue.id))
                setData(prevent => prevent.filter(data => data.id != taskCloseValue.id))
                setInputSearch(prevent => prevent.filter(data => data.id != taskCloseValue.id))
            }).catch((err) => {
                setIsLoading(false)
                console.log(err);
            })
    }
    const HighAlertTask = (e) => {
        setIsLoading(true)
        e.preventDefault();
        PrivateAxios.post("task/high-alert", highAlertValue)
            .then((res) => {
                SuccessMessage(res.data.message);
                HighAlertHide();
                setIsLoading(false)
                if (tab == 'todo') {
                    GetSystemToDoList()
                }
                if (tab == 'inprogress') {
                    GetSystemInprogressList()
                }
            }).catch((err) => {
                setIsLoading(false)
                console.log(err);
            })
    }
    const SwitchDoerTask = (e) => {
        setIsLoading(true);
        e.preventDefault();
        PrivateAxios.post("task/switch-task", switchDoerValue)
            .then((res) => {
                SuccessMessage(res.data.message);
                setIsLoading(false)
                if (tab == 'todo') {
                    GetSystemToDoList()
                }
                if (tab == 'inprogress') {
                    GetSystemInprogressList()
                }
                SwitchDoerHide();
            }).catch((err) => {
                setIsLoading(false);
                ErrorMessage(err.response.data.error)
            })

    }


    const [showChatWrap, setShowChatWrap] = useState(false);
    const [showChatValue, setShowChatVlaue] = useState('');

    const handleCloseChatWrap = () => {
        setShowChatWrap(false);
        setShowChatVlaue('')
    }
    const handleShowChatWrap = (data) => {
        setShowChatWrap(true);
        setShowChatVlaue(data)
    }



    //Tab specification
    const [tab, setTab] = useState('todo')

    const [taskData, setTaskData] = useState([]);

    //All api call tab specific
    const GetSystemToDoList = () => {
        setIsLoading(true);
        PrivateAxios.get('task/get-hod-todo')
            .then((res) => {
                setTaskData(res.data.data)
                setData(res.data.data)
                setInputSearch(res.data.data)
                setIsLoading(false);
            }).catch((err) => {
                setIsLoading(false);
            })
    }
    const GetSystemInprogressList = () => {
        setIsLoading(true);
        PrivateAxios.get('task/get-hod-inprogress')
            .then((res) => {
                setTaskData(res.data.data)
                setData(res.data.data)
                setInputSearch(res.data.data)
                setIsLoading(false);

            }).catch((err) => {
                setIsLoading(false);
            })
    }
    const GetSystemCompleteList = () => {
        setIsLoading(true);
        PrivateAxios.get('task/get-hod-complete')
            .then((res) => {
                setTaskData(res.data.data)
                setData(res.data.data)
                setInputSearch(res.data.data)
                setIsLoading(false);
            }).catch((err) => {
                setIsLoading(false);
            })
    }
    const GetSystemCloseList = () => {
        setIsLoading(true);
        PrivateAxios.get('task/get-hod-close')
            .then((res) => {
                setTaskData(res.data.data)
                setData(res.data.data)
                setInputSearch(res.data.data)
                setIsLoading(false);
            }).catch((err) => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        GetSystemToDoList();
        // if (tab == 'todo') {
        //     GetSystemToDoList()
        // }
        // if (tab == 'inprogress') {
        //     GetSystemInprogressList()
        // }
        // if (tab == 'complete') {
        //     GetSystemCompleteList()
        // }
        // if (tab == 'cancel') {
        //     GetSystemCloseList()
        // }
    }, [])

    function getUserNameById(userId) {
        const user = User.find(data => data.id == userId);
        return user ? user.name : "";
    }

    //=================Modal open==========================//
    const [modalShow, setModalShow] = React.useState(false);
    const [modalShowData, setModalShowData] = React.useState('');

    const selectedColumns = [
        {
            name: "",
            selector: (row) => row.name,
            sortable: true,
            width: "50px",
            omit: tab == "complete" || tab == "inprogress" || tab == "cancel" ? true : false,
            cell: (row) => (
                <label className="custom-checkbox">
                    <input
                        type="checkbox"
                        checked={deleteTaskId.includes(row.id) ? 1 : 0}
                        onChange={(e) => DeleteBulk(e, row)}
                    />
                    <span className="checkmark" />
                    <span className="text-">&nbsp;</span>
                </label>
            ),
        },
        {
            name: "Task Name",
            selector: (row) => row.name,
            sortable: true,
            reorder: true,
            width: "230px",
            cell: (row) => (
                <OverlayTrigger
                    placement="auto"
                    overlay={
                        <Tooltip>
                            {row.name}
                        </Tooltip>
                    }
                >
                    <button className="table-data-change-btn" onClick={() => handleShowScTaskDetails(row)}>
                        <div className="project-name text-truncate">
                            <h6 className="text-truncate">
                                {row.name}
                            </h6>
                        </div>
                    </button>
                </OverlayTrigger>
            ),
        },
        {
            name: "Assign By",
            selector: (row) => row.assigned_by ? getUserNameById(row.assigned_by) : "",
            sortable: true,
            reorder: true,
            width: "220px",
            cell: (row) => (
                <button className="table-data-change-btn">
                    {User && User.map((item) => (
                        item.id == row.assigned_by ?
                            < div className="profile-wrap" >
                                <div className="exp-avtar bg-exp-green">
                                    {item.user_photo != null ?
                                        < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                        <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                    }
                                </div>
                                <div className="ps-2 profile-name-wrap text-truncate">
                                    <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                                </div>
                            </div> : ""
                    ))
                    }
                </button>
            ),
        },
        {
            name: "Doer",
            selector: (row) => row.assign_to ? getUserNameById(row.assign_to) : "",
            sortable: true,
            reorder: true,
            width: "220px",
            cell: (row) => (
                <button className="table-data-change-btn">
                    {User && User.map((item) => (
                        item.id == row.assign_to ?
                            < div className="profile-wrap" >
                                <div className="exp-avtar bg-exp-yellow">
                                    {item.user_photo != null ?
                                        < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                        <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                    }
                                </div>
                                <div className="ps-2 profile-name-wrap text-truncate">
                                    <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                                </div>
                            </div> : ""
                    ))
                    }
                </button>
            ),
        },
        {
            name: "Priority",
            selector: (row) => row.delegation_status_id == 1 ? "Urgent" : row.delegation_status_id == 2 ? "High" : row.delegation_status_id == 3 ? "Normal" : row.delegation_status_id == 4 ? "Low" : "",
            sortable: true,
            width: "130px",
            cell: (row) => (
                <button className="table-data-change-btn">
                    <button className="table-data-change-btn">
                        {row.task_priority_id == 1 ?
                            <div className="priority-set urgent">
                                <i className="fas fa-flag mr-2"></i>
                                <span>Urgent</span>
                            </div> :
                            row.task_priority_id == 2 ? <div div className="priority-set high priority-list-item" >
                                <i className="fas fa-flag mr-2"></i>
                                <span>High</span>
                            </div> : row.task_priority_id == 3 ?
                                <div className="priority-set normal priority-list-item" >
                                    <i className="fas fa-flag mr-2"></i>
                                    <span>Normal</span>
                                </div> : row.task_priority_id == 4 ? <div className="priority-set low priority-list-item" >
                                    <i className="fas fa-flag mr-2"></i>
                                    <span>Low</span>
                                </div> : ""
                        }
                    </button>
                </button>
            ),
        },
        {
            name: "Delay (Hours)",
            selector: (row) => tab == "complete" ? calculateDelayInHoursEndTime(row.planned_date, row.completed_date) : tab == "cancel" ? "0.00" : calculateDelayInHoursEndTime(row.planned_date, new Date()),
            sortable: true,
            width: "130px",
            cell: (row) => (
                <div className="task-delay-hours text-exp-red">
                    {tab == "complete" ? calculateDelayInHoursEndTime(row.planned_date, row.completed_date) : tab == "cancel" ? "0.00" : calculateDelayInHoursEndTime(row.planned_date, new Date())} hrs
                </div>
            ),
        },
        {
            name: "Planned Date",
            selector: (row) => moment(row.planned_date).format('DD-MM-YYYY | hh:mm A'),
            sortable: true,
            reorder: true,
            width: "190px"
        },

        {
            name: "Complete Date",
            selector: (row) => row.completed_date ? moment(row.completed_date).format('DD-MM-YYYY | hh:mm A') : '',
            sortable: true,
            reorder: true,
            width: "190px",
            omit: tab == "complete" ? false : true,
        },
        {
            name: "Attached Link",
            selector: (row) => row.attachment_file_link,
            sortable: true,
            width: "130px",
            cell: (row) => (
                row.attachment_file_link ?
                    <button className="me-1 table-action-btn" onClick={() => { setModalShow(true); setModalShowData({ link: convertLinksNewLine(row.attachment_file_link), taskname: row.name }) }}>
                        <i className="fas fa-download"></i>
                    </button> : ""

            ),
        },
        {
            name: "Status",
            selector: (row) => row.delegation_status_id == 1 ? "To Do" : row.delegation_status_id == 2 ? "In-Progress" : row.delegation_status_id == 3 ? "Complete" : row.delegation_status_id == 4 ? "close" : "",
            sortable: true,
            width: "150px",
            cell: (row) => (
                <button className="table-data-change-btn">
                    {row.delegation_status_id == 1 ?
                        <div className="status-item todo status-list-item">
                            <i className="far fa-dot-circle me-2"></i>
                            <span>To Do</span>
                        </div> : row.delegation_status_id == 2 ?
                            <div div className="status-item inprogress">
                                <i className="fas fa-redo me-2"></i>
                                <span>In Progress</span>
                            </div> : row.delegation_status_id == 3 ?
                                <div className="status-item completed status-list-item">
                                    <i className="fas fa-check-circle me-2"></i>
                                    <span>Completed</span>
                                </div> : ""
                    }
                </button>
            ),
        },
        {
            name: "Remarks",
            selector: (row) => row.remarks,
            sortable: true,
            width: "230px",
            omit: tab == "complete" ? false : true,
            cell: (row) => (
                <div>
                    {row.remarks ? convertLinks(row.remarks) : ""}
                </div>
            )
        },
        {
            name: "Remarks Link",
            selector: (row) => row.done_file_link,
            sortable: true,
            width: "120px",
            omit: tab == "complete" ? false : true,
            cell: (row) => (
                row.done_file_link ?
                    <button className="me-1 table-action-btn m-auto" onClick={() => { setModalShow(true); setModalShowData({ link: convertLinksNewLine(row.done_file_link), taskname: row.name }) }}>
                        <i className="fas fa-download"></i>
                    </button> : ""

            ),
        },
        {
            name: "Action",
            headerStyle: { textAlign: "center" },
            width: "230px",
            selector: (row) => null,
            cell: (row) => (
                <div className="d-flex">

                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                View
                            </Tooltip>
                        }
                    >
                        <button className="me-1 table-action-btn" onClick={() => handleShowScTaskDetails(row)}>
                            <i className="fas fa-eye"></i>
                        </button>
                    </OverlayTrigger>
                    {tab == "todo" || tab == "inprogress" ?
                        <>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        Close/Stop
                                    </Tooltip>
                                }
                            >
                                <button className="me-1 table-action-btn" onClick={() => TaskCloseView(row.id)}>
                                    <i className="far fa-stop-circle"></i>
                                </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        High Alert
                                    </Tooltip>
                                }
                            >
                                <button className="me-1 table-action-btn" onClick={() => HighAlertView(row.id)}>
                                    <i className="fas fa-exclamation-circle text-exp-red"></i>
                                </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        Switch Doer
                                    </Tooltip>
                                }
                            >
                                <button className="me-1 table-action-btn" onClick={() => SwitchDoerView(row.id)}>
                                    <i className="fas fa-exchange-alt"></i>
                                </button>
                            </OverlayTrigger>
                        </> : ""
                    }
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                Message
                            </Tooltip>
                        }
                    >
                        <button className="me-1 table-action-btn" onClick={() => handleShowChatWrap(row)}>
                            <i className="fas fa-comment-dots"></i>
                        </button>
                    </OverlayTrigger>
                    {
                        tab == "complete" || tab == "cancel" ?
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        Re-Open
                                    </Tooltip>
                                }
                            >
                                <button className="me-1 table-action-btn" onClick={() => taskReOpenModalShow(row)}>
                                    <i class="fas fa-sync-alt"></i>
                                </button>
                            </OverlayTrigger> : ""
                    }

                </div>
            ),
        },
    ];

    //dark theme select
    const [isDarkTheme, setIsDarkTheme] = useState(false);

    // Check for the theme by looking at the body's class list
    useEffect(() => {
        const checkTheme = () => {
            setIsDarkTheme(document.body.classList.contains("dark-theme"));
        };

        // Initial check
        checkTheme();

        // MutationObserver to detect theme changes on body
        const observer = new MutationObserver(checkTheme);
        observer.observe(document.body, { attributes: true, attributeFilter: ["class"] });

        // Clean up observer on component unmount
        return () => observer.disconnect();
    }, []);
    //dark theme select end

    //============================Multi Search================================//
    //**************************************************************************/
    const [open, setOpen] = useState(false);
    const SearchData = (e) => {
        e.preventDefault();
        const filterData = data.filter((item) => {
            return Object.keys(searchData).every(key => {
                const searchValue = searchData[key];
                const itemValue = item[key];
                if (!searchValue) return true;
                if (itemValue === undefined || itemValue === null) return false;
                if (typeof itemValue === 'string') {
                    return itemValue.toLowerCase().replace(/\s+/g, '').includes(searchValue.toLowerCase().replace(/\s+/g, ''));
                }

                if (typeof itemValue === 'number') {
                    return itemValue.toString().replace(/\s+/g, '').includes(searchValue.toString().replace(/\s+/g, ''));
                }
                return false;
            }) && (
                    // Handle date range filtering
                    (!task.startData || new Date(item.planned_date) >= new Date(task.startData)) &&
                    (!task.endDate || new Date(item.planned_date) <= new Date(task.endDate))
                );
        })

        setTaskData(filterData);
        setInputSearch(filterData)
    }

    const ClearAllFilter = () => {
        setTaskData(data);
        setInputSearch(data)
        setSearchData({
            "name": "",
            "assigned_by": "",
            "assign_to": "",
            "task_priority_id": "",
        });
        setTask({
            startData: "",
            endDate: ""
        })

    }




    //=======================Bulk Switch Doer=======================
    const [switchDoerShowModel, setswitchDoerShowModel] = useState(false);
    const [DoerId, setDoerId] = useState('');
    const SwitchDoerCloseChecksheetModal = () => {
        setDoerId('')
        setswitchDoerShowModel(false);
    }
    const SwitchDoerOpenChecksheetModal = () => {
        setswitchDoerShowModel(true);
    }
    const SubmitBulkDoer = (e) => {
        e.preventDefault();
        if (!DoerId) {
            WaringMessage("Please filled doer name !")
            return;
        }
        const payload = {
            id: deleteTaskId.length > 0 ? JSON.stringify(deleteTaskId) : "",
            doer_id: DoerId
        }
        PrivateAxios.post("task/bulk-switch-task", payload)
            .then((res) => {
                GetSystemToDoList();
                SuccessMessage(res.data.message);
                SwitchDoerCloseChecksheetModal();
                setDeleteTaskId([]);
            }).catch((err) => {

            })

    }




    return (

        <React.Fragment>
            {isLoading && <Loader />}
            <HodTab />
            <div className='bg-white border-bottom mb-4'>
                <div className="d-flex flex-wrap px-4 py-2 justify-content-between align-items-center">
                    <ul className="nav nav-tabs gth-rounded-tab-2" id="systemControllerFilterTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" onClick={() => { setTab('todo'); GetSystemToDoList() }} data-bs-toggle="tab" data-bs-target="#systemTaskTodo" type="button" role="tab" aria-controls="systemTaskTodo" aria-selected="true" >
                                <span className="btn-todo"><i className="far fa-dot-circle me-1"></i>To Do</span>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" onClick={() => { setTab('inprogress'); GetSystemInprogressList() }} data-bs-toggle="tab" data-bs-target="#systemTaskInProgress" type="button" role="tab" aria-controls="systemTaskInProgress" aria-selected="false" >
                                <span className="btn-inprogress"><i className="fas fa-redo me-1"></i>In Progress</span>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" onClick={() => { setTab('complete'); GetSystemCompleteList() }} data-bs-toggle="tab" data-bs-target="#systemTaskCompleted" type="button" role="tab" aria-controls="messages" aria-selected="false">
                                <span className="btn-completed"><i className="fas fa-check-circle me-1"></i>Completed</span>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" onClick={() => { setTab('cancel'); GetSystemCloseList() }} data-bs-toggle="tab" data-bs-target="#systemTaskCancel" type="button" role="tab" aria-controls="messages" aria-selected="false">
                                <span className="btn-closeStop"><i className="far fa-stop-circle me-1"></i>Cancel</span>
                            </button>
                        </li>
                    </ul>
                    <div className='d-flex my-1 ms-auto'>
                        <button className="btn btn-exp-info btn-sm"
                            onClick={() => { ClearAllFilter(); setOpen(!open) }}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}
                        >
                            <i className="fas fa-filter me-2"></i>Filter
                        </button>
                        {/* <button className='btn btn-secondary ms-2 btn-sm' onClick={clearAll}>
                            <i className='fi fi-rr-clear-alt me-2'></i>Clear
                        </button> */}
                    </div>
                </div>

                <Collapse in={open} timeout={400}>
                    <div className="px-4 py-2" id="contentId">
                        <form id='search-form'>
                            <div className='card'>
                                <div className='card-header'>
                                    <h5 className='card-title font-weight-medium'>
                                        Search Task
                                    </h5>
                                </div>

                                <div className='card-body pb-1'>
                                    <div className='row'>
                                        <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                            <div className='form-group'>
                                                <label className='form-label'>Task Name</label>
                                                <input type='text' value={searchData.name} className='form-control' placeholder='Enter task name' onChange={(e) => setSearchData({ ...searchData, name: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                            <div className='form-group'>
                                                <label className='form-label'>Assign By</label>
                                                <div className='custom-select-wrap'>
                                                    <Select
                                                        name='selectDoer'
                                                        options={User}

                                                        getOptionLabel={(option) => option.name}
                                                        getOptionValue={(option) => option.id}
                                                        value={searchData.assigned_by ? User.find(data => data.id == searchData.assigned_by) : ""}
                                                        onChange={(e) => { setSearchData({ ...searchData, assigned_by: e.id }); }}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#ddddff',
                                                                primary: '#6161ff',
                                                            },
                                                        })}
                                                        styles={{
                                                            menu: (base) => ({
                                                                ...base,
                                                                backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                                color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                            }),
                                                            singleValue: (base) => ({
                                                                ...base,
                                                                color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                            }),
                                                            option: (base, state) => ({
                                                                ...base,
                                                                backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                                color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                            }),
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                            <div className='form-group'>
                                                <label className='form-label'>Select Doer</label>
                                                <div className='custom-select-wrap'>
                                                    <Select
                                                        name='selectDoer'
                                                        options={User}
                                                        getOptionLabel={(option) => option.name}
                                                        getOptionValue={(option) => option.id}
                                                        value={searchData.assign_to ? User.find(data => data.id == searchData.assign_to) : ""}
                                                        onChange={(e) => { setSearchData({ ...searchData, assign_to: e.id }); setSearchDataLabel({ ...searchDataLabel, assign_to: e.name }) }}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#ddddff',
                                                                primary: '#6161ff',
                                                            },
                                                        })}
                                                        styles={{
                                                            menu: (base) => ({
                                                                ...base,
                                                                backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                                color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                            }),
                                                            singleValue: (base) => ({
                                                                ...base,
                                                                color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                            }),
                                                            option: (base, state) => ({
                                                                ...base,
                                                                backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                                color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                            }),
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                            <div className='form-group'>
                                                <label className='form-label'>Select Priority</label>
                                                <div className='custom-select-wrap'>
                                                    <Select
                                                        name='selectPriority'
                                                        value={TaskPriority.find(data => data.id == searchData.task_priority_id)}
                                                        getOptionLabel={(option) => option.title}
                                                        getOptionValue={(option) => option.id}
                                                        options={TaskPriority}
                                                        onChange={(e) => { setSearchData({ ...searchData, task_priority_id: e.id }); setSearchDataLabel({ ...searchDataLabel, task_priority_id: e.label }) }}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#ddddff',
                                                                primary: '#6161ff',
                                                            },
                                                        })}
                                                        styles={{
                                                            menu: (base) => ({
                                                                ...base,
                                                                backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                                color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                            }),
                                                            singleValue: (base) => ({
                                                                ...base,
                                                                color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                            }),
                                                            option: (base, state) => ({
                                                                ...base,
                                                                backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                                color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                            }),
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                            <div className='form-group'>
                                                <label className='form-label'> Status</label>
                                                <div className='custom-select-wrap'>
                                                    <Select
                                                        name='selectStatus'
                                                        value={{ value: searchData.delegation_status_id, label: searchDataLabel.delegation_status_id }}
                                                        options={taskStatusAllData}
                                                        onChange={(e) => { setSearchData({ ...searchData, delegation_status_id: e.value }); setSearchDataLabel({ ...searchDataLabel, delegation_status_id: e.label }) }}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#ddddff',
                                                                primary: '#6161ff',
                                                            },
                                                        })}
                                                            styles={{
                                  menu: (base) => ({
                                    ...base,
                                    backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                  }),
                                  singleValue: (base) => ({
                                    ...base,
                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                  }),
                                  option: (base, state) => ({
                                    ...base,
                                    backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                  }),
                                }}
                                                    />
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                            <div className='form-group'>
                                                <label className='form-label'>From Date</label>
                                                <div className="exp-datepicker-cont">
                                                    <span className="cal-icon"><i className="fas fa-calendar-alt" /></span>
                                                    <DatePicker
                                                        selected={task.startData} onChange={(date) => setTask({ ...task, startData: date })}
                                                        dateFormat="dd/MM/YYYY"
                                                        placeholderText='Select Date'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                            <div className='form-group'>
                                                <label className='form-label'>To Date</label>
                                                <div className="exp-datepicker-cont">
                                                    <span className="cal-icon"><i className="fas fa-calendar-alt" /></span>
                                                    <DatePicker
                                                        selected={task.endDate} onChange={(date) => setTask({ ...task, endDate: date })}
                                                        dateFormat="dd/MM/YYYY"
                                                        placeholderText='Select Date'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <button className="btn btn-exp-green" onClick={SearchData}>Search</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Collapse>
            </div>
            <div className='gth-dashboard-v2-process-wrap'>
                <div className="tab-content pt-0">
                    <div className="tab-pane active" id="systemTaskTodo" role="tabpanel">
                        <div className='card'>
                            {/* <div className='card-header d-flex flex-wrap justify-content-between align-items-center'>
                                <h3 className="card-title">Task Tracker List</h3>
                                <div className="d-flex ms-auto align-items-center">

                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip >
                                                Table View
                                            </Tooltip>
                                        }
                                    >
                                        <button className={`icon-btn me-2 ${!grid ? 'icon-btn-active' : ''}`} onClick={tableView} >
                                            <i className="bi bi-table" />
                                        </button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip >
                                                Grid View
                                            </Tooltip>
                                        }
                                    >
                                        <button className={`icon-btn ${grid ? 'icon-btn-active' : ''}`} onClick={gridView}>
                                            <i className="bi bi-grid-3x3" />
                                        </button>
                                    </OverlayTrigger>

                                </div>
                            </div> */}
                            <div className='card-body p-0'>
                                {!inputSearch.length > 0 ?
                                    <div div className="w-100">
                                        <div className="card bg-warning-light mb-0">
                                            <div className="card-body">
                                                <div className="exp-no-data-found text-exp-red">
                                                    <img className="task-img mb-3" src={process.env.PUBLIC_URL + 'assets/images/search-no-record-found.webp'} alt="No task" />
                                                    <h6>No Record Found</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <>
                                        {
                                            // !grid ?
                                            <div className="table-view" >
                                                <div className='d-flex justify-content-between flex-wrap align-items-center pt-3 px-3'>
                                                    <div className='d-flex mb-3 align-items-center flex-wrap gap-3'>
                                                        <div className='table-button-group'>
                                                            <button className='btn table-export-btn' onClick={generateExcel} >
                                                                <i className="fas fa-file-csv"></i>
                                                            </button>
                                                            <button className='btn table-export-btn' onClick={generatePdf}>
                                                                <i className="fas fa-file-pdf"></i>
                                                            </button>
                                                            <button className='btn table-export-btn' onClick={generatePrint}>
                                                                <i className="fas fa-print"></i>
                                                            </button>
                                                        </div>
                                                        {
                                                            deleteTaskId.length > 0 ?
                                                                <button type="button" className="btn btn-exp-primary btn-sm" onClick={SwitchDoerOpenChecksheetModal}>
                                                                    <i className="me-2 fas fa-exchange-alt"></i>Bulk Switch Doer
                                                                </button> : ""
                                                        }

                                                    </div>

                                                    <div className='d-flex align-items-center mb-3'>
                                                        <label className='mr-2 mb-0'>Search: </label>
                                                        <input type='text' placeholder='Type here...' onChange={handleKeyUpSearch} className='form-control form-control-sm' />
                                                    </div>
                                                </div>

                                                <DataTable
                                                    columns={selectedColumns}
                                                    data={taskData}
                                                    pagination={[5, 10, 25, 50]}
                                                    theme="solarized"
                                                    striped
                                                    className='custom-table-wrap checklist-table-striped'
                                                //customStyles={customStyles}
                                                />
                                            </div>
                                            // <div className="grid-view" >
                                            //     <HotTable
                                            //         data={tableData}
                                            //         columns={gridColumns}
                                            //         filters={true}
                                            //         dropdownMenu={["filter_by_condition", "filter_operators", "---------", "filter_by_value", "filter_action_bar"]}
                                            //         width="100%"
                                            //         height="auto"
                                            //         colHeaders={true}
                                            //         rowHeaders={true}
                                            //         hiddenColumns={true}
                                            //         manualColumnResize={true}
                                            //         manualColumnMove={true}
                                            //         manualRowMove={true}
                                            //         autoWrapRow={true}
                                            //         autoWrapCol={true}
                                            //         contextMenu={true}
                                            //         multiColumnSorting={true}
                                            //         stretchH="all"
                                            //         licenseKey="non-commercial-and-evaluation"
                                            //     />
                                            // </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="systemTaskInProgress" role="tabpanel">
                        <div className='card'>
                            {/* <div className='card-header d-flex flex-wrap justify-content-between align-items-center'>
                                <h3 className="card-title">Task Tracker List</h3>
                                <div className="d-flex ms-auto align-items-center">

                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip >
                                                Table View
                                            </Tooltip>
                                        }
                                    >
                                        <button className={`icon-btn me-2 ${!grid ? 'icon-btn-active' : ''}`} onClick={tableView} >
                                            <i className="bi bi-table" />
                                        </button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip >
                                                Grid View
                                            </Tooltip>
                                        }
                                    >
                                        <button className={`icon-btn ${grid ? 'icon-btn-active' : ''}`} onClick={gridView}>
                                            <i className="bi bi-grid-3x3" />
                                        </button>
                                    </OverlayTrigger>

                                </div>
                            </div> */}
                            <div className='card-body p-0'>
                                {!inputSearch.length > 0 ?
                                    <div div className="w-100">
                                        <div className="card bg-warning-light mb-0">
                                            <div className="card-body">
                                                <div className="exp-no-data-found text-exp-red">
                                                    <img className="task-img mb-3" src={process.env.PUBLIC_URL + 'assets/images/search-no-record-found.webp'} alt="No task" />
                                                    <h6>No Record Found</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <>
                                        {/* {
                                            !grid ? */}
                                        <div className="table-view" >
                                            <div className='d-flex justify-content-between flex-wrap align-items-center pt-3 px-3'>
                                                <div className='d-flex mb-3 align-items-center flex-wrap gap-3'>
                                                    <div className='table-button-group'>
                                                        <button className='btn table-export-btn' onClick={generateExcel} >
                                                            <i className="fas fa-file-csv"></i>
                                                        </button>
                                                        <button className='btn table-export-btn' onClick={generatePdf}>
                                                            <i className="fas fa-file-pdf"></i>
                                                        </button>
                                                        <button className='btn table-export-btn' onClick={generatePrint}>
                                                            <i className="fas fa-print"></i>
                                                        </button>
                                                    </div>

                                                </div>
                                                <div className='d-flex align-items-center mb-3'>
                                                    <label className='mr-2 mb-0'>Search: </label>
                                                    <input type='text' placeholder='Type here...' onChange={handleKeyUpSearch} className='form-control form-control-sm' />
                                                </div>
                                            </div>

                                            <DataTable
                                                columns={selectedColumns}
                                                data={taskData}
                                                pagination={[5, 10, 25, 50]}
                                                theme="solarized"
                                                striped
                                                className='custom-table-wrap checklist-table-striped'
                                            //customStyles={customStyles}
                                            />
                                        </div>
                                        {/* :
                                                <div className="grid-view" >
                                                    <HotTable
                                                        data={tableData}
                                                        columns={gridColumns}
                                                        filters={true}
                                                        dropdownMenu={["filter_by_condition", "filter_operators", "---------", "filter_by_value", "filter_action_bar"]}
                                                        width="100%"
                                                        height="auto"
                                                        colHeaders={true}
                                                        rowHeaders={true}
                                                        hiddenColumns={true}
                                                        manualColumnResize={true}
                                                        manualColumnMove={true}
                                                        manualRowMove={true}
                                                        autoWrapRow={true}
                                                        autoWrapCol={true}
                                                        contextMenu={true}
                                                        multiColumnSorting={true}
                                                        stretchH="all"
                                                        licenseKey="non-commercial-and-evaluation"
                                                    />
                                                </div> */}
                                        {/* } */}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="systemTaskCompleted" role="tabpanel">
                        <div className='card'>
                            {/* <div className='card-header d-flex flex-wrap justify-content-between align-items-center'>
                                <h3 className="card-title">Task Tracker List</h3>
                                <div className="d-flex ms-auto align-items-center">

                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip >
                                                Table View
                                            </Tooltip>
                                        }
                                    >
                                        <button className={`icon-btn me-2 ${!grid ? 'icon-btn-active' : ''}`} onClick={tableView} >
                                            <i className="bi bi-table" />
                                        </button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip >
                                                Grid View
                                            </Tooltip>
                                        }
                                    >
                                        <button className={`icon-btn ${grid ? 'icon-btn-active' : ''}`} onClick={gridView}>
                                            <i className="bi bi-grid-3x3" />
                                        </button>
                                    </OverlayTrigger>

                                </div>
                            </div> */}
                            <div className='card-body p-0'>
                                {!inputSearch.length > 0 ?
                                    <div div className="w-100">
                                        <div className="card bg-warning-light mb-0">
                                            <div className="card-body">
                                                <div className="exp-no-data-found text-exp-red">
                                                    <img className="task-img mb-3" src={process.env.PUBLIC_URL + 'assets/images/search-no-record-found.webp'} alt="No task" />
                                                    <h6>No Record Found</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <>
                                        {
                                            // !grid ?
                                            <div className="table-view" >
                                                <div className='d-flex justify-content-between flex-wrap align-items-center pt-3 px-3'>
                                                    <div className='table-button-group mb-3'>
                                                        <button className='btn table-export-btn' onClick={generateExcel} >
                                                            <i className="fas fa-file-csv"></i>
                                                        </button>
                                                        <button className='btn table-export-btn' onClick={generatePdf}>
                                                            <i className="fas fa-file-pdf"></i>
                                                        </button>
                                                        <button className='btn table-export-btn' onClick={generatePrint}>
                                                            <i className="fas fa-print"></i>
                                                        </button>
                                                    </div>
                                                    <div className='d-flex align-items-center mb-3'>
                                                        <label className='mr-2 mb-0'>Search: </label>
                                                        <input type='text' placeholder='Type here...' onChange={handleKeyUpSearch} className='form-control form-control-sm' />
                                                    </div>
                                                </div>

                                                <DataTable
                                                    columns={selectedColumns}
                                                    data={taskData}
                                                    pagination={[5, 10, 25, 50]}
                                                    theme="solarized"
                                                    striped
                                                    className='custom-table-wrap checklist-table-striped'
                                                //customStyles={customStyles}
                                                />
                                            </div>
                                            // :
                                            // <div className="grid-view" >
                                            //     <HotTable
                                            //         data={tableData}
                                            //         columns={gridColumns}
                                            //         filters={true}
                                            //         dropdownMenu={["filter_by_condition", "filter_operators", "---------", "filter_by_value", "filter_action_bar"]}
                                            //         width="100%"
                                            //         height="auto"
                                            //         colHeaders={true}
                                            //         rowHeaders={true}
                                            //         hiddenColumns={true}
                                            //         manualColumnResize={true}
                                            //         manualColumnMove={true}
                                            //         manualRowMove={true}
                                            //         autoWrapRow={true}
                                            //         autoWrapCol={true}
                                            //         contextMenu={true}
                                            //         multiColumnSorting={true}
                                            //         stretchH="all"
                                            //         licenseKey="non-commercial-and-evaluation"
                                            //     />
                                            // </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="systemTaskCancel" role="tabpanel">
                        <div className='card'>
                            {/* <div className='card-header d-flex flex-wrap justify-content-between align-items-center'>
                                <h3 className="card-title">Task Tracker List</h3>
                                <div className="d-flex ms-auto align-items-center">

                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip >
                                                Table View
                                            </Tooltip>
                                        }
                                    >
                                        <button className={`icon-btn me-2 ${!grid ? 'icon-btn-active' : ''}`} onClick={tableView} >
                                            <i className="bi bi-table" />
                                        </button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip >
                                                Grid View
                                            </Tooltip>
                                        }
                                    >
                                        <button className={`icon-btn ${grid ? 'icon-btn-active' : ''}`} onClick={gridView}>
                                            <i className="bi bi-grid-3x3" />
                                        </button>
                                    </OverlayTrigger>

                                </div>
                            </div> */}
                            <div className='card-body p-0'>
                                {!inputSearch.length > 0 ?
                                    <div div className="w-100">
                                        <div className="card bg-warning-light mb-0">
                                            <div className="card-body">
                                                <div className="exp-no-data-found text-exp-red">
                                                    <img className="task-img mb-3" src={process.env.PUBLIC_URL + 'assets/images/search-no-record-found.webp'} alt="No task" />
                                                    <h6>No Record Found</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <>
                                        {
                                            // !grid ?
                                            <div className="table-view" >
                                                <div className='d-flex justify-content-between flex-wrap align-items-center pt-3'>
                                                    <div className='table-button-group mb-3'>
                                                        <button className='btn table-export-btn' onClick={generateExcel} >
                                                            <i className="fas fa-file-csv"></i>
                                                        </button>
                                                        <button className='btn table-export-btn' onClick={generatePdf}>
                                                            <i className="fas fa-file-pdf"></i>
                                                        </button>
                                                        <button className='btn table-export-btn' onClick={generatePrint}>
                                                            <i className="fas fa-print"></i>
                                                        </button>
                                                    </div>
                                                    <div className='d-flex align-items-center mb-3'>
                                                        <label className='mr-2 mb-0'>Search: </label>
                                                        <input type='text' placeholder='Type here...' onChange={handleKeyUpSearch} className='form-control form-control-sm' />
                                                    </div>
                                                </div>

                                                <DataTable
                                                    columns={selectedColumns}
                                                    data={taskData}
                                                    pagination={[5, 10, 25, 50]}
                                                    theme="solarized"
                                                    striped
                                                    className='custom-table-wrap checklist-table-striped'
                                                //customStyles={customStyles}
                                                />
                                            </div>
                                            // :
                                            // <div className="grid-view" >
                                            //     <HotTable
                                            //         data={tableData}
                                            //         columns={gridColumns}
                                            //         filters={true}
                                            //         dropdownMenu={["filter_by_condition", "filter_operators", "---------", "filter_by_value", "filter_action_bar"]}
                                            //         width="100%"
                                            //         height="auto"
                                            //         colHeaders={true}
                                            //         rowHeaders={true}
                                            //         hiddenColumns={true}
                                            //         manualColumnResize={true}
                                            //         manualColumnMove={true}
                                            //         manualRowMove={true}
                                            //         autoWrapRow={true}
                                            //         autoWrapCol={true}
                                            //         contextMenu={true}
                                            //         multiColumnSorting={true}
                                            //         stretchH="all"
                                            //         licenseKey="non-commercial-and-evaluation"
                                            //     />
                                            // </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Info modal */}
            <Modal size="lg" show={taskInfoShow} onHide={TaskInfoHide} backdrop="static" keyboard={false} centered >
                <Modal.Header closeButton>
                    <Modal.Title>{taskInfoValue && taskInfoValue.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex'>
                            <h6>Doer- </h6>
                            <p> {taskInfoValue && taskInfoValue.assignedToUser.name}</p>
                        </div>
                        <div className='d-flex'>
                            <h6>Assign By- </h6>
                            <p> {taskInfoValue && taskInfoValue.assignedByUser.name}</p>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex'>
                            <h6>Issue Date- </h6>
                            <p> {taskInfoValue && moment(taskInfoValue.created_at).format('DD-MM-YYYY')}</p>
                        </div>
                        <div className='d-flex'>
                            <h6>Planned Date- </h6>
                            <p> {taskInfoValue && moment(taskInfoValue.planned_date).format('DD-MM-YYYY')}</p>
                        </div>
                        <div className='d-flex'>
                            <h6>Complete Date- </h6>
                            <p> {taskInfoValue && taskInfoValue.completed_date && moment(taskInfoValue.completed_date).format('DD-MM-YYYY')}</p>
                        </div>
                    </div>

                    <h6>Description-</h6>
                    {taskInfoValue && convertLinks(taskInfoValue.message)}
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-secondary btn-sm' onClick={TaskInfoHide}>
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal>
            {/* end info modal */}

            {/* Task close modal */}
            <Modal show={taskCloseShow} onHide={TaskCloseHide} backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton className="gth-blue-light-bg">
                    <Modal.Title>Task Close</Modal.Title>
                </Modal.Header>
                <form onSubmit={CloseTask}>
                    <Modal.Body className="pb-1">
                        <div className='form-group'>
                            <label className='form-label'>Remark</label>
                            <textarea type='text' name='remark' rows="4" required className='form-control' onChange={(e) => setTaskCloseValue({ ...taskCloseValue, remark: e.target.value })} >{taskCloseValue.remark}</textarea>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='submit' className='btn btn-exp-green'>
                            Confirm
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* Task close modal end */}

            {/* High Alert modal */}
            <Modal show={highAlertShow} onHide={HighAlertHide} backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton className="gth-blue-light-bg">
                    <Modal.Title>High Alert</Modal.Title>
                </Modal.Header>
                <form onSubmit={HighAlertTask}>
                    <Modal.Body className="pb-1">
                        <div className='form-group'>
                            <label className='form-label'>Remark</label>
                            <textarea type='text' name='remark' rows="4" required className='form-control' onChange={(e) => setHighAlertValue({ ...highAlertValue, remark: e.target.value })} >{highAlertValue.remark}</textarea>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-secondary' onClick={HighAlertHide}>
                            Cancel
                        </button>
                        <button type='submit' className='btn btn-exp-green' >
                            Save
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* High Alert modal end */}

            {/* Switch Doer modal */}
            <Modal className='task-details-modal' show={switchDoerShow} onHide={SwitchDoerHide} backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Switch Doer</Modal.Title>
                </Modal.Header>
                <form onSubmit={SwitchDoerTask}>
                    <Modal.Body>
                        <div className='form-group'>
                            <label className='form-label'>Switch Doer</label>
                            <div className='custom-select-wrap'>
                                <Select
                                    name='selectDoer'
                                    options={User}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    value={switchDoerValue.doer_id ? User.find(data => data.id == switchDoerValue.doer_id) : ""}
                                    onChange={(e) => setSwitchDoerValue({ ...switchDoerValue, doer_id: e.id, doer_label: e.label })}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#ddddff',
                                            primary: '#6161ff',
                                        },
                                    })}
                                    styles={{
                                        menu: (base) => ({
                                            ...base,
                                            backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                            color: isDarkTheme ? "#e0e0e0" : "#000000",
                                        }),
                                        singleValue: (base) => ({
                                            ...base,
                                            color: isDarkTheme ? "#e0e0e0" : "#000000",
                                        }),
                                        option: (base, state) => ({
                                            ...base,
                                            backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                            color: isDarkTheme ? "#e0e0e0" : "#000000",
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className='form-group'>
                            <label className='form-label'>Remark</label>
                            <textarea type='text' name='remark' rows="4" required className='form-control' onChange={(e) => setSwitchDoerValue({ ...switchDoerValue, remark: e.target.value })}></textarea>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-secondary' onClick={SwitchDoerHide}>
                            Cancel
                        </button>
                        <button type='submit' className='btn btn-exp-green'>
                            Switch
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* Switch Doer modal end */}
            {/* chat box */}
            <TaskMessage
                show={showChatWrap}
                handleClose={handleCloseChatWrap}
                title="Select Doer"
                data={showChatValue}
                selectedValue={"Assign by Me"}
                getMyTask={''}
                getCreateTask={''}
            />

            {/* chat box end */}

            {/* View process details modal start */}
            <TaskTrackerDetails show={showScTaskDetails} handleModel={handleCloseScTaskDetails} TaskData={showScTaskView} where={"system"} handleMessageShow={handleShowChatWrap} />

            {/* View process details modal end */}
            {/* for doer */}
            <form>
                <Offcanvas className="assignee-canvas" show={showDoer} onHide={handleCloseDoer} placement="end">
                    <Offcanvas.Header closeButton className="d-block">
                        <Offcanvas.Title>Change Doer</Offcanvas.Title>
                        <div className="search-assignee">
                            <span className="search-assignee-icon">
                                <i className="fas fa-search"></i>
                            </span>
                            <input type="text" className="form-control" placeholder="Search or enter email.." />
                        </div>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div id="selectAssignee" className="assignee-wrapper assignee-wrapper-body">
                            <div className="user-list-wrap">
                                <div className="user-item">
                                    <div className="profile-wrap">
                                        <div className="exp-avtar">
                                            <i className="fas fa-user user-icon"></i>
                                        </div>
                                        <div className="ps-2 profile-name-wrap text-truncate">
                                            <h5 className="profile-name text-nowrap text-truncate">Sujit Paul</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="user-item">
                                    <div className="profile-wrap">
                                        <div className="exp-avtar bg-exp-green">
                                            <span>JP</span>
                                        </div>
                                        <div className="ps-2 profile-name-wrap text-truncate">
                                            <h5 className="profile-name text-nowrap text-truncate">Jhon Parker</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="user-item">
                                    <div className="profile-wrap">
                                        <div className="exp-avtar">
                                            <img className="prof-img" src={process.env.PUBLIC_URL + 'assets/images/users/1694671741_1517351722263.jpg'} alt="user" />
                                        </div>
                                        <div className="ps-2 profile-name-wrap text-truncate">
                                            <h5 className="profile-name text-nowrap text-truncate">Subhadeep Chowdhury</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="user-item">
                                    <div className="profile-wrap">
                                        <div className="exp-avtar">
                                            <i className="fas fa-user user-icon"></i>
                                        </div>
                                        <div className="ps-2 profile-name-wrap text-truncate">
                                            <h5 className="profile-name text-nowrap text-truncate">Sujit Paul</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="user-item">
                                    <div className="profile-wrap">
                                        <div className="exp-avtar bg-exp-blue">
                                            <span>MS</span>
                                        </div>
                                        <div className="ps-2 profile-name-wrap text-truncate">
                                            <h5 className="profile-name text-nowrap text-truncate">Moumita Shome</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="user-item">
                                    <div className="profile-wrap">
                                        <div className="exp-avtar">
                                            <img className="prof-img" src={process.env.PUBLIC_URL + 'assets/images/users/1694671598_002.png'} alt="user" />
                                        </div>
                                        <div className="ps-2 profile-name-wrap text-truncate">
                                            <h5 className="profile-name text-nowrap text-truncate">Gopal Mukherjee</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </form>
            {/* for doer end */}
            {/* Task ReOpen modal start */}
            <Modal id="task-reopen"
                show={taskReOpenShow}
                onHide={taskReOpenModalClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton className="gth-blue-light-bg">
                    <Modal.Title className="gth-modal-title">Re-open <span>{taskReOpenData && taskReOpenData.name}</span></Modal.Title>
                </Modal.Header>
                <form onSubmit={submitReopen}>
                    <Modal.Body className='pb-1'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Assign By : </label>
                                    <p className='mb-0'>
                                        {taskReOpenData && User && User.map((item) => (
                                            item.id == taskReOpenData.assigned_by ?
                                                < div className="profile-wrap" >
                                                    <div className="exp-avtar bg-exp-green">
                                                        {item.user_photo != null ?
                                                            < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                                            <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                                        }
                                                    </div>
                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                        <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                                                    </div>
                                                </div> : ""
                                        ))
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Assign To : </label>
                                    <p className='mb-0'>
                                        {taskReOpenData && User && User.map((item) => (
                                            item.id == taskReOpenData.assign_to ?
                                                < div className="profile-wrap" >
                                                    <div className="exp-avtar bg-exp-green">
                                                        {item.user_photo != null ?
                                                            < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                                            <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                                        }
                                                    </div>
                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                        <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                                                    </div>
                                                </div> : ""
                                        ))
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Planned Date : </label>
                                    <p className='mb-0'>{taskReOpenData && taskReOpenData.planned_date && moment(taskReOpenData.planned_date).format('DD-MM-YYYY')}</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Completed Date : </label>
                                    <p className='mb-0'>{taskReOpenData && taskReOpenData.completed_date && moment(taskReOpenData.completed_date).format('DD-MM-YYYY')}</p>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='form-group'>
                                    <label className='form-label'>Re-Planned Date <span className='text-exp-red'>*</span></label>
                                    <div className="exp-datepicker-cont">
                                        <span className="cal-icon"><i class="fas fa-calendar-alt" /></span>
                                        <DatePicker
                                            selected={planeDate} onChange={(date) => setPlaneDate(date)}
                                            showTimeSelect
                                            dateFormat="yyyy-MM-dd HH:mm:ss"
                                            timeFormat="HH:mm"
                                            filterDate={filterDate}
                                            placeholderText='Select Date'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-exp-green'>
                            Re-Open
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* Task ReOpen modal end */}


            <AutoModalOn show={modalShow} onHide={() => setModalShow(false)} header={"Link"} data={modalShowData} />

            {/* Switch Doer modal */}
            <SwitchDoerModal
                show={switchDoerShowModel}
                onHide={SwitchDoerCloseChecksheetModal}
                SubmitBulkDoer={SubmitBulkDoer}
                users={User}
                setDoerId={setDoerId}
                DoerId={DoerId}
            />
            {/* Switch Doer modal end */}
        </React.Fragment >

    )
}

export default HodTask;