import React, { useEffect, useState } from "react";
import PrivateRoutes from "./routes/PrivateRoutes";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import "@flaticon/flaticon-uicons/css/all/all.css";
import { Modal } from "react-bootstrap";
import ExpireSubscriptionModal from "./pages/CommonComponent/PlanExpireModal";
import { UserAuth } from "./pages/auth/Auth";

function App() {
  return (
    <>
      <PrivateRoutes />
      <ToastContainer />
      {/* Expire Subscription Modal Start*/}
     
      {/* Expire Subscription Modal end*/}
    </>
  );
}

export default App;
