import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { UserAuth } from '../auth/Auth';

function HodTab() {
    const location = useLocation();
    const { companyInfoGlobal } = UserAuth();
    return (
        <div className="gthh-controller-bar">
            <ul className="gth-controller-view-block">
                {companyInfoGlobal.tasktracker == 1 ?
                    <li>
                        <Link className={`gth-controller-view-item ${location.pathname === "/hod-task" ? 'active' : ''}`} to="/hod-task">
                            <i className="fas fa-file-alt" />
                            Task Tracker
                        </Link>
                    </li> :
                    <li><Link to="/upgrade-plan" className={`gth-controller-view-item ${location.pathname == "/upgrade-plan" ? 'active' : ""}`}><i className="fas fa-file-alt"></i>Task Tracker</Link></li>
                }
                {companyInfoGlobal.checksheet == 1 ?
                    <li>
                        <Link className={`gth-controller-view-item ${location.pathname === "/hod-checksheet" ? 'active' : ''}`} to="/hod-checksheet">
                            <i className="fas fa-file-invoice me-2" />
                            CheckSheet
                        </Link>
                    </li> : <li><Link to="/upgrade-plan" className={`gth-controller-view-item ${location.pathname == "/upgrade-plan" ? 'active' : ""}`}><i className="fas fa-file-invoice me-2" />CheckSheet</Link></li>
                }
                {/* {companyInfoGlobal.workflow == 1 ?
                    <li>
                        <Link className={`gth-controller-view-item ${location.pathname === "/system-flow" ? 'active' : ''}`} to="/system-flow">
                            <i className="fas fa-project-diagram me-2"></i>
                            Work Flow
                        </Link>
                    </li> : <li><Link to="/upgrade-plan" className={`gth-controller-view-item ${location.pathname == "/upgrade-plan" ? 'active' : ""}`}><i className="fas fa-project-diagram me-2"></i>Work Flow</Link></li>
                } */}
                {/* <li>
                    <Link className={`gth-controller-view-item ${location.pathname === "/system-flow-grid" ? 'active' : ''}`} to="/system-flow-grid">
                        <i className="fas fa-project-diagram me-2"></i>
                        Work Flow Grid
                    </Link>
                </li> */}
            </ul>
        </div>
    )
}

export default HodTab