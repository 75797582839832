import React, { memo } from 'react'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

function MemoizedAudioPlayer({ audioBlob }) {
    return (
        <AudioPlayer
            // ref={audioRef}
            crossOrigin=""
            src={audioBlob ? URL.createObjectURL(audioBlob) : ""}
            customAdditionalControls={[]}
            autoPlay={false}
            autoPlayAfterSrcChange={false}
        />
    )
}

export default memo(MemoizedAudioPlayer);


