import React, { useEffect, useState } from 'react'
import SOPBar from './SOPBar'
import { Link } from 'react-router-dom'

const SOPWorkFlow = () => {

    const [mainVideo, setMainVideo] = useState(() => {
        return JSON.parse(localStorage.getItem("mainVideo")) || {
            url: "https://www.youtube.com/embed/vN35oJENOwU?autoplay=1",
            title: "Default Video Title",
        };
    });

    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        localStorage.setItem("mainVideo", JSON.stringify(mainVideo));
    }, [mainVideo]);

    const handlePlay = () => {
        setIsPlaying(true);
    };

    const updateMainVideo = (videoUrl, videoTitle) => {
        setMainVideo({
            url: `${videoUrl}?autoplay=1`,
            title: videoTitle,
        });
        setIsPlaying(true);
    };

    return (
        <>
            <div className="gthh-controller-bar">
                <ul className="gth-controller-view-block">
                    <SOPBar />
                </ul>
            </div>
            <div className='p-4'>
                <div className='card mb-0 cal_height'>
                    <div className='card-body'>
                        <div className="row">
                            {/* Main Video Section */}
                            <div className="col-xl-8 col-md-12">
                                <div className={`videoBox position-relative ${isPlaying ? "video-active" : ""}`}>
                                    {/* Display Updated Title */}


                                    <div className="video_wrp">
                                        <button className="border-0 shadow-none" onClick={handlePlay}>
                                            <i className="fab fa-youtube playbtn"></i>
                                        </button>
                                    </div>

                                    {isPlaying && (
                                        <div className="video">
                                            <iframe
                                                src={mainVideo.url}
                                                frameBorder="0"
                                                allow="autoplay; fullscreen"
                                                allowFullScreen
                                                width="100%"
                                                height="100%"
                                            ></iframe>
                                        </div>
                                    )}
                                    <h3 className="fw-bold mt-3">{mainVideo.title}</h3>
                                </div>
                            </div>

                            {/* Featured Playlist */}
                            <div className="col-xl-4 col-md-12">
                                <div className=' playList'>
                                    <h4 className="fw-semibold mb-3 f-s-20">Feature Playlist</h4>
                                    <div className="row">
                                        {/* Video 1 */}
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-5 col-mb-6 col-sm-4">
                                                    <div className="side_video mb-2">
                                                        <div className="videoBox position-relative">
                                                            <div className="video_wrp">
                                                                <button
                                                                    className="border-0 shadow-none"
                                                                    onClick={() =>
                                                                        updateMainVideo(
                                                                            "https://www.youtube.com/embed/vN35oJENOwU?autoplay=1",
                                                                            "New BMS Add Task Video"
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="fab fa-youtube playbtn"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-7 col-mb-6 col-sm-8">
                                                    <h5 className="f-s-18 fw-bold">New BMS Add Task Video</h5>
                                                    <p className="text-info fw-semibold">1:23 Min</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Video 2 */}
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-5 col-mb-6 col-sm-4">
                                                    <div className="side_video mb-2">
                                                        <div className="videoBox position-relative">
                                                            <div className="video_wrp">
                                                                <button
                                                                    className="border-0 shadow-none"
                                                                    onClick={() =>
                                                                        updateMainVideo(
                                                                            "https://www.youtube.com/embed/NlLpVlhLQKI",
                                                                            "New BMS CheckSheet Video"
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="fab fa-youtube playbtn"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-7 col-mb-6 col-sm-8">
                                                    <h5 className="f-s-18 fw-bold">New BMS CheckSheet Video</h5>
                                                    <p className="text-info fw-semibold">1:23 Min</p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Video 3 */}
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-5 col-mb-6 col-sm-4">
                                                    <div className="side_video mb-2">
                                                        <div className="videoBox position-relative">
                                                            <div className="video_wrp">
                                                                <button
                                                                    className="border-0 shadow-none"
                                                                    onClick={() =>
                                                                        updateMainVideo(
                                                                            "https://www.youtube.com/embed/imLQUXjxGuk",
                                                                            "Overview"
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="fab fa-youtube playbtn"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-7 col-mb-6 col-sm-8">
                                                    <h5 className="f-s-18 fw-bold">Overview</h5>
                                                    <p className="text-info fw-semibold">2:15 Min</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SOPWorkFlow