import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

function UpgradeYourPlan() {
    const navigate = useNavigate();
    return (
        <>
            <div className='p-4'>
                <div className='mb-4 d-flex align-items-center justify-content-betweem'>
                    <Link className='text-dark back-btn me-3' onClick={() => navigate(-1)}><i className="fas fa-long-arrow-alt-left me-1" />Back</Link>
                </div>
                <div className='card upgrade_wrap'>
                    <div className='card-body'>
                        <div className='text-center'>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width={100}
                                height={100}
                                x={0}
                                y={0}
                                viewBox="0 0 512 512"
                                style={{ enableBackground: "new 0 0 512 512" }}
                                xmlSpace="preserve"
                                className=""
                            >
                                <g>
                                    <linearGradient
                                        id="a"
                                        x1="138.376"
                                        x2="351.35"
                                        y1="445.332"
                                        y2="76.45"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop offset={0} stopColor="#ff6100" />
                                        <stop offset={1} stopColor="#fdb82e" />
                                    </linearGradient>
                                    <path
                                        fill="url(#a)"
                                        fillRule="evenodd"
                                        d="M481 166.786c0 14.783-12.057 26.84-26.84 26.84-1.684 0-3.331-.164-4.931-.463l-28.692 153.505a44.647 44.647 0 0 0-.97-.013H92.432c-.324 0-.647.005-.969.012L62.771 193.163c-1.6.299-3.246.463-4.93.463-14.783 0-26.84-12.057-26.84-26.84s12.057-26.84 26.84-26.84 26.84 12.057 26.84 26.84a26.684 26.684 0 0 1-5.434 16.156l80.559 60.799 85.375-88.79c-11.678-4.399-20.026-15.692-20.026-28.877 0-16.989 13.856-30.846 30.846-30.846s30.845 13.856 30.845 30.846c0 13.185-8.348 24.478-20.027 28.877l85.375 88.789 80.559-60.799a26.684 26.684 0 0 1-5.434-16.156c0-14.783 12.057-26.84 26.84-26.84s26.84 12.057 26.84 26.84zm-61.433 209.869H92.432c-7.28 0-13.237 5.957-13.237 13.237v13.641c0 7.28 5.957 13.237 13.237 13.237h327.135c7.281 0 13.237-5.957 13.237-13.237v-13.641c0-7.281-5.957-13.237-13.237-13.237z"
                                        opacity={1}
                                        data-original="url(#a)"
                                        className=""
                                    />
                                </g>
                            </svg>

                            <h2 className='fw-bold'>Upgrade to access the full potential of Growthh</h2>
                            <p className='f-s-16'>
                                Unlock limitless content possibilities with Growthh. Upgrade now to exceed your credit limit and access valuable content that fuels creativity. Imagine a world where your imagination is the only limit: get more credit and unleash your full potential with our paid plans.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpgradeYourPlan