import React, { memo, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PrivateAxiosFile } from '../../../environment/AxiosInstance';
import { formatDate } from '../../../environment/DateFormat';
import { ErrorMessage, SuccessMessage, WaringMessage } from '../../../environment/ToastMessage';
import { UserAuth } from '../../auth/Auth';
import { AllUser, GetTaskPriority, GetTaskRemainder } from '../../../environment/GlobalApi';
import "../../global.css"
import "./TaskManage.css"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Modal } from 'react-bootstrap';
import { isHoliday, isWeekend } from '../../../environment/HolidayDateProtect';
import { TaskPriority } from '../../../utils/StaticData';
import { format } from 'date-fns';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import MemoizedAudioPlayer from '../MemoizedAudioPlayer';



function TaskTrackerAdd({ showTaskTrackerModal, handleCloseTaskTrackerModal, getAllTask }) {
    const [isCheckedReminder, setIsCheckedReminder] = useState(false);
    const [betweenDate, setBetweenDate] = useState('');
    const { User, priority, Logout, holidayList, auditUser, officeTimeList, branchName, userDetails, MatchPermission, department, MDExist } = UserAuth();
    const [error, setError] = useState({});
    const [taskData, setTaskData] = useState({
        "task_name": "",
        "assign_by": userDetails.id,
        "assign_to": "",
        "planned_date": "",
        "attachment_file_link": "",
        "file": "",
        "hourly": "",
        "description": "",
        "priority": "",
        "notification_to": 0,
        "auditor": 0,
        "remember_mode": "",
        "reminder_time": 0,
        "remainder_frequency": "",
        "is_require_file": 0,
    });

    const handleSetReminder = (e) => {
        if (betweenDate >= 1) {
            setIsCheckedReminder(e.target.checked);
        } else {
            WaringMessage("Exceed your plan date")
        }

    };
    const handleFIleRequired = (e) => {
        setTaskData({ ...taskData, is_require_file: e.target.checked });
    };

    //for datepicker
    const [issueDate, setIssueDate] = useState({
        startData: "",
    })

    const fileUpload = async (e) => {
        const file = e.target.files[0];
        let fileSize = file?.size;
        if (Number(fileSize) >= 20 * 1024 * 1024) {
            setError({ file: "This image in getter than 20MB" })
        } else {
            setTaskData({ ...taskData, file: e.target.files[0] });
            setError("")
        }
    }


    const getTaskData = async (e, data) => {
        if (e.target) {
            var name = e.target.name;
            if (name === "reminder_time") {
                if (e.target.value > betweenDate) {
                    WaringMessage("Exceed your plan date")
                } else {
                    setTaskData({ ...taskData, reminder_time: e.target.value })
                }

            } else {
                setTaskData({ ...taskData, [name]: e.target.value })
            }
        } else {
            setTaskData({ ...taskData, [data.name]: e.id })
        }
    }

    //======================Real time Audio Attach=================================//
    //******************************************************************************/
    const [recording, setRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);
    const mediaRecorderRef = useRef(null);
    const audioRef = useRef(null);  // Ref for the audio element
    const audioChunks = useRef([]);
    useEffect(() => {
        if (audioRef.current) {
            // Pause the audio when the src changes
            audioRef.current.audio.current.pause();
        }
    }, [audioBlob]);

    useEffect(() => {
        if (audioRef.current && audioRef.current.audio.current.paused) {
            audioRef.current.audio.current.play().catch((error) => {
                console.error("Audio playback error:", error);
            });
        }
    }, [taskData]);

    const startRecording = async () => {
        if (!navigator.mediaDevices || typeof navigator.mediaDevices.getUserMedia !== "function") {
            throw new Error("getUserMedia is not supported in this browser");
        }

        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

        const mimeType = MediaRecorder.isTypeSupported("audio/mp4")
            ? "audio/mp4"
            : MediaRecorder.isTypeSupported("audio/webm")
                ? "audio/webm"
                : "";

        if (!mimeType) {
            alert("Your browser does not support the required audio formats for recording.");
            return;
        }

        mediaRecorderRef.current = new MediaRecorder(stream, { mimeType });

        mediaRecorderRef.current.ondataavailable = (event) => {
            audioChunks.current.push(event.data);
        };

        mediaRecorderRef.current.onstop = () => {
            const audioBlob = new Blob(audioChunks.current, { type: mimeType });
            setAudioBlob(audioBlob);
            audioChunks.current = [];
        };

        mediaRecorderRef.current.start();
        setRecording(true);
    };

    const stopRecording = () => {
        mediaRecorderRef.current.stop();
        setRecording(false);
    };
    //======================Real time Audio Attach End=================================//
    //******************************************************************************/


    const diffDate = () => {
        let currentDate = new Date();
        let plannedDate = new Date(issueDate.startData ? formatDate(issueDate.startData.toString()) : "");
        let timeDifference = plannedDate - currentDate;
        let millisecondsInADay = 1000 * 60 * 60 * 24;
        let dayDifference = timeDifference / millisecondsInADay;
        setBetweenDate(Math.ceil(dayDifference));
    }
    useEffect(() => {
        diffDate()
    }, [issueDate.startData])


    const SubmitData = async (e) => {
        e.preventDefault();
        if (isCheckedReminder && taskData.reminder_time == 0) {
            return WaringMessage('Please select Day Before')
        }
        let formData = new FormData();
        formData.append('task_name', taskData.task_name)
        formData.append('assign_by', taskData.assign_by)
        formData.append('assign_to', taskData.assign_to)
        // formData.append('hourly', taskData.hourly)
        formData.append('planned_date', issueDate.startData)
        formData.append('description', taskData.description)
        formData.append('priority', taskData.priority)
        formData.append('notification_to', taskData.notification_to)
        formData.append('auditor', taskData.auditor)
        formData.append('attachment_file_link', taskData.attachment_file_link)

        const audioFile = new File([audioBlob], `audio-${Date.now()}.webm`, { type: 'audio/webm' });

        formData.append('audio', audioFile?.size > 5 ? audioFile : "")

        if (isCheckedReminder) {
            formData.append('reminder_mode', taskData.remember_mode)
            formData.append('reminder_time', taskData.reminder_time)
            formData.append('remainder_frequency', taskData.remainder_frequency)
        } else {
            formData.append('reminder_mode', "")
            formData.append('reminder_time', 0)
            formData.append('remainder_frequency', "")
        }
        formData.append('is_require_file', taskData.is_require_file)
        formData.append("files", taskData.file);

        PrivateAxiosFile.post("task/add", formData)
            .then((res) => {
                setError('')
                SuccessMessage(res.data.message);
                handleCloseTaskTrackerModal();
                clearAll();
                getAllTask();
            }).catch((err) => {
                console.log(err);
                var userError = {}
                if (!err.response?.data.errorMessage && err.response?.data.details) {
                    err.response?.data.details.map((err) => (
                        userError[err.context.label] = err.message
                    ))
                } else {
                    ErrorMessage(err.response?.data.errorMessage);
                }
                setError(userError)
            })

    }

    const filterDate = date => {
        return !isHoliday(date, holidayList) && !isWeekend(date, officeTimeList);
    };


    const clearAll = () => {
        setTaskData({
            "task_name": "",
            "assign_by": userDetails.id,
            "assign_to": "",
            "planned_date": "",
            "file": "",
            "hourly": "",
            "description": "",
            "priority": "",
            "attachment_file_link": "",
            "notification_to": 0,
            "auditor": 0,
            "remember_mode": "",
            "reminder_time": 0,
            "remainder_frequency": "",
            "is_require_file": 0,
        })
        setAudioBlob(null)
        setIssueDate({
            startData: "",
        })
    }

    // console.log(filterDate);
    //dark theme select
    const [isDarkTheme, setIsDarkTheme] = useState(false);

    // Check for the theme by looking at the body's class list
    useEffect(() => {
        const checkTheme = () => {
            setIsDarkTheme(document.body.classList.contains("dark-theme"));
        };

        // Initial check
        checkTheme();

        // MutationObserver to detect theme changes on body
        const observer = new MutationObserver(checkTheme);
        observer.observe(document.body, { attributes: true, attributeFilter: ["class"] });

        // Clean up observer on component unmount
        return () => observer.disconnect();
    }, []);
    //dark theme select end



    return (
        <Modal id="CreateNewTaskTracker" show={showTaskTrackerModal} onHide={() => { handleCloseTaskTrackerModal(); clearAll() }} backdrop="static" centered size="xl">
            <Modal.Header closeButton className="gth-blue-light-bg">
                <Modal.Title className="gth-modal-title">Create New Task Tracker</Modal.Title>
            </Modal.Header>
            <form action='' onSubmit={SubmitData} method='post'>
                <Modal.Body className="pb-0">
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                            <div className='form-group'>
                                <label className='form-label'>Task Name</label>
                                <input type='text' className="form-control" name='task_name' placeholder='Enter Task Name' onChange={getTaskData} />
                                {error.task_name ? <span className="field-invalid"><i class="fas fa-exclamation-triangle me-1"></i>{error.task_name}</span> : ""}
                            </div>

                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                            <div className='form-group'>
                                <label className='form-label'>Assigned By <span className='text-exp-red'>*</span></label>
                                <div className='custom-select-wrap'>
                                    <Select
                                        name='assign_by'
                                        // options={MatchPermission(['System Controller']) ? User : User.filter(data => data.id == taskData.assign_by)}

                                        options={MDExist ? User : MatchPermission(['System Controller']) ? userDetails.branch_position == "HOD" ? User.filter(item => item.departments_id == userDetails.departments_id || item.branch_position == "HOD") : User.filter(item => item.departments_id == userDetails.departments_id && item.branch_id == userDetails.branch_id) : User.filter(data => data.id == taskData.assign_by)}

                                        getOptionLabel={(option) => `${option.name}${option.branch_position == "HOD" ? `(${department.find(item => item.id == option.departments_id)?.title})(H)` : ""}`}
                                        getOptionValue={(option) => option.id}
                                        value={User.length > 0 ? User.find(data => data.id == taskData.assign_by) : ""}
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ddddff',
                                                primary: '#6161ff',
                                            },
                                        })}
                                        onChange={getTaskData}
                                        styles={{
                                            menu: (base) => ({
                                                ...base,
                                                backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                color: isDarkTheme ? "#e0e0e0" : "#000000",
                                            }),
                                            singleValue: (base) => ({
                                                ...base,
                                                color: isDarkTheme ? "#e0e0e0" : "#000000",
                                            }),
                                            option: (base, state) => ({
                                                ...base,
                                                backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                color: isDarkTheme ? "#e0e0e0" : "#000000",
                                            }),
                                        }}
                                    />
                                </div>
                                {error.assign_by ? <span className="field-invalid"><i class="fas fa-exclamation-triangle me-1"></i>{error.assign_by}</span> : ""}
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                            <div className='form-group'>
                                {console.log(userDetails)
                                }
                                <label className='form-label'>Assigned To <span className='text-exp-red'>*</span></label>
                                <div className='custom-select-wrap'>
                                    <Select
                                        name='assign_to'
                                        options={MDExist ? User : userDetails.branch_position == "HOD" ? User.filter(item => item.departments_id == userDetails.departments_id || item.branch_position == "HOD") :  User.filter(item => item.departments_id == userDetails.departments_id && item.branch_id == userDetails.branch_id)}
                                        getOptionLabel={(option) => `${option.name}${option.branch_position == "HOD" ? `(${department.find(item => item.id == option.departments_id)?.title})(H)` : ""}`}
                                        getOptionValue={(option) => option.id}
                                        required
                                        isMulti
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ddddff',
                                                primary: '#6161ff',
                                            },
                                        })}
                                        onChange={(data) => {
                                            const selectedValues = data ? data.map(option => option.id) : [];
                                            const MultiDoers = JSON.stringify(selectedValues);
                                            setTaskData({ ...taskData, assign_to: MultiDoers })
                                        }
                                        }
                                        styles={{
                                            menu: (base) => ({
                                                ...base,
                                                backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                color: isDarkTheme ? "#e0e0e0" : "#000000",
                                            }),
                                            singleValue: (base) => ({
                                                ...base,
                                                color: isDarkTheme ? "#e0e0e0" : "#000000",
                                            }),
                                            option: (base, state) => ({
                                                ...base,
                                                backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                color: isDarkTheme ? "#e0e0e0" : "#000000",
                                            }),
                                        }}
                                    />
                                </div>
                                {error.assign_to ? <span className="field-invalid"><i class="fas fa-exclamation-triangle me-1"></i>{error.assign_to}</span> : ""}
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                            <div className='form-group'>
                                <label className='form-label'>Planned Date <span className='text-exp-red'>*</span></label>
                                <div className="exp-datepicker-cont with-time">
                                    <span className="cal-icon"><i class="fas fa-calendar-alt" /></span>
                                    <DatePicker
                                        selected={issueDate.startData}
                                        onChange={(date) => { setIssueDate({ ...issueDate, startData: date }); setIsCheckedReminder(false); setTaskData({ ...taskData, reminder_time: '' }) }}
                                        //minDate={new Date()}
                                        minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 20))} // Allow dates from the previous year
                                        showTimeSelect
                                        dateFormat="yyyy-MM-dd HH:mm:ss"
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        filterDate={filterDate}
                                        // filterTime={(time) => {
                                        //     const hour = time.getHours();
                                        //     return hour >= 7 && hour <= 20;
                                        // }}
                                        placeholderText='Select Date'
                                        //showMonthDropdown
                                        //showYearDropdown
                                        dropdownMode="select" // Use select dropdown instead of scroll
                                    />
                                </div>
                                {error.planned_date ? <span className="field-invalid"><i class="fas fa-exclamation-triangle me-1"></i>{error.planned_date}</span> : ""}
                            </div>
                        </div>
                        {/* <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                            <div className='form-group'>
                                <label className='form-label'>Hours<span className='text-exp-red'>*</span></label>
                                <input type='number' className="form-control" name='hourly' placeholder='Enter hour' onChange={getTaskData} required />
                            </div>
                            {error.hourly ? <span className="field-invalid"><i class="fas fa-exclamation-triangle me-1"></i>{error.hourly}</span> : ""}
                        </div>  */}
                        <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                            <div className='form-group'>
                                <label className='form-label'>Task Priority <span className='text-exp-red'>*</span></label>
                                <div className='custom-select-wrap'>
                                    <Select
                                        name='priority'
                                        options={TaskPriority}
                                        getOptionLabel={(option) => option.title}
                                        getOptionValue={(option) => option.id}
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ddddff',
                                                primary: '#6161ff',
                                            },
                                        })}
                                        onChange={getTaskData}
                                        styles={{
                                            menu: (base) => ({
                                                ...base,
                                                backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                color: isDarkTheme ? "#e0e0e0" : "#000000",
                                            }),
                                            singleValue: (base) => ({
                                                ...base,
                                                color: isDarkTheme ? "#e0e0e0" : "#000000",
                                            }),
                                            option: (base, state) => ({
                                                ...base,
                                                backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                color: isDarkTheme ? "#e0e0e0" : "#000000",
                                            }),
                                        }}
                                    />
                                </div>
                                {error.priority ? <span className="field-invalid"><i class="fas fa-exclamation-triangle me-1"></i>{error.priority}</span> : ""}
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                            <div className='form-group'>
                                <label className='form-label'>Upload File (if any)</label>
                                <input type='file' className='form-control' placeholder='Enter Task Name' accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx , .doc" onChange={fileUpload} />
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                            <div className='form-group'>
                                <label className='form-label'>Record Audio</label>
                                <div className='audio_record_main_wrap'>
                                    <div className='audio_record_button'>
                                        <button type='button' className='record_button' onClick={!recording ? startRecording : stopRecording} title={!recording ? 'Start recording' : 'Stop recording'}>
                                            {!recording ? <span><i class="fas fa-microphone"></i></span> :
                                                <span><i class="fas fa-stop-circle"></i></span>
                                            }
                                        </button>
                                    </div>
                                    <div className='audio_record_player'>
                                        <MemoizedAudioPlayer audioBlob={audioBlob} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-8 col-md-6 col-sm-12 col-12'>
                            <div className='form-group'>
                                <label className='form-label'>Attach file link</label>
                                <textarea className='form-control' name='attachment_file_link' placeholder='Attached Link' cols={14} onChange={getTaskData} />
                                <p className='mb-0 mt-1 f-s-12'>
                                    <div className='d-flex gap-2 mb-1'>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            version="1.1"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            width={20}
                                            height={20}
                                            x={0}
                                            y={0}
                                            viewBox="0 0 511.999 511.999"
                                            xmlSpace="preserve"
                                            className=""
                                        >
                                            <g>
                                                <path
                                                    fill="#0066dd"
                                                    d="m38.563 418.862 22.51 39.042c4.677 8.219 11.41 14.682 19.319 19.388l80.744-57.248.147-82.19-80.577-36.303L0 337.565c-.016 9.09 2.313 18.185 6.991 26.404z"
                                                    opacity={1}
                                                    data-original="#0066dd"
                                                />
                                                <path
                                                    fill="#00ad3c"
                                                    d="m256.293 173.808 4.212-107.064-84.604-32.663c-7.926 4.678-14.682 11.117-19.389 19.319L7.085 311.186C2.379 319.389.016 328.475 0 337.565l161.283.288z"
                                                    opacity={1}
                                                    data-original="#00ad3c"
                                                />
                                                <path
                                                    fill="#00831e"
                                                    d="m256.293 173.808 77.503-41.694 3.387-97.745c-7.909-4.706-16.996-7.068-26.379-7.085l-108.499-.194c-9.384-.017-18.479 2.606-26.405 6.991z"
                                                    opacity={1}
                                                    data-original="#00831e"
                                                />
                                                <path
                                                    fill="#0084ff"
                                                    d="m350.716 338.192-189.434-.338-80.89 139.438c7.909 4.706 16.996 7.068 26.379 7.085l297.933.532c9.384.017 18.479-2.606 26.405-6.991l.314-93.66z"
                                                    opacity={1}
                                                    data-original="#0084ff"
                                                />
                                                <path
                                                    fill="#ff4131"
                                                    d="M431.109 477.919c7.926-4.678 14.682-11.117 19.388-19.319l9.413-16.111 45.005-77.629c4.706-8.202 7.069-17.288 7.085-26.379l-93.221-49.051-67.768 48.764z"
                                                    opacity={1}
                                                    data-original="#ff4131"
                                                />
                                                <path
                                                    fill="#ffba00"
                                                    d="m430.756 182.917-74.253-129.16c-4.677-8.22-11.41-14.683-19.32-19.389l-80.891 139.439 94.423 164.385 160.99.288c.016-9.09-2.314-18.185-6.991-26.405z"
                                                    opacity={1}
                                                    data-original="#ffba00"
                                                />
                                            </g>
                                        </svg>

                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            version="1.1"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            width={20}
                                            height={20}
                                            x={0}
                                            y={0}
                                            viewBox="0 0 447.232 447.232"
                                            xmlSpace="preserve"
                                            className=""
                                        >
                                            <g>
                                                <path
                                                    d="M207.527 251.676 92.903 177.758a10.272 10.272 0 0 0-12.007.63L3.833 240.403c-5.458 4.392-5.015 12.839.873 16.636l114.624 73.918a10.272 10.272 0 0 0 12.007-.63l77.063-62.014c5.458-4.393 5.015-12.84-.873-16.637zM238.833 268.312l77.063 62.014a10.272 10.272 0 0 0 12.007.63l114.624-73.918c5.888-3.797 6.331-12.244.873-16.636l-77.063-62.014a10.272 10.272 0 0 0-12.007-.63l-114.624 73.918c-5.887 3.797-6.331 12.244-.873 16.636zM208.4 74.196l-77.063-62.014a10.272 10.272 0 0 0-12.007-.63L4.706 85.47c-5.888 3.797-6.331 12.244-.873 16.636l77.063 62.014a10.272 10.272 0 0 0 12.007.63l114.624-73.918c5.888-3.797 6.331-12.244.873-16.636zM442.527 85.47 327.903 11.552a10.272 10.272 0 0 0-12.007.63l-77.063 62.014c-5.458 4.392-5.015 12.839.873 16.636l114.625 73.918a10.272 10.272 0 0 0 12.007-.63l77.063-62.014c5.457-4.393 5.014-12.84-.874-16.636z"
                                                    style={{}}
                                                    fill="#1587ea"
                                                    data-original="#1587ea"
                                                    className=""
                                                />
                                                <path
                                                    d="m218 279.2-86.3 68.841a9.319 9.319 0 0 1-10.861.547L99.568 334.87c-6.201-3.999-14.368.453-14.368 7.831v7.416a9.32 9.32 0 0 0 4.488 7.969l128.481 77.884a9.317 9.317 0 0 0 9.661 0l128.481-77.884a9.319 9.319 0 0 0 4.488-7.969v-6.619c0-7.378-8.168-11.83-14.368-7.831l-20.024 12.913a9.318 9.318 0 0 1-10.876-.559l-85.893-68.809A9.32 9.32 0 0 0 218 279.2z"
                                                    style={{}}
                                                    fill="#1587ea"
                                                    data-original="#1587ea"
                                                    className=""
                                                />
                                            </g>
                                        </svg>

                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            version="1.1"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            width={20}
                                            height={20}
                                            x={0}
                                            y={0}
                                            viewBox="0 0 512 512"
                                            style={{ enableBackground: "new 0 0 512 512" }}
                                            xmlSpace="preserve"
                                            className="hovered-paths"
                                        >
                                            <g>
                                                <path
                                                    d="M191.309 122.22a102.052 102.052 0 0 0-19.709-1.92c-51.554 0-94.297 38.443-101.066 88.169C30.558 215.188 0 250.043 0 291.9c0 45.099 35.474 82.063 79.983 84.469A104.902 104.902 0 0 1 77 351.5c0-24.023 8.352-47.523 23.516-66.171 9.965-12.254 22.582-22.082 36.705-28.775 8.955-60.228 57.84-107.594 118.78-114.246l30-27.441-30-27.441c-24.612 4.462-47.2 16.544-64.692 34.794z"
                                                    style={{}}
                                                    fill="#007caa"
                                                    data-original="#007caa"
                                                    className=""
                                                />
                                                <path
                                                    d="M165.75 276.5c0 .589.005 1.179.015 1.77C132.188 285.708 107 315.718 107 351.5c0 41.355 33.645 75 75 75h74l60-126.979-60-126.979c-50.938 7.192-90.25 51.067-90.25 103.958z"
                                                    style={{}}
                                                    fill="#007caa"
                                                    data-original="#007caa"
                                                    className=""
                                                />
                                                <path
                                                    d="M450.959 277.802C444.343 234.653 406.971 201.5 362 201.5c-5.471 0-10.917.497-16.281 1.483-19.695-20.116-46.521-31.483-74.969-31.483-5.006 0-9.929.36-14.75 1.041V426.5h181c41.355 0 75-33.645 75-75 0-36.587-26.331-67.139-61.041-73.698zM355.844 171.659a119.715 119.715 0 0 1 37.375 3.988C380.139 123.906 333.198 85.5 277.45 85.5c-7.267 0-14.44.655-21.45 1.926v54.881c4.846-.529 9.766-.808 14.75-.808 31.441.001 61.14 10.615 85.094 30.16z"
                                                    style={{}}
                                                    fill="#015e82"
                                                    data-original="#015e82"
                                                    className="hovered-path"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                    <i className='fas fa-info-circle me-1 text-info'></i>
                                    To quickly add multiple file links, paste a link and press Enter on your keyboard to add additional file links.
                                </p>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label className='form-label'>Description</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                        toolbar: {
                                            items: [
                                                'heading', '|',
                                                'bold', 'italic', 'link', '|',
                                                'bulletedList', 'numberedList', '|',
                                                'blockQuote', '|',
                                                'undo', 'redo',
                                            ]
                                        }
                                    }}

                                    onChange={(event, editor) => setTaskData({ ...taskData, description: editor.getData() })}
                                />
                                {/* <textarea className='form-control' name='description' placeholder='Enter Description' rows={3} onChange={getTaskData}></textarea> */}
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                            <div className='form-group'>
                                <label className='form-label'>Notify To (if not done)</label>
                                <div className='custom-select-wrap'>
                                    <Select
                                        name='notification_to'
                                        options={User}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ddddff',
                                                primary: '#6161ff',
                                            },
                                        })}
                                        onChange={getTaskData}
                                        styles={{
                                            menu: (base) => ({
                                                ...base,
                                                backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                color: isDarkTheme ? "#e0e0e0" : "#000000",
                                            }),
                                            singleValue: (base) => ({
                                                ...base,
                                                color: isDarkTheme ? "#e0e0e0" : "#000000",
                                            }),
                                            option: (base, state) => ({
                                                ...base,
                                                backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                color: isDarkTheme ? "#e0e0e0" : "#000000",
                                            }),
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                            <div className='form-group'>
                                <label className='form-label'>Auditor</label>
                                <div className='custom-select-wrap'>
                                    <Select
                                        name='auditor'
                                        options={auditUser}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ddddff',
                                                primary: '#6161ff',
                                            },
                                        })}
                                        onChange={getTaskData}
                                        styles={{
                                            menu: (base) => ({
                                                ...base,
                                                backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                color: isDarkTheme ? "#e0e0e0" : "#000000",
                                            }),
                                            singleValue: (base) => ({
                                                ...base,
                                                color: isDarkTheme ? "#e0e0e0" : "#000000",
                                            }),
                                            option: (base, state) => ({
                                                ...base,
                                                backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                color: isDarkTheme ? "#e0e0e0" : "#000000",
                                            }),
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label className="custom-switch" >
                                    <span className='switch-name'>Set Reminder</span>
                                    <input type="checkbox" checked={isCheckedReminder} onChange={handleSetReminder} />
                                    <div className="switch-slider switch-round" />
                                </label>
                            </div>
                        </div>
                        {isCheckedReminder && (
                            <div className='col-12 reminder-wrap'>
                                <div className='row'>
                                    <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                        <div className='form-group'>
                                            <label className='form-label'>Reminder Mode</label>
                                            <div className='custom-select-wrap'>
                                                <input type='text' value='Daily' className='form-control' readOnly />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                        <div className='form-group'>
                                            <label className='form-label'>Day Before </label>
                                            <input type='number' name='reminder_time' value={taskData.reminder_time} className='form-control' min={0} max={betweenDate} placeholder='Day Before' onChange={getTaskData} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className='col-12'>
                            <div className='form-group'>
                                <label className="custom-switch" >
                                    <span className='switch-name'>Attachment is required for this task</span>
                                    <input type="checkbox" name='is_require_file' onChange={handleFIleRequired} />
                                    <div className="switch-slider switch-round" />
                                </label>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-end'>

                    <button type="submit" class="btn btn-exp-green">Create</button>

                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default memo(TaskTrackerAdd);