import React from 'react'
import { Outlet } from 'react-router-dom';
import UnauthorizedPage from './UnauthorizedPage';

function PermissionRoute({ allPermission, name, children }) {
   
    
    if (!name) {
        return <UnauthorizedPage />;
    }
    // if (name && !allPermission.includes(name)) {
    //     return <UnauthorizedPage />;
    // }
    return children;
}

export default PermissionRoute
