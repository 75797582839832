export const TatType = [
    { value: '', label: '-Select-', isDisabled: true },
    { value: 'daily', label: 'Daily' },
    { value: 'hourly', label: 'Hourly' },
    { value: 'before-estimated', label: 'Before Estimated' },
]
export const TaskPriority = [
    { 'id': 1, "title": "Very High" },
    { "id": 2, "title": "High" },
    { "id": 3, "title": "Medium" },
    { "id": 4, "title": "Low" },
]
export const TaskMode = [
    { 'id': 1, "title": "Yearly" },
    { 'id': 2, "title": "Halfyearly" },
    { 'id': 3, "title": "Quarterly" },
    { 'id': 4, "title": "Last Date of Every Month" },
    { 'id': 5, "title": "Monthly" },
    { 'id': 6, "title": "Weekly" },
    { 'id': 7, "title": "Daily" },
    { 'id': 8, "title": "Custom" },
    // { 'id': 8, "title": "Biweekly(15 days)" },
    // { 'id': 9, "title": "Twice a Week(3 day)" },
    // { 'id': 10, "title": "Thrice a Week( 2 day)" }
]
export const TaskStatus = [
    { 'id': 1, "title": "Open" },
    { 'id': 2, "title": "In Progress" },
    { 'id': 3, "title": "Done" },
    { 'id': 4, "title": "Closed" }
]