import React from 'react'
import { Link, useLocation } from 'react-router-dom'

function SOPBar() {
    const location = useLocation();
    return (
        <React.Fragment>
            <li>
                <Link to="/sop-task" className={`gth-controller-view-item ${location.pathname == "/sop-task" ? 'active' : ""}`}><i className="fas fa-file-alt"></i>Task Tracker / One Time Task</Link>
            </li>
            <li>
                <Link to="/sop-checksheet" className={`gth-controller-view-item ${location.pathname == "/sop-checksheet" ? 'active' : ""}`}><i className="fas fa-file-invoice me-2"></i>Checksheet / Repeated Task</Link>
            </li>
            <li>
                <Link to="/sop-work-flow" className={`gth-controller-view-item ${location.pathname == "/sop-work-flow" ? 'active' : ""}`}><i className="fas fa-project-diagram"></i>Work Flow Task</Link>
            </li>
             <li>
                <Link to="/my-sop" className={`gth-controller-view-item ${location.pathname == "/my-sop" ? 'active' : ""}`}><i className="fas fa-university"></i>My SOP</Link>
            </li>
           
        </React.Fragment>
    )
}

export default SOPBar