import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { Dropdown, Modal, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UserAuth } from '../../auth/Auth';
import { formatDate } from '../../../environment/DateFormat';
import { PrivateAxios, PrivateAxiosFile, url } from '../../../environment/AxiosInstance';
import { ErrorMessage, SuccessMessage, WaringMessage } from '../../../environment/ToastMessage';
import moment from 'moment';
import { convertLinks, convertLinksNewLine } from '../../../environment/ConvertLinks';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import AutoHeightTextarea from '../../CommonComponent/AutoHeightTextarea';
import TaskMessage from '../../../component/TaskMessage';

function TaskTrackerDetails({ show, handleModel, TaskData, handleMessageShow, taskDoneModalShow, where }) {
    const [startDate, setStartDate] = useState('');

    const { User, priority, status, Logout } = UserAuth();

    const [users, setUsers] = useState([])
    const [doers, setDoers] = useState([])
    const [notify, setNotify] = useState([])

    const [assignee, setAssignee] = useState('')
    const [userDoers, setUserDoers] = useState('')
    const [description, setDescription] = useState('')
    const [attachFile, setAttachFile] = useState('')
    const [name, setName] = useState('')
    const [NotifyUser, setNotifyUser] = useState('')

    const datePickerRef = useRef();
    const priorityRef = useRef();
    const statusRef = useRef();

    const [update, setUpdate] = useState({
        "id": "",
        'name': '',
        'assigned_by': '',
        'assign_to': '',
        'planned_date': '',
        'task_priority_id': '',
        'message': '',
        'notify_to': '',
        'auditor_id': '',
        'reminder_mode_id': '',
        'reminder_frequency': '',
        'file_is_require': '',
        'before_reminder': '',
        "delegation_status_id": "",
        "file": "",
        "attachment_file_link": "",
        "remarks": "",
        "remark_file": "",
        "close_remark": "",
        "high_alert_remark": "",
        "switch_doer_remark": "",
        "completed_date": "",
        "start_date": "",
        "audio_attach": "",
        "done_file_link": ""
    })
    useEffect(() => {
        if (TaskData) {
            setUpdate({
                "id": TaskData.id,
                'name': TaskData.name,
                'assigned_by': TaskData.assigned_by,
                'assign_to': TaskData.assign_to,
                'planned_date': TaskData.planned_date,
                'task_priority_id': TaskData.task_priority_id,
                'message': TaskData.message,
                'notify_to': TaskData.notify_to,
                'auditor_id': TaskData.auditor_id,
                'reminder_mode_id': TaskData.reminder_mode_id,
                'reminder_frequency': TaskData.reminder_frequency,
                'file_is_require': TaskData.file_is_require,
                'before_reminder': TaskData.before_reminder,
                "delegation_status_id": TaskData.delegation_status_id,
                "start_date": TaskData.start_date,
                "completed_date": TaskData.completed_date,
                "remarks": TaskData.remarks,
                "remark_file": TaskData.remark_file,
                "close_remark": TaskData.close_remark,
                "high_alert_remark": TaskData.high_alert_remark,
                "switch_doer_remark": TaskData.switch_doer_remark,
                "audio_attach": TaskData.audio_attach,
                "attachment_file_link": TaskData.attachment_file_link,
                "done_file_link": TaskData.done_file_link
            });
            setUsers(User);
            setDoers(User)
            setNotify(User)
            setDescription(TaskData && TaskData.message);
            setAttachFile(TaskData && TaskData.attachment_file)
            setStartDate(TaskData && TaskData.planned_date)
            setName(TaskData && TaskData.name)
            setNotifyUser(TaskData && TaskData.notifyTo && TaskData.notifyTo.name)
        }

    }, [TaskData == '']);

    const handleDownload = (item) => {
        const fileExtension = item.split('.').pop();
        const fileNamewithextension = item;
        const OnlyfileName = fileNamewithextension.slice(0, fileNamewithextension.lastIndexOf('.'));
        const fileName = `${OnlyfileName}.${fileExtension}`;
        const link = document.createElement('a');
        link.href = url + item;
        link.download = fileName;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    const DownloadRemark = (filename) => {
        const fileExtension = filename.split('.').pop();
        const fileNamewithextension = filename;
        const OnlyfileName = fileNamewithextension.slice(0, fileNamewithextension.lastIndexOf('.'));
        const fileNames = `${OnlyfileName}.${fileExtension}`;
        const link = document.createElement('a');
        link.href = url + filename;
        link.download = fileNames;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function getUserNameById(userId) {
        const user = User.find(data => data.id == userId);
        return user ? user.name : "";
    }

    const OnlyFileName = (filePath) => {
        const fileNameWithExt = filePath.split('\\').pop();
        const fileNameWithoutExt = fileNameWithExt.slice(0, fileNameWithExt.lastIndexOf('.'));
        return fileNameWithoutExt;
    }


    const contentRef = useRef(null);
    useEffect(() => {
        if (contentRef.current) {
            const links = contentRef.current.querySelectorAll("a");
            links.forEach((link) => {
                link.setAttribute("target", "_blank");
                link.setAttribute("rel", "noopener noreferrer");
            });
        }
    }, [description]);



    return (
        <>
            <Modal id="edit-task" show={show} onHide={handleModel} backdrop="static" centered size="xl" className="task-details-modal">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="task-title-edit-wrap">
                            <input value={update.name != null ? update.name : ""} className="task-name" />
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="task-details-modal-body-wrap">
                    <div className="task-details-modal-body">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <div className="status-item todo">
                                            <i className="fas fa-user me-2"></i>
                                            <span>Assignee</span>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <Dropdown className="gth-modal-dropdown" id='assigneeDrop' >
                                            <Dropdown.Toggle id="status" className="gth-transparent-btn">
                                                <div className="profile-wrap">
                                                    <div className="exp-avtar bg-exp-green">
                                                        {TaskData && User.map((item) => (
                                                            item.id == update.assigned_by ?
                                                                item.user_photo != null ?
                                                                    < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                                                    <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span> : ""

                                                        ))}
                                                    </div>
                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                        <h5 className="profile-name text-nowrap">{TaskData && getUserNameById(update.assigned_by)}</h5>
                                                    </div>
                                                </div>
                                            </Dropdown.Toggle>

                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <div className="status-item todo">
                                            <i className="fas fa-user me-2"></i>
                                            <span>Doer</span>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <Dropdown className="gth-modal-dropdown">
                                            <Dropdown.Toggle id="status" className="gth-transparent-btn">
                                                <div className="profile-wrap">
                                                    <div className="exp-avtar bg-exp-yellow">
                                                        {TaskData && User.map((item) => (
                                                            item.id == update.assign_to ?
                                                                item.user_photo != null ?
                                                                    < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                                                    <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span> : ""

                                                        ))}
                                                    </div>
                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                        <h5 className="profile-name text-nowrap">{TaskData && getUserNameById(update.assign_to)}</h5>
                                                    </div>
                                                </div>
                                            </Dropdown.Toggle>

                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <div className="status-item todo">
                                            <i className="fas fa-calendar-alt me-2"></i>
                                            <span>Planned Date</span>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <Dropdown className="gth-modal-dropdown" >
                                            <Dropdown.Toggle id="status" className="gth-transparent-btn">
                                                <span className="planned-date-txt">
                                                    {/* {planDate.toDateString()} */}
                                                    {startDate != null ? moment(startDate).format("DD-MM-YYYY") : ""}
                                                </span>
                                            </Dropdown.Toggle>

                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <div className="status-item todo">
                                            <i className="fas fa-flag me-2"></i>
                                            <span>Priority</span>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <Dropdown className="gth-modal-dropdown" >
                                            <Dropdown.Toggle id="status" className="gth-transparent-btn">
                                                {update.task_priority_id == 1 ?
                                                    <div className="priority-set urgent">
                                                        <i className="fas fa-flag mr-2"></i>
                                                        <span>Urgent</span>
                                                    </div> :
                                                    update.task_priority_id == 2 ? <div div className="priority-set high priority-list-item" >
                                                        <i className="fas fa-flag mr-2"></i>
                                                        <span>High</span>
                                                    </div> : update.task_priority_id == 3 ?
                                                        <div className="priority-set normal priority-list-item" >
                                                            <i className="fas fa-flag mr-2"></i>
                                                            <span>Normal</span>
                                                        </div> : <div className="priority-set low priority-list-item" >
                                                            <i className="fas fa-flag mr-2"></i>
                                                            <span>Low</span>
                                                        </div>
                                                }
                                            </Dropdown.Toggle>

                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <div className="status-item todo">
                                            <i className="far fa-dot-circle me-2"></i>
                                            <span>Status</span>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <Dropdown className="gth-modal-dropdown">
                                            <Dropdown.Toggle id="status" className="gth-transparent-btn">
                                                {update.delegation_status_id == 1 ?
                                                    <div className="status-item todo status-list-item">
                                                        <i className="far fa-dot-circle me-2"></i>
                                                        <span>To Do</span>
                                                    </div> : update.delegation_status_id == 2 ?
                                                        <div div className="status-item inprogress">
                                                            <i className="fas fa-redo me-2"></i>
                                                            <span>In Progress</span>
                                                        </div> : update.delegation_status_id == 3 ?
                                                            <div className="status-item completed status-list-item">
                                                                <i className="fas fa-check-circle me-2"></i>
                                                                <span>Completed</span>
                                                            </div> : ""
                                                }
                                            </Dropdown.Toggle>

                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <div className="status-item todo">
                                            <i className="fas fa-user me-2"></i>
                                            <span>Notify To <br />(if not done)</span>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <Dropdown className="gth-modal-dropdown" >
                                            <Dropdown.Toggle id="status" className="gth-transparent-btn">
                                                <div className="profile-wrap">
                                                    {update.notify_to != null && update.notify_to != 0 ?
                                                        <>
                                                            <div className="exp-avtar bg-exp-green">
                                                                {TaskData && User.map((item) => (
                                                                    item.id == update.notify_to ?
                                                                        item.user_photo != null ?
                                                                            < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                                                            <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span> : ""

                                                                ))}
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-nowrap">{TaskData && getUserNameById(update.notify_to)}</h5>
                                                            </div></> :
                                                        <div className="status-item todo">
                                                            <i className="fas fa-user me-2"></i>
                                                            <span>Unset</span>
                                                        </div>
                                                    }
                                                </div>
                                            </Dropdown.Toggle>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <div className="status-item todo">
                                            <i className="fas fa-calendar-alt me-2"></i>
                                            <span>Start Date</span>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <Dropdown className="gth-modal-dropdown" >
                                            <Dropdown.Toggle id="status" className="gth-transparent-btn">
                                                <span className="planned-date-txt">
                                                    {update.start_date != '' ? moment(update.start_date).format("DD-MM-YYYY , h:mm a") : ""}
                                                </span>
                                            </Dropdown.Toggle>

                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <div className="status-item todo">
                                            <i className="fas fa-calendar-alt me-2"></i>
                                            <span>Completed Date</span>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <Dropdown className="gth-modal-dropdown" >
                                            <Dropdown.Toggle id="status" className="gth-transparent-btn">
                                                <span className="planned-date-txt">
                                                    {update.completed_date != '' ? moment(update.completed_date).format("DD-MM-YYYY") : ""}
                                                </span>
                                            </Dropdown.Toggle>

                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <div className="status-item todo">
                                            <i className="fas fa-headphones me-2"></i>
                                            <span>Audio Attach</span>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <span className="planned-date-txt">
                                            {update.audio_attach && (
                                                <div className='audio_record_player'>
                                                    <AudioPlayer
                                                        crossOrigin=""
                                                        src={url + update.audio_attach}
                                                        customAdditionalControls={[]}
                                                        autoPlay={false}
                                                        autoPlayAfterSrcChange={false}
                                                    />
                                                </div>
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none border mt-3 overflow-hidden">
                            <div className="card-header bg-exp-primary-grey-light-1 d-flex flex-wrap align-items-center justify-content-between">
                                <h6 className="mb-0 me-3">Set Reminder</h6>
                            </div>
                            <div className="card-body pb-1">
                                <div className='row'>
                                    <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                        <div className='form-group'>
                                            <label className='form-label'>Reminder Mode</label>
                                            <div className='custom-select-wrap'>
                                                <input type='text' value="Daily" readOnly className='form-control' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                        <div className='form-group'>
                                            <label className='form-label'>Day Before </label>
                                            <input type='number' className='form-control' readOnly value={update.before_reminder} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="my-3" />
                        <div className="row">
                            <div className="col-12">
                                <div className="attachment-header">
                                    <h6>Attachments</h6>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="card shadow-none border grid-files mt-2 mb-0">
                                    <div className="card-header bg-exp-primary-grey-light-1">
                                        <h6>Files</h6>
                                    </div>
                                    <div className="card-body">
                                        <div className="gth-attachment-body">
                                            {attachFile ?
                                                attachFile.split('.').pop() == "png" || attachFile.split('.').pop() == "jpg" || attachFile.split('.').pop() == "jpeg" ?
                                                    <figure className="gth-attachment-tile-item">
                                                        <div className="grid-action-btn">
                                                            <OverlayTrigger
                                                                trigger="click"
                                                                rootClose
                                                                placement="auto"
                                                                overlay={
                                                                    <Popover id="statusChange" className="action-wrap">
                                                                        <div className="action-list">
                                                                            <div className="action-list-item" onClick={() => handleDownload(attachFile)}>
                                                                                <i className="fas fa-download me-2"></i>
                                                                                <span>Download</span>
                                                                            </div>

                                                                        </div>
                                                                    </Popover>
                                                                }
                                                            >
                                                                <button className="action-btn">
                                                                    <i className="fas fa-ellipsis-v"></i>
                                                                </button>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <div className="attachment-image">
                                                            <span className="related-icon"><i className="fas fa-image"></i></span>
                                                        </div>
                                                    </figure> : attachFile.split('.').pop() == "pdf" ?
                                                        <figure className="gth-attachment-tile-item">
                                                            <div className="grid-action-btn">
                                                                <OverlayTrigger
                                                                    trigger="click"
                                                                    rootClose
                                                                    placement="auto"
                                                                    overlay={
                                                                        <Popover id="statusChange" className="action-wrap">
                                                                            <div className="action-list">
                                                                                <div className="action-list-item" onClick={() => handleDownload(attachFile)}>
                                                                                    <i className="fas fa-download me-2"></i>
                                                                                    <span>Download</span>
                                                                                </div>

                                                                            </div>
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <button className="action-btn">
                                                                        <i className="fas fa-ellipsis-v"></i>
                                                                    </button>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className="attachment-image">
                                                                <span className="related-icon"><i className="fas fa-file-pdf"></i></span>
                                                            </div>
                                                        </figure> : attachFile.split('.').pop() == "xls" || attachFile.split('.').pop() == "xlsx" ?
                                                            <figure className="gth-attachment-tile-item">
                                                                <div className="grid-action-btn">
                                                                    <OverlayTrigger
                                                                        trigger="click"
                                                                        rootClose
                                                                        placement="auto"
                                                                        overlay={
                                                                            <Popover id="statusChange" className="action-wrap">
                                                                                <div className="action-list">
                                                                                    <div className="action-list-item" onClick={() => handleDownload(attachFile)}>
                                                                                        <i className="fas fa-download me-2"></i>
                                                                                        <span>Download</span>
                                                                                    </div>

                                                                                </div>
                                                                            </Popover>
                                                                        }
                                                                    >
                                                                        <button className="action-btn">
                                                                            <i className="fas fa-ellipsis-v"></i>
                                                                        </button>
                                                                    </OverlayTrigger>
                                                                </div>
                                                                <div className="attachment-image">
                                                                    <span className="related-icon"><i className="fas fa-file-excel"></i></span>
                                                                </div>
                                                            </figure> : attachFile.split('.').pop() == "doc" ?
                                                                <figure figure className="gth-attachment-tile-item">
                                                                    <div className="grid-action-btn">
                                                                        <OverlayTrigger
                                                                            trigger="click"
                                                                            rootClose
                                                                            placement="auto"
                                                                            overlay={
                                                                                <Popover id="statusChange" className="action-wrap">
                                                                                    <div className="action-list">
                                                                                        <div className="action-list-item" onClick={() => handleDownload(attachFile)}>
                                                                                            <i className="fas fa-download me-2"></i>
                                                                                            <span>Download</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Popover>
                                                                            }
                                                                        >
                                                                            <button className="action-btn">
                                                                                <i className="fas fa-file-word"></i>
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    <div className="attachment-image">
                                                                        <span className="related-icon"><i className="fas fa-file-excel"></i></span>
                                                                    </div>
                                                                </figure> : "" : ""

                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {update.attachment_file_link ?
                            <>
                                <hr className="my-3" />
                                <div className="description">
                                    <h6>Attached Link</h6>
                                    <div className="workflow-details-wrap">
                                        {update && convertLinksNewLine(update.attachment_file_link)}

                                    </div>
                                </div></> : ""
                        }
                        <hr className="my-3" />
                        <div className="description">
                            <h6>Description</h6>
                            {description && (
                                <div ref={contentRef} dangerouslySetInnerHTML={{ __html: (description) }} />
                            )}
                        </div>


                        {
                            update.remarks ?
                                <>
                                    <hr className="my-3" />
                                    <div className="description">
                                        <h6>Remark</h6>
                                        <div className="workflow-details-wrap">
                                            {update && convertLinks(update.remarks)}

                                        </div>
                                    </div>
                                </> : ""
                        }

                        {update.remark_file ?
                            <div className="remarks mb-3">
                                <hr className="my-3" />
                                <h6>Remarks File</h6>
                                <div className="remarks-attachment-wrap">
                                    {update.remark_file && update.remark_file ?
                                        update.remark_file.split('.').pop() == "png" || update.remark_file.split('.').pop() == "jpg" || update.remark_file.split('.').pop() == "jpeg" ?
                                            <div className="remarks-attachment-item">
                                                <div className="icon">
                                                    <i className="fas fa-image"></i>
                                                </div>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>
                                                            {OnlyFileName(update.remark_file)}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <p className="text-truncate filename">
                                                        {OnlyFileName(update.remark_file)}
                                                    </p>
                                                </OverlayTrigger>
                                                <button className="attachment-download action-btn" onClick={() => DownloadRemark(update.remark_file)}>
                                                    <i className="fas fa-download"></i>
                                                </button>
                                            </div> : update.remark_file.split('.').pop() == "pdf" ?
                                                <div className="remarks-attachment-item">
                                                    <div className="icon">
                                                        <i className="fas fa-file-pdf"></i>
                                                    </div>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                {OnlyFileName(update.remark_file)}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <p className="text-truncate filename">
                                                            {OnlyFileName(update.remark_file)}
                                                        </p>
                                                    </OverlayTrigger>
                                                    <button className="attachment-download action-btn" onClick={() => DownloadRemark(update.remark_file)}>
                                                        <i className="fas fa-download"></i>
                                                    </button>
                                                </div> : update.remark_file.split('.').pop() == "xls" || update.remark_file.split('.').pop() == "xlsx" ?
                                                    <div className="remarks-attachment-item">
                                                        <div className="icon">
                                                            <i className="fas fa-file-excel"></i>
                                                        </div>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip>
                                                                    {OnlyFileName(update.remark_file)}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <p className="text-truncate filename">
                                                                {OnlyFileName(update.remark_file)}
                                                            </p>
                                                        </OverlayTrigger>
                                                        <button className="attachment-download action-btn" onClick={() => DownloadRemark(update.remark_file)}>
                                                            <i className="fas fa-download"></i>
                                                        </button>
                                                    </div>
                                                    : update.remark_file.split('.').pop() == "doc" ?
                                                        <div className="remarks-attachment-item">
                                                            <div className="icon">
                                                                <i className="fas fa-file-word"></i>
                                                            </div>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        {OnlyFileName(update.remark_file)}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <p className="text-truncate filename">
                                                                    {OnlyFileName(update.remark_file)}
                                                                </p>
                                                            </OverlayTrigger>
                                                            <button className="attachment-download action-btn" onClick={() => DownloadRemark(update.remark_file)}>
                                                                <i className="fas fa-download"></i>
                                                            </button>
                                                        </div> : "" : ""
                                    }
                                </div>
                            </div> : ""
                        }
                        {
                            update.done_file_link ?
                                <>
                                    <hr className="my-3" />
                                    <div className="description">
                                        <h6>Remark Link</h6>
                                        <div className="workflow-details-wrap">
                                            {update && convertLinksNewLine(update.done_file_link)}

                                        </div>
                                    </div>
                                </> : ""
                        }
                        {update.close_remark ? <>
                            <hr className="my-3" />
                            <div className="remarks mb-3">
                                <h6>Close Remark</h6>
                                <p className="mb-0 text-muted">{update.close_remark && update.close_remark}</p>
                            </div></> : ""
                        }
                        {
                            update.high_alert_remark ? <>
                                <hr className="my-3" />
                                <div className="description">
                                    <h6>High Alert Remark</h6>
                                    <div className="workflow-details-wrap">
                                        {update && update.high_alert_remark}

                                    </div>
                                </div>
                            </> : ""
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-between flex-wrap'>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                Chat
                            </Tooltip>
                        }
                    >
                        <button type='button' className="me-1 table-action-btn" onClick={() => handleMessageShow(update)}>
                            <i className="fas fa-comment-dots"></i>
                        </button>
                    </OverlayTrigger>
                    {where == "system" ? "" :
                        <button type='submit' className='btn btn-exp-green' onClick={() => taskDoneModalShow(update)} >Done</button>
                    }
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default TaskTrackerDetails