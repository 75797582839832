import axios from 'axios';

// export const url = "https://bms.growthh.com/api/uploads/"
// export const BaseUrl = "https://bms.growthh.com/api/"

export const url = "https://bms.growthh.in/api/uploads/"
export const BaseUrl = "https://bms.growthh.in/api/"

// export const url = "http://localhost:5000/api/uploads/"
// export const BaseUrl = "http://localhost:5000/api/"

// export const url = "https://bms.gyanmartindia.in/api/uploads/"
// export const BaseUrl = "https://bms.gyanmartindia.in/api/"

export const Axios = axios.create({
    baseURL: BaseUrl,
    headers: {
        'Content-Type': 'application/json',
    },
})


export const PrivateAxios = axios.create({
    baseURL: BaseUrl,
})
PrivateAxios.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem('token');
        if (token) {
            config.headers['authentication'] = `${token}`;
            config.headers['Content-Type'] = 'application/json';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


export const PrivateAxiosFile = axios.create({
    baseURL: BaseUrl,
})
PrivateAxiosFile.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem('token');
        if (token) {
            config.headers['authentication'] = `${token}`;
            config.headers['Content-Type'] = 'multipart/form-data';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);