import { format } from 'date-fns'
import { SlideshowLightbox } from 'lightbox.js-react';
import React, { memo, useEffect, useRef, useState } from 'react'
import { Dropdown, Modal, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UserAuth } from '../../auth/Auth';
import { PrivateAxios, PrivateAxiosFile, url } from '../../../environment/AxiosInstance';
import moment from 'moment';
import { formatDate } from '../../../environment/DateFormat';
import DeleteModel from '../../../component/DeleteModel';
import { SuccessMessage, WaringMessage } from '../../../environment/ToastMessage';
import { TaskMode } from '../../../utils/StaticData';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { convertLinks, convertLinksNewLine } from '../../../environment/ConvertLinks';
import AutoHeightTextarea from '../../CommonComponent/AutoHeightTextarea';
import CheckSheetMsg from '../../../component/CheckSheetMsg';


function CheckSheetDetails({ showChecksheetViewDetails, where,doer,handleShowChatWrap, fileLink,issueDate,completeDate, setTableData, setAllTableData, handleCloseChecksheetViewDetails, closeRemark, completeRemark, completeFile, checkSheet, taskId, assignTo, Auditor, auditRemark }) {
  const { User, priority, status, Logout, mode } = UserAuth();
  const [users, setUsers] = useState([])
  const [doers, setDoers] = useState([])
  const [notify, setNotify] = useState([])
  const [update, setUpdate] = useState({
    'name': "",
    'assigned_by': '',
    'assign_to': '',
    'issue_date': '',
    'task_priority_id': '',
    'message': '',
    'notify_to': '',
    'auditor_id': '',
    'reminder_mode_id': '',
    'reminder_frequency': '',
    'file_is_require': '',
    'before_reminder': '',
    "status": "",
    "file": "",
    "task_mode_id": "",
    "audio_attach": ""
  })
  const [assignee, setAssignee] = useState('')
  const [userDoers, setUserDoers] = useState('')
  const [NotifyUser, setNotifyUser] = useState('')
  const [description, setDescription] = useState('')
  const [attachFile, setAttachFile] = useState('')
  const [startDate, setStartDate] = useState('');
  const [name, setName] = useState('')
  const [subTask, setSubTask] = useState();
  const [SUbTaskValue, setSubTaskValue] = useState()
  useEffect(() => {
    if (checkSheet) {
      setUpdate({
        "id": checkSheet.id,
        'name': checkSheet.name,
        'assigned_by': checkSheet.assigned_by,
        'assign_to': assignTo,
        'issue_date': checkSheet.issue_date,
        'task_priority_id': checkSheet.task_priority_id,
        'message': checkSheet.message,
        'notify_to': checkSheet.notify_to,
        'reminder_mode_id': checkSheet.reminder_mode_id,
        'file_is_require': checkSheet.file_is_require,
        'status': checkSheet.status,
        'before_reminder': checkSheet.before_reminder,
        'task_mode_id': checkSheet.task_mode_id,
        "auditor_id": Auditor,
        "audio_attach": checkSheet.audio_attach
      });
      setUsers(User);
      setDoers(User);
      setNotify(User);
      setSubTaskValue(checkSheet)
      // setAssignee(checkSheet && checkSheet.assignedByUser.name);
      // setUserDoers(checkSheet && checkSheet.assignedToUser.name);
      setDescription(checkSheet && checkSheet.message);
      setStartDate(checkSheet && checkSheet.issue_date)
      setName(checkSheet && checkSheet.name)
      setSubTask(checkSheet && checkSheet && checkSheet.subtask)
      setAttachFile(checkSheet && checkSheet && checkSheet.attach_file)

      // setNotifyUser(checkSheet && checkSheet.notifyTo && checkSheet.notifyTo.name)
    }

  }, [checkSheet != ''])

  //------------------------------------SubTask Add delete and update------------------------------
  const removeSubTask = (index) => {
    if (subTask.length === 1) {
      return;
    }
    const newProducts = subTask.filter((_, i) => i !== index);
    setSubTask(newProducts);
  };
  const addSubTask = () => {
    setSubTask([
      ...subTask,
      {
        name: "",

      },
    ]);
  }
  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState('');
  const deleteModalClose = () => {
    setDeleteShow(false);
    setDeleteIndex('')
  }
  const deleteModalShow = (index) => {
    setDeleteShow(true);
    setDeleteIndex(index)
  }

  const handleSubtaskChange = (index, field, value) => {
    const newSubTask = [...subTask];
    newSubTask[index][field] = value;
    setSubTask(newSubTask);
  };
  const DeleteConfirm = () => {
    removeSubTask(deleteIndex)
    deleteModalClose()
  }
  const [subtaskDone, setSubtaskDone] = useState([])
  const GetDoneSubTask = () => {
    PrivateAxios.get(`checksheet/done-subtask/${taskId}`)
      .then((res) => {
        let subTask = [];
        if (res.data.data.length > 0) {
          res.data.data.map(item => subTask.push(item.sub_task_id))
        }
        setSubtaskDone(subTask);
      }).catch((err) => {
        if (err.response.status == 401) {
          Logout();
        }
      })
  }
  useEffect(() => {
    if (taskId) {
      GetDoneSubTask()
    }
  }, [taskId])

  const handleDownload = (item) => {
    const fileExtension = item.split('.').pop();
    const fileNamewithextension = item;
    const OnlyfileName = fileNamewithextension.slice(0, fileNamewithextension.lastIndexOf('.'));
    const fileName = `${OnlyfileName}.${fileExtension}`;
    const link = document.createElement('a');
    link.href = url + item;
    link.download = fileName;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  const DownloadRemark = (filename) => {
    const fileExtension = filename.split('.').pop();
    const fileNamewithextension = filename;
    const OnlyfileName = fileNamewithextension.slice(0, fileNamewithextension.lastIndexOf('.'));
    const fileNames = `${OnlyfileName}.${fileExtension}`;
    const link = document.createElement('a');
    link.href = url + filename;
    link.download = fileNames;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const clearAll = () => {
    setNotify(User)
    setAssignee('');
    setUserDoers('');
    setDescription('');
    setAttachFile('')
    setStartDate('')
    setName('')
    setSubTask('')
    setNotifyUser('')
  }

  const OnlyFileName = (filePath) => {
    const fileNameWithExt = filePath.split('\\').pop();
    const fileNameWithoutExt = fileNameWithExt.slice(0, fileNameWithExt.lastIndexOf('.'));
    return fileNameWithoutExt;
  }

  const contentRef = useRef(null);
  useEffect(() => {
    if (contentRef.current) {
      const links = contentRef.current.querySelectorAll("a");
      links.forEach((link) => {
        link.setAttribute("target", "_blank");
        link.setAttribute("rel", "noopener noreferrer");
      });
    }
  }, [checkSheet.message]);

  //===============================Task Done modal===========================
  const [taskDoneShow, setTaskDoneShow] = useState(false);
  const [taskIds, setTaskIds] = useState([])
  const taskDoneModalClose = () => {
    // setSubTaskValue('')
    setTaskDoneShow(false);
    setTaskIds([]);
  }
  const getSubTask = (e, id) => {
    setTaskIds((prevSelectedIds) => {
      if (e.target.checked) {
        return [...prevSelectedIds, id];
      } else {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      }
    });
  }
  const taskDoneModalShow = (data) => {
    // setSubTaskValue(data);
    setTaskDoneShow(true);
  }
  const [subTaskDetails, setSubTaskDetails] = useState({
    "id": "",
    "remark": "",
    "remark_file": "",
    "done_file_link": ""
  })

  const fileUpload = async (e) => {
    const file = e.target.files[0];
    let fileSize = file?.size;
    if (Number(fileSize) >= 20 * 1024 * 1024) {
      WaringMessage("This image in getter than 20MB");
    } else {
      setSubTaskDetails({ ...subTaskDetails, remark_file: e.target.files[0] });
    }
  }

  const submitDone = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append('remark', subTaskDetails.remark)
    formData.append('file', subTaskDetails.remark_file)
    formData.append('done_file_link', subTaskDetails.done_file_link);
    formData.append('id', taskId)
    formData.append('subtask', taskIds.length > 0 ? JSON.stringify(taskIds) : "")
    formData.append('total_subtask', SUbTaskValue.subtask.length);

    PrivateAxiosFile.post("checksheet/done", formData)
      .then((res) => {
        setTableData(prevent => prevent.filter(data => data.id != taskId))
        setAllTableData(prevent => prevent.filter(data => data.id != taskId))
        taskDoneModalClose();
        SuccessMessage(res.data.message);
      }).catch((err) => {
      })
  }

//  const [showChatWrap, setShowChatWrap] = useState(false);
//     const [chatValue, setChatValue] = useState('');

//     const handleCloseChatWrap = () => {
//         setShowChatWrap(false);
//     }
//     const handleShowChatWrap = (data) => {
//         setShowChatWrap(true);
//         setChatValue(data)
//     }
    
  return (
    <>
      <Modal id="ChecksheetDetails" show={showChecksheetViewDetails} onHide={() => { handleCloseChecksheetViewDetails(); clearAll() }} backdrop="static" centered size="xl" className="task-details-modal">
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="task-title-edit-wrap">
              <input value={update.name} className="task-name" />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="task-details-modal-body-wrap">
          <div className="task-details-modal-body">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="status-item todo">
                      <i className="fas fa-user me-2"></i>
                      <span>Assignee</span>
                    </div>
                  </div>
                  <div className="col-8">
                    <Dropdown className="gth-modal-dropdown" >
                      <Dropdown.Toggle id="status" className="gth-transparent-btn">
                        {update.assigned_by && User && User.map((item) => (
                          item.id == update.assigned_by ?
                            < div className="profile-wrap" key={item.id}>
                              <div className="exp-avtar bg-exp-green">
                                {item.user_photo != null ?
                                  < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                  <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                }
                              </div>
                              <div className="ps-2 profile-name-wrap text-truncate">
                                <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                              </div>
                            </div> : ""
                        ))
                        }
                      </Dropdown.Toggle>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="status-item todo">
                      <i className="fas fa-user me-2"></i>
                      <span>Doer</span>
                    </div>
                  </div>
                  <div className="col-8">
                    <Dropdown className="gth-modal-dropdown" >
                      <Dropdown.Toggle id="status" className="gth-transparent-btn">
                        {update.assign_to && User && User.map((item) => (
                          item.id == update.assign_to ?
                            < div className="profile-wrap" key={item.id}>
                              <div className="exp-avtar bg-exp-yellow">
                                {item.user_photo != null ?
                                  < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                  <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                }
                              </div>
                              <div className="ps-2 profile-name-wrap text-truncate">
                                <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                              </div>
                            </div> : ""
                        ))
                        }
                      </Dropdown.Toggle>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="status-item todo">
                      <i className="fas fa-calendar-alt me-2"></i>
                      <span>Planned Date</span>
                    </div>
                  </div>
                  <div className="col-8">
                    <Dropdown className="gth-modal-dropdown" >
                      <Dropdown.Toggle id="status" className="gth-transparent-btn">
                        <span className="planned-date-txt">
                          {issueDate != null ? moment(issueDate).format("DD-MM-YYYY") : ""}
                        </span>
                      </Dropdown.Toggle>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="status-item todo">
                      <i className="fas fa-flag me-2"></i>
                      <span>Priority</span>
                    </div>
                  </div>
                  <div className="col-8">
                    <Dropdown className="gth-modal-dropdown">
                      <Dropdown.Toggle id="status" className="gth-transparent-btn">
                        {update.task_priority_id == 1 ?
                          <div className="priority-set urgent">
                            <i className="fas fa-flag mr-2"></i>
                            <span>Urgent</span>
                          </div> :
                          update.task_priority_id == 2 ? <div div className="priority-set high priority-list-item" >
                            <i className="fas fa-flag mr-2"></i>
                            <span>High</span>
                          </div> : update.task_priority_id == 3 ?
                            <div className="priority-set normal priority-list-item" >
                              <i className="fas fa-flag mr-2"></i>
                              <span>Normal</span>
                            </div> : <div className="priority-set low priority-list-item" >
                              <i className="fas fa-flag mr-2"></i>
                              <span>Low</span>
                            </div>
                        }
                      </Dropdown.Toggle>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="status-item">
                      <i className="fas fa-calendar-alt me-2"></i>
                      <span>Mode</span>
                    </div>
                  </div>
                  <div className="col-8">
                    <Dropdown className="gth-modal-dropdown">
                      <Dropdown.Toggle id="status" className="gth-transparent-btn">
                        <div className="status-item inprogress">
                          {TaskMode.map((item) => (
                            update.task_mode_id == item.id ?
                              <span> {item.title}</span> : ""
                          ))
                          }

                        </div>
                      </Dropdown.Toggle>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="status-item todo">
                      <i className="fas fa-calendar-alt me-2"></i>
                      <span>Start Date</span>
                    </div>
                  </div>
                  <div className="col-8">
                    <Dropdown className="gth-modal-dropdown">
                      <Dropdown.Toggle id="status" className="gth-transparent-btn">
                        <span className="planned-date-txt">
                          {startDate != null ? moment(startDate).format("DD-MM-YYYY") : ""}
                        </span>
                      </Dropdown.Toggle>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center">

              <div className="col-lg-6">
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="status-item todo">
                      <i className="fas fa-calendar-alt me-2"></i>
                      <span>Complete Date</span>
                    </div>
                  </div>
                  <div className="col-8">
                    <Dropdown className="gth-modal-dropdown">
                      <Dropdown.Toggle id="status" className="gth-transparent-btn">
                        <span className="planned-date-txt">
                          {completeDate != null ? moment(completeDate).format("DD-MM-YYYY") : ""}
                        </span>
                      </Dropdown.Toggle>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="status-item todo">
                      <i className="fas fa-user me-2"></i>
                      <span>Notify To <br />(if not done)</span>
                    </div>
                  </div>
                  <div className="col-8">
                    <Dropdown className="gth-modal-dropdown">
                      <Dropdown.Toggle id="status" className="gth-transparent-btn">
                        {update.notify_to != 0 ? User && User.map((item) => (
                          item.id == update.notify_to ?
                            < div className="profile-wrap" key={item.id}>
                              <div className="exp-avtar bg-exp-green">
                                {item.user_photo != null ?
                                  < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                  <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                }
                              </div>
                              <div className="ps-2 profile-name-wrap text-truncate">
                                <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                              </div>
                            </div> : ""
                        )) : <div className="status-item todo">
                          <i className="fas fa-user me-2"></i>
                          <span>Unset</span>
                        </div>
                        }
                        {/* <div className="profile-wrap">
                          {update.notify_to != null ?
                            <>
                              <div className="exp-avtar bg-exp-green">
                                {checkSheet && User.map((item) => (
                                  item.id == update.notify_to ?
                                    item.user_photo != null ?
                                      < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                      <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span> : ""

                                ))}
                              </div>
                              <div className="ps-2 profile-name-wrap text-truncate">
                                <h5 className="profile-name text-nowrap">{NotifyUser}</h5>
                              </div></> :
                            <div className="status-item todo">
                              <i className="fas fa-user me-2"></i>
                              <span>Unset</span>
                            </div>
                          }
                        </div> */}
                      </Dropdown.Toggle>
                    </Dropdown>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="status-item todo">
                      <i className="fas fa-user me-2"></i>
                      <span>Auditor</span>
                    </div>
                  </div>
                  <div className="col-8">
                    <Dropdown className="gth-modal-dropdown">
                      <Dropdown.Toggle id="status" className="gth-transparent-btn">
                        {update.auditor_id != 0 ? User && User.map((item) => (
                          item.id == update.auditor_id ?
                            < div className="profile-wrap" key={item.id}>
                              <div className="exp-avtar bg-exp-green">
                                {item.user_photo != null ?
                                  < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                  <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                }
                              </div>
                              <div className="ps-2 profile-name-wrap text-truncate">
                                <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                              </div>
                            </div> : ""
                        )) : <div className="status-item todo">
                          <i className="fas fa-user me-2"></i>
                          <span>Unset</span>
                        </div>
                        }
                        {/* <div className="profile-wrap">
                          {update.notify_to != null ?
                            <>
                              <div className="exp-avtar bg-exp-green">
                                {checkSheet && User.map((item) => (
                                  item.id == update.notify_to ?
                                    item.user_photo != null ?
                                      < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                      <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span> : ""

                                ))}
                              </div>
                              <div className="ps-2 profile-name-wrap text-truncate">
                                <h5 className="profile-name text-nowrap">{NotifyUser}</h5>
                              </div></> :
                            <div className="status-item todo">
                              <i className="fas fa-user me-2"></i>
                              <span>Unset</span>
                            </div>
                          }
                        </div> */}
                      </Dropdown.Toggle>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row align-items-center">
                  <div className="col-4">
                    <div className="status-item todo">
                      <i className="fas fa-headphones me-2"></i>
                      <span>Audio Attach</span>
                    </div>
                  </div>
                  <div className="col-8">
                    <span className="planned-date-txt">
                      {update.audio_attach && (
                        <>
                          {/* <audio src={url + update.audio_attach} controls /> */}
                          <div className='audio_record_player'>
                            <AudioPlayer
                              crossOrigin=""
                              src={url + update.audio_attach}
                              customAdditionalControls={[]}
                              autoPlay={false}
                              autoPlayAfterSrcChange={false}
                            />
                          </div>
                        </>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="card shadow-none border mt-3 overflow-hidden">
              <div className="card-header bg-exp-primary-grey-light-1 d-flex flex-wrap align-items-center justify-content-between">
                <h6 className="mb-0 me-3">Set Reminder</h6>
              </div>
              <div className="card-body pb-1">
                <div className='row'>
                  <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                    <div className='form-group'>
                      <label className='form-label'>Reminder Mode</label>
                      <div className='custom-select-wrap'>
                        <input type='text' value="Daily" className='form-control' readonly />
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                    <div className='form-group'>
                      <label className='form-label'>Day Before </label>
                      <input type='number' readOnly className='form-control' value={update.before_reminder} placeholder='Day Before' onChange={(e) => setUpdate({ ...update, before_reminder: e.target.value })} />
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <hr className="my-3" />
            <div className='col-lg-12'>
              <div className='card shadow-none border overflow-hidden bg-light-blue'>
                <div className='card-body p-0'>
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>

                          <th>Set Sub Task</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {subTask && subTask.map((task, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                className="form-control"
                                type="text"
                                readOnly
                                value={task.name}
                                placeholder="Enter Subtask Name"
                                onChange={(e) =>
                                  handleSubtaskChange(
                                    index,
                                    "name",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td className='width-80'>
                              {taskId != '' ? subtaskDone.includes(task.id) ? <i class="fas fa-check-circle gth-text-success fs-5"></i> : <i class="fas fa-spinner gth-text-info fs-5"></i> : ""}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <hr className="my-3" />
            <div className="row">
              <div className="col-12">
                <div className="attachment-header">
                  <h6>Attachments</h6>
                </div>
              </div>
              <div className="col-12">
                <div className="card shadow-none border grid-files mt-2 mb-0">
                  <div className="card-header bg-exp-primary-grey-light-1">
                    <h6>Files</h6>
                  </div>
                  <div className="card-body">
                    <div className="gth-attachment-body">
                      {attachFile ?
                        attachFile.split('.').pop() == "png" || attachFile.split('.').pop() == "jpg" || attachFile.split('.').pop() == "jpeg" ?
                          <figure className="gth-attachment-tile-item">
                            <div className="grid-action-btn">
                              <OverlayTrigger
                                trigger="click"
                                rootClose
                                placement="auto"
                                overlay={
                                  <Popover id="statusChange" className="action-wrap">
                                    <div className="action-list" >
                                      <div className="action-list-item" onClick={() => handleDownload(attachFile)} >
                                        <i className="fas fa-download me-2"></i>
                                        <span>Download</span>
                                      </div>
                                    </div>
                                  </Popover>
                                }
                              >
                                <button className="action-btn">
                                  <i className="fas fa-ellipsis-v"></i>
                                </button>
                              </OverlayTrigger>
                            </div>
                            <div className="attachment-image">
                              <span className="related-icon"><i className="fas fa-image"></i></span>
                            </div>
                          </figure> : attachFile.split('.').pop() == "pdf" ?
                            <figure className="gth-attachment-tile-item">
                              <div className="grid-action-btn">
                                <OverlayTrigger
                                  trigger="click"
                                  rootClose
                                  placement="auto"
                                  overlay={
                                    <Popover id="statusChange" className="action-wrap">
                                      <div className="action-list" >
                                        <div className="action-list-item" onClick={() => handleDownload(attachFile)} >
                                          <i className="fas fa-download me-2"></i>
                                          <span>Download</span>
                                        </div>
                                      </div>
                                    </Popover>
                                  }
                                >
                                  <button className="action-btn">
                                    <i className="fas fa-ellipsis-v"></i>
                                  </button>
                                </OverlayTrigger>
                              </div>
                              <div className="attachment-image">
                                <span className="related-icon"><i className="fas fa-file-pdf"></i></span>
                              </div>
                            </figure> : attachFile.split('.').pop() == "xls" || attachFile.split('.').pop() == "xlsx" ?
                              <figure className="gth-attachment-tile-item">
                                <div className="grid-action-btn">
                                  <OverlayTrigger
                                    trigger="click"
                                    rootClose
                                    placement="auto"
                                    overlay={
                                      <Popover id="statusChange" className="action-wrap">
                                        <div className="action-list" >
                                          <div className="action-list-item" onClick={() => handleDownload(attachFile)} >
                                            <i className="fas fa-download me-2"></i>
                                            <span>Download</span>
                                          </div>
                                        </div>
                                      </Popover>
                                    }
                                  >
                                    <button className="action-btn">
                                      <i className="fas fa-ellipsis-v"></i>
                                    </button>
                                  </OverlayTrigger>
                                </div>
                                <div className="attachment-image">
                                  <span className="related-icon"><i className="fas fa-file-excel"></i></span>
                                </div>
                              </figure> : attachFile.split('.').pop() == "doc" ?
                                <figure figure className="gth-attachment-tile-item">
                                  <div className="grid-action-btn">
                                    <OverlayTrigger
                                      trigger="click"
                                      rootClose
                                      placement="auto"
                                      overlay={
                                        <Popover id="statusChange" className="action-wrap">
                                          <div className="action-list" >
                                            <div className="action-list-item" onClick={() => handleDownload(attachFile)} >
                                              <i className="fas fa-download me-2"></i>
                                              <span>Download</span>
                                            </div>
                                          </div>
                                        </Popover>
                                      }
                                    >
                                      <button className="action-btn">
                                        <i className="fas fa-file-word"></i>
                                      </button>
                                    </OverlayTrigger>
                                  </div>
                                  <div className="attachment-image">
                                    <span className="related-icon"><i className="fas fa-file-excel"></i></span>
                                  </div>
                                </figure> : "" : ""

                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>
            {checkSheet.attachment_file_link ?
              <>
                <hr className="my-3" />
                <div className="description">
                  <h6>Attached Link</h6>
                  <div className="workflow-details-wrap">
                    {checkSheet && convertLinksNewLine(checkSheet.attachment_file_link)}

                  </div>
                </div></> : ""
            }
            <hr className="my-3" />
            <div className="description mb-3">
              <h6>Description</h6>
              {checkSheet?.message && (
                <div ref={contentRef} dangerouslySetInnerHTML={{ __html: (checkSheet.message) }} />
              )}
              {/* <p>{checkSheet && checkSheet &&}</p> */}
            </div>
            {
              completeRemark ?
                <>
                  <hr className="my-3" />
                  <div className="description">
                    <h6>Remark</h6>
                    <div className="workflow-details-wrap">
                      {completeRemark && convertLinks(completeRemark)}

                    </div>
                  </div>
                </> : ""
            }
            {fileLink ?
              <>
                <hr className="my-3" />
                <div className="description">
                  <h6>Remark Link</h6>
                  <div className="workflow-details-wrap">
                    {fileLink && convertLinksNewLine(fileLink)}

                  </div>
                </div></> : ""
            }
            {completeFile ?
              <div className="remarks mb-3">
                <hr className="my-3" />
                <h6>Remarks File</h6>
                {/* <p className="mb-0 text-muted">{update.remarks && update.remarks}</p> */}
                <div className="remarks-attachment-wrap">
                  {completeFile && completeFile ?
                    completeFile.split('.').pop() == "png" || completeFile.split('.').pop() == "jpg" || completeFile.split('.').pop() == "jpeg" ?
                      <div className="remarks-attachment-item">
                        <div className="icon">
                          <i className="fas fa-image"></i>
                        </div>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              {OnlyFileName(completeFile)}
                            </Tooltip>
                          }
                        >
                          <p className="text-truncate filename">
                            {OnlyFileName(completeFile)}
                          </p>
                        </OverlayTrigger>
                        <button className="attachment-download action-btn" onClick={() => DownloadRemark(completeFile)}>
                          <i className="fas fa-download"></i>
                        </button>
                      </div> : completeFile.split('.').pop() == "pdf" ?
                        <div className="remarks-attachment-item">
                          <div className="icon">
                            <i className="fas fa-file-pdf"></i>
                          </div>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                {OnlyFileName(completeFile)}
                              </Tooltip>
                            }
                          >
                            <p className="text-truncate filename">
                              {OnlyFileName(completeFile)}
                            </p>
                          </OverlayTrigger>
                          <button className="attachment-download action-btn" onClick={() => DownloadRemark(completeFile)}>
                            <i className="fas fa-download"></i>
                          </button>
                        </div> : completeFile.split('.').pop() == "xls" || completeFile.split('.').pop() == "xlsx" ?
                          <div className="remarks-attachment-item">
                            <div className="icon">
                              <i className="fas fa-file-excel"></i>
                            </div>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  {OnlyFileName(completeFile)}
                                </Tooltip>
                              }
                            >
                              <p className="text-truncate filename">
                                {OnlyFileName(completeFile)}
                              </p>
                            </OverlayTrigger>
                            <button className="attachment-download action-btn" onClick={() => DownloadRemark(completeFile)}>
                              <i className="fas fa-download"></i>
                            </button>
                          </div>
                          : completeFile.split('.').pop() == "doc" ?
                            <div className="remarks-attachment-item">
                              <div className="icon">
                                <i className="fas fa-file-word"></i>
                              </div>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip>
                                    {OnlyFileName(completeFile)}
                                  </Tooltip>
                                }
                              >
                                <p className="text-truncate filename">
                                  {OnlyFileName(completeFile)}
                                </p>
                              </OverlayTrigger>
                              <button className="attachment-download action-btn" onClick={() => DownloadRemark(completeFile)}>
                                <i className="fas fa-download"></i>
                              </button>
                            </div> : "" : ""
                  }
                </div>
              </div> : ""
            }

            {auditRemark && (
              <>
                <hr className="my-3" />
                <div className="description mb-3">
                  <h6>Audit Remark</h6>

                  <div>{convertLinks(auditRemark)}</div>
                </div>
              </>
            )}

            {closeRemark && (
              <>
                <hr className="my-3" />
                <div className="description mb-3">
                  <h6>Close Remark</h6>

                  <div>{convertLinks(closeRemark)}</div>
                </div>
              </>
            )}



          </div>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between flex-wrap'>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                Chat
              </Tooltip>
            }
          >
            <button type="button" className="me-1 table-action-btn" onClick={()=>handleShowChatWrap(checkSheet)}>
              <i className="fas fa-comment-dots"></i>
            </button>
          </OverlayTrigger>
          {where == "system" ? "" :
            <button type='submit' className='btn btn-exp-green' onClick={() => taskDoneModalShow()}>Done</button>
          }
        </Modal.Footer>
      </Modal >

      <Modal
        id="CS-done-modal"
        show={taskDoneShow}
        onHide={taskDoneModalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton className="gth-blue-light-bg">
          <Modal.Title className="gth-modal-title">Complete Task of <span>{SUbTaskValue && SUbTaskValue.name}</span></Modal.Title>
        </Modal.Header>
        <form onSubmit={submitDone}>
          <Modal.Body className='pb-1'>
            <div className='row'>
              <div className='col-md-6'>
                <div className="form-group">
                  <label className="form-label">Assigned By : </label>
                  {SUbTaskValue && User && User.map((item) => (
                    item.id == SUbTaskValue.assigned_by ?
                      < div className="profile-wrap" key={item.id}>
                        <div className="exp-avtar bg-exp-yellow">
                          {item.user_photo != null ?
                            < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                            <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                          }
                        </div>
                        <div className="ps-2 profile-name-wrap text-truncate">
                          <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                        </div>
                      </div> : ""
                  ))
                  }
                </div>
              </div>
              <div className='col-md-6'>
                <div className="form-group">
                  <label className="form-label">Planned Date : </label>
                  <p className='mb-0'>{startDate && moment(startDate).format("DD MMMM YYYY")}</p>
                </div>
              </div>
              <div className='col-12'>
                <div className="form-group">
                  <label className="form-label">Sub Task : </label>
                  <div>
                    {checkSheet && SUbTaskValue && SUbTaskValue.subtask.length > 0 ?
                      SUbTaskValue.subtask.map((item) => (
                        <div>
                          <label className="custom-checkbox">
                            <input type="checkbox" onChange={(e) => getSubTask(e, item.id)} />
                            <span className="checkmark" />
                            <span className="text-">{item.name}</span>
                          </label>
                        </div>
                      )) : "null"
                    }

                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div className="form-group">
                  <label className="form-label">Remarks : </label>
                  <textarea className="form-control" name="remarks" rows="3" onChange={(e) => setSubTaskDetails({ ...subTaskDetails, remark: e.target.value })}></textarea>
                </div>
              </div>
              <div className='col-12'>
                <div className="form-group">
                  <label className="form-label">File : {SUbTaskValue && SUbTaskValue.file_is_require == true ? <span className='text-danger'>*</span> : ""}</label>
                  <input className="form-control" accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx , .doc" required={SUbTaskValue && SUbTaskValue.file_is_require} type='file' onChange={fileUpload} />
                </div>
              </div>
              <div className='col-12'>
                <div className='form-group'>
                  <label className='form-label'>Attach file link</label>
                  {/* <textarea className='form-control' name='attachment_file_link' placeholder='Attach Link' cols={14} onChange={(e) => setTaskCloseInputValue({ ...taskCloseInputValue, done_file_link: e.target.value })} /> */}
                  <AutoHeightTextarea placeholder="Attach file link"
                    value={subTaskDetails.done_file_link}
                    onChange={(e) =>
                      setSubTaskDetails({
                        ...subTaskDetails,
                        done_file_link: e.target.value
                      })
                    } />
                  <p className='mb-0 mt-1 f-s-12'>
                    <div className='d-flex gap-2 mb-1'>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width={20}
                        height={20}
                        x={0}
                        y={0}
                        viewBox="0 0 511.999 511.999"
                        xmlSpace="preserve"
                        className=""
                      >
                        <g>
                          <path
                            fill="#0066dd"
                            d="m38.563 418.862 22.51 39.042c4.677 8.219 11.41 14.682 19.319 19.388l80.744-57.248.147-82.19-80.577-36.303L0 337.565c-.016 9.09 2.313 18.185 6.991 26.404z"
                            opacity={1}
                            data-original="#0066dd"
                          />
                          <path
                            fill="#00ad3c"
                            d="m256.293 173.808 4.212-107.064-84.604-32.663c-7.926 4.678-14.682 11.117-19.389 19.319L7.085 311.186C2.379 319.389.016 328.475 0 337.565l161.283.288z"
                            opacity={1}
                            data-original="#00ad3c"
                          />
                          <path
                            fill="#00831e"
                            d="m256.293 173.808 77.503-41.694 3.387-97.745c-7.909-4.706-16.996-7.068-26.379-7.085l-108.499-.194c-9.384-.017-18.479 2.606-26.405 6.991z"
                            opacity={1}
                            data-original="#00831e"
                          />
                          <path
                            fill="#0084ff"
                            d="m350.716 338.192-189.434-.338-80.89 139.438c7.909 4.706 16.996 7.068 26.379 7.085l297.933.532c9.384.017 18.479-2.606 26.405-6.991l.314-93.66z"
                            opacity={1}
                            data-original="#0084ff"
                          />
                          <path
                            fill="#ff4131"
                            d="M431.109 477.919c7.926-4.678 14.682-11.117 19.388-19.319l9.413-16.111 45.005-77.629c4.706-8.202 7.069-17.288 7.085-26.379l-93.221-49.051-67.768 48.764z"
                            opacity={1}
                            data-original="#ff4131"
                          />
                          <path
                            fill="#ffba00"
                            d="m430.756 182.917-74.253-129.16c-4.677-8.22-11.41-14.683-19.32-19.389l-80.891 139.439 94.423 164.385 160.99.288c.016-9.09-2.314-18.185-6.991-26.405z"
                            opacity={1}
                            data-original="#ffba00"
                          />
                        </g>
                      </svg>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width={20}
                        height={20}
                        x={0}
                        y={0}
                        viewBox="0 0 447.232 447.232"
                        xmlSpace="preserve"
                        className=""
                      >
                        <g>
                          <path
                            d="M207.527 251.676 92.903 177.758a10.272 10.272 0 0 0-12.007.63L3.833 240.403c-5.458 4.392-5.015 12.839.873 16.636l114.624 73.918a10.272 10.272 0 0 0 12.007-.63l77.063-62.014c5.458-4.393 5.015-12.84-.873-16.637zM238.833 268.312l77.063 62.014a10.272 10.272 0 0 0 12.007.63l114.624-73.918c5.888-3.797 6.331-12.244.873-16.636l-77.063-62.014a10.272 10.272 0 0 0-12.007-.63l-114.624 73.918c-5.887 3.797-6.331 12.244-.873 16.636zM208.4 74.196l-77.063-62.014a10.272 10.272 0 0 0-12.007-.63L4.706 85.47c-5.888 3.797-6.331 12.244-.873 16.636l77.063 62.014a10.272 10.272 0 0 0 12.007.63l114.624-73.918c5.888-3.797 6.331-12.244.873-16.636zM442.527 85.47 327.903 11.552a10.272 10.272 0 0 0-12.007.63l-77.063 62.014c-5.458 4.392-5.015 12.839.873 16.636l114.625 73.918a10.272 10.272 0 0 0 12.007-.63l77.063-62.014c5.457-4.393 5.014-12.84-.874-16.636z"
                            style={{}}
                            fill="#1587ea"
                            data-original="#1587ea"
                            className=""
                          />
                          <path
                            d="m218 279.2-86.3 68.841a9.319 9.319 0 0 1-10.861.547L99.568 334.87c-6.201-3.999-14.368.453-14.368 7.831v7.416a9.32 9.32 0 0 0 4.488 7.969l128.481 77.884a9.317 9.317 0 0 0 9.661 0l128.481-77.884a9.319 9.319 0 0 0 4.488-7.969v-6.619c0-7.378-8.168-11.83-14.368-7.831l-20.024 12.913a9.318 9.318 0 0 1-10.876-.559l-85.893-68.809A9.32 9.32 0 0 0 218 279.2z"
                            style={{}}
                            fill="#1587ea"
                            data-original="#1587ea"
                            className=""
                          />
                        </g>
                      </svg>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width={20}
                        height={20}
                        x={0}
                        y={0}
                        viewBox="0 0 512 512"
                        style={{ enableBackground: "new 0 0 512 512" }}
                        xmlSpace="preserve"
                        className="hovered-paths"
                      >
                        <g>
                          <path
                            d="M191.309 122.22a102.052 102.052 0 0 0-19.709-1.92c-51.554 0-94.297 38.443-101.066 88.169C30.558 215.188 0 250.043 0 291.9c0 45.099 35.474 82.063 79.983 84.469A104.902 104.902 0 0 1 77 351.5c0-24.023 8.352-47.523 23.516-66.171 9.965-12.254 22.582-22.082 36.705-28.775 8.955-60.228 57.84-107.594 118.78-114.246l30-27.441-30-27.441c-24.612 4.462-47.2 16.544-64.692 34.794z"
                            style={{}}
                            fill="#007caa"
                            data-original="#007caa"
                            className=""
                          />
                          <path
                            d="M165.75 276.5c0 .589.005 1.179.015 1.77C132.188 285.708 107 315.718 107 351.5c0 41.355 33.645 75 75 75h74l60-126.979-60-126.979c-50.938 7.192-90.25 51.067-90.25 103.958z"
                            style={{}}
                            fill="#007caa"
                            data-original="#007caa"
                            className=""
                          />
                          <path
                            d="M450.959 277.802C444.343 234.653 406.971 201.5 362 201.5c-5.471 0-10.917.497-16.281 1.483-19.695-20.116-46.521-31.483-74.969-31.483-5.006 0-9.929.36-14.75 1.041V426.5h181c41.355 0 75-33.645 75-75 0-36.587-26.331-67.139-61.041-73.698zM355.844 171.659a119.715 119.715 0 0 1 37.375 3.988C380.139 123.906 333.198 85.5 277.45 85.5c-7.267 0-14.44.655-21.45 1.926v54.881c4.846-.529 9.766-.808 14.75-.808 31.441.001 61.14 10.615 85.094 30.16z"
                            style={{}}
                            fill="#015e82"
                            data-original="#015e82"
                            className="hovered-path"
                          />
                        </g>
                      </svg>
                    </div>
                    <i className='fas fa-info-circle me-1 text-info'></i>
                    To quickly add multiple file links, paste a link and press Enter on your keyboard to add additional file links.
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-between flex-wrap'>
            {/* <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Chat
                                </Tooltip>
                            }
                        >
                            <button type="button" className="me-1 table-action-btn" onClick={() => handleShowChatWrap()}>
                                <i className="fas fa-comment-dots"></i>
                            </button>
                        </OverlayTrigger> */}

            <button className='btn btn-exp-green' >
              Confirm
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* ===================================chat box ==================================*/}
      {/* <CheckSheetMsg
      showChatWrap={showChatWrap} 
      handleCloseChatWrap={handleCloseChatWrap} 
      data={chatValue} 
      selectedValue={""} 
      GetToDoSheet={""} 
      GetInProgressSheet={""} 
      GetCompleteSheet={""} 
      GetCloseSheet={""} 
      /> */}
    </>
  )
}

export default CheckSheetDetails