import React, { useEffect, useRef, useState } from 'react'
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { UserAuth } from '../../auth/Auth';
import { PrivateAxios, url } from '../../../environment/AxiosInstance';
import { useReactToPrint } from 'react-to-print';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const optionsChecksheet = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        color: '#9e9e9e',
        align: 'start', // Left-align the labels
        usePointStyle: true,
        padding: 10,
      },
    },
    // title: {
    //     display: true,
    //     fontSize: 20,
    //     text: 'Checksheet'
    // }
  },
  cutout: '60%',
}


function ChecksheetReportIndex() {

  const { User, MDExist, userDetails } = UserAuth();
  const currentDate = new Date();
  const startDateInput = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
  const endDateInput = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);

  const [TaskData, setTaskData] = useState({
    "startDate": startDateInput,
    "endDate": endDateInput,
    "doer_id": "",
    "pie": 0,
    "chat": 0,
  })
  const [reportData, setReportData] = useState([])
  const GetReport = (data) => {
    PrivateAxios.post('report/dynamic/sheet-report', data)
      .then((res) => {
        setReportData(res.data.data);

      }).catch((err) => {
        console.log(err);

      })

  }


  const GetReportData = (e) => {
    e.preventDefault();
    GetReport(TaskData)
  }


  const labels = reportData.map(item => `${User.find(data => data.id == item.userId)?.name}`);

  const data = {
    labels,
    datasets: [
      {
        label: 'Total Task',
        data: reportData.map(item => item.total),
        backgroundColor: 'rgb(97, 97, 255)',
      },
      {
        label: 'Pending Task',
        data: reportData.map(item => item.pending),
        backgroundColor: 'rgb(223, 47, 74)',
      },
      {
        label: 'Completed Task',
        data: reportData.map(item => item.complete),
        backgroundColor: 'rgb(0, 200, 117)',
      },
      {
        label: 'To DO Task',
        data: reportData.map(item => item.todo),
        backgroundColor: '#747373',
      },
      {
        label: 'Inprogress Task',
        data: reportData.map(item => item.inprogress),
        backgroundColor: 'rgb(254, 171, 61)',
      },
    ],
  };

  const BarData = {
    labels: [
      'Total Task',
      'Pending Task',
      'Completed Task',
      'To Do Task',
      'Inprogress Task',
    ],
    datasets: [
      {
        data: [
          reportData.map(item => item.total) || 0,
          reportData.map(item => item.pending) || 0,
          reportData.map(item => item.complete) || 0,
          reportData.map(item => item.todo) || 0,
          reportData.map(item => item.inprogress) || 0
        ],
        backgroundColor: [
          'rgb(16, 148, 6)',
          'rgb(255, 230, 43)',
          'rgb(63, 213, 52)',
          'rgb(127, 127, 127)',
          'rgb(89, 71, 208)',
        ],
        borderWidth: 0,
      },
    ],
  };

  const contentRef = useRef(null);
  const reactToPrintFn = useReactToPrint({ contentRef });
  //dark theme select
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  // Check for the theme by looking at the body's class list
  useEffect(() => {
    const checkTheme = () => {
      setIsDarkTheme(document.body.classList.contains("dark-theme"));
    };

    // Initial check
    checkTheme();

    // MutationObserver to detect theme changes on body
    const observer = new MutationObserver(checkTheme);
    observer.observe(document.body, { attributes: true, attributeFilter: ["class"] });

    // Clean up observer on component unmount
    return () => observer.disconnect();
  }, []);
  //dark theme select end

  const [hide, setHide] = useState(false);
  //custom range select
  const [selectedRange, setSelectedRange] = useState('3m'); // Default selection
  const [fmsData, setFmsData] = useState({ startDate: null, endDate: null });
  const handleRadioChange = (event) => {
    setSelectedRange(event.target.value);
  };

  return (
    <React.Fragment>
      <div className="p-4" ref={contentRef}>
        <div className="mb-4 d-flex align-items-center justify-content-betweem">
          <Link className="text-dark back-btn me-3" to="/reports">
            <i className="fas fa-long-arrow-alt-left me-1" />Back
          </Link>
        </div>
        <form className='card mb-4' onSubmit={GetReportData}>
          <div className="card-header">
            <h3 className="card-title">CheckSheet REPORT</h3>
          </div>
          <div className='card-body pb-1'>
            <div className='w-100'>
              <div className='row'>
                {/*==== new requirement ====*/}
                {/* <div className='col-xl-6'>
                  <div className='form-group mb-1'>
                    <label className='form-label'>Quick Report <span className='text-exp-red'>*</span></label>
                    <div className="d-flex flex-wrap">
                      {['3m', '6m', '1y', '2y', 'custom'].map((val, index) => (
                        <label key={index} className="custom-radio btn-type-radio mb-2 me-3">
                          <input
                            type="radio"
                            name="reportRange"
                            value={val}
                            onChange={handleRadioChange}
                            checked={selectedRange === val}
                          />
                          <span className="checkmark" />
                          <span className="text-">
                            {val === '3m'
                              ? '3 Months'
                              : val === '6m'
                                ? '6 Months'
                                : val === '1y'
                                  ? '1 Year'
                                  : val === '2y'
                                    ? '2 Years'
                                    : 'Custom Range'}
                          </span>
                        </label>
                      ))}
                    </div>
                  </div>
                  {selectedRange === 'custom' &&
                    <div className='row'>
                      <div className='col-sm-6 col-12'>
                        <div className='form-group'>
                          <label className='form-label'>From <span className='text-exp-red'>*</span></label>
                          <div className="exp-datepicker-cont">
                            <span className="cal-icon"><i class="fas fa-calendar-alt" /></span>
                            <DatePicker
                              required
                              selected={TaskData.startDate} onChange={(date) => setTaskData({ ...TaskData, startDate: date })}
                              dateFormat="dd/MM/YYYY"
                              placeholderText='Select Date'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-6 col-12'>
                        <div className='form-group'>
                          <label className='form-label'>To <span className='text-exp-red'>*</span></label>
                          <div className="exp-datepicker-cont">
                            <span className="cal-icon"><i class="fas fa-calendar-alt" /></span>
                            <DatePicker
                              required
                              selected={TaskData.endDate} onChange={(date) => setTaskData({ ...TaskData, endDate: date })}
                              dateFormat="dd/MM/YYYY"
                              placeholderText='Select Date'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div> */}
                {/* ====new requirement end ====*/}
                <div className='col-xl-6 col-md-12 col-12'>
                  <div className='form-group'>
                    <label className='form-label'>Doer Name <span className='text-exp-red'>*</span></label>
                    <div className='custom-select-wrap multi-select'>
                      <Select
                        required
                        name='doerName'
                        options={MDExist ? User : userDetails.branch_position == "HOD" ? User.filter(item => item.departments_id == userDetails.departments_id && item.branch_id == userDetails.branch_id) : User.filter(item => item.id == userDetails.id)}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isMulti
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: '#ddddff',
                            primary: '#6161ff',
                          },
                        })}
                        onChange={(data) => {
                          const selectedValues = data ? data.map(option => option.id) : [];
                          if (selectedValues.length > 1) {
                            setHide(true)
                          } else {
                            setHide(false)
                          }
                          const MultiDoers = JSON.stringify(selectedValues);
                          setTaskData({ ...TaskData, doer_id: MultiDoers, pie: selectedValues.length > 1 ? 1 : 0, chat: selectedValues.length > 1 ? 0 : 0, })
                        }}
                        styles={{
                          menu: (base) => ({
                            ...base,
                            backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                            color: isDarkTheme ? "#e0e0e0" : "#000000",
                          }),
                          singleValue: (base) => ({
                            ...base,
                            color: isDarkTheme ? "#e0e0e0" : "#000000",
                          }),
                          option: (base, state) => ({
                            ...base,
                            backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                            color: isDarkTheme ? "#e0e0e0" : "#000000",
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-12'>
                  <div className='form-group'>
                    <label className='form-label'>From <span className='text-exp-red'>*</span></label>
                    <div className="exp-datepicker-cont">
                      <span className="cal-icon"><i class="fas fa-calendar-alt" /></span>
                      <DatePicker
                        required
                        selected={TaskData.startDate} onChange={(date) => setTaskData({ ...TaskData, startDate: date })}
                        dateFormat="dd/MM/YYYY"
                        placeholderText='Select Date'
                      />
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-12'>
                  <div className='form-group'>
                    <label className='form-label'>To <span className='text-exp-red'>*</span></label>
                    <div className="exp-datepicker-cont">
                      <span className="cal-icon"><i class="fas fa-calendar-alt" /></span>
                      <DatePicker
                        required
                        selected={TaskData.endDate} onChange={(date) => setTaskData({ ...TaskData, endDate: date })}
                        dateFormat="dd/MM/YYYY"
                        placeholderText='Select Date'
                      />
                    </div>
                  </div>
                </div>

                <div className='col-12'>
                  <div className='form-group mb-1'>
                    <div className='d-flex flex-wrap'>
                      <label className="custom-checkbox me-4">
                        Bar Chart

                        <input
                          type="checkbox"
                          name="workflow"
                          checked={TaskData.pie === 1}
                          onChange={(e) => setTaskData({ ...TaskData, pie: e.target.checked ? 1 : 0 })}
                        />
                        <span className="checkmark" />
                      </label>
                      {hide ? "" :
                        <label className="custom-checkbox me-4">
                          Pie Chart
                          <input
                            type="checkbox"
                            name="checksheet"
                            checked={TaskData.chat === 1}
                            onChange={(e) => setTaskData({ ...TaskData, chat: e.target.checked ? 1 : 0 })}
                          />
                          <span className="checkmark" />
                        </label>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer d-flex justify-content-end">
            <button type="reset" name="reset_button" className="btn btn-exp-light me-2">Reset</button>
            <button type="submit" name="submit_button" className="btn btn-exp-green">Generate Report</button>
          </div>
        </form>
        <div className="row">
          {!reportData.length > 0 ?
            <div className="col-12">
              <div className="card bg-warning-light mb-0">
                <div className="card-body">
                  <div className="exp-no-data-found text-exp-red">
                    <img className="task-img mb-3" src={process.env.PUBLIC_URL + 'assets/images/search-no-record-found.webp'} alt="No task" />
                    <h6>No Record Found</h6>
                  </div>
                </div>
              </div>
            </div> :
            <div className='col-12'>
              <div className='d-flex justify-content-end mb-3'>
                <button className='btn btn-secondary btn-sm' onClick={reactToPrintFn}>
                  <i className="fas fa-print me-2"></i>
                  Print
                </button>
              </div>
              <div className='card rounded-10 overflow-hidden mb-4'>
                <div className='card-body p-0'>
                  <div className='table-responsive'>
                    <table className='table table-striped mb-0'>
                      <thead>
                        <tr>
                          <th style={{ width: '25%' }}>Doer Name</th>
                          <th style={{ width: '10%' }}><span className='text-nowrap d-block text-center'>To Do</span></th>
                          <th style={{ width: '10%' }}><span className='text-nowrap d-block text-center'>In-Progress</span></th>
                          <th style={{ width: '10%' }}><span className='text-nowrap d-block text-center'>Complete</span></th>
                          <th style={{ width: '10%' }}><span className='text-nowrap d-block text-center'>Pending</span></th>
                          <th style={{ width: '10%' }}><span className='text-nowrap d-block text-center text-exp-red'>Percentage of Pending task</span></th>
                          <th style={{ width: '10%' }}><span className='text-nowrap d-block text-center'>Total task</span></th>
                        </tr>
                      </thead>
                      <tbody>
                        {reportData.map((data) => (
                          <tr key={data.id}>
                            <td>
                              {/* <div className="profile-wrap">
                                <div className="exp-avtar bg-exp-yellow">
                                  <span>SC</span>
                                </div>
                                <div className="ps-2 profile-name-wrap text-truncate">
                                  <h5 className="profile-name text-nowrap text-truncate">Subhadeep Chowdhury</h5>
                                </div>
                              </div> */}
                              {User && User.map((item) => (
                                item.id == data.userId ?
                                  < div className="profile-wrap" key={item.id}>
                                    <div className="exp-avtar bg-exp-yellow">
                                      {item.user_photo != null ?
                                        < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                        <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                      }
                                    </div>
                                    <div className="ps-2 profile-name-wrap text-truncate">
                                      <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                                    </div>
                                  </div> : ""
                              ))
                              }
                            </td>
                            <td>
                              <span className='d-block text-center'>{data.todo}</span>
                            </td>
                            <td>
                              <span className='d-block text-center'>{data.inprogress}</span>
                            </td>
                            <td>
                              <span className='d-block text-center'>{data.complete}</span>
                            </td>
                            <td>
                              <span className='d-block text-center'>{data.pending}</span>
                            </td>
                            <td>
                              <span className='d-block text-center text-exp-red'>{((data.pending / data.total) * 100) ? `${((data.pending / data.total) * 100).toFixed(2)} % ` : ''}</span>
                            </td>
                            <td>
                              <span className='d-block text-center'>{data.total}</span>
                            </td>
                          </tr>
                        ))

                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          }
          {reportData.length > 0 ?
            <>
              {
                TaskData.pie == 1 ?
                  <div className={`${hide ? 'col-lg-12' : 'col-lg-8'} col-md-12`}>
                    <div className='card'>
                      <div className='card-body'>
                        <div className='overflow-x-scroll'>
                          <div className='bar-card-report'>
                            <Bar data={data} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> : ""
              }
              {TaskData.chat == 1 ?
                <div className='col-lg-4 col-md-6'>
                  <div className='card'>
                    <div className='card-body'>
                      <Doughnut data={BarData} options={optionsChecksheet} />
                    </div>
                  </div>
                </div> : ""
              }
            </> : ""
          }
        </div>
      </div>
    </React.Fragment>
  )
}

export default ChecksheetReportIndex