import React, { useEffect, useRef, useState } from 'react'
import { Dropdown, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Select from 'react-select'
import { PrivateAxios, PrivateAxiosFile, url } from '../../../environment/AxiosInstance'
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UserAuth } from '../../auth/Auth';
import { SuccessMessage } from '../../../environment/ToastMessage';

let selectedValues = {};

function SubmitWorkFlow({ flowSubmitModalShow,handleSubmitChat, flowSubmitModalClose, submitForm, inProgressTask }) {

    const [formValue, setFormValue] = useState([]);
    const [formData, setFormData] = useState([]);
    const [view, setView] = useState(false)
    const { User, Logout } = UserAuth();
    const [issueDate, setIssueDate] = useState({
        startData: "",
        endDate: ""
    })
    const [taskRePlannedDate, setTaskRePlannedDate] = useState({
        startData: "",
        endDate: ""
    })
    const [initialForm, setInitialForm] = useState([]);
    const [initialTat, setInitialTat] = useState([]);
    const TatTypes = [
        { value: 'daily', label: 'Daily' },
        { value: 'hourly', label: 'Hourly' },
        { value: 'before-estimated', label: 'Before Estimated' }
    ]

    const [buttonValue, setButtonValue] = useState('');
    const [staggeredAssign, setStaggeredAssign] = useState('')
    const [staggeredCheck, setStaggeredCheck] = useState('')
    const getFormWithData = async () => {
        await PrivateAxios.post('fms/get-form-data', { fms_task_id: submitForm.fms_task_id, fms_id: submitForm.fms_id })
            .then((res) => {
                setFormValue(res.data.data);
            }).catch((err) => {
                console.log(err);
            })
    }

    const getAllSUbmitData = async () => {
        await PrivateAxios.post('fms/get-all-submit-data', { step_id: submitForm.step_id, fms_id: submitForm.fms_id, fms_task_id: submitForm.fms_task_id })
            .then((res) => {
                const sort = res.data.form.length > 0 ? res.data.form.sort((a, b) => {
                    if (a.parent_id === 0 && b.parent_id !== 0) {
                        return -1;
                    }
                    if (a.parent_id !== 0 && b.parent_id === 0) {
                        return 1;
                    }
                    return 0;
                }) : [];
                

                setFormData(sort)


                const iniForm = res.data.assignDoer.filter(item =>
                    res.data.fmsStep.some(data => item.id === data.step_id)
                ).map(item => {
                    return {
                        ...item,
                    };
                });
                setInitialForm(iniForm);
                const iniDynamic = res.data.dynamicTat.filter(item =>
                    res.data.fmsStep.some(data => item.id === data.step_id)
                ).map(item => {
                    return { ...item, };
                });
                setInitialTat(iniDynamic)
            }).catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        if (submitForm) {
            getFormWithData()
            getAllSUbmitData();
        }
    }, [submitForm])

    const [adjustMent, setAdjustMent] = useState(0)


    const submitTask = (e) => {
        e.preventDefault();
        let totalBalance = 0;
        if (formData.length > 0) {
            formData.forEach(inputData => {

                if (staggeredAssign && staggeredAssign.field_id == inputData.id) {
                    totalBalance = inputData.field_value;
                }
                if (staggeredCheck && staggeredCheck.check_field_id == inputData.id) {
                    totalBalance = inputData.field_value;
                }
                let form = new FormData();
                if (inputData.field_type == "file") {
                    form.append('fms_id', submitForm.fms_id)
                    form.append('step_id', submitForm.step_id)
                    form.append('fms_task_id', submitForm.fms_task_id)
                    form.append('field_id', inputData.id)
                    form.append('file', inputData.field_value)
                } else {
                    form.append('fms_id', submitForm.fms_id)
                    form.append('step_id', submitForm.step_id)
                    form.append('fms_task_id', submitForm.fms_task_id)
                    form.append('field_id', inputData.id)
                    form.append('field_value', inputData.field_value)
                }
                PrivateAxiosFile.post('fms/fms-task-log', form)
                    .then((res) => {
                        

                    })
                    .catch((err) => {

                    })
            });
        }
        const payload = {
            id: submitForm.id,
            initialTat: initialTat.length > 0 ? JSON.stringify(initialTat) : "",
            selectDoer: initialForm.length > 0 ? JSON.stringify(initialForm) : "",
            fms_id: submitForm.fms_id,
            step_id: submitForm.step_id,
            fms_task_id: submitForm.fms_task_id,
            btnValue: buttonValue,
            totalBalance: totalBalance,
            adjust: adjustMent
        }
        PrivateAxios.post('fms/submit-task', payload)
            .then((res) => {
                inProgressTask();
                flowSubmitModalClose();
                setTaskRePlannedDate({ ...taskRePlannedDate, startData: "" })
                setAdjustMent(0)
                const payload = {
                    fmsId: res.data.fmsId,
                    stepId: res.data.stepId,
                    fmsTaskId: res.data.fmsTaskId,
                    companyId: res.data.companyId,
                }
                PrivateAxios.post('fms/send-msg', { fms_id: submitForm.fms_id, fms_task_id: submitForm.fms_task_id })
                    .then((res) => {

                    }).catch((err) => {
                        console.log(err);
                    })
                PrivateAxios.post('fms/msg', payload)
                    .then((res) => {

                    }).catch((err) => {
                        console.log(err);
                    })

                SuccessMessage("Work Flow task has been completed")
            }).catch((err) => {
                console.log(err);

            })

    }

    const handleDataSelectField = async (data, id) => {
        const otherField = formData.filter(data => data.parent_id == id);

        setFormData(prevSteps =>
            prevSteps.map(step =>
                step.id === id ? { ...step, field_value: data.value } : step
            )
        );
        PrivateAxios.post("same-row-value", { database_id: data.database_id, count_id: data.count_id })
            .then((res) => {
                res.data.data.map((item) => {
                    otherField.map((data) => {
                        if (item.field_name_id == data.field_id) {
                            setFormData(prevSteps =>
                                prevSteps.map(step =>
                                    step.id === data.id ? { ...step, field_value: item.value } : step
                                )
                            );

                        }
                    })
                })

            }).catch((err) => {
                // console.log(err);
            })
    }

    const getAssignStaggered = () => {
        PrivateAxios.post("fms/assign-staggered", { step_id: submitForm.step_id, fms_id: submitForm.fms_id })
            .then((res) => {
                setStaggeredAssign(res.data.data);

            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }
    const getCheckedStaggered = () => {
        PrivateAxios.post("fms/check-staggered", { check_id: submitForm.step_id, fms_id: submitForm.fms_id })
            .then((res) => {
                setStaggeredCheck(res.data.data);
            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }
    const [FMSTaskData, setFMSTaskData] = useState('')
    const getFMSData = () => {
        PrivateAxios.post("fms/fms-task-details", { task_id: submitForm.fms_task_id })
            .then((res) => {
                setFMSTaskData(res.data.data);
            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }

    useEffect(() => {
        if (flowSubmitModalShow) {
            getAssignStaggered()
            getCheckedStaggered()
            getFMSData();
        }
    }, [flowSubmitModalShow])

    //dark theme select
    const [isDarkTheme, setIsDarkTheme] = useState(false);

    // Check for the theme by looking at the body's class list
    useEffect(() => {
        const checkTheme = () => {
            setIsDarkTheme(document.body.classList.contains("dark-theme"));
        };
        // Initial check
        checkTheme();

        // MutationObserver to detect theme changes on body
        const observer = new MutationObserver(checkTheme);
        observer.observe(document.body, { attributes: true, attributeFilter: ["class"] });

        // Clean up observer on component unmount
        return () => observer.disconnect();
    }, []);
    //dark theme select end

    const [skipYear, setSkipYear] = useState(false);

    const contentRef = useRef(null);
    useEffect(() => {
        if (contentRef.current) {
            const links = contentRef.current.querySelectorAll("a");
            links.forEach((link) => {
                link.setAttribute("target", "_blank");
                link.setAttribute("rel", "noopener noreferrer");
            });
        }
    }, [submitForm]);


    return (
        <Modal id="submit_work_flow" show={flowSubmitModalShow} onHide={flowSubmitModalClose} backdrop="static" keyboard={false} centered size="lg" >
            <Modal.Header closeButton className="gth-blue-light-bg">
                <Modal.Title className="gth-modal-title">{submitForm && submitForm.step.name}</Modal.Title>
            </Modal.Header>
            <form onSubmit={submitTask}>
                <Modal.Body className='pb-1'>
                    <div className='d-flex justify-content-end mb-3'>
                        <button type='button' className='btn btn-secondary btn-sm' onClick={() => setView(!view)}><i className="fas fa-eye me-2"></i>View Details</button>
                    </div>
                    <div className='row'>
                        {
                            formValue.length > 0 ? formValue.map((data) => (
                                <React.Fragment>
                                    {
                                        data.form?.display_in == 1 ?
                                            <>
                                                {data.form.field_type == 'file' ?
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label className="form-label">{data.form.label}</label>
                                                            <div>
                                                                <Link to={`${url}${data.field_value}`} target='_blank' className='mb-0'><i className="fas fa-download me-2"></i>Download</Link>
                                                            </div>
                                                        </div>
                                                    </div> :
                                                    <div className='col-md-6'>
                                                        <div className="form-group">
                                                            <label className="form-label">{data.form.label}</label>
                                                            <p className='mb-0'>{data.field_value}</p>
                                                        </div>
                                                    </div>
                                                }
                                            </> :
                                            <>
                                                {view ?
                                                    <>
                                                        {data.form.field_type == 'file' ?
                                                            <div className='col-md-6'>
                                                                <div className="form-group">
                                                                    <label className="form-label">{data.form.label}</label>
                                                                    <div>
                                                                        <Link to={`${url}${data.field_value}`} target='_blank' className='mb-0'><i className="fas fa-download me-2"></i>Download</Link>
                                                                    </div>

                                                                </div>
                                                            </div> :
                                                            <div className='col-md-6'>
                                                                <div className="form-group">
                                                                    <label className="form-label">{data.form.label}</label>
                                                                    <p className='mb-0'>{data.field_value}</p>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                    : ""
                                                }
                                            </>

                                    }
                                </React.Fragment>)) : ""
                        }
                    </div>


                    <div className='row'>
                        <div className='col-12' ref={contentRef} dangerouslySetInnerHTML={{ __html: `<h6 class="text-exp-blue">${submitForm ? submitForm.step.description : ""}</h6>` }}></div>
                        <div className='row'>
                            {
                                formData && formData.map((item, index) => (
                                    <React.Fragment key={index}>
                                        {
                                            item.field_type == "text" ?
                                                item.dynamic_table == 0 ?
                                                    <div div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                                        <div className="form-group">
                                                            <label className="form-label">{item.label} {item.is_required == 1 ? <span className="gth-text-danger">*</span> : ""}</label>
                                                            <input type="text" required={item.is_required == 1} className="form-control" placeholder="Enter Task Name" onChange={(e) => {
                                                                setFormData(prevSteps =>
                                                                    prevSteps.map(step =>
                                                                        step.id === item.id ? { ...step, field_value: e.target.value } : step
                                                                    )
                                                                );
                                                            }} />
                                                        </div>
                                                    </div> :
                                                    item.parent_id == 0 ?
                                                        <div div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                                            <label className="form-label">{item.label} {item.is_required == 1 ? <span className="gth-text-danger">*</span> : ""}</label>
                                                            <div className='custom-select-wrap'>
                                                                <Select
                                                                    name='selectMultipleDoers'
                                                                    options={item.fieldname && item.fieldname.value.length > 0 ? item.fieldname.value : ""}
                                                                    required={item.is_required == 1}
                                                                    getOptionLabel={(option) => option.value}
                                                                    getOptionValue={(option) => option.id}
                                                                    theme={(theme) => ({
                                                                        ...theme,
                                                                        colors: {
                                                                            ...theme.colors,
                                                                            primary25: '#ddddff',
                                                                            primary: '#6161ff',
                                                                        },
                                                                    })}
                                                                    styles={{
                                                                        menu: (base) => ({
                                                                            ...base,
                                                                            backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                                            color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                        }),
                                                                        singleValue: (base) => ({
                                                                            ...base,
                                                                            color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                        }),
                                                                        option: (base, state) => ({
                                                                            ...base,
                                                                            backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                                            color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                        }),
                                                                    }}
                                                                    onChange={(data) => handleDataSelectField(data, item.id)}
                                                                />
                                                            </div>

                                                        </div> :
                                                        <div div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                                            <div className="form-group">
                                                                <label className="form-label">{item.label} {item.is_required == 1 ? <span className="gth-text-danger">*</span> : ""}</label>
                                                                <input type="text" value={item.field_value} readOnly required={item.is_required == 1} className="form-control" />
                                                            </div>
                                                        </div>

                                                : ""

                                        }
                                        {
                                            item.field_type == "email" ?
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <div className="form-group">
                                                        <label className="form-label">{item.label}{item.is_required == 1 ? <span className="gth-text-danger">*</span> : ""}</label>
                                                        <input type="email" required={item.is_required == 1} className="form-control" placeholder="Enter email" onChange={(e) => {
                                                            setFormData(prevSteps =>
                                                                prevSteps.map(step =>
                                                                    step.id === item.id ? { ...step, field_value: e.target.value } : step
                                                                )
                                                            );
                                                        }} />
                                                    </div>
                                                </div> : ""
                                        }
                                        {
                                            item.field_type == "number" ?
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <div className="form-group">
                                                        <label className="form-label">{item.label}{item.is_required == 1 ? <span className="gth-text-danger">*</span> : ""}</label>
                                                        <input type="number" required={item.is_required == 1} className="form-control" onChange={(e) => {
                                                            setFormData(prevSteps =>
                                                                prevSteps.map(step =>
                                                                    step.id === item.id ? { ...step, field_value: e.target.value } : step
                                                                )
                                                            );
                                                        }} />
                                                    </div>
                                                </div> : ""
                                        }
                                        {
                                            item.field_type == "tel" ?
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                                    <div className="form-group">
                                                        <label className="form-label">{item.label}{item.is_required == 1 ? <span className="gth-text-danger">*</span> : ""}</label>
                                                        <input type="tel" required={item.is_required == 1} className="form-control" onChange={(e) => {
                                                            setFormData(prevSteps =>
                                                                prevSteps.map(step =>
                                                                    step.id === item.id ? { ...step, field_value: e.target.value } : step
                                                                )
                                                            );
                                                        }} />
                                                    </div>
                                                </div> : ""
                                        }
                                        {item.field_type == "select" ?
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                                <div className="form-group">
                                                    <label className="form-label">{item.label}{item.is_required == 1 ? <span className="gth-text-danger">*</span> : ""}</label>
                                                    <div className='custom-select-wrap'>
                                                        <Select
                                                            required={item.is_required == 1}
                                                            name='dropdown'
                                                            options={item.option_value && JSON.parse(item.option_value).map(data => ({
                                                                value: data,
                                                                label: data,
                                                            })
                                                            )}
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#ddddff',
                                                                    primary: '#6161ff',
                                                                },
                                                            })}
                                                            styles={{
                                                                menu: (base) => ({
                                                                    ...base,
                                                                    backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                }),
                                                                singleValue: (base) => ({
                                                                    ...base,
                                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                }),
                                                                option: (base, state) => ({
                                                                    ...base,
                                                                    backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                }),
                                                            }}
                                                            onChange={(data) => {
                                                                setFormData(prevSteps =>
                                                                    prevSteps.map(step =>
                                                                        step.id === item.id ? { ...step, field_value: data.value } : step
                                                                    )
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div> : ""
                                        }

                                        {item.field_type == "radio" ?
                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='form-group'>
                                                    <label className='form-label'>{item.label}{item.is_required == 1 ? <span className="gth-text-danger">*</span> : ""}</label>
                                                    <div className="d-flex flex-wrap">
                                                        {
                                                            item.option_value && JSON.parse(item.option_value).map((data, i) => (
                                                                <label className="custom-radio me-3 mb-2" key={i}>
                                                                    <input type="radio" name={item.field_name} onChange={(e) => {
                                                                        setFormData(prevSteps =>
                                                                            prevSteps.map(step =>
                                                                                step.id === item.id ? { ...step, field_value: data } : step
                                                                            )
                                                                        );
                                                                    }}
                                                                    />
                                                                    <span className="checkmark" />
                                                                    <span className="text-">{data}</span>
                                                                </label>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div> : ""
                                        }
                                        {item.field_type == "checkbox" ?

                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12' >
                                                <div className='form-group'>
                                                    <label className='form-label'>{item.label} {item.is_required == 1 ? <span className="gth-text-danger">*</span> : ""}</label>
                                                    <div className="d-flex flex-wrap">
                                                        {item.option_value && JSON.parse(item.option_value).map((data, i) => (

                                                            <label className="custom-checkbox me-3 mb-2" key={i}>
                                                                <input type="checkbox" value={data} name={`${item.field_name}[]`} onChange={(e) => {
                                                                    const { value, checked } = e.target;
                                                                    if (!selectedValues[item.id]) {
                                                                        selectedValues[item.id] = [];
                                                                    }
                                                                    if (checked) {
                                                                        selectedValues[item.id].push(value);
                                                                    } else {
                                                                        selectedValues[item.id] = selectedValues[item.id].filter(val => val !== value);
                                                                    }

                                                                    setFormData(prevSteps =>
                                                                        prevSteps.map(step =>
                                                                            step.id === item.id ? { ...step, field_value: JSON.stringify(selectedValues[item.id]) } : step
                                                                        )
                                                                    );
                                                                }} />
                                                                <span className="checkmark" />
                                                                <span className="text-">{data}</span>
                                                            </label>
                                                        ))
                                                        }
                                                    </div>
                                                </div>
                                            </div> : ""
                                        }
                                        {item.field_type == "file" ?
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                                <div className="form-group">
                                                    <label className="form-label">{item.label} {item.is_required == 1 ? <span className="gth-text-danger">*</span> : ""}</label>
                                                    <input type="file" required={item.is_required == 1} className="form-control" placeholder="Enter Task Name" accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx , .doc" onChange={(e) => {
                                                        setFormData(prevSteps =>
                                                            prevSteps.map(step =>
                                                                step.id === item.id ? { ...step, field_value: e.target.files[0] } : step
                                                            )
                                                        );
                                                    }} />
                                                </div>
                                            </div> : ""
                                        }
                                        {item.field_type == "date" ?
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                                <div className='form-group'>
                                                    <label className='form-label'>{item.label}{item.is_required == 1 ? <span className='text-exp-red'>*</span> : ""}</label>
                                                    <div className="exp-datepicker-cont">
                                                        <span className="cal-icon"><i className="fas fa-calendar-alt" /></span>
                                                        <DatePicker
                                                            required={item.is_required == 1}
                                                            selected={formData.find(step => step.id == item.id)?.field_value}
                                                            // showMonthYearPicker
                                                            // howMonthDropdown
                                                            // showFullMonthYearPicker
                                                            // dateFormat="dd/MM/YYYY"
                                                            placeholderText='Select Date'
                                                            showMonthDropdown // Enable the month dropdown
                                                            showYearDropdown // Enable the year dropdown
                                                            dropdownMode="select" // Use dropdown instead of scrolling
                                                            onChange={(date) => {
                                                                setFormData(prevSteps =>
                                                                    prevSteps.map(step =>
                                                                        step.id === item.id ? { ...step, field_value: date } : step
                                                                    )
                                                                );
                                                                // setTaskRePlannedDate({ ...issueDate, startData: new Date(date).toISOString().slice(0, 10) })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div> : ""
                                        }

                                    </React.Fragment>
                                ))
                            }
                        </div>
                    </div>
                    <div className='row'>
                        {
                            initialForm.length > 0 ? initialForm.map((item, index) => (
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12" key={index} >
                                    <div className="form-group">
                                        <h6 className="text-exp-blue">Assign doer to {item.name} step</h6>
                                        <div className='custom-select-wrap'>
                                            <select className="form-select" required onChange={(e) => {
                                                setInitialForm(prevSteps =>
                                                    prevSteps.map(step =>
                                                        step.id === item.id ? { ...step, select_assign_doer: e.target.value } : step
                                                    )
                                                );

                                            }}>
                                                <option selected disabled value="">Select one</option>
                                                {
                                                    item.doers && JSON.parse(item.doers).map(item => (
                                                        User.map((data) => (
                                                            data.id == item ?
                                                                <option value={data.id}>{data.name}</option> : ""
                                                        ))
                                                    ))


                                                }

                                            </select>
                                        </div>
                                    </div>
                                </div>

                            )) : ""
                        }
                    </div>

                    {
                        initialTat.length > 0 ? initialTat.map((tat) => (
                            <div className="col-lg-12 col-md-12 col-sm-12 col-6 row">
                                <div className="col-md-12">
                                    <h6 className="text-exp-blue">Assign TAT value for {tat.name}</h6>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                    <div className='form-group'>
                                        <label className='form-label'>Tat Type</label>
                                        <div className='custom-select-wrap'>
                                            <Select
                                                name='tatType'
                                                required
                                                options={tat.tat_type == "before-estimated" ? TatTypes : TatTypes.filter(data => data.value != "before-estimated")}
                                                value={TatTypes.find(data => data.value == tat.tat_type)}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#ddddff',
                                                        primary: '#6161ff',
                                                    },
                                                })}
                                                styles={{
                                                    menu: (base) => ({
                                                        ...base,
                                                        backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                    }),
                                                    singleValue: (base) => ({
                                                        ...base,
                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                    }),
                                                    option: (base, state) => ({
                                                        ...base,
                                                        backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                    }),
                                                }}
                                                onChange={(data) => {
                                                    setInitialTat(prevSteps =>
                                                        prevSteps.map(step =>
                                                            step.id === tat.id ? { ...step, tat_type: data.value } : step
                                                        )
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                    <div className='form-group'>
                                        <label className='form-label d-block w-100'>Tat Value</label>
                                        <input type='number' required className="form-control" value={tat.tat_value} placeholder="Enter" onChange={(e) => {
                                            setInitialTat(prevSteps =>
                                                prevSteps.map(step =>
                                                    step.id === tat.id ? { ...step, tat_value: e.target.value } : step
                                                )
                                            );
                                        }} />
                                    </div>
                                </div>
                            </div>)) : ""
                    }
                    {FMSTaskData.total_amount > 0 ?
                        <div className='row'>
                            <div div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="form-group">
                                    <label className="form-label">Total Value</label>
                                    <input type="text" value={FMSTaskData.total_amount} readOnly className="form-control" />
                                </div>
                            </div>
                            <div div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="form-group">
                                    <label className="form-label">Remain Value</label>
                                    <input type="text" value={FMSTaskData.remain_amount} readOnly className="form-control" />
                                </div>
                            </div>
                            <div div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="form-group">
                                    <label className="form-label">Submit Value</label>
                                    <input type="text" value={FMSTaskData.submit_amount} readOnly className="form-control" />
                                </div>
                            </div>
                        </div> : ""
                    }
                    {
                        staggeredCheck ?
                            <>
                                {
                                    staggeredCheck.adjustment == 1 ?
                                        <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                            <div className='form-group'>
                                                <label className="custom-switch mt-4" >
                                                    <span className='switch-name'>AdjustMent</span>
                                                    <input type="checkbox" name='is_require_file' checked={adjustMent == 1} onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setAdjustMent(1)
                                                        } else {
                                                            setAdjustMent(0)
                                                        }
                                                    }} />
                                                    < div className="switch-slider switch-round" />
                                                </label>
                                            </div>
                                        </div> : ""
                                }
                            </> : ""
                    }

                </Modal.Body >
                <Modal.Footer className='d-flex justify-content-between flex-wrap'>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                Chat
                            </Tooltip>
                        }
                    >
                        <button type="button" className="me-1 table-action-btn" onClick={()=>handleSubmitChat(submitForm)}>
                            <i className="fas fa-comment-dots"></i>
                        </button>
                    </OverlayTrigger>
                    <div className='d-flex gap-2 flex-wrap'>
                        {
                            submitForm && submitForm.step.acc_to_action == true ?
                                <>
                                    <button type='submit' className='btn btn-secondary d-flex' onClick={() => setButtonValue(submitForm.step.onreject_button_text)}>
                                        <i className="fas fa-times-circle me-1 mt-1"></i>{submitForm.step.onreject_button_text}
                                    </button>
                                    <button type='submit' className='btn btn-exp-green' onClick={() => setButtonValue(submitForm.step.onaccept_button_text)}>
                                        <i className="fas fa-check-circle me-1"></i>{submitForm.step.onaccept_button_text}
                                    </button>
                                </>
                                :
                                <button type='submit' className='btn btn-exp-green' onClick={() => setButtonValue("done")}>
                                    Done
                                </button>
                        }
                    </div>
                </Modal.Footer>
            </form>
        </Modal >
    )
}

export default SubmitWorkFlow