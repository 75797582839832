import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable, { createTheme } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { Collapse, Dropdown, Modal, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import Handsontable from 'handsontable/base';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import { PrivateAxios, url } from '../../environment/AxiosInstance';
import { UserAuth } from '../auth/Auth';
import Loader from '../../loader/Loader';
import Form from 'react-bootstrap/Form';
import { AllUser, GetTaskPriority, GetTaskStatus } from '../../environment/GlobalApi';
import { exportExcel, exportPDF, printTable } from '../../environment/ExportData';
import moment from 'moment';
import { ErrorMessage, SuccessMessage, WaringMessage } from '../../environment/ToastMessage';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { customStyles } from '../../environment/Datatable';
import CheckSheetMsg from '../../component/CheckSheetMsg';
import { convertLinks, convertLinksNewLine } from '../../environment/ConvertLinks';
import { TaskMode, TaskPriority } from '../../utils/StaticData';
import CheckSheetDetails from '../taskManager/checksheet/CheckSheetDetails';
import { calculateDelayInHoursEndTime } from '../../environment/Helper';
import AutoModalOn from '../CommonComponent/AutoModalOn';
import SwitchDoerModal from '../CommonComponent/SwitchDoerModal';
import DeleteModel from '../../component/DeleteModel';
import HodTab from './HodTab';

// registerAllModules();

function HodCheckSheet() {
    const { User, Logout } = UserAuth()
    const [grid, setGrid] = useState(false);
    const [data, setData] = useState([])
    const [inputSearch, setInputSearch] = useState([]);
    const [searchData, setSearchData] = useState({
        "name": "",
        "assigned_by": "",
        "assign_to": "",
        "task_priority_id": "",
        "status": ""
    })
    const [statusSearch, setStatusSearch] = useState({
        "status_label": ""
    })

    const [searchDataLabel, setSearchDataLabel] = useState({
        "assign_to": "",
        "task_priority_id": "",
    })



    const [Tab, setTab] = useState("master");
    const [loading, setLoading] = useState(false)

    //======================All Assign To Me Api Call===================
    const [tableData, setTableData] = useState([]);
    const [allTableData, setAllTableData] = useState([]);

    const GetMaster = () => {
        setLoading(true)
        PrivateAxios.get('checksheet/hod-master')
            .then((res) => {
              

                setLoading(false)
                setTableData(res.data.data)
                setAllTableData(res.data.data)
            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }

    const GetToDoSheet = () => {
        setLoading(true)
        PrivateAxios.get('checksheet/hod-to-do')
            .then((res) => {
                setLoading(false)
                setTableData(res.data.data)
                setData(res.data.data)
                setInputSearch(res.data.data)
                setAllTableData(res.data.data)
            }).catch((err) => {
                setLoading(false)
                // if (err.response.status == 401) {
                //     Logout();
                // }
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }
    const GetInProgressSheet = () => {
        setLoading(true)
        PrivateAxios.get('checksheet/hod-in-progress')
            .then((res) => {
                setLoading(false)
                setTableData(res.data.data)
                setData(res.data.data)
                setInputSearch(res.data.data)
                setAllTableData(res.data.data)
            }).catch((err) => {
                setLoading(false)
                // if (err.response.status == 401) {
                //     Logout();
                // }
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }
    const GetCompleteSheet = () => {
        setLoading(true)
        PrivateAxios.get('checksheet/hod-complete')
            .then((res) => {
                setLoading(false)
                setTableData(res.data.data)
                setData(res.data.data)
                setInputSearch(res.data.data)
                setAllTableData(res.data.data)
            }).catch((err) => {
                setLoading(false)
                // if (err.response.status == 401) {
                //     Logout();
                // }
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }
    const GetCloseSheet = () => {
        setLoading(true)
        PrivateAxios.get('checksheet/hod-close')
            .then((res) => {
                setLoading(false)
                setTableData(res.data.data)
                setData(res.data.data)
                setInputSearch(res.data.data)
                setAllTableData(res.data.data)
            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }

    useEffect(() => {
        GetMaster()
    }, [])
    //================================Tab Management==============================
    const TabChange = (data) => {
        setTab(data);
        if (data == "inprogress") {
            GetInProgressSheet();
        }
        if (data == "master") {
            GetMaster();
        }
        if (data == "complete") {
            GetCompleteSheet();
        }
        if (data == "todo") {
            GetToDoSheet();
        }
        if (data == "close") {
            GetCloseSheet();
        }
    }



    const getUser = (id) => {
        var name = User.find(data => data?.id == id)?.name;
        return name;
    }
    const getTaskMode = (id) => {
        var mode = TaskMode.find(data => data?.id == id)?.title;
        return mode;
    }

    //=================Modal open==========================//
    const [modalShow, setModalShow] = React.useState(false);
    const [modalShowData, setModalShowData] = React.useState('');

    //================ Bulk switch Upload============================//
    const [deleteTaskId, setDeleteTaskId] = useState([]);
    const DeleteBulk = (e, row) => {
        setDeleteTaskId((prevSelectedIds) =>
            e.target.checked
                ? [...prevSelectedIds, row.id]
                : prevSelectedIds.filter((selectedId) => selectedId !== row.id)
        );
    }
    const [switchDoerShowModal, setswitchDoerShowModal] = useState(false);
    const [DoerId, setDoerId] = useState('');
    const SwitchDoerCloseChecksheetModal = () => {
        setDoerId('')
        setswitchDoerShowModal(false);
    }
    const SwitchDoerOpenChecksheetModal = () => {
        setswitchDoerShowModal(true);
    }
    const SubmitBulkDoer = (e) => {
        e.preventDefault();
        if (!DoerId) {
            WaringMessage("Please filled doer name !")
            return;
        }
        const payload = {
            id: deleteTaskId.length > 0 ? JSON.stringify(deleteTaskId) : "",
            doer_id: DoerId
        }
        PrivateAxios.post("checksheet/bulk-switch-doer", payload)
            .then((res) => {
                GetMaster();
                SuccessMessage(res.data.message);
                SwitchDoerCloseChecksheetModal();
                setDeleteTaskId([]);
            }).catch((err) => {

            })

    }

    //------------------------------------------Delete Checksheet---------------------------------
    const [deleteShow, setDeleteShow] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const deleteModalClose = () => {
        setDeleteShow(false);

    }
    const deleteModalShow = (id) => {
        setDeleteShow(true);
        setDeleteId(id)
    }
    const DeleteAction = () => {
        PrivateAxios.delete(`checksheet/delete/${deleteId}`)
            .then((res) => {
                GetMaster();
                SuccessMessage(res.data.message);

                deleteModalClose();
            }).catch((err) => {

            })
    }

    //  //===========================Message Modal============================
    //     const [showChatWrap, setShowChatWrap] = useState(false);
    //     const [chatValue, setChatValue] = useState('');

    //     const handleCloseChatWrap = () => {
    //         setShowChatWrap(false);
    //     }
    //     const handleShowChatWrap = (data) => {
    //         setShowChatWrap(true);
    //         setChatValue(data)
    //     }
    //===============DataTable Declare========================
    const MasterColumns = [
        {
            name: "",
            selector: (row) => row.name,
            sortable: true,
            width: "50px",
            cell: (row) => (
                <label className="custom-checkbox">
                    <input
                        type="checkbox"
                        checked={deleteTaskId.includes(row.id) ? 1 : 0}
                        onChange={(e) => DeleteBulk(e, row)}
                    />
                    <span className="checkmark" />
                    <span className="text-">&nbsp;</span>
                </label>
            ),
        },
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            width: "240px",
            cell: (row) => (
                <>
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip>
                                {row.name}
                            </Tooltip>
                        }
                    >
                        <button className="table-data-change-btn" >
                            <div className="project-name text-truncate">
                                {/* onClick={() => DetailsModalShow(row.checkSheetList)} */}
                                <h6 className="text-truncate gap-2">{row && row.name} </h6>
                            </div>
                        </button>
                    </OverlayTrigger>
                    {row && row.subtask && row.subtask.length > 0 ?
                        <OverlayTrigger
                            trigger="click"
                            rootClose
                            placement="auto"
                            overlay={
                                <Popover id="statusChange" className="status-wrap">
                                    <div className="px-3 py-2">
                                        {
                                            row && row.subtask.map((item) => (
                                                <ul className="subtask-ul">
                                                    <li>{item.name}</li>
                                                </ul>
                                            ))
                                        }
                                    </div>
                                </Popover>
                            }
                        >
                            <button className="link-btn ms-2" >
                                <span className="text-exp-blue fw-bold" ><i class="fas fa-info-circle"></i></span>
                            </button>
                        </OverlayTrigger> : ""
                    }

                </>

            ),
        },
        {
            name: "Assignee",
            selector: (row) => row.assigned_by != 0 ? getUser(row.assigned_by) : "",
            sortable: true,
            width: "220px",
            cell: (row) => (
                <button className="table-data-change-btn" >
                    {User && User.map((item) => (
                        item.id == row.assigned_by ?
                            < div className="profile-wrap" key={item.id}>
                                <div className="exp-avtar bg-exp-yellow">
                                    {item.user_photo != null ?
                                        < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                        <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                    }
                                </div>
                                <div className="ps-2 profile-name-wrap text-truncate">
                                    <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                                </div>
                            </div> : ""
                    ))
                    }
                </button>

            ),
        },
        {
            name: "Doer",
            selector: (row) => row.assigned_by != 0 ? getUser(row.assigned_by) : "",
            sortable: true,
            width: "220px",
            cell: (row) => (
                <button className="table-data-change-btn" >
                    {User && User.map((item) => (
                        item.id == row.assign_to ?
                            < div className="profile-wrap" key={item.id}>
                                <div className="exp-avtar bg-exp-yellow">
                                    {item.user_photo != null ?
                                        < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                        <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                    }
                                </div>
                                <div className="ps-2 profile-name-wrap text-truncate">
                                    <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                                </div>
                            </div> : ""
                    ))
                    }
                </button>

            ),
        },
        {
            name: "Mode",
            selector: (row) => row && getTaskMode(row.task_mode_id),
            sortable: true,
            width: "150px",
        },
        {
            name: "Issue Date",
            selector: (row) => row && row.created_at ? moment(row.created_at).format("DD-MM-YYYY hh:mm A") : "",
            sortable: true,
            width: "190px",
        },
        {
            name: "Priority",
            selector: (row) => row.task_priority_id == 1 ? "Urgent" : row.task_priority_id == 2 ? "High" : row.task_priority_id == 3 ? "Normal" : row.task_priority_id == 4 ? "Low" : "",
            sortable: true,
            width: "130px",
            cell: (row) => (
                <div >
                    <button className="table-data-change-btn">
                        {row.task_priority_id == 1 ?
                            <div className="priority-set urgent">
                                <i className="fas fa-flag mr-2"></i>
                                <span>Urgent</span>
                            </div> :
                            row.task_priority_id == 2 ? <div div className="priority-set high priority-list-item" >
                                <i className="fas fa-flag mr-2"></i>
                                <span>High</span>
                            </div> : row.task_priority_id == 3 ?
                                <div className="priority-set normal priority-list-item" >
                                    <i className="fas fa-flag mr-2"></i>
                                    <span>Normal</span>
                                </div> : row.task_priority_id == 4 ? <div className="priority-set low priority-list-item" >
                                    <i className="fas fa-flag mr-2"></i>
                                    <span>Low</span>
                                </div> : ""
                        }
                    </button>
                </div>
            ),
        },
        {
            name: "Attached Link",
            selector: (row) => row.attachment_file_link,
            sortable: true,
            width: "130px",
            cell: (row) => (
                row.attachment_file_link ?
                    <button className="me-1 table-action-btn" onClick={() => { setModalShow(true); setModalShowData({ link: convertLinksNewLine(row.attachment_file_link), taskname: row.name }) }}>
                        <i className="fas fa-download"></i>
                    </button> : ""

            ),
        },
        {
            name: "Action",
            width: "210px",
            selector: (row) => null,
            cell: (row) => (
                <div className="d-flex">
                    {/* <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                Chat
                            </Tooltip>
                        }
                    >
                        <button className="me-1 table-action-btn" onClick={() => handleShowChatWrap(row)}>
                            <i className="fas fa-comment-dots"></i>
                        </button>
                    </OverlayTrigger> */}
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                Delete
                            </Tooltip>
                        }
                    >
                        <button className="me-1 table-action-btn" onClick={() => deleteModalShow(row.id)}>
                            <i className="fas fa-trash-alt text-exp-red"></i>
                        </button>
                    </OverlayTrigger>
                </div>
            ),
        },
    ];
    const selectedColumns = [
        {
            name: "Name",
            selector: (row) => row.checkSheetList?.name,
            sortable: true,
            width: "240px",
            cell: (row) => (
                <>
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip>
                                {row.checkSheetList?.name}
                            </Tooltip>
                        }
                    >
                        <button className="table-data-change-btn" onClick={() => TaskInfoView(row)} >
                            <div className="project-name text-truncate">
                                {/* onClick={() => DetailsModalShow(row.checkSheetList)} */}
                                <h6 className="text-truncate gap-2">{row.checkSheetList && row.checkSheetList.name} </h6>
                            </div>
                        </button>
                    </OverlayTrigger>
                    {row.checkSheetList && row.checkSheetList.subtask && row.checkSheetList?.subtask.length > 0 ?
                        <OverlayTrigger
                            trigger="click"
                            rootClose
                            placement="auto"
                            overlay={
                                <Popover id="statusChange" className="status-wrap">
                                    <div className="px-3 py-2">
                                        {
                                            row.checkSheetList && row.checkSheetList.subtask.length > 0 && row.checkSheetList.subtask.map((item) => (
                                                <ul className="subtask-ul">
                                                    <li>{item.name}</li>
                                                </ul>
                                            ))
                                        }
                                    </div>
                                </Popover>
                            }
                        >
                            <button className="link-btn ms-2" >
                                <span className="text-exp-blue fw-bold" ><i class="fas fa-info-circle"></i></span>
                            </button>
                        </OverlayTrigger> : ""
                    }

                </>

            ),
        },
        {
            name: "Assignee",
            selector: (row) => row.checkSheetList?.assigned_by != 0 ? getUser(row.checkSheetList?.assigned_by) : "",
            sortable: true,
            width: "220px",
            cell: (row) => (
                <button className="table-data-change-btn">
                    {row.checkSheetList && User && User.map((item) => (
                        item.id == row.checkSheetList.assigned_by ?
                            < div className="profile-wrap" key={item.id}>
                                <div className="exp-avtar bg-exp-yellow">
                                    {item.user_photo != null ?
                                        < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                        <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                    }
                                </div>
                                <div className="ps-2 profile-name-wrap text-truncate">
                                    <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                                </div>
                            </div> : ""
                    ))
                    }
                </button>

            ),
        },
        {
            name: "Doer",
            selector: (row) => row.doer_id != 0 ? getUser(row.doer_id) : "",
            sortable: true,
            width: "220px",
            cell: (row) => (
                <button className="table-data-change-btn">
                    {User && User.map((item) => (
                        item.id == row.doer_id ?
                            < div className="profile-wrap" key={item.id}>
                                <div className="exp-avtar bg-exp-yellow">
                                    {item.user_photo != null ?
                                        < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                        <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                    }
                                </div>
                                <div className="ps-2 profile-name-wrap text-truncate">
                                    <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                                </div>
                            </div> : ""
                    ))
                    }
                </button>

            ),
        },
        {
            name: "Mode",
            selector: (row) => row.checkSheetList && getTaskMode(row.checkSheetList.task_mode_id),
            sortable: true,
            width: "120px",
            cell: (row) => (
                <div className='w-100 h-100'>
                    <button className="table-data-change-btn text-nowrap">
                        {TaskMode.find(data => data.id == row.checkSheetList?.task_mode_id)?.title}
                    </button>
                </div>

            ),
        },
        {
            name: "Delay (Hours)",
            selector: (row) => calculateDelayInHoursEndTime(row.due_date, new Date()),
            sortable: true,
            width: "130px",
            cell: (row) => (
                <div className="task-delay-hours text-exp-red">
                    {calculateDelayInHoursEndTime(row.due_date, new Date())} hrs
                </div>
            ),
        },
        {
            name: "Planned Date",
            selector: (row) => row.due_date ? moment(row.due_date).format('DD-MM-YYYY hh:mm A') : "",
            sortable: true,
            reorder: true,
            width: "190px"
        },
        {
            name: "Priority",
            selector: (row) => row.checkSheetList && row.checkSheetList.task_priority_id == 1 ? "Urgent" : row.checkSheetList.task_priority_id == 2 ? "High" : row.checkSheetList.task_priority_id == 3 ? "Normal" : row.checkSheetList.task_priority_id == 4 ? "Low" : "",
            sortable: true,
            width: "130px",
            cell: (row) => (
                <div className='w-100 h-100'>
                    <button className="table-data-change-btn text-nowrap">
                        {row && row.checkSheetList && row.checkSheetList.task_priority_id != '' ? row.checkSheetList.task_priority_id == 1 ?
                            <div className="priority-set urgent">
                                <i className="fas fa-flag mr-2"></i>
                                <span>Urgent</span>
                            </div> :
                            row.checkSheetList.task_priority_id == 2 ? <div div className="priority-set high priority-list-item" >
                                <i className="fas fa-flag mr-2"></i>
                                <span>High</span>
                            </div> : row.checkSheetList.task_priority_id == 3 ?
                                <div className="priority-set normal priority-list-item" >
                                    <i className="fas fa-flag mr-2"></i>
                                    <span>Normal</span>
                                </div> : row.checkSheetList.task_priority_id == 4 ? <div className="priority-set low priority-list-item" >
                                    <i className="far fa-stop-circle mr-2"></i>
                                    <span>Low</span>
                                </div> : "" : ""
                        }
                    </button>
                </div>
            ),
        },
        {
            name: "Status",
            selector: (row) => row.status == 1 ? "To Do" : row.status == 2 ? "In Progress" : row.status == 3 ? "Done" : row.status == 4 ? "Close" : "",
            sortable: true,
            width: "150px",
            cell: (row) => (
                <div className='w-100 h-100'>
                    <button className="table-data-change-btn text-nowrap">
                        {row.status == 1 ?
                            <div className="status-item todo status-list-item">
                                <i className="far fa-dot-circle me-2"></i>
                                <span>To Do</span>
                            </div> : row.status == 2 ?
                                <div div className="status-item inprogress">
                                    <i className="fas fa-redo me-2"></i>
                                    <span>In Progress</span>
                                </div> : row.status == 3 ?
                                    <div className="status-item completed status-list-item">
                                        <i className="fas fa-check-circle me-2"></i>
                                        <span>Completed</span>
                                    </div> : row.status == 4 ?
                                        <div className="status-item status-list-item">
                                            <i className="far fa-stop-circle me-2"></i>
                                            <span>Close</span>
                                        </div> : ""
                        }
                    </button>
                </div>


            ),
        },
        {
            name: "Attached Link",
            selector: (row) => row.checkSheetList?.attachment_file_link,
            sortable: true,
            width: "130px",
            cell: (row) => (
                row.checkSheetList?.attachment_file_link ?
                    <button className="me-1 table-action-btn" onClick={() => { setModalShow(true); setModalShowData({ link: convertLinksNewLine(row.checkSheetList.attachment_file_link), taskname: row.checkSheetList.name }) }}>
                        <i className="fas fa-download"></i>
                    </button> : ""

            ),
        },
        {
            name: "Action",
            width: "210px",
            selector: (row) => null,
            cell: (row) => (
                <div className="d-flex">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                View
                            </Tooltip>
                        }
                    >
                        <button className="me-1 table-action-btn" onClick={() => TaskInfoView(row)}>
                            <i className="fas fa-eye"></i>
                        </button>
                    </OverlayTrigger>
                    {row.status == 9 || row.status == 8 ? '' :
                        <>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        Close
                                    </Tooltip>
                                }
                            >
                                <button className="me-1 table-action-btn" onClick={() => TaskCloseView(row.id)}>
                                    <i className="far fa-stop-circle"></i>
                                </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        High Alert
                                    </Tooltip>
                                }
                            >
                                <button className="me-1 table-action-btn" onClick={() => HighAlertView(row.checkSheetList.id, row.id)}>
                                    <i className="fas fa-exclamation-circle text-exp-red"></i>
                                </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        Switch Doer
                                    </Tooltip>
                                }
                            >
                                <button className="me-1 table-action-btn" onClick={() => SwitchDoerView(row.checkSheetList.id, row.id)}>
                                    <i className="fas fa-exchange-alt"></i>
                                </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        Message
                                    </Tooltip>
                                }
                            >
                                <button className="me-1 table-action-btn" onClick={() => handleShowChatWrap(row.checkSheetList)}>
                                    <i className="fas fa-comment-dots"></i>
                                </button>
                            </OverlayTrigger>
                        </>
                    }
                </div>
            ),
        },
    ];
    const completeColumns = [
        {
            name: "Name",
            selector: (row) => row.checkSheetList && row.checkSheetList.name,
            sortable: true,
            width: "240px",
            cell: (row) => (
                <>
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip>
                                {row.checkSheetList?.name}
                            </Tooltip>
                        }
                    >
                        <button className="table-data-change-btn" onClick={() => TaskInfoView(row)}>
                            <div className="project-name text-truncate">
                                {/* onClick={() => DetailsModalShow(row.checkSheetList)} */}
                                <h6 className="text-truncate gap-2">{row.checkSheetList && row.checkSheetList.name} </h6>
                            </div>
                        </button>
                    </OverlayTrigger>
                    {row.checkSheetList && row.checkSheetList.subtask && row.checkSheetList.subtask.length > 0 ?
                        <OverlayTrigger
                            trigger="click"
                            rootClose
                            placement="auto"
                            overlay={
                                <Popover id="statusChange" className="status-wrap">
                                    <div className="px-3 py-2">
                                        {
                                            row.checkSheetList && row.checkSheetList.subtask.length > 0 && row.checkSheetList.subtask.map((item) => (
                                                <ul className="subtask-ul">
                                                    <li>{item.name}</li>
                                                </ul>
                                            ))
                                        }
                                    </div>
                                </Popover>
                            }
                        >
                            <button className="link-btn ms-2" >
                                <span className="text-exp-blue fw-bold" ><i class="fas fa-info-circle"></i></span>
                            </button>
                        </OverlayTrigger> : ""
                    }

                </>

            ),
        },
        {
            name: "Assignee",
            selector: (row) => row.checkSheetList && row.checkSheetList.assigned_by != 0 ? getUser(row.checkSheetList.assigned_by) : "",
            sortable: true,
            width: "220px",
            cell: (row) => (
                <button className="table-data-change-btn">
                    {row.checkSheetList && User && User.map((item) => (
                        item.id == row.checkSheetList.assigned_by ?
                            < div className="profile-wrap" key={item.id}>
                                <div className="exp-avtar bg-exp-yellow">
                                    {item.user_photo != null ?
                                        < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                        <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                    }
                                </div>
                                <div className="ps-2 profile-name-wrap text-truncate">
                                    <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                                </div>
                            </div> : ""
                    ))
                    }
                </button>

            ),
        },
        {
            name: "Doer",
            selector: (row) => row.doer_id != 0 ? getUser(row.doer_id) : "",
            sortable: true,
            width: "220px",
            cell: (row) => (
                <button className="table-data-change-btn">
                    {User && User.map((item) => (
                        item.id == row.doer_id ?
                            < div className="profile-wrap" key={item.id}>
                                <div className="exp-avtar bg-exp-yellow">
                                    {item.user_photo != null ?
                                        < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                        <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                    }
                                </div>
                                <div className="ps-2 profile-name-wrap text-truncate">
                                    <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                                </div>
                            </div> : ""
                    ))
                    }
                </button>

            ),
        },
        {
            name: "Mode",
            selector: (row) => row.checkSheetList && getTaskMode(row.checkSheetList.task_mode_id),
            sortable: true,
            width: "120px",
            cell: (row) => (
                <div className='w-100 h-100'>
                    <button className="table-data-change-btn text-nowrap">
                        {TaskMode.find(data => data.id == row.checkSheetList?.task_mode_id)?.title}
                    </button>
                </div>

            ),
        },
        {
            name: "Delay (Hours)",
            selector: (row) => calculateDelayInHoursEndTime(row.due_date, row.completed_date),
            sortable: true,
            width: "130px",
            cell: (row) => (
                <div className="task-delay-hours text-exp-red">
                    {calculateDelayInHoursEndTime(row.due_date, row.completed_date)} hrs
                </div>
            ),
        },
        {
            name: "Planned Date",
            selector: (row) => row.due_date ? moment(row.due_date).format('DD-MM-YYYY | hh:mm A') : "",
            sortable: true,
            reorder: true,
            width: "180px"
        },
        {
            name: "Complete Date",
            selector: (row) => row.completed_date ? moment(row.completed_date).format('DD-MM-YYYY | hh:mm A') : '',
            sortable: true,
            reorder: true,
            width: "180px",
        },
        {
            name: "Priority",
            selector: (row) => row.checkSheetList && row.checkSheetList.task_priority_id == 1 ? "Urgent" : row.checkSheetList.task_priority_id == 2 ? "High" : row.checkSheetList.task_priority_id == 3 ? "Normal" : row.checkSheetList.task_priority_id == 4 ? "Low" : "",
            sortable: true,
            width: "120px",
            cell: (row) => (
                <div className='w-100 h-100'>
                    <button className="table-data-change-btn text-nowrap">
                        {row && row.checkSheetList && row.checkSheetList.task_priority_id != '' ? row.checkSheetList.task_priority_id == 1 ?
                            <div className="priority-set urgent">
                                <i className="fas fa-flag mr-2"></i>
                                <span>Urgent</span>
                            </div> :
                            row.checkSheetList.task_priority_id == 2 ? <div div className="priority-set high priority-list-item" >
                                <i className="fas fa-flag mr-2"></i>
                                <span>High</span>
                            </div> : row.checkSheetList.task_priority_id == 3 ?
                                <div className="priority-set normal priority-list-item" >
                                    <i className="fas fa-flag mr-2"></i>
                                    <span>Normal</span>
                                </div> : row.checkSheetList.task_priority_id == 4 ? <div className="priority-set low priority-list-item" >
                                    <i className="far fa-stop-circle mr-2"></i>
                                    <span>Low</span>
                                </div> : "" : ""
                        }
                    </button>
                </div>
            ),
        },
        {
            name: "Attached Link",
            selector: (row) => row.checkSheetList?.attachment_file_link,
            sortable: true,
            width: "130px",
            cell: (row) => (
                row.checkSheetList?.attachment_file_link ?
                    <button className="me-1 table-action-btn" onClick={() => { setModalShow(true); setModalShowData({ link: convertLinksNewLine(row.checkSheetList.attachment_file_link), taskname: row.checkSheetList.name }) }}>
                        <i className="fas fa-download"></i>
                    </button> : ""

            ),
        },
        {
            name: "Status",
            selector: (row) => row.status == 1 ? "To Do" : row.status == 2 ? "In Progress" : row.status == 3 ? "Done" : row.status == 4 ? "Close" : "",
            sortable: true,
            width: "150px",
            cell: (row) => (
                <div className='w-100 h-100'>
                    <button className="table-data-change-btn text-nowrap">
                        {row.status == 1 ?
                            <div className="status-item todo status-list-item">
                                <i className="far fa-dot-circle me-2"></i>
                                <span>To Do</span>
                            </div> : row.status == 2 ?
                                <div div className="status-item inprogress">
                                    <i className="fas fa-redo me-2"></i>
                                    <span>In Progress</span>
                                </div> : row.status == 3 ?
                                    <div className="status-item completed status-list-item">
                                        <i className="fas fa-check-circle me-2"></i>
                                        <span>Completed</span>
                                    </div> : row.status == 4 ?
                                        <div className="status-item status-list-item">
                                            <i className="far fa-stop-circle me-2"></i>
                                            <span>Close</span>
                                        </div> : ""
                        }
                    </button>
                </div>


            ),
        },
        {
            name: "Remarks",
            selector: (row) => row.task_remarks,
            sortable: true,
            width: "190px",
            cell: (row) => (
                <p>{row.task_remarks ? convertLinks(row.task_remarks) : ""}</p>

            ),
        },
        {
            name: "Remarks Link",
            selector: (row) => row.done_file_link,
            sortable: true,
            width: "120px",
            cell: (row) => (
                row.done_file_link ?
                    <button className="me-1 table-action-btn m-auto" onClick={() => { setModalShow(true); setModalShowData({ link: convertLinksNewLine(row.done_file_link), taskname: row.checkSheetList.name }) }}>
                        <i className="fas fa-download"></i>
                    </button> : ""

            ),
        },
        {
            name: "Action",
            width: "210px",
            selector: (row) => null,
            cell: (row) => (
                <div className="d-flex">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                View
                            </Tooltip>
                        }
                    >
                        <button className="me-1 table-action-btn" onClick={() => TaskInfoView(row)}>
                            <i className="fas fa-eye"></i>
                        </button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                Chat
                            </Tooltip>
                        }
                    >
                        <button className="me-1 table-action-btn" onClick={() => handleShowChatWrap(row.checkSheetList)}>
                            <i className="fas fa-comment-dots"></i>
                        </button>
                    </OverlayTrigger>
                </div>
            ),
        },
    ];


    //==================================Collapse===========================
    const [isCollapsed, setIsCollapsed] = useState(false);
    const collapseRef = useRef(null);
    const [height, setHeight] = useState('0px');
    const handleToggle = () => {
        setIsCollapsed(!isCollapsed);
    };
    useEffect(() => {
        if (isCollapsed) {
            setHeight(`${collapseRef.current.scrollHeight}px`);
        } else {
            setHeight('0px');
        }
    }, [isCollapsed]);



    // const TaskData = async () => {
    //     setIsLoading(true)
    //     PrivateAxios.get("checksheet/system-checksheet").then((res) => {
    //         setTableData(res.data.data);
    //         setData(res.data.data)
    //         setInputSearch(res.data.data)
    //         setIsLoading(false)
    //     }).catch((err) => {
    //         setIsLoading(false)
    //         if (err.response.data == 401) {
    //             Logout();
    //         }
    //     })
    // }

    // for task details modal
    const [showScTaskDetails, setShowScTaskDetails] = useState(false);
    const handleCloseScTaskDetails = () => setShowScTaskDetails(false);
    const handleShowScTaskDetails = () => setShowScTaskDetails(true);
    // for attachment list action
    const attachmentListAction = (
        <Popover id="statusChange" className="action-wrap">
            <div className="action-list">
                <div className="action-list-item">
                    <i className="fas fa-download me-2"></i>
                    <span>Download</span>
                </div>
                <div className="action-list-item">
                    <i className="fas fa-trash-alt text-exp-red me-2"></i>
                    <span>Delete</span>
                </div>
            </div>
        </Popover>
    );
    // for attachment list action end
    // for Doer selection    
    const [showDoer, setShowDoer] = useState(false);
    const handleCloseDoer = () => setShowDoer(false);
    const handleShowDoer = () => setShowDoer(true);




    //for datepicker
    const [task, setTask] = useState({
        startData: "",
        endDate: ""
    })


    const [open, setOpen] = useState(false);
    const SearchDataButtonMaster = (e) => {
        e.preventDefault();
        if (Tab == 'master') {
            const filterData = allTableData.filter((item) => {
                return Object.keys(searchDataMaster).every(key => {
                    const searchValue = searchDataMaster[key];
                    let itemValue = '';
                    if (key == "status") {
                        itemValue = item[key];
                    } else {
                        itemValue = item[key];
                    }
                    if (!searchValue) return true;
                    if (itemValue === undefined || itemValue === null) return false;
                    if (typeof itemValue === 'string') {
                        return itemValue.toLowerCase().replace(/\s+/g, '').includes(searchValue.toLowerCase().replace(/\s+/g, ''));
                    }

                    if (typeof itemValue === 'number') {
                        return itemValue.toString().replace(/\s+/g, '').includes(searchValue.toString().replace(/\s+/g, ''));
                    }
                    return false;
                })
                //  && (
                //         (!task.startData || new Date(item.due_date) >= new Date(task.startData)) &&
                //         (!task.endDate || new Date(item.due_date) <= new Date(task.endDate))
                //     )
            })
            setTableData(filterData);

        } else {
            const filterData = allTableData.filter((item) => {
                return Object.keys(searchData).every(key => {
                    const searchValue = searchData[key];
                    let itemValue = '';
                    if (key == "status") {
                        itemValue = item[key];
                    } else {
                        itemValue = item.checkSheetList[key];
                    }
                    if (!searchValue) return true;
                    if (itemValue === undefined || itemValue === null) return false;
                    if (typeof itemValue === 'string') {
                        return itemValue.toLowerCase().replace(/\s+/g, '').includes(searchValue.toLowerCase().replace(/\s+/g, ''));
                    }

                    if (typeof itemValue === 'number') {
                        return itemValue.toString().replace(/\s+/g, '').includes(searchValue.toString().replace(/\s+/g, ''));
                    }
                    return false;
                }) && (
                        (!task.startData || new Date(item.due_date) >= new Date(task.startData)) &&
                        (!task.endDate || new Date(item.due_date) <= new Date(task.endDate))
                    )
            })
            setTableData(filterData);
        }
    }
    const SearchData = (e) => {
        e.preventDefault();
        const filterData = data.filter((item) => {
            return Object.keys(searchData).every(key => {
                const searchValue = searchData[key];
                let itemValue = '';
                if (key == "status" || key == "doer_id") {
                    itemValue = item[key];
                } else {
                    itemValue = item.checkSheetList ? item.checkSheetList[key] : null;
                }
                if (!searchValue) return true;
                if (itemValue === undefined || itemValue === null) return false;
                if (typeof itemValue === 'string') {
                    return itemValue.toLowerCase().replace(/\s+/g, '').includes(searchValue.toLowerCase().replace(/\s+/g, ''));
                }

                if (typeof itemValue === 'number') {
                    return itemValue.toString().replace(/\s+/g, '').includes(searchValue.toString().replace(/\s+/g, ''));
                }
                return false;
            }) && (
                    // Handle date range filtering
                    (!task.startData || new Date(item.due_date) >= new Date(task.startData)) &&
                    (!task.endDate || new Date(item.due_date) <= new Date(task.endDate))
                )
        })
        setTableData(filterData)
        setInputSearch(filterData)
    }

    const [openMaster, setOpenMaster] = useState(false);
    const [searchDataMaster, setSearchDataMaster] = useState({
        "name": "",
        "assign_to": "",
        "assigned_by": "",
        "task_priority_id": "",
        "task_mode_id": ""
    })
    // const ClearAll = () => {

    // }
    const ClearAllFilter = () => {
        // setTableData(data);
        // setSearchData({
        //     "name": "",
        //     "assigned_by": "",
        //     "assign_to": "",
        //     "task_priority_id": "",
        // });
        // setTask({
        //     startData: "",
        //     endDate: ""
        // })
        setSearchData({
            "name": "",
            "assign_to": "",
            "assigned_by": "",
            "task_priority_id": "",
        });
        setSearchDataMaster({
            "name": "",
            "doer_id": "",
            "assigned_by": "",
            "task_priority_id": "",
            "task_mode_id": ""
        });
        setTask({
            startData: "",
            endDate: ""
        })
        setTableData(allTableData);

    }

    const handleKeyUpSearch = (e) => {
        const filteredItems = data.filter((item) => {
            return item && item.checkSheetList?.name && item.checkSheetList.name.toLowerCase().replace(/\s+/g, '').includes(e.target.value.toLowerCase().replace(/\s+/g, ''))
        }
        );
        setTableData(filteredItems);
    }
    // exportExcel
    const generatePdf = () => {
        exportPDF(selectedColumns, tableData, "Checksheet")
    }
    const generateExcel = () => {
        exportExcel(selectedColumns, tableData, "Checksheet")
    }
    const generatePrint = () => {
        printTable(selectedColumns, tableData, "Checksheet")
    }
    const generatePdfComplete = () => {
        exportPDF(completeColumns, tableData, "CheckList")
    }
    const generateExcelComplete = () => {
        exportExcel(completeColumns, tableData, "CheckList")
    }
    const generatePrintComplete = () => {
        printTable(completeColumns, tableData, "CheckList")
    }
    const clearAll = () => {
        setTableData(data);
        setInputSearch(data)
        document.getElementById('contentId').classList.remove('show');
        setSearchData({ ...searchData, name: "", assign_to: "", task_priority_id: "", status: "" });
        setSearchDataLabel({ ...searchDataLabel, assign_to: "", task_priority_id: "" });
        setStatusSearch({ ...statusSearch, status_label: "" })
        setTask({ ...task, startData: "", endDate: "" })
    }

    //All Modal and action for checksheet
    const [taskInfoShow, setTaskInfoShow] = useState(false);
    const [taskInfoValue, setTaskInfoValue] = useState('');
    const [taskCloseShow, setTaskCloseShow] = useState(false);
    const [taskCloseValue, setTaskCloseValue] = useState({
        "remark": "",
        "id": ""
    });
    const [highAlertShow, setHighAlertShow] = useState(false);
    const [highAlertValue, setHighAlertValue] = useState({
        "remark": "",
        "id": "",
        "checkTaskId": ""
    });
    const [switchDoerShow, setSwitchDoerShow] = useState(false);
    const [switchDoerValue, setSwitchDoerValue] = useState({
        "doer_id": "",
        "doer_label": "",
        "remark": "",
        "id": "",
        "task_id": ""
    });

    const TaskInfoView = (e) => {
        setTaskInfoShow(true);
        setTaskInfoValue(e)
    }
    const TaskInfoHide = () => {
        setTaskInfoShow(false);
        setTaskInfoValue('');
    }
    const TaskCloseView = (e) => {
        setTaskCloseShow(true);
        setTaskCloseValue({ ...taskCloseValue, id: e });
    }
    const TaskCloseHide = () => {
        setTaskCloseShow(false);
        setTaskCloseValue({ ...taskCloseValue, id: "", remark: "" })
    }

    const HighAlertView = (e, id) => {
        setHighAlertShow(true)
        setHighAlertValue({ ...highAlertValue, id: e, checkTaskId: id })
    }
    const HighAlertHide = () => {
        setHighAlertShow(false)
        setHighAlertValue({ ...highAlertValue, id: "", remark: "", checkTaskId: "" })
    }

    const SwitchDoerView = (e, taskId) => {
        setSwitchDoerShow(true);
        setSwitchDoerValue({ ...switchDoerValue, id: e, task_id: taskId })

    }
    const SwitchDoerHide = () => {
        setSwitchDoerShow(false)
        setSwitchDoerValue({ ...switchDoerValue, id: '', remark: "", doer_id: "", doer_label: "" })
    }

    const CloseTask = (e) => {
        e.preventDefault();
        setLoading(true)
     
        PrivateAxios.post("checksheet/close", taskCloseValue)
            .then((res) => {
                SuccessMessage(res.data.message);
                setLoading(false)
                TaskCloseHide();
                if (Tab == "inprogress") {
                    GetInProgressSheet();
                }
                if (Tab == "complete") {
                    GetCompleteSheet();
                }
                if (Tab == "todo") {
                    GetToDoSheet();
                }
                if (Tab == "close") {
                    GetCloseSheet();
                }
                if (data == "master") {
                    GetMaster();
                }
            }).catch((err) => {
                setLoading(false)
            })
    }
    const HighAlertTask = (e) => {
        setLoading(true)
        e.preventDefault();
        PrivateAxios.post("checksheet/high-alert", highAlertValue)
            .then((res) => {
                SuccessMessage(res.data.message);
                HighAlertHide();
                setLoading(false)
                if (Tab == "inprogress") {
                    GetInProgressSheet();
                }
                if (Tab == "complete") {
                    GetCompleteSheet();
                }
                if (Tab == "todo") {
                    GetToDoSheet();
                }
                if (Tab == "close") {
                    GetCloseSheet();
                }
                if (data == "master") {
                    GetMaster();
                }
            }).catch((err) => {
                setLoading(false)
            })
    }
    const SwitchDoerTask = (e) => {
        setLoading(true);
        e.preventDefault();
        if (switchDoerValue.doer_id == '') {
            WaringMessage("Please select doer first")
        }
        PrivateAxios.post("checksheet/switch-doer", switchDoerValue)
            .then((res) => {
                SuccessMessage(res.data.message);
                setLoading(false)
                if (Tab == "inprogress") {
                    GetInProgressSheet();
                }
                if (Tab == "complete") {
                    GetCompleteSheet();
                }
                if (Tab == "todo") {
                    GetToDoSheet();
                }
                if (Tab == "close") {
                    GetCloseSheet();
                }
                if (data == "master") {
                    GetMaster();
                }
                SwitchDoerHide();
            }).catch((err) => {
                setLoading(false);
                ErrorMessage(err.response.data.error)
            })

    }

    const [showChatWrap, setShowChatWrap] = useState(false);
    const [chatValue, setChatValue] = useState('');

    const handleCloseChatWrap = () => {
        setShowChatWrap(false);
    }
    const handleShowChatWrap = (data) => {
        setShowChatWrap(true);
        setChatValue(data)
    }
    //dark theme select
    const [isDarkTheme, setIsDarkTheme] = useState(false);

    // Check for the theme by looking at the body's class list
    useEffect(() => {
        const checkTheme = () => {
            setIsDarkTheme(document.body.classList.contains("dark-theme"));
        };

        // Initial check
        checkTheme();

        // MutationObserver to detect theme changes on body
        const observer = new MutationObserver(checkTheme);
        observer.observe(document.body, { attributes: true, attributeFilter: ["class"] });

        // Clean up observer on component unmount
        return () => observer.disconnect();
    }, []);
    //dark theme select end


    //Master
    const generatePdfMaster = () => {
        exportPDF(MasterColumns, tableData, "CheckList")
    }
    const generateExcelMaster = () => {
        exportExcel(MasterColumns, tableData, "CheckList")
    }
    const generatePrintMaster = () => {
        printTable(MasterColumns, tableData, "CheckList")
    }
    const handleKeyUpMasterSearch = (e) => {
        const filteredItems = allTableData.filter((item) => {
            return item && item.name.toLowerCase().replace(/\s+/g, '').includes(e.target.value.toLowerCase().replace(/\s+/g, ''))
        }
        );
        setTableData(filteredItems);
    }



    return (

        <React.Fragment>
            {loading && <Loader />}
            <HodTab />
            <div className='bg-white border-bottom mb-4'>
                <div className="d-flex flex-wrap px-4 py-2 justify-content-between align-items-center">
                    <ul className="nav nav-tabs gth-rounded-tab-2" id="systemControllerFilterTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button onClick={() => TabChange("master")} className="nav-link active" data-bs-toggle="tab" data-bs-target="#systemTaskMaster" type="button" role="tab" aria-controls="systemTaskMaster" aria-selected="true" >
                                <span className="btn-master"><i className="fas fa-th me-1"></i>Master</span>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" onClick={() => TabChange("todo")} data-bs-toggle="tab" data-bs-target="#systemTaskTodo" type="button" role="tab" aria-controls="systemTaskTodo" aria-selected="true" >
                                <span className="btn-todo"><i className="far fa-dot-circle me-1"></i>To Do</span>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" onClick={() => TabChange("inprogress")} data-bs-toggle="tab" data-bs-target="#systemTaskInProgress" type="button" role="tab" aria-controls="systemTaskInProgress" aria-selected="false" >
                                <span className="btn-inprogress"><i className="fas fa-redo me-1"></i>In Progress</span>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" onClick={() => TabChange("complete")} data-bs-toggle="tab" data-bs-target="#systemTaskCompleted" type="button" role="tab" aria-controls="messages" aria-selected="false">
                                <span className="btn-completed"><i className="fas fa-check-circle me-1"></i>Completed</span>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" onClick={() => TabChange("close")} data-bs-toggle="tab" data-bs-target="#systemTaskCancel" type="button" role="tab" aria-controls="messages" aria-selected="false">
                                <span className="btn-closeStop"><i className="far fa-stop-circle me-1"></i>Cancel</span>
                            </button>
                        </li>
                    </ul>
                    <div className='d-flex my-1 ms-auto'>
                        {Tab == 'master' ?
                            <button className="btn btn-info btn-sm"
                                type="button"
                                onClick={() => { setOpenMaster(!openMaster); ClearAllFilter() }}
                                aria-controls="example-collapse-text"
                                aria-expanded={openMaster}
                            >
                                <i className="fas fa-filter me-2"></i>Filter
                            </button> :
                            <button className="btn btn-info btn-sm"
                                type="button"
                                onClick={() => { setOpen(!open); ClearAllFilter() }}
                                aria-controls="example-collapse-text"
                                aria-expanded={open}
                            >
                                <i className="fas fa-filter me-2"></i>Filter
                            </button>
                        }
                    </div>
                </div>
                {Tab == 'master' ?
                    <Collapse in={openMaster} timeout={400}>
                        <div className={` px-4 py-2`}
                            style={{ height: height, }}
                        >
                            <form id='search-form'>
                                <div className='card'>
                                    <div className='card-header'>
                                        <h5 className='card-title font-weight-medium'>
                                            Search CheckSheet
                                        </h5>
                                    </div>

                                    <div className='card-body pb-1'>
                                        <div className='row'>
                                            <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                                <div className='form-group'>
                                                    <label className='form-label'>Task Name</label>
                                                    <input
                                                        type='text'
                                                        value={searchDataMaster.name}
                                                        className='form-control'
                                                        placeholder='Enter Task Name'
                                                        onChange={(e) => setSearchDataMaster({ ...searchDataMaster, name: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                                <div className='form-group'>
                                                    <label className='form-label'>Assign By</label>
                                                    <div className='custom-select-wrap'>
                                                        <Select
                                                            name='selectDoer'
                                                            options={User}
                                                            getOptionLabel={(option) => option.name}
                                                            getOptionValue={(option) => option.id}
                                                            value={User.find(data => data.id == searchDataMaster.assigned_by) || ""}
                                                            onChange={(e) => { setSearchDataMaster({ ...searchDataMaster, assigned_by: e.id, }) }}
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#ddddff',
                                                                    primary: '#6161ff',
                                                                },
                                                            })}
                                                            styles={{
                                                                menu: (base) => ({
                                                                    ...base,
                                                                    backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                }),
                                                                singleValue: (base) => ({
                                                                    ...base,
                                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                }),
                                                                option: (base, state) => ({
                                                                    ...base,
                                                                    backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                                <div className='form-group'>
                                                    <label className='form-label'>Doer</label>
                                                    <div className='custom-select-wrap'>
                                                        <Select
                                                            name='selectDoer'
                                                            options={User}
                                                            getOptionLabel={(option) => option.name}
                                                            getOptionValue={(option) => option.id}
                                                            value={User.find(data => data.id == searchDataMaster.assign_to) || ""}
                                                            onChange={(e) => { setSearchDataMaster({ ...searchDataMaster, assign_to: e.id, }) }}
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#ddddff',
                                                                    primary: '#6161ff',
                                                                },
                                                            })}
                                                            styles={{
                                                                menu: (base) => ({
                                                                    ...base,
                                                                    backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                }),
                                                                singleValue: (base) => ({
                                                                    ...base,
                                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                }),
                                                                option: (base, state) => ({
                                                                    ...base,
                                                                    backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                                <div className='form-group'>
                                                    <label className='form-label'>Mode</label>
                                                    <div className='custom-select-wrap'>
                                                        <Select
                                                            name='selectDoer'
                                                            options={TaskMode}
                                                            //isMulti
                                                            getOptionLabel={(option) => option.title}
                                                            getOptionValue={(option) => option.id}
                                                            value={TaskMode.find(data => data.id == searchDataMaster.task_mode_id) || ""}
                                                            onChange={(e) => { setSearchDataMaster({ ...searchDataMaster, task_mode_id: e.id }) }}
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#ddddff',
                                                                    primary: '#6161ff',
                                                                },
                                                            })}
                                                            styles={{
                                                                menu: (base) => ({
                                                                    ...base,
                                                                    backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                }),
                                                                singleValue: (base) => ({
                                                                    ...base,
                                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                }),
                                                                option: (base, state) => ({
                                                                    ...base,
                                                                    backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                                <div className='form-group'>
                                                    <label className='form-label'>Priority</label>
                                                    <div className='custom-select-wrap'>
                                                        <Select
                                                            name='selectDoer'
                                                            options={TaskPriority}
                                                            getOptionLabel={(option) => option.title}
                                                            getOptionValue={(option) => option.id}
                                                            value={TaskPriority.find(data => data.id == searchDataMaster.task_priority_id) || ''}
                                                            onChange={(e) => { setSearchDataMaster({ ...searchDataMaster, task_priority_id: e.id }); }}
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#ddddff',
                                                                    primary: '#6161ff',
                                                                },
                                                            })}
                                                            styles={{
                                                                menu: (base) => ({
                                                                    ...base,
                                                                    backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                }),
                                                                singleValue: (base) => ({
                                                                    ...base,
                                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                }),
                                                                option: (base, state) => ({
                                                                    ...base,
                                                                    backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex justify-content-end">
                                        <button className="btn btn-exp-green" onClick={SearchDataButtonMaster} >Search</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Collapse> :
                    <Collapse in={open} timeout={400}>
                        <div className="px-4 py-2" id="contentId">
                            <form id='search-form'>
                                <div className='card'>
                                    <div className='card-header'>
                                        <h5 className='card-title font-weight-medium'>
                                            Search Checksheet
                                        </h5>
                                    </div>

                                    <div className='card-body pb-1'>
                                        <div className='row'>
                                            <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                                <div className='form-group'>
                                                    <label className='form-label'>Task Name</label>
                                                    <input type='text' value={searchData.name} className='form-control' placeholder='Enter task name' onChange={(e) => setSearchData({ ...searchData, name: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                                <div className='form-group'>
                                                    <label className='form-label'>Assign By</label>
                                                    <div className='custom-select-wrap'>
                                                        <Select
                                                            name='selectDoer'
                                                            options={User}
                                                            getOptionLabel={(option) => option.name}
                                                            getOptionValue={(option) => option.id}
                                                            value={searchData.assigned_by ? User.find(data => data.id == searchData.assigned_by) : ""}
                                                            onChange={(e) => { setSearchData({ ...searchData, assigned_by: e.id }); }}
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#ddddff',
                                                                    primary: '#6161ff',
                                                                },
                                                            })}
                                                            styles={{
                                                                menu: (base) => ({
                                                                    ...base,
                                                                    backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                }),
                                                                singleValue: (base) => ({
                                                                    ...base,
                                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                }),
                                                                option: (base, state) => ({
                                                                    ...base,
                                                                    backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                                <div className='form-group'>
                                                    <label className='form-label'>Select Doer</label>
                                                    <div className='custom-select-wrap'>
                                                        <Select
                                                            name='selectDoer'
                                                            options={User}
                                                            getOptionLabel={(option) => option.name}
                                                            getOptionValue={(option) => option.id}
                                                            value={searchData.assign_to ? User.find(data => data.id == searchData.assign_to) : ""}
                                                            onChange={(e) => { setSearchData({ ...searchData, assign_to: e.id }); setSearchDataLabel({ ...searchDataLabel, assign_to: e }) }}
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#ddddff',
                                                                    primary: '#6161ff',
                                                                },
                                                            })}
                                                            styles={{
                                                                menu: (base) => ({
                                                                    ...base,
                                                                    backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                }),
                                                                singleValue: (base) => ({
                                                                    ...base,
                                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                }),
                                                                option: (base, state) => ({
                                                                    ...base,
                                                                    backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                                <div className='form-group'>
                                                    <label className='form-label'>Select Priority</label>
                                                    <div className='custom-select-wrap'>
                                                        <Select
                                                            name='selectPriority'
                                                            options={TaskPriority}
                                                            value={searchData.task_priority_id ? TaskPriority.find(data => data.id == searchData.task_priority_id) : ""}
                                                            getOptionLabel={(option) => option.title}
                                                            getOptionValue={(option) => option.id}
                                                            onChange={(e) => { setSearchData({ ...searchData, task_priority_id: e.id }); setSearchDataLabel({ ...searchDataLabel, task_priority_id: e }) }}
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#ddddff',
                                                                    primary: '#6161ff',
                                                                },
                                                            })}
                                                            styles={{
                                                                menu: (base) => ({
                                                                    ...base,
                                                                    backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                }),
                                                                singleValue: (base) => ({
                                                                    ...base,
                                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                }),
                                                                option: (base, state) => ({
                                                                    ...base,
                                                                    backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                            <div className='form-group'>
                                                <label className='form-label'> Status</label>
                                                <div className='custom-select-wrap'>
                                                    <Select
                                                        name='selectStatus'
                                                        options={status}
                                                        value={statusSearch.status_label}
                                                        getOptionLabel={(option) => option.title}
                                                        getOptionValue={(option) => option.id}
                                                        onChange={(e) => { setSearchData({ ...searchData, status: e.id }); setStatusSearch({ ...statusSearch, status_label: e }) }}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#ddddff',
                                                                primary: '#6161ff',
                                                            },
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div> */}
                                            <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                                <div className='form-group'>
                                                    <label className='form-label'>From Date</label>
                                                    <div className="exp-datepicker-cont">
                                                        <span className="cal-icon"><i className="fas fa-calendar-alt" /></span>
                                                        <DatePicker
                                                            selected={task.startData} onChange={(date) => setTask({ ...task, startData: date })}
                                                            dateFormat="dd/MM/YYYY"
                                                            placeholderText='Select Date'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                                                <div className='form-group'>
                                                    <label className='form-label'>To Date</label>
                                                    <div className="exp-datepicker-cont">
                                                        <span className="cal-icon"><i className="fas fa-calendar-alt" /></span>
                                                        <DatePicker
                                                            selected={task.endDate} onChange={(date) => setTask({ ...task, endDate: date })}
                                                            dateFormat="dd/MM/YYYY"
                                                            placeholderText='Select Date'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex justify-content-end">
                                        <button className="btn btn-exp-green" onClick={SearchData}>Search</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Collapse>
                }

            </div>
            <div className='gth-dashboard-v2-process-wrap'>
                <div className="tab-content pt-0">
                    <div className="tab-pane active" id="systemTaskMaster" role="tabpanel"  >
                        <div className="card">
                            <div className="card-body p-0">
                                <div className='d-flex justify-content-between flex-wrap align-items-center pt-3 px-3'>
                                    <div className='d-flex mb-3 align-items-center flex-wrap gap-3'>
                                        <div className='table-button-group'>
                                            <button className='btn table-export-btn' onClick={generateExcelMaster} >
                                                <i className="fas fa-file-csv"></i>
                                            </button>
                                            <button className='btn table-export-btn' onClick={generatePdfMaster}>
                                                <i className="fas fa-file-pdf"></i>
                                            </button>
                                            <button className='btn table-export-btn' onClick={generatePrintMaster}>
                                                <i className="fas fa-print"></i>
                                            </button>

                                        </div>
                                        {deleteTaskId.length > 0 ?
                                            <button type='button' className='btn btn-exp-primary btn-sm' onClick={SwitchDoerOpenChecksheetModal}>
                                                <i className='me-2 fas fa-exchange-alt'></i>
                                                Bulk Switch Doer
                                            </button>
                                            : ""
                                        }
                                    </div>
                                    <div className='d-flex align-items-center mb-3 ms-auto'>
                                        <label className='mr-2 mb-0'>Search: </label>
                                        <input type='text' placeholder='Type here... ' onChange={handleKeyUpMasterSearch} className='form-control form-control-sm' />
                                    </div>
                                </div>
                                <DataTable
                                    columns={MasterColumns}
                                    data={tableData}
                                    pagination={[5, 10, 25, 50]}
                                    theme="solarized"
                                    striped
                                    className='custom-table-wrap workflow-table-striped'
                                />
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="systemTaskTodo" role="tabpanel">
                        <div className='card'>

                            <div className='card-body p-0'>
                                {!inputSearch.length > 0 ?
                                    <div div className="w-100">
                                        <div className="card bg-warning-light mb-0">
                                            <div className="card-body">
                                                <div className="exp-no-data-found text-exp-red">
                                                    <img className="task-img mb-3" src={process.env.PUBLIC_URL + 'assets/images/search-no-record-found.webp'} alt="No task" />
                                                    <h6>No Record Found</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className="table-view" >
                                        <div className='d-flex justify-content-between flex-wrap align-items-center pt-3 px-3'>
                                            <div className='d-flex mb-3 align-items-center flex-wrap gap-3'>
                                                <div className='table-button-group'>
                                                    <button className='btn table-export-btn' onClick={generateExcel} >
                                                        <i className="fas fa-file-csv"></i>
                                                    </button>
                                                    <button className='btn table-export-btn' onClick={generatePdf}>
                                                        <i className="fas fa-file-pdf"></i>
                                                    </button>
                                                    <button className='btn table-export-btn' onClick={generatePrint}>
                                                        <i className="fas fa-print"></i>
                                                    </button>
                                                </div>

                                            </div>
                                            <div className='d-flex align-items-center mb-3'>
                                                <label className='mr-2 mb-0'>Search: </label>
                                                <input type='text' placeholder='Type here...' onChange={handleKeyUpSearch} className='form-control form-control-sm' />
                                            </div>
                                        </div>

                                        <DataTable
                                            columns={selectedColumns}
                                            data={tableData}
                                            pagination={[5, 10, 25, 50]}
                                            theme="solarized"
                                            striped
                                            className='custom-table-wrap Checksheet-table-striped'
                                        //customStyles={customStyles}
                                        />
                                    </div>

                                }
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="systemTaskInProgress" role="tabpanel">
                        <div className='card'>

                            <div className='card-body p-0'>
                                {!inputSearch.length > 0 ?
                                    <div div className="w-100">
                                        <div className="card bg-warning-light mb-0">
                                            <div className="card-body">
                                                <div className="exp-no-data-found text-exp-red">
                                                    <img className="task-img mb-3" src={process.env.PUBLIC_URL + 'assets/images/search-no-record-found.webp'} alt="No task" />
                                                    <h6>No Record Found</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <>
                                        <div className="table-view" >
                                            <div className='d-flex justify-content-between flex-wrap align-items-center pt-3 px-3'>
                                                <div className='d-flex mb-3 align-items-center flex-wrap gap-3'>
                                                    <div className='table-button-group'>
                                                        <button className='btn table-export-btn' onClick={generateExcel} >
                                                            <i className="fas fa-file-csv"></i>
                                                        </button>
                                                        <button className='btn table-export-btn' onClick={generatePdf}>
                                                            <i className="fas fa-file-pdf"></i>
                                                        </button>
                                                        <button className='btn table-export-btn' onClick={generatePrint}>
                                                            <i className="fas fa-print"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center mb-3'>
                                                    <label className='mr-2 mb-0'>Search: </label>
                                                    <input type='text' placeholder='Type here...' onChange={handleKeyUpSearch} className='form-control form-control-sm' />
                                                </div>
                                            </div>

                                            <DataTable
                                                columns={selectedColumns}
                                                data={tableData}
                                                pagination={[5, 10, 25, 50]}
                                                theme="solarized"
                                                striped
                                                className='custom-table-wrap Checksheet-table-striped'
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="systemTaskCompleted" role="tabpanel">
                        <div className='card'>
                            {/* <div className='card-header d-flex flex-wrap justify-content-between align-items-center'>
                                <h3 className="card-title">Checksheet Task List</h3>
                                <div className="d-flex ms-auto align-items-center">
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip >
                                                Table View
                                            </Tooltip>
                                        }
                                    >
                                        <button className={`icon-btn me-2 ${!grid ? 'icon-btn-active' : ''}`} onClick={tableView} >
                                            <i className="bi bi-table" />
                                        </button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip >
                                                Grid View
                                            </Tooltip>
                                        }
                                    >
                                        <button className={`icon-btn ${grid ? 'icon-btn-active' : ''}`} onClick={gridView}>
                                            <i className="bi bi-grid-3x3" />
                                        </button>
                                    </OverlayTrigger>

                                </div>
                            </div> */}
                            <div className='card-body p-0'>
                                {!inputSearch.length > 0 ?
                                    <div div className="w-100">
                                        <div className="card bg-warning-light mb-0">
                                            <div className="card-body">
                                                <div className="exp-no-data-found text-exp-red">
                                                    <img className="task-img mb-3" src={process.env.PUBLIC_URL + 'assets/images/search-no-record-found.webp'} alt="No task" />
                                                    <h6>No Record Found</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <>
                                        <div className="table-view" >
                                            <div className='d-flex justify-content-between flex-wrap align-items-center pt-3 px-3'>
                                                <div className='d-flex mb-3 align-items-center flex-wrap gap-3'>
                                                    <div className='table-button-group'>
                                                        <button className='btn table-export-btn' onClick={generateExcelComplete} >
                                                            <i className="fas fa-file-csv"></i>
                                                        </button>
                                                        <button className='btn table-export-btn' onClick={generatePdfComplete}>
                                                            <i className="fas fa-file-pdf"></i>
                                                        </button>
                                                        <button className='btn table-export-btn' onClick={generatePrintComplete}>
                                                            <i className="fas fa-print"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <label className='mr-2 mb-0'>Search: </label>
                                                    <input type='text' placeholder='Type here...' onChange={handleKeyUpSearch} className='form-control form-control-sm' />
                                                </div>
                                            </div>

                                            <DataTable
                                                columns={completeColumns}
                                                data={tableData}
                                                pagination={[5, 10, 25, 50]}
                                                theme="solarized"
                                                striped
                                                className='custom-table-wrap Checksheet-table-striped'
                                            //customStyles={customStyles}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="systemTaskCancel" role="tabpanel">
                        <div className='card'>
                            <div className='card-body p-0'>
                                {!inputSearch.length > 0 ?
                                    <div div className="w-100">
                                        <div className="card bg-warning-light mb-0">
                                            <div className="card-body">
                                                <div className="exp-no-data-found text-exp-red">
                                                    <img className="task-img mb-3" src={process.env.PUBLIC_URL + 'assets/images/search-no-record-found.webp'} alt="No task" />
                                                    <h6>No Record Found</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <>
                                        <div className="table-view" >
                                            <div className='d-flex justify-content-between flex-wrap align-items-center pt-3 px-3'>
                                                <div className='table-button-group mb-3'>
                                                    <button className='btn table-export-btn' onClick={generateExcelComplete} >
                                                        <i className="fas fa-file-csv"></i>
                                                    </button>
                                                    <button className='btn table-export-btn' onClick={generatePdfComplete}>
                                                        <i className="fas fa-file-pdf"></i>
                                                    </button>
                                                    <button className='btn table-export-btn' onClick={generatePrintComplete}>
                                                        <i className="fas fa-print"></i>
                                                    </button>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <label className='mr-2 mb-0'>Search: </label>
                                                    <input type='text' placeholder='Type here...' onChange={handleKeyUpSearch} className='form-control form-control-sm' />
                                                </div>
                                            </div>

                                            <DataTable
                                                columns={completeColumns}
                                                data={tableData}
                                                pagination={[5, 10, 25, 50]}
                                                theme="solarized"
                                                striped
                                                className='custom-table-wrap Checksheet-table-striped'
                                            //customStyles={customStyles}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Info modal */}
            {/* <Modal size="lg" show={taskInfoShow} onHide={TaskInfoHide} backdrop="static" keyboard={false} centered >
                <Modal.Header closeButton>
                    <Modal.Title>{taskInfoValue && taskInfoValue.checkSheetList.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex'>
                            <h6>Doer- </h6>
                            <p> {taskInfoValue && taskInfoValue.checkSheetList.assignedToUser.name}</p>
                        </div>
                        <div className='d-flex'>
                            <h6>Assign By- </h6>
                            <p> {taskInfoValue && taskInfoValue.checkSheetList.assignedByUser.name}</p>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex'>
                            <h6>Issue Date- </h6>
                            <p> {taskInfoValue && moment(taskInfoValue.created_at).format('DD-MM-YYYY')}</p>
                        </div>
                        <div className='d-flex'>
                            <h6>Planned Date- </h6>
                            <p> {taskInfoValue && moment(taskInfoValue.due_date).format('DD-MM-YYYY')}</p>
                        </div>
                        <div className='d-flex'>
                            <h6>Complete Date- </h6>
                            <p> {taskInfoValue && taskInfoValue.completed_date && moment(taskInfoValue.completed_date).format('DD-MM-YYYY')}</p>
                        </div>
                    </div>

                    <h6>Description-</h6>
                    {taskInfoValue && convertLinks(taskInfoValue.checkSheetList.message)}
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-secondary btn-sm' onClick={TaskInfoHide}>
                        Cancel
                    </button>
                </Modal.Footer>
            </Modal> */}
            <CheckSheetDetails where={"system"} handleShowChatWrap={handleShowChatWrap}  showChecksheetViewDetails={taskInfoShow} handleCloseChecksheetViewDetails={TaskInfoHide} taskId={taskInfoValue.id} checkSheet={taskInfoValue && taskInfoValue.checkSheetList} assignTo={taskInfoValue.doer_id} issueDate={taskInfoValue?.due_date} completeDate={taskInfoValue?.completed_date} Auditor={taskInfoValue.auditor_id} completeRemark={taskInfoValue?.task_remarks} fileLink={taskInfoValue.done_file_link} closeRemark={taskInfoValue.close_remark} completeFile={taskInfoValue?.remark_file} />
            {/* end info modal */}

            {/* Task close modal */}
            <Modal show={taskCloseShow} onHide={TaskCloseHide} backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton className="gth-blue-light-bg">
                    <Modal.Title>Checksheet Close</Modal.Title>
                </Modal.Header>
                <form onSubmit={CloseTask}>
                    <Modal.Body className="pb-1">
                        <div className='form-group'>
                            <label className='form-label'>Remark</label>
                            <textarea type='text' name='remark' rows="4" required className='form-control' onChange={(e) => setTaskCloseValue({ ...taskCloseValue, remark: e.target.value })} >{taskCloseValue.remark}</textarea>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='submit' className='btn btn-exp-green'>
                            Confirm
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* Task close modal end */}

            {/* High Alert modal */}
            <Modal show={highAlertShow} onHide={HighAlertHide} backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton className="gth-blue-light-bg">
                    <Modal.Title>High Alert</Modal.Title>
                </Modal.Header>
                <form onSubmit={HighAlertTask}>
                    <Modal.Body className="pb-1">
                        <div className='form-group'>
                            <label className='form-label'>Remark</label>
                            <textarea type='text' name='remark' rows="4" required className='form-control' onChange={(e) => setHighAlertValue({ ...highAlertValue, remark: e.target.value })} >{highAlertValue.remark}</textarea>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='submit' className='btn btn-exp-green' >
                            Save
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* High Alert modal end */}

            {/* Switch Doer modal */}
            <Modal show={switchDoerShow} onHide={SwitchDoerHide} backdrop="static" keyboard={false} centered className='task-details-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Switch Doer</Modal.Title>
                </Modal.Header>
                <form onSubmit={SwitchDoerTask}>
                    <Modal.Body>
                        <div className='form-group'>
                            <label className='form-label'>Switch Doer</label>
                            <Select
                                name='selectDoer'
                                options={User}
                                value={switchDoerValue.doer_label}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(e) => setSwitchDoerValue({ ...switchDoerValue, doer_id: e.id, doer_label: e })}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#ddddff',
                                        primary: '#6161ff',
                                    },
                                })}
                            />
                        </div>
                        <div className='form-group'>
                            <label className='form-label'>Remark</label>
                            <textarea type='text' name='remark' rows="4" required className='form-control' onChange={(e) => setSwitchDoerValue({ ...switchDoerValue, remark: e.target.value })}></textarea>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-secondary' onClick={SwitchDoerHide}>
                            Cancel
                        </button>
                        <button type='submit' className='btn btn-exp-green'>
                            Switch
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* Switch Doer modal end */}
            {/* chat box */}
            <CheckSheetMsg showChatWrap={showChatWrap} handleCloseChatWrap={handleCloseChatWrap} data={chatValue} selectedValue={Tab} GetToDoSheet={GetToDoSheet} GetInProgressSheet={GetInProgressSheet} GetCompleteSheet={GetCompleteSheet} GetCloseSheet={GetCloseSheet} />
            {/* chat box end */}

            {/* View process details modal start */}
            <form>
                <Modal id="flow-process-step-view-modal" show={showScTaskDetails} onHide={handleCloseScTaskDetails} backdrop="static" centered size="xl" className="task-details-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="task-title-edit-wrap d-flex">
                                <span className="">Process Name:</span> DSW Sub 2 (SKP)
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="task-details-modal-body-wrap">
                        <div className="task-details-modal-body">
                            <div className="row align-items-center">
                                <div className="col-lg-6 mb-2">
                                    <div className="row align-items-center">
                                        <div className="col-sm-4">
                                            <div className="status-item todo">
                                                <i className="fas fa-user me-2"></i>
                                                <span>Assign By</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-8">
                                            <button className="btn gth-transparent-btn cursor-not-allowed">
                                                <div className="profile-wrap">
                                                    <div className="exp-avtar bg-exp-green">
                                                        <span>JP</span>
                                                    </div>
                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                        <h5 className="profile-name text-nowrap">Jhon Parker</h5>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="row align-items-center">
                                        <div className="col-sm-4">
                                            <div className="status-item todo">
                                                <i className="fas fa-user me-2"></i>
                                                <span>Doer</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-8">
                                            <button className="btn gth-transparent-btn cursor-not-allowed">
                                                <div className="profile-wrap">
                                                    <div className="exp-avtar bg-exp-yellow">
                                                        <span>SP</span>
                                                    </div>
                                                    <div className="ps-2 profile-name-wrap text-truncate">
                                                        <h5 className="profile-name text-nowrap">Sujit Paul</h5>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-lg-6 mb-2">
                                    <div className="row align-items-center">
                                        <div className="col-sm-4">
                                            <div className="status-item todo">
                                                <i className="fas fa-flag me-2"></i>
                                                <span>Priority</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-8">
                                            <button className="btn gth-transparent-btn cursor-not-allowed">
                                                <div className="priority-set urgent">
                                                    <i className="fas fa-flag mr-2"></i><span>Urgent</span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="row align-items-center">
                                        <div className="col-sm-4">
                                            <div className="status-item todo">
                                                <i className="fas fa-calendar-alt me-2"></i>
                                                <span>Issue Date</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-8">
                                            <button className="btn gth-transparent-btn cursor-not-allowed">
                                                <span className="planned-date-txt f-w-medium">
                                                    22/07/2024
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-lg-6 mb-2">
                                    <div className="row align-items-center">
                                        <div className="col-sm-4">
                                            <div className="status-item todo">
                                                <i className="fas fa-calendar-alt me-2"></i>
                                                <span>Planned Date</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-8">
                                            <button className="btn gth-transparent-btn cursor-not-allowed">
                                                <span className="planned-date-txt f-w-medium">
                                                    23/07/2024
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="row align-items-center">
                                        <div className="col-sm-4">
                                            <div className="status-item todo">
                                                <i className="fas fa-calendar-alt me-2"></i>
                                                <span>Completed Date</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-8">
                                            <button className="btn gth-transparent-btn cursor-not-allowed">
                                                <span className="planned-date-txt f-w-medium">
                                                    23/07/2024
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-2">
                                    <div className="row align-items-center">
                                        <div className="col-sm-4">
                                            <div className="status-item todo">
                                                <i className="far fa-dot-circle me-2"></i>
                                                <span>Status</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-8">
                                            <button className="btn gth-transparent-btn cursor-not-allowed">
                                                <div className="status-item inprogress">
                                                    <i className="fas fa-redo me-2"></i>
                                                    <span>In Progress</span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className='my-3' />
                            <div className="col-lg-12">
                                <div className="card shadow-none border overflow-hidden bg-light-blue">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Sub Task</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <input className="form-control" type="text" placeholder="Enter Subtask Name" value="task 1" readOnly />
                                                        </td>
                                                        <td className="width-80"><i class="fas fa-check-circle gth-text-success fs-5"></i></td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input className="form-control" type="text" placeholder="Enter Subtask Name" value="task 2" readOnly />
                                                        </td>
                                                        <td className="width-80"><i class="fas fa-check-circle gth-text-success fs-5"></i></td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input className="form-control" type="text" placeholder="Enter Subtask Name" value="task 3" readOnly />
                                                        </td>
                                                        <td className="width-80"><i class="fas fa-spinner gth-text-info fs-5"></i></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr className="my-3" />
                            <div className="row">
                                <div className="col-12">
                                    <div className="attachment-header">
                                        <h6>Attachments</h6>
                                        <div className="attachment-buttons">
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        Download All
                                                    </Tooltip>
                                                }
                                            >
                                                <button className="gth-action-button ms-2">
                                                    <i className="fas fa-download"></i>
                                                </button>
                                            </OverlayTrigger>
                                            <Dropdown className="gth-modal-attachment-drpdown" align="end">
                                                <Dropdown.Toggle id="status" className="gth-action-button ms-2 ">
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                Add Attachments
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <i className="fas fa-plus"></i>
                                                    </OverlayTrigger>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="attachment-wrap">
                                                    <div className="attachment-list">
                                                        <div className="attachment-list-item custom-file-upload">
                                                            <i className="fas fa-paperclip me-2"></i>
                                                            <button className="upload-btn">Upload File</button>
                                                            <input type="file" name="myfile" className="input-upload" />
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    {/* for grid view */}
                                    <div className="card shadow-none border grid-files mt-2 mb-0">
                                        <div className="card-header bg-exp-primary-grey-light-1">
                                            <h6>Files</h6>
                                        </div>
                                        <div className="card-body">
                                            <div className="gth-attachment-body">
                                                <figure className="gth-attachment-tile-item">
                                                    <div className="grid-action-btn">
                                                        <OverlayTrigger
                                                            trigger="click"
                                                            rootClose
                                                            placement="auto"
                                                            overlay={attachmentListAction}
                                                        >
                                                            <button className="action-btn">
                                                                <i className="fas fa-ellipsis-v"></i>
                                                            </button>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div className="attachment-image">
                                                        <div className="image-expand">
                                                            <img className="figure-img" src={process.env.PUBLIC_URL + 'assets/images/login-bg-bk.jpg'} alt="attachment" />
                                                        </div>
                                                        <span className="image-expand-arrow"><i className="fas fa-expand-alt"></i></span>
                                                    </div>
                                                    <figcaption className="figure-caption">
                                                        <h6 className="file-name">sample-image.jpg</h6>
                                                        <p className="file-upload-time">9:45 am</p>
                                                    </figcaption>
                                                </figure>
                                                <figure className="gth-attachment-tile-item">
                                                    <div className="grid-action-btn">
                                                        <OverlayTrigger
                                                            trigger="click"
                                                            rootClose
                                                            placement="auto"
                                                            overlay={attachmentListAction}
                                                        >
                                                            <button className="gth-action-button">
                                                                <i className="fas fa-ellipsis-v"></i>
                                                            </button>
                                                        </OverlayTrigger>
                                                    </div>

                                                    <div className="attachment-image">
                                                        <div className="image-expand">
                                                            <img className="figure-img" src={process.env.PUBLIC_URL + 'assets/images/visitor.jpg'} alt="attachment" />
                                                        </div>
                                                        <span className="image-expand-arrow"><i className="fas fa-expand-alt"></i></span>
                                                    </div>
                                                    <figcaption className="figure-caption">
                                                        <h6 className="file-name">sample-image.jpg</h6>
                                                        <p className="file-upload-time">9:45 am</p>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                    {/* for grid view end*/}
                                </div>
                            </div>
                            <hr className="my-3" />
                            <div className="description">
                                <h6>Description</h6>
                                <div className="workflow-details-wrap">
                                    <p>
                                        Interview
                                    </p>
                                    <p>
                                        Candidate Name: <strong>Tamal Barui</strong>
                                    </p>
                                    <p>
                                        Contact: <strong>+9123618202</strong>
                                    </p>
                                    <p>
                                        Email: <strong>tbjgbs@gmail.com</strong>
                                    </p>
                                    <p>
                                        Qualification: <strong>Graduate</strong>
                                    </p>
                                    <p>
                                        Post Applied: <strong>MIS</strong>
                                    </p>
                                    <p>
                                        Experience: <strong>Experienced</strong>
                                    </p>
                                    <p>
                                        CV: <a href="#"><strong><i className="fas fa-download me-2"></i>Download</strong></a>
                                    </p>

                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                </Modal>
            </form>
            {/* View process details modal end */}
            {/* for doer */}
            <form>
                <Offcanvas className="assignee-canvas" show={showDoer} onHide={handleCloseDoer} placement="end">
                    <Offcanvas.Header closeButton className="d-block">
                        <Offcanvas.Title>Change Doer</Offcanvas.Title>
                        <div className="search-assignee">
                            <span className="search-assignee-icon">
                                <i className="fas fa-search"></i>
                            </span>
                            <input type="text" className="form-control" placeholder="Search or enter email.." />
                        </div>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div id="selectAssignee" className="assignee-wrapper assignee-wrapper-body">
                            <div className="user-list-wrap">
                                <div className="user-item">
                                    <div className="profile-wrap">
                                        <div className="exp-avtar">
                                            <i className="fas fa-user user-icon"></i>
                                        </div>
                                        <div className="ps-2 profile-name-wrap text-truncate">
                                            <h5 className="profile-name text-nowrap text-truncate">Sujit Paul</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="user-item">
                                    <div className="profile-wrap">
                                        <div className="exp-avtar bg-exp-green">
                                            <span>JP</span>
                                        </div>
                                        <div className="ps-2 profile-name-wrap text-truncate">
                                            <h5 className="profile-name text-nowrap text-truncate">Jhon Parker</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="user-item">
                                    <div className="profile-wrap">
                                        <div className="exp-avtar">
                                            <img className="prof-img" src={process.env.PUBLIC_URL + 'assets/images/users/1694671741_1517351722263.jpg'} alt="user" />
                                        </div>
                                        <div className="ps-2 profile-name-wrap text-truncate">
                                            <h5 className="profile-name text-nowrap text-truncate">Subhadeep Chowdhury</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="user-item">
                                    <div className="profile-wrap">
                                        <div className="exp-avtar">
                                            <i className="fas fa-user user-icon"></i>
                                        </div>
                                        <div className="ps-2 profile-name-wrap text-truncate">
                                            <h5 className="profile-name text-nowrap text-truncate">Sujit Paul</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="user-item">
                                    <div className="profile-wrap">
                                        <div className="exp-avtar bg-exp-blue">
                                            <span>MS</span>
                                        </div>
                                        <div className="ps-2 profile-name-wrap text-truncate">
                                            <h5 className="profile-name text-nowrap text-truncate">Moumita Shome</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="user-item">
                                    <div className="profile-wrap">
                                        <div className="exp-avtar">
                                            <img className="prof-img" src={process.env.PUBLIC_URL + 'assets/images/users/1694671598_002.png'} alt="user" />
                                        </div>
                                        <div className="ps-2 profile-name-wrap text-truncate">
                                            <h5 className="profile-name text-nowrap text-truncate">Gopal Mukherjee</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </form>
            {/* for doer end */}
            <AutoModalOn show={modalShow} onHide={() => setModalShow(false)} header={"Link"} data={modalShowData} />

            {/* Switch Doer modal */}
            <SwitchDoerModal
                show={switchDoerShowModal}
                onHide={SwitchDoerCloseChecksheetModal}
                SubmitBulkDoer={SubmitBulkDoer}
                users={User}
                setDoerId={setDoerId}
                DoerId={DoerId}
            />
            {/* Switch Doer modal end */}

            <DeleteModel deleteShow={deleteShow} deleteModalClose={deleteModalClose} deleteAction={DeleteAction} />

        </React.Fragment >

    )
}

export default HodCheckSheet;