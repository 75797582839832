import { format } from 'date-fns'
import React, { memo, useEffect, useRef, useState } from 'react'
import { Offcanvas } from 'react-bootstrap'
import { PrivateAxios, PrivateAxiosFile, url } from '../environment/AxiosInstance';
import { UserAuth } from '../pages/auth/Auth';
import moment from 'moment';
import { WaringMessage } from '../environment/ToastMessage';

function CheckSheetMsg({ showChatWrap, handleCloseChatWrap, data, selectedValue, GetCompleteSheet, GetCloseSheet, GetInProgressSheet, GetToDoSheet }) {

  const { userDetails, Logout, User } = UserAuth();
  const [fileCount, setFileCount] = useState(0);
  const [msgData, setMsgData] = useState({
    checksheet_id: "",
    user_id: "",
    message: "",
    file: ""
  })

  const GetMessage = async () => {
    PrivateAxios.get(`checksheet/get-message/${data.id}`)
      .then((res) => {
        data.messages = res.data.data
      }).catch((err) => {
        if (err.response.status == 401) {
          Logout();
        }
      })
  }

  useEffect(() => {
    if (data) {
      GetMessage()
    }
    setMsgData({ ...msgData, checksheet_id: data?.id, user_id: userDetails.id })

  }, [data]);

  const FileUpload = (e) => {
    const file = e.target.files[0];
    setFileCount(e.target.files.length);
    let fileSize = file.size;
    if (Number(fileSize) >= 20 * 1024 * 1024) {
      WaringMessage("This image in getter than 20MB")
    } else {
      setMsgData({ ...msgData, file: e.target.files[0] });
    }
  }

  const scrollViewRef = useRef();

  const SubmitMsg = (e) => {
    e.preventDefault();
    if (msgData.message || msgData.file) {
      const formData = new FormData();
      formData.append("checklist_id", msgData.checksheet_id)
      formData.append("user_id", msgData.user_id)
      formData.append("message", msgData.message)
      formData.append("file", msgData.file)
      PrivateAxiosFile.post("checksheet/post-message", formData)
        .then((res) => {
          GetMessage();
          setMsgData({ ...msgData, message: "", file: "" })
          if (selectedValue == "inprogress") {
            GetInProgressSheet();
          }
          if (selectedValue == "complete") {
            GetCompleteSheet();
          }
          if (selectedValue == "todo") {
            GetToDoSheet();
          }
          if (selectedValue == "close") {
            GetCloseSheet();
          }
          GetMessage();
          setFileCount(0)
        }).catch((err) => {
          if (err.response.status == 401) {
            Logout();
          }
        })
    } else {
      WaringMessage("Please give some message")

    }

  }

  return (
    <Offcanvas className="chat-canvas" show={showChatWrap} onHide={handleCloseChatWrap} placement="end" backdrop="static">
      <Offcanvas.Header closeButton className="d-block gth-blue-light-bg">
        <Offcanvas.Title className="gth-modal-title">{data && data.name}</Offcanvas.Title>
        <div className="short-details-wrap mt-2">
          <div className="row">
            <div className="col-md-6">
              <div className="mb-2">
                <label className="form-label">Assignee</label>
                {data && User && User.map((item) => (
                  item.id == data.assigned_by ?
                    < div className="profile-wrap" key={item.id}>
                      <div className="exp-avtar bg-exp-yellow">
                        {item.user_photo != null ?
                          < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                          <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                        }
                      </div>
                      <div className="ps-2 profile-name-wrap text-truncate">
                        <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                      </div>
                    </div> : ""
                ))
                }
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-2">
                <label className="form-label">Doer</label>
                {data && User && User.map((item) => (
                  item.id == data.assign_to ?
                    < div className="profile-wrap" key={item.id}>
                      <div className="exp-avtar bg-exp-green">
                        {item.user_photo != null ?
                          < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                          <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                        }
                      </div>
                      <div className="ps-2 profile-name-wrap text-truncate">
                        <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                      </div>
                    </div> : ""
                ))
                }
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-2">
                <label className="form-label">Date</label>
                <p className="mb-0 f-s-14">{data && format(data.issue_date, "d MMMM, yyyy")}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-2">
                <label className="form-label">Priority</label>
                {data && data.task_priority_id != "" ? data.task_priority_id == 1 ?
                  <div className="priority-set urgent">
                    <i className="fas fa-flag mr-2"></i>
                    <span>Urgent</span>
                  </div> :
                  data.task_priority_id == 2 ? <div div className="priority-set high priority-list-item" >
                    <i className="fas fa-flag mr-2"></i>
                    <span>High</span>
                  </div> : data.task_priority_id == 3 ?
                    <div className="priority-set normal priority-list-item" >
                      <i className="fas fa-flag mr-2"></i>
                      <span>Normal</span>
                    </div> : data.task_priority_id == 4 ? <div className="priority-set low priority-list-item" >
                      <i className="fas fa-flag mr-2"></i>
                      <span>Low</span>
                    </div> : "" : ""
                }
              </div>
            </div>
          </div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="chat-wrapper">
          <div className="chat-conversation-wrap" ref={scrollViewRef}>
            {data?.messages && data.messages.map((item) => (
              item.user_id == userDetails.id ?
                <div className="chat-msg">
                  <div className="chat-msg-profile-image">
                    {data && User && User.map((UserItem) => (
                      UserItem.id == userDetails.id ?
                        < div className="profile-wrap" key={UserItem.id}>
                          <div className="exp-avtar bg-exp-green">
                            {UserItem.user_photo != null ?
                              < img className="prof-img" src={url + UserItem.user_photo} alt="user" /> :
                              <span>{UserItem.name != '' ? UserItem.name.split(' ').length > 1 ? UserItem.name.split(' ')[0].split('')[0] + UserItem.name.split(' ')[1].split('')[0] : UserItem.name.split('')[0] + UserItem.name.split('')[1] : ""}</span>
                            }
                          </div>
                          {/* <div className="ps-2 profile-name-wrap text-truncate">
                              <h5 className="profile-name text-nowrap text-truncate">{UserItem.name}</h5>
                            </div> */}
                        </div> : ""
                    ))
                    }
                  </div>
                  <div className="chat-msg-content">
                    <div className="chat-profile-name text-truncate">
                      {User.find(data => data.id == userDetails.id).name}{item.audit_msg == 1 ? "(Auditor Message)" : item.audit_msg == 2 ? "(Switch Doer Remark)" : ""}
                    </div>
                    <div className="chat-msg-text">
                      {item.message && item.message != null ?
                        item.message && item.message : ""
                      }
                      {
                        item.file && item.file != null ?
                          <div className="chat-attachment-item">
                            {item.file && item.file.split('.').pop() == "png" || item.file.split('.').pop() == "jpg" || item.file.split('.').pop() == "jpeg" ?
                              <div className="icon">
                                <i className="fas fa-image"></i>
                              </div> : item.file.split('.').pop() == "pdf" ?
                                <div className="icon">
                                  <i className="fas fa-file-pdf"></i>
                                </div> : item.file.split('.').pop() == "doc" ?
                                  <div className="icon">
                                    <i className="fas fa-file-word"></i>
                                  </div> : item.file.split('.').pop() == "xls" || item.file.split('.').pop() == "xlsx" ?
                                    <div className="icon">
                                      <i className="fas fa-file-excel"></i>
                                    </div> : ""
                            }
                            <p className="text-truncate filename">{item.file && item.file}</p>
                            <button className="attachment-download action-btn" onClick={() => {
                              const link = document.createElement('a');
                              link.href = url + item.file;
                              link.download = 'file.pdf';
                              link.target = "_blank";
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                            }}>
                              <i className="fas fa-download"></i>
                            </button>
                          </div> : ""
                      }
                    </div>
                    <div className="chat-msg-date">
                      {item.created_at && moment(item.created_at).format('DD/MM/YYYY hh:mm A')}
                    </div>
                  </div>
                </div> :
                <div className="chat-msg owner">
                  <div className="chat-msg-profile-image">
                    {data && User && User.map((userItem) => (
                      userItem.id == item.user_id ?
                        < div className="profile-wrap" key={userItem.id}>
                          <div className="exp-avtar bg-exp-green">
                            {userItem.user_photo != null ?
                              < img className="prof-img" src={url + userItem.user_photo} alt="user" /> :
                              <span>{userItem.name != '' ? userItem.name.split(' ').length > 1 ? userItem.name.split(' ')[0].split('')[0] + userItem.name.split(' ')[1].split('')[0] : userItem.name.split('')[0] + userItem.name.split('')[1] : ""}</span>
                            }
                          </div>
                          {/* <div className="ps-2 profile-name-wrap text-truncate">
                            <h5 className="profile-name text-nowrap text-truncate">{userItem.name}</h5>
                          </div> */}
                        </div> : ""
                    ))
                    }
                  </div>
                  <div className="chat-msg-content">
                    <div className="chat-profile-name text-truncate">
                      {User.find(data => data.id == item.user_id).name}{item.audit_msg == 1 ? "(Auditor Message)" : item.audit_msg == 2 ? "(Switch Doer Remark)" : ""}
                    </div>
                    <div className="chat-msg-text">
                      {item?.message && item.message != null ?
                        item.message && item.message : ""
                      }
                      {
                        item?.file && item.file != null ?
                          <div className="chat-attachment-item">
                            {item.file && item.file.split('.').pop() == "png" || item.file.split('.').pop() == "jpg" || item.file.split('.').pop() == "jpeg" ?
                              <div className="icon">
                                <i className="fas fa-image"></i>
                              </div> : item.file.split('.').pop() == "pdf" ?
                                <div className="icon">
                                  <i className="fas fa-file-pdf"></i>
                                </div> : item.file.split('.').pop() == "doc" ?
                                  <div className="icon">
                                    <i className="fas fa-file-word"></i>
                                  </div> : item.file.split('.').pop() == "xls" || item.file.split('.').pop() == "xlsx" ?
                                    <div className="icon">
                                      <i className="fas fa-file-excel"></i>
                                    </div> : ""
                            }
                            <p className="text-truncate filename">{item.file && item.file}</p>
                            <button className="attachment-download action-btn" onClick={() => {
                              const link = document.createElement('a');
                              link.href = url + item.file;
                              link.download = 'file.pdf';
                              link.target = "_blank";
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                            }}>
                              <i className="fas fa-download"></i>
                            </button>
                          </div> : ""
                      }
                    </div>
                    <div className="chat-msg-date">
                      {item.created_at && moment(item.created_at).format('DD/MM/YYYY hh:mm A')}
                    </div>
                  </div>
                </div>
            ))
            }
          </div>
          <form onSubmit={SubmitMsg} className="chat-area-footer">
            <div className="chat-file-upload custom-file-upload">
              <i className="fas fa-paperclip"></i>
              <input
                type="file"
                accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc"
                name="myfile"
                className="input-upload"
                onChange={FileUpload}
              // multiple  // Allow multiple files if needed
              />
              {fileCount > 0 && (
                <span className="file-badge">
                  {fileCount}
                </span>
              )}
            </div>
            <input
              type="text"
              placeholder="Type something here..."
              value={msgData.message}
              className="chat-input"
              onChange={(e) => setMsgData({ ...msgData, message: e.target.value })}
            />
            <button type="submit" className="send-btn">
              <i className="fas fa-paper-plane"></i>
            </button>
          </form>
        </div>

      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default memo(CheckSheetMsg)