import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { PrivateAxios } from '../../environment/AxiosInstance';
import { SuccessMessage } from '../../environment/ToastMessage';
import { UserAuth } from '../auth/Auth';
import Select from 'react-select'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DeleteModel from '../../component/DeleteModel';

function OfficeTiming() {
  const [startTime, setStartTime] = useState(false);
  const [endTime, setEndTime] = useState(false);
  const [officeTime, setOfficeTime] = useState({
    'start': "",
    'end': "",
    'workingDay': [],
  });

  const getOfficeTime = async () => {
    PrivateAxios.get("office-time")
      .then((res) => {
        setOfficeTime({ ...officeTime, start: res.data.data.start_time, end: res.data.data.end_time, workingDay: JSON.parse(res.data.data.working_days) })
      }).catch((err) => {
        console.log(err);
      })
  }
  useEffect(() => {
    getOfficeTime();
  }, [])
  const week = [
    { value: '0', name: 'Sunday' },
    { value: '1', name: 'Monday' },
    { value: '2', name: 'Tuesday' },
    { value: '3', name: 'Wednesday' },
    { value: '4', name: 'Thursday' },
    { value: '5', name: 'Friday' },
    { value: '6', name: 'Saturday' }
  ];
  let i = 0
  const handleStartClick = () => {
    setStartTime(true);
  };

  const handleStartChange = (event) => {
    setOfficeTime({ ...officeTime, start: event.target.value });
  };
  const { OfficeTiming } = UserAuth();
  const handleStartBlur = () => {
    i = 0;
    setStartTime(false);
    submit(officeTime)
  };

  const handleEndClick = () => {
    setEndTime(true);
  };

  const handleEndChange = (event) => {
    setOfficeTime({ ...officeTime, end: event.target.value });
  };

  const handleEndBlur = () => {
    i = 0;
    setEndTime(false);
    submit(officeTime);
  };

  const handleWeekChange = (event) => {
    i = 0;
    const { value, checked } = event.target;
    setOfficeTime(prevState => {
      let updatedWorkingDays = [...prevState.workingDay];
      if (checked) {
        if (!updatedWorkingDays.includes(value)) {
          updatedWorkingDays.push(value);
        }
      } else {
        updatedWorkingDays = updatedWorkingDays.filter(day => day !== value);
      }
      updatedWorkingDays.sort((a, b) => a - b);
      const updatedState = { ...prevState, workingDay: updatedWorkingDays };
      submit(updatedState);
      return updatedState;
    });
  };

  const submit = (data) => {
    PrivateAxios.post("office-time/change", data)
      .then((res) => {
        if (i == 0) {
          OfficeTiming();
          SuccessMessage(res.data.message)
        }
        i++;
        return
      }).catch((err) => {
        
      })

  }
  // department start
  const [selectedDepartmentOptions, setSelectedDepartmentOptions] = useState([]);

  const handleChangeDepartment = (selected) => {
    if (selected.some(option => option.value === "All")) {
      setSelectedDepartmentOptions([{ value: "All", label: "All" }]); // Only allow "All"
    } else {
      setSelectedDepartmentOptions(selected); // Allow normal selection
    }
  };

  const departmentOptions = [
    { value: "All", label: "All" },
    { value: "Accounts", label: "Accounts" },
    { value: "HR", label: "HR" },
    { value: "Development", label: "Development" },
    { value: "Sales", label: "Sales" }
  ];
  // department end

  // clone office timing card
  const [officeTimingCards, setOfficeTimingCards] = useState([1]);

  const handleAddOfficeTiming = () => {
    setOfficeTimingCards([...officeTimingCards, officeTimingCards.length + 1]);
  };


  //delete modal
  const [deleteShow, setDeleteShow] = useState(false);
  const deleteModalClose = () => setDeleteShow(false);
  const deleteModalShow = () => {
    setDeleteShow(true);
  }
  const deleteAction = () => {

  }
  return (
    <>
      <div className='p-4'>
        {/* <div className='mb-4 d-flex justify-content-end'>
          <button
            type='button'
            className='btn btn-exp-primary btn-sm'
            onClick={handleAddOfficeTiming}
          >
            <i className="fas fa-plus me-2"></i>Add Office Timing
          </button>
        </div> */}
        {officeTimingCards.map((cardId) => (
          <div className='card col-12 col-md-12 new-card'>
            <div className='card-header d-flex flex-wrap justify-content-between align-items-center'>
              <h5 className='card-title my-1'>Office Timing</h5>
              {officeTimingCards.length > 1 && (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Remove</Tooltip>}
                >
                  <button onClick={deleteModalShow}
                    type='button'
                    className='icon-btn ms-auto remove_card_timing'

                  >
                    <i className="fas fa-trash-alt text-danger"></i>
                  </button>
                </OverlayTrigger>
              )}
            </div>
            <div className='card-body pb-2'>
              <div className='row'>
                {/* <div className='col-xl-4 col-lg-12 col-md-12 col-sm-12'>
                  <div className='card shadow-none border'>
                    <div className='card-body'>
                      <div className=''>
                        <label className='my-1 text-muted'>Department</label>
                        <div className="custom-select-wrap">
                          <Select
                            options={departmentOptions}
                            isMulti
                            value={selectedDepartmentOptions}
                            onChange={handleChangeDepartment}
                            closeMenuOnSelect={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                  <div className='card shadow-none border'>
                    <div className='card-body'>
                      <div className=''>
                        <label className='my-1 text-muted'>Working Days</label>
                        <div className='d-flex flex-wrap'>
                          {week.map((data) => (
                            <>
                              <label class="custom-checkbox me-3">
                                <input
                                  type='checkbox'
                                  value={data.value}
                                  checked={officeTime.workingDay.includes(data.value)}
                                  className="form-check-input me-1"
                                  onChange={handleWeekChange}
                                />
                                <span class="checkmark"></span>
                                {data.name}
                              </label>
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12'>
                  <div className='card shadow-none border'>
                    <div className='card-body'>
                      <div className='d-flex flex-wrap justify-content-between align-items-center'>
                        <label className='my-1 text-muted'>Open Time</label>
                        {startTime ? (
                          <input
                            type="time"
                            name="start_time"
                            className="form-control float-end w-auto"
                            value={officeTime.start}
                            onChange={handleStartChange}
                            onBlur={handleStartBlur}
                            autoFocus
                          />
                        ) : (
                          <span className="f-s-16 fw-medium office_timing" onClick={handleStartClick}>
                            {moment(officeTime.start, 'HH:mm:ss').format('hh:mm A')}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12'>
                  <div className='card shadow-none border'>
                    <div className='card-body'>
                      <div className='d-flex flex-wrap justify-content-between align-items-center'>
                        <label className='my-1 text-muted'>Close Time</label>
                        {endTime ? (
                          <input
                            type="time"
                            name="end_time"
                            className="form-control float-end w-auto"
                            value={officeTime.end}
                            onChange={handleEndChange}
                            onBlur={handleEndBlur}
                            autoFocus
                          />
                        ) : (
                          <span className="f-s-16 fw-medium office_timing" onClick={handleEndClick}>
                            {moment(officeTime.end, 'HH:mm:ss').format('hh:mm A')}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12'>
                  <div className='card shadow-none border'>
                    <div className='card-body'>
                      <div className='d-flex flex-wrap justify-content-between align-items-center'>
                        <label className='my-1 text-muted'>Week off</label>
                        <span className="f-s-16 fw-medium office_timing">{officeTime.workingDay.length > 0 ? week[officeTime.workingDay[0]].name : ""}</span>
                      </div>
                    </div>
                  </div>
                </div> */}

              </div>


            </div>
          </div>
        ))}
       
      </div>

      <DeleteModel
          deleteShow={deleteShow}
          deleteModalClose={deleteModalClose}
          deleteAction={deleteAction}
        />
    </>
  );
}

export default OfficeTiming;
