
import React, { createContext, useContext, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import { AllUser } from '../../environment/GlobalApi';
import { PrivateAxios } from '../../environment/AxiosInstance';


export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(sessionStorage.getItem("token") || '');
    const [userDetails, setUserDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [User, setUser] = useState([]);
    const [holidayList, setHolidayList] = useState([]);
    const [officeTimeList, setOfficeTimeList] = useState([]);
    const [allPermissions, setAllPermissions] = useState([]);
    const [auditUser, setAuditUser] = useState([])
    const [userPermissions, setUserPermission] = useState([]);
    const [MDExist, setMdExist] = useState(false);
    const [AdminExist, setAdminExist] = useState(false);
    const [theme, setTheme] = useState(false);
    const [companyInfoGlobal, setCompanyInfoGlobal] = useState('')
    const [branchName, setBranchName] = useState([])
    const [department, setDepartment] = useState([]);

    const [mdBranch, setMdBranch] = useState(0);
    //Login Store in localstorage
    const StoreToken = (token) => {
        sessionStorage.setItem("token", token);
        setToken(token)
        return <Navigate to="/dashboard" />;
    };

    //Logout from Dashboard
    const Logout = () => {
        setToken("");
        sessionStorage.removeItem("token");
        return <Navigate to="/login" />;
    }

    //Call all necessary function
    const AllUsers = async () => {
        PrivateAxios.get("user/all-user")
            .then((res) => {
                setUser(res.data.user);
            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })

    }

    const MatchPermission = (permission) => {
        if (userPermissions.length > 0) {
            const hasPermission = permission.some(permission =>
                userPermissions.includes(permission)
            );
            return hasPermission;
        } else {
            return false;
        }
    }

    const holidayDates = async () => {
        PrivateAxios.get("all-holiday-list")
            .then((res) => {


                let holiday = [];
                res.data.data.map(date => {
                    holiday.push(new Date(date.holiday_date));
                });
                setHolidayList(holiday)
            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }
    const GetUserPermission = async () => {
        PrivateAxios.get("user/user-permission")
            .then((res) => {
                setUserPermission(res.data.data)
                setMdExist(false);
                setAdminExist(false)
                if (res.data.role.length > 0) {
                    setMdExist(true)
                }
                if (res.data.admin.length > 0) {
                    setAdminExist(true)
                }

            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }
    const GetAllPermission = async () => {
        PrivateAxios.get("user/all-permission")
            .then((res) => {
                setAllPermissions(res.data.data);


            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }

    const OfficeTiming = async () => {
        PrivateAxios.get("office-time")
            .then((res) => {
                setOfficeTimeList(res.data.data ? JSON.parse(res.data.data.working_days) : []);
            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }

    const auditUserList = async () => {
        PrivateAxios.get("user/audit-user")
            .then((res) => {
                let userList = []


                outerLoop: for (let i = 0; i < res.data.data.length; i++) {
                    const users = res.data.data[i];
                    if (users.role.length > 0) {
                        for (let j = 0; j < users.role.length; j++) {
                            const item = users.role[j];
                            let permission = item.permission_name ? JSON.parse(item.permission_name) : [];
                            if (permission.length > 0) {
                                for (let k = 0; k < permission.length; k++) {
                                    const data = permission[k];
                                    if (data === "Auditor") {
                                        userList.push(users);
                                        continue outerLoop;
                                    }
                                }
                            }
                        }
                    }
                }
                setAuditUser(userList);
            }).catch((err) => {
                if (err.response?.status == 401) {
                    Logout();
                }
            })
    }

    const GetCompany = () => {
        PrivateAxios.get('company/get-company-detail')
            .then((res) => {
                setCompanyInfoGlobal(res.data.data)
            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }

    const GetBranch = () => {
        PrivateAxios.get('branch/get-all-branch')
            .then((res) => {
                setBranchName(res.data.data)
            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }

    const GetDepartmentData = () => {
        PrivateAxios.get('all-department')
            .then((res) => {
                setDepartment(res.data.data);
            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }

    useEffect(() => {
        if (token) {
            setUserDetails(jwtDecode(token));
            GetAllPermission();
            GetUserPermission();
            AllUsers();
            holidayDates();
            OfficeTiming();
            auditUserList();
            GetCompany();
            GetBranch();
            GetDepartmentData();
        }
    }, [token])


    let isLoggedIn = !!token; //return true false

    return <AuthContext.Provider value={{ StoreToken, AllUsers, AdminExist, token, department, setDepartment, GetDepartmentData, mdBranch, setMdBranch, GetBranch, branchName, setBranchName, userDetails, auditUserList, setTheme, theme, MDExist, companyInfoGlobal, GetCompany, allPermissions, auditUserList, auditUser, GetUserPermission, MatchPermission, OfficeTiming, isLoading, setIsLoading, holidayDates, holidayList, officeTimeList, Logout, isLoggedIn, User, }}>
        {children}
    </AuthContext.Provider>
}
//console.log(sessionStorage.getItem("token"));
export const UserAuth = () => {
    const authCOntextValue = useContext(AuthContext);
    if (!authCOntextValue) {
        throw new Error("useAuth used outside of the provider")
    }
    return authCOntextValue;
}