import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UserAuth } from '../../auth/Auth';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ErrorMessage, SuccessMessage, WaringMessage } from '../../../environment/ToastMessage';
import { formatDate } from '../../../environment/DateFormat';
import { PrivateAxiosFile } from '../../../environment/AxiosInstance';
import { Button } from 'bootstrap';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DeleteModel from '../../../component/DeleteModel';
import { isHoliday, isWeekend } from '../../../environment/HolidayDateProtect';
import { TaskMode, TaskPriority } from '../../../utils/StaticData';
import Loader from '../../../loader/Loader';
import MemoizedAudioPlayer from '../MemoizedAudioPlayer';
import AutoHeightTextarea from '../../CommonComponent/AutoHeightTextarea';



function AddNewChecksheet({ showChecksheetModal, handleCloseChecksheetModal, GetMaster }) {

    const [isCheckedReminder, setIsCheckedReminder] = useState(false);
    const { User, priority, mode, holidayList, officeTimeList, userDetails, department, auditUser, MatchPermission, MDExist } = UserAuth();

    const [checkSheet, setCheckSheet] = useState({
        "name": "",
        "assign_by": userDetails.id,
        "assign_to": "",
        "file": "",
        "description": "",
        "priority": "",
        "notification_to": 0,
        "task_mode_id": "",
        "attachment_file_link": "",
        "auditor": 0,
        "day_before": 0,
        "is_require_file": 0,
        'days': 1
    });
    const [betweenDate, setBetweenDate] = useState('');
    const [error, setError] = useState('');
    const [files, setFiles] = useState([]);

    const fileUpload = async (e) => {
        const file = e.target.files[0];
        let fileSize = file?.size;
        if (Number(fileSize) >= 20 * 1024 * 1024) {
            WaringMessage("This image in getter than 20MB")
        } else {
            setCheckSheet({ ...checkSheet, file: e.target.files[0] });
        }
    }
    const diffDate = () => {
        let currentDate = new Date();
        let plannedDate = new Date(issueDate.startData ? formatDate(issueDate.startData.toString()) : "");
        let timeDifference = plannedDate - currentDate;
        let millisecondsInADay = 1000 * 60 * 60 * 24;
        let dayDifference = timeDifference / millisecondsInADay;
        setBetweenDate(Math.ceil(dayDifference));
    }

    const handleSetReminder = (e) => {
        // if (betweenDate >= 1) {
        setIsCheckedReminder(e.target.checked);
        // } else {
        // WaringMessage("Exceed your issue date")
        // }
    };
    const DayBefore = (e) => {
        // if (e.target.value > betweenDate) {
        //     WaringMessage("Exceed your issue date")
        // } else {
        setCheckSheet({ ...checkSheet, day_before: e.target.value })
        // }
    }
    const [issueDate, setIssueDate] = useState({
        startData: "",
        endDate: ""
    })
    useEffect(() => {
        diffDate()
    }, [issueDate.startData])

    // sub task 
    const [subTask, setSubTask] = useState([
        {
            sub_task: "",

        },
    ]);
    const [deleteShow, setDeleteShow] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState('');
    const deleteModalClose = () => {
        setDeleteShow(false);
        setDeleteIndex('')
    }
    const deleteModalShow = (index) => {
        setDeleteShow(true);
        setDeleteIndex(index)
    }
    const deleteSubTask = () => {
        removeSubTask(deleteIndex);
        deleteModalClose();
    }
    const removeSubTask = (index) => {
        if (subTask.length === 1) {
            return;
        }
        const newProducts = subTask.filter((_, i) => i !== index);
        setSubTask(newProducts);
    };
    const addSubTask = () => {
        setSubTask([
            ...subTask,
            {
                sub_task: "",

            },
        ]);
    }
    const handleSubtaskChange = (index, field, value) => {
        const newSubTask = [...subTask];
        newSubTask[index][field] = value;
        setSubTask(newSubTask);
    };

    //======================Real time Audio Attach=================================//
    //******************************************************************************/
    const [recording, setRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);
    const mediaRecorderRef = useRef(null);
    const audioRef = useRef(null);  // Ref for the audio element
    const audioChunks = useRef([]);
    useEffect(() => {
        if (audioRef.current) {
            // Pause the audio when the src changes
            audioRef.current.audio.current.pause();
        }
    }, [audioBlob]);


    const startRecording = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'audio/webm' });

        mediaRecorderRef.current.ondataavailable = (event) => {
            audioChunks.current.push(event.data);
        };

        mediaRecorderRef.current.onstop = () => {
            const audioBlob = new Blob(audioChunks.current, { type: 'audio/webm' });
            setAudioBlob(audioBlob);
            audioChunks.current = [];  // Clear chunks for next recording
        };

        mediaRecorderRef.current.start();
        setRecording(true);
    };

    const stopRecording = () => {
        mediaRecorderRef.current.stop();
        setRecording(false);
    };
    //***************************End Real time Audio Attach**********************//
    //=============================================================================//

    const [createLoader, setCreateLoader] = useState(false);
    const SubmitData = async (e) => {
        e.preventDefault();
        setCreateLoader(true)
        let formData = new FormData();
        const subtaskData = subTask.filter((task) => task.sub_task !== '');
        formData.append('name', checkSheet.name)
        formData.append('assign_by', checkSheet.assign_by)
        formData.append('assign_to', checkSheet.assign_to)
        formData.append('issue_date', issueDate.startData)
        formData.append('description', checkSheet.description)
        formData.append('priority', checkSheet.priority)
        formData.append('auditor', checkSheet.auditor)
        formData.append('days', checkSheet.days)
        formData.append('notification_to', checkSheet.notification_to)
        formData.append('task_mode_id', checkSheet.task_mode_id)
        formData.append('attachment_file_link', checkSheet.attachment_file_link)

        const audioFile = new File([audioBlob], `audio-${Date.now()}.webm`, { type: 'audio/webm' });
        formData.append('audio', audioFile?.size > 5 ? audioFile : "")

        formData.append(`subtask`, (subtaskData.length > 0) ? JSON.stringify(subTask) : '');
        if (isCheckedReminder) {
            formData.append('day_before', checkSheet.day_before)
        } else {
            formData.append('day_before', 0)
        }
        formData.append('is_require_file', checkSheet.is_require_file)

        formData.append('file', checkSheet.file)

        PrivateAxiosFile.post("checksheet/create", formData)
            .then((res) => {
                setError('')
                setCreateLoader(false)
                SuccessMessage(res.data.message);
                handleCloseChecksheetModal();
                clearAll();
                GetMaster();
            }).catch((err) => {
                setCreateLoader(false)
                var userError = {}
                if (!err.response?.data.errorMessage && err.response?.data.details) {
                    err.response?.data.details.map((err) => (
                        userError[err.context.label] = err.message
                    ))
                } else {
                    ErrorMessage(err.response?.data.errorMessage);
                }
                setError(userError)
            })

    }

    const filterDate = date => {
        return !isHoliday(date, holidayList) && !isWeekend(date, officeTimeList);
    };

    const clearAll = () => {
        setSubTask([{ sub_task: "" }])
        setCheckSheet({
            "name": "",
            "assign_by": userDetails.id,
            "assign_to": "",
            "file": "",
            "description": "",
            "priority": "",
            "notification_to": 0,
            "task_mode_id": "",
            "auditor": 0,
            "day_before": 0,
            "attachment_file_link": "",
            "is_require_file": 0,
            "days": 1,
        });
        setIssueDate({ ...issueDate, startData: "" })
    }
    const [isDarkTheme, setIsDarkTheme] = useState(false);
    useEffect(() => {
        const checkTheme = () => {
            setIsDarkTheme(document.body.classList.contains("dark-theme"));
        };
        checkTheme();
        const observer = new MutationObserver(checkTheme);
        observer.observe(document.body, { attributes: true, attributeFilter: ["class"] });
        return () => observer.disconnect();
    }, []);
    //dark theme select end

    const [custom, setCustom] = useState(false)

    return (
        <React.Fragment>
            {createLoader ? <Loader /> : ""}
            <Modal id="new-checksheet" show={showChecksheetModal} onHide={() => { handleCloseChecksheetModal(); clearAll() }} backdrop="static" centered size="xl">
                <form onSubmit={SubmitData} className="w-100">
                    <Modal.Header closeButton className="gth-blue-light-bg">
                        <Modal.Title className="gth-modal-title">Create New Checksheet</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <div className='row'>
                            <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                <div className='form-group'>
                                    <label className='form-label'>Checksheet  Name</label>
                                    <input type='text' required className='form-control' placeholder='Enter Checksheet Name' onChange={(e) => setCheckSheet({ ...checkSheet, name: e.target.value })} />
                                    {error.name ? <span className="field-invalid"><i class="fas fa-exclamation-triangle me-1"></i>{error.name}</span> : ""}
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                <div className='form-group'>
                                    <label className='form-label'>Assigned By <span className='text-exp-red'>*</span></label>
                                    <div className='custom-select-wrap'>
                                        <Select
                                            name='selectAssignedBy'
                                            options={MDExist ? User : MatchPermission(['System Controller']) ? userDetails.branch_position == "HOD" ? User.filter(item => item.departments_id == userDetails.departments_id || item.branch_position == "HOD") : User.filter(item => item.departments_id == userDetails.departments_id && item.branch_id == userDetails.branch_id) : User.filter(data => data.id == userDetails.id)}
                                            getOptionLabel={(option) => `${option.name}${option.branch_position == "HOD" ? `(${department.find(item => item.id == option.departments_id)?.title})(H)` : ""}`}

                                            // options={MatchPermission(['System Controller']) ? User : User.filter(data => data.id == userDetails.id)}
                                            required
                                            value={checkSheet.assign_by ? User.find(data => data.id == checkSheet.assign_by) : User.find(data => data.id == userDetails.id)}
                                            //getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            onChange={(e) => setCheckSheet({ ...checkSheet, assign_by: e.id })}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ddddff',
                                                    primary: '#6161ff',
                                                },
                                            })}
                                            styles={{
                                                menu: (base) => ({
                                                    ...base,
                                                    backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                }),
                                                singleValue: (base) => ({
                                                    ...base,
                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                }),
                                                option: (base, state) => ({
                                                    ...base,
                                                    backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                }),
                                            }}
                                        />
                                        {error.assign_by ? <span className="field-invalid"><i class="fas fa-exclamation-triangle me-1"></i>{error.assign_by}</span> : ""}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                <div className='form-group'>
                                    <label className='form-label'>Assigned To <span className='text-exp-red'>*</span></label>
                                    <div className='custom-select-wrap'>
                                        <Select
                                            name='selectAssignedTo'
                                            //options={User}
                                            options={MDExist ? User : userDetails.branch_position == "HOD" ? User.filter(item => item.departments_id == userDetails.departments_id || item.branch_position == "HOD") : User.filter(item => item.departments_id == userDetails.departments_id && item.branch_id == userDetails.branch_id)}
                                            getOptionLabel={(option) => `${option.name}${option.branch_position == "HOD" ? `(${department.find(item => item.id == option.departments_id)?.title})(H)` : ""}`}
                                            required
                                            //getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            onChange={(e) => setCheckSheet({ ...checkSheet, assign_to: e.id })}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ddddff',
                                                    primary: '#6161ff',
                                                },
                                            })}
                                            styles={{
                                                menu: (base) => ({
                                                    ...base,
                                                    backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                }),
                                                singleValue: (base) => ({
                                                    ...base,
                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                }),
                                                option: (base, state) => ({
                                                    ...base,
                                                    backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                }),
                                            }}
                                        />
                                        {error.assign_to ? <span className="field-invalid"><i class="fas fa-exclamation-triangle me-1"></i>{error.assign_to}</span> : ""}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className='card shadow-none border overflow-hidden bg-light-blue'>
                                    <div className='card-body p-0'>
                                        <div className="table-responsive">
                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>

                                                        <th>Set Sub Task</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {subTask.map((task, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    value={task.sub_task}
                                                                    placeholder="Enter Subtask Name"
                                                                    onChange={(e) =>
                                                                        handleSubtaskChange(
                                                                            index,
                                                                            "sub_task",
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                />
                                                            </td>
                                                            <td>
                                                                {subTask.length != 1 ?
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip>
                                                                                Remove Sub Task
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <button className="table-action-btn" onClick={() => deleteModalShow(index)}>
                                                                            {/* <button className="table-action-btn" onClick={() => removeSubTask(index)}> */}
                                                                            <i class="fas fa-trash-alt text-exp-red"></i>
                                                                        </button></OverlayTrigger> : ""
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>


                                        </div>
                                    </div>
                                    <div className="card-footer d-flex justify-content-end">
                                        <button type='button' className='btn btn-exp-primary btn-sm' variant="primary" onClick={addSubTask}>
                                            <i className="fas fa-plus me-2"></i>Add Sub Task
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                <div className='form-group'>
                                    <label className='form-label'>Issue Date <span className='text-exp-red'>*</span></label>
                                    <div className="exp-datepicker-cont with-time">
                                        <span className="cal-icon"><i class="fas fa-calendar-alt" /></span>
                                        <DatePicker
                                            required
                                            minDate={new Date()}
                                            // filterDate={filterDate}
                                            selected={issueDate.startData}
                                            onChange={(date) => { setIssueDate({ ...issueDate, startData: date }); setIsCheckedReminder(false); setCheckSheet({ ...checkSheet, day_before: '' }) }}
                                            showTimeSelect
                                            dateFormat="yyyy-MM-dd HH:mm:ss"
                                            timeFormat="HH:mm"
                                            timeIntervals={15}
                                            placeholderText='Select Date'
                                        />
                                    </div>
                                    {error.issue_date ? <span className="field-invalid"><i class="fas fa-exclamation-triangle me-1"></i>{error.issue_date}</span> : ""}
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                <div className='form-group'>
                                    <label className='form-label'>Mode <span className='text-exp-red'>*</span></label>
                                    <div className='custom-select-wrap'>
                                        <Select
                                            name='task_mode_id'
                                            options={TaskMode}
                                            required
                                            getOptionLabel={(option) => option.title}
                                            getOptionValue={(option) => option.id}
                                            onChange={(e) => {
                                                setCheckSheet({ ...checkSheet, task_mode_id: e.id });
                                                if (e.id == 8) {
                                                    setCustom(true)
                                                } else {
                                                    setCustom(false)
                                                }
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ddddff',
                                                    primary: '#6161ff',
                                                },
                                            })}
                                            styles={{
                                                menu: (base) => ({
                                                    ...base,
                                                    backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                }),
                                                singleValue: (base) => ({
                                                    ...base,
                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                }),
                                                option: (base, state) => ({
                                                    ...base,
                                                    backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                }),
                                            }}
                                        />
                                        {error.mode ? <span className="field-invalid"><i class="fas fa-exclamation-triangle me-1"></i>{error.mode}</span> : ""}
                                    </div>
                                </div>
                            </div>
                            {custom ?
                                <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                    <div className='form-group'>
                                        <label className='form-label'>Days <span className='text-exp-red'>*</span></label>
                                        <input type='number' required className='form-control' min="1" value={checkSheet.days} placeholder='Enter Days' onChange={(e) => {
                                           const value = e.target.value;
                                           setCheckSheet({ ...checkSheet, days: value === "" ? "" : Math.max(1, Number(value)) });
                                        }
                                        } />
                                    </div>
                                </div> : ""
                            }
                            <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                <div className='form-group'>
                                    <label className='form-label'>Task Priority <span className='text-exp-red'>*</span></label>
                                    <div className='custom-select-wrap'>
                                        <Select
                                            name='selectTaskPriority'
                                            options={TaskPriority}
                                            required
                                            getOptionLabel={(option) => option.title}
                                            getOptionValue={(option) => option.id}
                                            onChange={(e) => setCheckSheet({ ...checkSheet, priority: e.id })}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ddddff',
                                                    primary: '#6161ff',
                                                },
                                            })}
                                            styles={{
                                                menu: (base) => ({
                                                    ...base,
                                                    backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                }),
                                                singleValue: (base) => ({
                                                    ...base,
                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                }),
                                                option: (base, state) => ({
                                                    ...base,
                                                    backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                }),
                                            }}
                                        />
                                        {error.priority ? <span className="field-invalid"><i class="fas fa-exclamation-triangle me-1"></i>{error.priority}</span> : ""}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                <div className='form-group'>
                                    <label className='form-label'>Record Audio</label>
                                    <div className='audio_record_main_wrap'>
                                        <div className='audio_record_button'>
                                            <button type='button' className='record_button' onClick={!recording ? startRecording : stopRecording} title={!recording ? 'Start recording' : 'Stop recording'}>
                                                {!recording ? <span><i class="fas fa-microphone"></i></span> :
                                                    <span><i class="fas fa-stop-circle"></i></span>
                                                }
                                            </button>
                                        </div>
                                        <div className='audio_record_player'>
                                            <MemoizedAudioPlayer audioBlob={audioBlob} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='form-group'>
                                    <label className='form-label'>Description</label>
                                    {/* <textarea className='form-control' placeholder='Enter Description' rows={3}></textarea> */}
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{
                                            toolbar: {
                                                items: [
                                                    'heading', '|',
                                                    'bold', 'italic', 'link', '|',
                                                    'bulletedList', 'numberedList', '|',
                                                    'blockQuote', '|',
                                                    'undo', 'redo',
                                                ]
                                            }
                                        }}
                                        onChange={(event, editor) => setCheckSheet({ ...checkSheet, description: editor.getData() })}
                                    />
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                <div className='form-group'>
                                    <label className='form-label'>Upload File (if any)</label>
                                    <input type='file' className='form-control' placeholder='Enter Task Name' accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx , .doc" onChange={fileUpload} />
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                <div className='form-group'>
                                    <label className='form-label'>Notify To (if not done)</label>
                                    <div className='custom-select-wrap'>
                                        <Select
                                            name='selectTaskNotifyTo'
                                            options={User}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            onChange={(e) => setCheckSheet({ ...checkSheet, notification_to: e.id })}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ddddff',
                                                    primary: '#6161ff',
                                                },
                                            })}
                                            styles={{
                                                menu: (base) => ({
                                                    ...base,
                                                    backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                }),
                                                singleValue: (base) => ({
                                                    ...base,
                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                }),
                                                option: (base, state) => ({
                                                    ...base,
                                                    backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                }),
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                <div className='form-group'>
                                    <label className='form-label'>Auditor</label>
                                    <div className='custom-select-wrap'>
                                        <Select
                                            name='selectAuditor'
                                            options={auditUser}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            onChange={(e) => setCheckSheet({ ...checkSheet, auditor: e.id })}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#ddddff',
                                                    primary: '#6161ff',
                                                },
                                            })}
                                            styles={{
                                                menu: (base) => ({
                                                    ...base,
                                                    backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                }),
                                                singleValue: (base) => ({
                                                    ...base,
                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                }),
                                                option: (base, state) => ({
                                                    ...base,
                                                    backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                    color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                }),
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                <div className='form-group'>
                                    <label className='form-label'>Attach file link</label>
                                    <AutoHeightTextarea placeholder="Attach file link"
                                        value={checkSheet.attachment_file_link}
                                        onChange={(e) =>
                                            setCheckSheet({
                                                ...checkSheet,
                                                attachment_file_link: e.target.value
                                            })
                                        } />
                                    <p className='mb-0 mt-1 f-s-12'>
                                        <div className='d-flex gap-2 mb-1'>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                version="1.1"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                width={20}
                                                height={20}
                                                x={0}
                                                y={0}
                                                viewBox="0 0 511.999 511.999"
                                                xmlSpace="preserve"
                                                className=""
                                            >
                                                <g>
                                                    <path
                                                        fill="#0066dd"
                                                        d="m38.563 418.862 22.51 39.042c4.677 8.219 11.41 14.682 19.319 19.388l80.744-57.248.147-82.19-80.577-36.303L0 337.565c-.016 9.09 2.313 18.185 6.991 26.404z"
                                                        opacity={1}
                                                        data-original="#0066dd"
                                                    />
                                                    <path
                                                        fill="#00ad3c"
                                                        d="m256.293 173.808 4.212-107.064-84.604-32.663c-7.926 4.678-14.682 11.117-19.389 19.319L7.085 311.186C2.379 319.389.016 328.475 0 337.565l161.283.288z"
                                                        opacity={1}
                                                        data-original="#00ad3c"
                                                    />
                                                    <path
                                                        fill="#00831e"
                                                        d="m256.293 173.808 77.503-41.694 3.387-97.745c-7.909-4.706-16.996-7.068-26.379-7.085l-108.499-.194c-9.384-.017-18.479 2.606-26.405 6.991z"
                                                        opacity={1}
                                                        data-original="#00831e"
                                                    />
                                                    <path
                                                        fill="#0084ff"
                                                        d="m350.716 338.192-189.434-.338-80.89 139.438c7.909 4.706 16.996 7.068 26.379 7.085l297.933.532c9.384.017 18.479-2.606 26.405-6.991l.314-93.66z"
                                                        opacity={1}
                                                        data-original="#0084ff"
                                                    />
                                                    <path
                                                        fill="#ff4131"
                                                        d="M431.109 477.919c7.926-4.678 14.682-11.117 19.388-19.319l9.413-16.111 45.005-77.629c4.706-8.202 7.069-17.288 7.085-26.379l-93.221-49.051-67.768 48.764z"
                                                        opacity={1}
                                                        data-original="#ff4131"
                                                    />
                                                    <path
                                                        fill="#ffba00"
                                                        d="m430.756 182.917-74.253-129.16c-4.677-8.22-11.41-14.683-19.32-19.389l-80.891 139.439 94.423 164.385 160.99.288c.016-9.09-2.314-18.185-6.991-26.405z"
                                                        opacity={1}
                                                        data-original="#ffba00"
                                                    />
                                                </g>
                                            </svg>

                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                version="1.1"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                width={20}
                                                height={20}
                                                x={0}
                                                y={0}
                                                viewBox="0 0 447.232 447.232"
                                                xmlSpace="preserve"
                                                className=""
                                            >
                                                <g>
                                                    <path
                                                        d="M207.527 251.676 92.903 177.758a10.272 10.272 0 0 0-12.007.63L3.833 240.403c-5.458 4.392-5.015 12.839.873 16.636l114.624 73.918a10.272 10.272 0 0 0 12.007-.63l77.063-62.014c5.458-4.393 5.015-12.84-.873-16.637zM238.833 268.312l77.063 62.014a10.272 10.272 0 0 0 12.007.63l114.624-73.918c5.888-3.797 6.331-12.244.873-16.636l-77.063-62.014a10.272 10.272 0 0 0-12.007-.63l-114.624 73.918c-5.887 3.797-6.331 12.244-.873 16.636zM208.4 74.196l-77.063-62.014a10.272 10.272 0 0 0-12.007-.63L4.706 85.47c-5.888 3.797-6.331 12.244-.873 16.636l77.063 62.014a10.272 10.272 0 0 0 12.007.63l114.624-73.918c5.888-3.797 6.331-12.244.873-16.636zM442.527 85.47 327.903 11.552a10.272 10.272 0 0 0-12.007.63l-77.063 62.014c-5.458 4.392-5.015 12.839.873 16.636l114.625 73.918a10.272 10.272 0 0 0 12.007-.63l77.063-62.014c5.457-4.393 5.014-12.84-.874-16.636z"
                                                        style={{}}
                                                        fill="#1587ea"
                                                        data-original="#1587ea"
                                                        className=""
                                                    />
                                                    <path
                                                        d="m218 279.2-86.3 68.841a9.319 9.319 0 0 1-10.861.547L99.568 334.87c-6.201-3.999-14.368.453-14.368 7.831v7.416a9.32 9.32 0 0 0 4.488 7.969l128.481 77.884a9.317 9.317 0 0 0 9.661 0l128.481-77.884a9.319 9.319 0 0 0 4.488-7.969v-6.619c0-7.378-8.168-11.83-14.368-7.831l-20.024 12.913a9.318 9.318 0 0 1-10.876-.559l-85.893-68.809A9.32 9.32 0 0 0 218 279.2z"
                                                        style={{}}
                                                        fill="#1587ea"
                                                        data-original="#1587ea"
                                                        className=""
                                                    />
                                                </g>
                                            </svg>

                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                version="1.1"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                width={20}
                                                height={20}
                                                x={0}
                                                y={0}
                                                viewBox="0 0 512 512"
                                                style={{ enableBackground: "new 0 0 512 512" }}
                                                xmlSpace="preserve"
                                                className="hovered-paths"
                                            >
                                                <g>
                                                    <path
                                                        d="M191.309 122.22a102.052 102.052 0 0 0-19.709-1.92c-51.554 0-94.297 38.443-101.066 88.169C30.558 215.188 0 250.043 0 291.9c0 45.099 35.474 82.063 79.983 84.469A104.902 104.902 0 0 1 77 351.5c0-24.023 8.352-47.523 23.516-66.171 9.965-12.254 22.582-22.082 36.705-28.775 8.955-60.228 57.84-107.594 118.78-114.246l30-27.441-30-27.441c-24.612 4.462-47.2 16.544-64.692 34.794z"
                                                        style={{}}
                                                        fill="#007caa"
                                                        data-original="#007caa"
                                                        className=""
                                                    />
                                                    <path
                                                        d="M165.75 276.5c0 .589.005 1.179.015 1.77C132.188 285.708 107 315.718 107 351.5c0 41.355 33.645 75 75 75h74l60-126.979-60-126.979c-50.938 7.192-90.25 51.067-90.25 103.958z"
                                                        style={{}}
                                                        fill="#007caa"
                                                        data-original="#007caa"
                                                        className=""
                                                    />
                                                    <path
                                                        d="M450.959 277.802C444.343 234.653 406.971 201.5 362 201.5c-5.471 0-10.917.497-16.281 1.483-19.695-20.116-46.521-31.483-74.969-31.483-5.006 0-9.929.36-14.75 1.041V426.5h181c41.355 0 75-33.645 75-75 0-36.587-26.331-67.139-61.041-73.698zM355.844 171.659a119.715 119.715 0 0 1 37.375 3.988C380.139 123.906 333.198 85.5 277.45 85.5c-7.267 0-14.44.655-21.45 1.926v54.881c4.846-.529 9.766-.808 14.75-.808 31.441.001 61.14 10.615 85.094 30.16z"
                                                        style={{}}
                                                        fill="#015e82"
                                                        data-original="#015e82"
                                                        className="hovered-path"
                                                    />
                                                </g>
                                            </svg>
                                        </div>
                                        <i className='fas fa-info-circle me-1 text-info'></i>
                                        To quickly add multiple file links, paste a link and press Enter on your keyboard to add additional file links.
                                    </p>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='form-group'>
                                    <label className="custom-switch" >
                                        <span className='switch-name'>Set Reminder</span>
                                        <input type="checkbox" checked={isCheckedReminder} onChange={handleSetReminder} />
                                        <div className="switch-slider switch-round" />
                                    </label>
                                </div>
                            </div>
                            {isCheckedReminder && (
                                <div className='col-12 reminder-wrap'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                            <div className='form-group'>
                                                <label className='form-label'>Reminder Mode</label>
                                                <div className='custom-select-wrap'>
                                                    <input type='text' readOnly value="Daily" className='form-control' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                            <div className='form-group'>
                                                <label className='form-label'>Day Before </label>
                                                <input type='number' className='form-control' value={checkSheet.day_before} readOnly={checkSheet.task_mode_id == 7 || checkSheet.task_mode_id == '' ? true : false} placeholder='Day Before' onChange={DayBefore} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className='col-12'>
                                <div className='form-group'>
                                    <label className="custom-switch" >
                                        <span className='switch-name'>Attachment is required for this task</span>
                                        <input type="checkbox" value={checkSheet.is_require_file} name='is_require_file' onChange={(e) => setCheckSheet({ ...checkSheet, is_require_file: e.target.checked ? 1 : 0 })} />
                                        <div className="switch-slider switch-round" />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div class="d-flex justify-content-end">
                            <button type="submit" class="btn btn-exp-green">Create</button>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* Delete modal start */}
            <DeleteModel deleteShow={deleteShow} deleteModalClose={deleteModalClose} deleteAction={deleteSubTask} />
            {/* Delete modal end */}
        </React.Fragment>

    )
}

export default AddNewChecksheet