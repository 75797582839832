function getMonthNumber(monthName) {
    var months = {
        "Jan": "01",
        "Feb": "02",
        "Mar": "03",
        "Apr": "04",
        "May": "05",
        "Jun": "06",
        "Jul": "07",
        "Aug": "08",
        "Sep": "09",
        "Oct": "10",
        "Nov": "11",
        "Dec": "12"
    };
    return months[monthName];
}
export function formatDate(dateString) {
    var parts = dateString.split(" ");
    if(parts[2]==undefined){
        return dateString;
    }
    var day = parts[2];
    var month = getMonthNumber(parts[1]);
    var year = parts[3];
    return year + "-" + month + "-" + day;
}