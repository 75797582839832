const findDifference = (arr1, arr2) => {
    return arr1.filter(element => !arr2.includes(element));
};

export const isWeekend = (date,officeTimeList) => {
    const arr1 = ['0', '1', '2', '3', '4', '5', '6'];
    const extraDay = findDifference(arr1, officeTimeList);

    const disabledDays = extraDay.map(Number)
    const day = date.getDay();
    return disabledDays.includes(day)
};

export const isHoliday = (date, holidays) => {
    return holidays.some(holiday =>
        holiday.getDate() === date.getDate() &&
        holiday.getMonth() === date.getMonth() &&
        holiday.getFullYear() === date.getFullYear()
    );
};