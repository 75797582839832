import * as React from "react";
import { createDataTree } from "@progress/kendo-react-data-tools";
import { OrgChart, processOrgChartItems } from "@progress/kendo-react-orgchart";
import '@progress/kendo-theme-default/dist/all.css';

const idField = "ID";
const childrenField = "employees";
const titleField = "name";
const subtitleField = "position";  // Fixed: 'title' was incorrect

const flatdata = [{
    ID: 1,
    name: "Gevin Bell",
    position: "CEO",
    Department: "CEO",
    avatar: "https://demos.telerik.com/kendo-ui/content/web/treelist/people/1.jpg",
    parentId: null,
    contactNo: "+91 00000 00000"
}, {
    ID: 2,
    name: "Clevey Thrustfield",
    position: "CEO",
    Department: "CEO",
    avatar: "https://demos.telerik.com/kendo-ui/content/web/treelist/people/2.jpg",
    parentId: null,
    contactNo: "+91 00000 00000"
}, {
    ID: 3,
    name: "Carol Baker",
    position: "CMO",
    Department: "Chief Officers",
    parentId: 1,
    avatar: "https://demos.telerik.com/kendo-ui/content/web/treelist/people/3.jpg",
    contactNo: "+91 00000 00000"
}, {
    ID: 4,
    name: "Kendra Howell",
    position: "CFO",
    Department: "Chief Officers",
    parentId: 1,
    avatar: "https://demos.telerik.com/kendo-ui/content/web/treelist/people/4.jpg",
    contactNo: "+91 00000 00000"
}, {
    ID: 5,
    name: "Sean Rusell",
    position: "Financial Manager",
    Department: "Financial Dep.",
    parentId: 2,
    avatar: "https://demos.telerik.com/kendo-ui/content/web/treelist/people/5.jpg",
    contactNo: "+91 00000 00000"
}, {
    ID: 6,
    name: "Andrew Berry",
    position: "Team Lead",
    Department: "Team Leads",
    parentId: 3,
    avatar: "https://demos.telerik.com/kendo-ui/content/web/treelist/people/8.jpg",
    contactNo: "+91 00000 00000"
}, {
    ID: 8,
    name: "Austin Piper",
    position: "Accountant",
    Department: "Accountants",
    parentId: 5,
    avatar: "https://demos.telerik.com/kendo-ui/content/web/treelist/people/10.jpg",
    contactNo: "+91 00000 00000"
}, {
    ID: 9,
    name: "Dilyana Newman",
    position: "Junior Accountant",
    Department: "Accountants",
    parentId: 5,
    avatar: "https://demos.telerik.com/kendo-ui/content/web/treelist/people/11.jpg",
    contactNo: "+91 00000 00000"
}, {
    ID: 10,
    name: "Eva Andrews",
    position: "Operations Manager",
    Department: "Operations Managers",
    parentId: 4,
    avatar: "https://demos.telerik.com/kendo-ui/content/web/treelist/people/12.jpg",
    contactNo: "+91 00000 00000"
},
{
    ID: 11,
    name: "Elvis Andrews",
    position: "Operations Manager",
    Department: "Operations Managers",
    parentId: 10,
    avatar: "https://demos.telerik.com/kendo-ui/content/web/treelist/people/12.jpg",
    contactNo: "+91 00000 00000"
},
{
    ID: 12,
    name: "Evas Andrews",
    position: "Operations Manager",
    Department: "Operations Managers",
    parentId: 11,
    avatar: "https://demos.telerik.com/kendo-ui/content/web/treelist/people/12.jpg",
    contactNo: "+91 00000 00000"
},
{
    ID: 13,
    name: "Eval Andrews",
    position: "Operations Manager",
    Department: "Operations Managers",
    parentId: 12,
    avatar: "https://demos.telerik.com/kendo-ui/content/web/treelist/people/12.jpg",
    contactNo: "+91 00000 00000"
},
{
    ID: 14,
    name: "Evan Andrews",
    position: "Operations Manager",
    Department: "Operations Managers",
    parentId: 12,
    avatar: "https://demos.telerik.com/kendo-ui/content/web/treelist/people/12.jpg",
    contactNo: "+91 00000 00000"
}
];

// Create tree data from flat data
const treeData = createDataTree(flatdata, i => i.ID, i => i.parentId, childrenField);

// Custom Org Chart Card Component
// const CustomOrgChartCard = ({ dataItem }) => {
//     return (
//         <div className="p-3 border rounded shadow-sm text-center bg-white">
//             <img src={dataItem.avatar} alt={dataItem.name} className="rounded-circle mb-2" width="50" height="50" />
//             <h6 className="mb-1">{dataItem.name}</h6>
//             <small className="text-muted">{dataItem.position}</small>
//             <p className="mt-1 mb-0"><strong>📞 {dataItem.contactNo}</strong></p>
//         </div>
//     );
// };

const OrgChartIndex = () => {
    const [expand, setExpand] = React.useState({ ids: [1, 2], idField: "ID" });

    const onExpandChange = (event) => {
        let ids = [...expand.ids];

        event.items?.forEach(item => {
            const index = ids.indexOf(item.ID);
            index === -1 ? ids.push(item.ID) : ids.splice(index, 1);
        });

        setExpand(prev => ({
            ...prev,
            ids: [...ids]
        }));
    };

    const expandedData = React.useMemo(() => {
        return processOrgChartItems(treeData, {
            expand: expand,
            childrenField: childrenField
        });
    }, [expand]);

    return (
        <>
            <div className="p-4">
                <div className="card mb-0">
                    <div className="card-header">
                        <h5 className="mb-0">Organization Chart</h5>
                    </div>
                    <div className="card-body">
                        <OrgChart
                            className="theme-org-chart" 
                            cardWidth={270}
                            groupField="Department"
                            idField={idField}
                            childrenField={childrenField}
                            titleField={titleField}
                            subtitleField={subtitleField}
                            data={expandedData}
                            onExpandChange={onExpandChange}
                            //card={CustomOrgChartCard}  // Use custom card
                            groupTitleHeight={20}
                        />
                    </div>
                </div>
            </div>

        </>
    );
};

export default OrgChartIndex;