import React, { useState } from "react";
import { Flowchart, useFlowchart } from "react-flowchart-designer";

function FlowChartExample() {
  const { setHandles, flowchart } = useFlowchart();
  const [draggingShape, setDraggingShape] = useState(null);
  const shapeSize = 120; // Adjust this value for size

  // Handle Drag Start
  const handleDragStart = (shape) => {
    setDraggingShape(shape);
  };

  // Handle Drop Event
  const handleDrop = (event) => {
    event.preventDefault();
    if (!draggingShape) return;

    const flowchartRect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - flowchartRect.left;
    const y = event.clientY - flowchartRect.top;

    if (draggingShape === "rectangle") {
      flowchart.addRectNode(x, y, "Rectangle");
    } else if (draggingShape === "circle") {
      flowchart.addCircleNode(x, y, "Circle");
    } else {
      // Use addRectNode but with custom rendering
      flowchart.addRectNode(x, y, draggingShape, {
        width: shapeSize,
        height: shapeSize,
        data: { label: draggingShape },
        render: ({ data }) => (
          <svg width={shapeSize} height={shapeSize}>
            {/* Diamond Shape */}
            {data.label === "diamond" && (
              <polygon
                points={`${shapeSize / 2},0 ${shapeSize},${shapeSize / 2} ${shapeSize / 2},${shapeSize} 0,${shapeSize / 2}`}
                fill="lightblue"
                stroke="black"
                strokeWidth="2"
              />
            )}

            {/* Triangle Shape */}
            {data.label === "triangle" && (
              <polygon
                points={`${shapeSize / 2},0 ${shapeSize},${shapeSize} 0,${shapeSize}`}
                fill="lightgreen"
                stroke="black"
                strokeWidth="2"
              />
            )}

            <text
              x={shapeSize / 2}
              y={shapeSize / 2 + 5}
              fontSize="12"
              fontWeight="bold"
              textAnchor="middle"
              alignmentBaseline="middle"
              fill="black"
            >
              {data.label}
            </text>
          </svg>
        ),
      });
    }

    setDraggingShape(null);
  };

  return (
    <div style={{ display: "flex", height: "700px" }}>
      {/* Sidebar for Dragging Shapes */}
      <div
        style={{
          width: "200px",
          padding: "10px",
          borderRight: "2px solid #ccc",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <h3>Shapes</h3>
        <div
          draggable
          onDragStart={() => handleDragStart("rectangle")}
          style={{
            padding: "10px",
            background: "lightgray",
            cursor: "grab",
            textAlign: "center",
          }}
        >
          Rectangle
        </div>
        <div
          draggable
          onDragStart={() => handleDragStart("circle")}
          style={{
            padding: "10px",
            background: "lightgray",
            cursor: "grab",
            textAlign: "center",
          }}
        >
          Circle
        </div>
        <div
          draggable
          onDragStart={() => handleDragStart("diamond")}
          style={{
            padding: "10px",
            background: "lightblue",
            cursor: "grab",
            textAlign: "center",
          }}
        >
          Diamond
        </div>
        <div
          draggable
          onDragStart={() => handleDragStart("triangle")}
          style={{
            padding: "10px",
            background: "lightgreen",
            cursor: "grab",
            textAlign: "center",
          }}
        >
          Triangle
        </div>
      </div>

      {/* Flowchart Canvas */}
      <div
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
        style={{ flex: 1, position: "relative" }}
      >
        <Flowchart setHandles={setHandles} width="100%" height="100%" editable={true} />
      </div>
    </div>
  );
}

export default FlowChartExample;
