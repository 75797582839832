import React from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';

const SwitchDoerModal = ({
  show,
  onHide,
  SubmitBulkDoer,
  users,
  setDoerId,
  DoerId
}) => {
  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered className='task-details-modal'>
      <Modal.Header closeButton>
        <Modal.Title>Switch Doer</Modal.Title>
      </Modal.Header>
      <form onSubmit={SubmitBulkDoer}>
        <Modal.Body>
          <div className='form-group'>
            <label className='form-label'>Switch Doer</label>
            <Select
              name='selectDoer'
              options={users}
              value={users.find(item => item.id == DoerId)}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              onChange={(e) => setDoerId(e.id)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#ddddff',
                  primary: '#6161ff',
                },
              })}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-secondary' onClick={onHide}>
            Cancel
          </button>
          <button type='submit' className='btn btn-exp-green'>
            Switch
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default SwitchDoerModal;
