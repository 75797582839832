import React from "react";
import { ResizableBox } from "react-resizable";
import { Handle, Position } from "react-flow-renderer";

const CustomResizableNode = ({ data, id, removeNode, updateNodeLabel, updateNodeSize }) => {
    return (
        <ResizableBox
            width={data.width || 150} // Use width from state
            height={data.height || 40} // Use height from state
            minConstraints={[100, 40]}
            maxConstraints={[300, 200]}
            onResizeStop={(e, data) => updateNodeSize(id, data.size.width, data.size.height)}
            style={{                
                borderRadius: 8, 
                background: "white", 
                position: "relative", 
                padding: 5 
            }}
        >
            <Handle type="target" position={Position.Top} />
            <Handle type="source" position={Position.Bottom} />
            <Handle type="source" position={Position.Left} />
            <Handle type="source" position={Position.Right} />

            <input
                type="text"
                value={data.label}
                onChange={(e) => updateNodeLabel(id, e.target.value)}
                style={{ border: "none", textAlign: "center", width: "100%", outline: "none", fontSize: 14 }}
            />

            <button
                onClick={() => removeNode(id)}
                style={{
                    position: "absolute",
                    top: -8,
                    right: -8,
                    background: "red",
                    color: "white",
                    border: "none",
                    borderRadius: "50%",
                    width: 20,
                    height: 20,
                    cursor: "pointer"
                }}
            >
                <i class="fas fa-times"></i>
            </button>
        </ResizableBox>
    );
};

export default CustomResizableNode;
