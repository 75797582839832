import { format } from 'date-fns';
import React, { memo, useEffect, useRef, useState } from 'react'
import { Offcanvas } from 'react-bootstrap';
import { PrivateAxios, PrivateAxiosFile, url } from '../environment/AxiosInstance';
import { UserAuth } from '../pages/auth/Auth';
import { splitText } from '../environment/Helper';
import moment, { suppressDeprecationWarnings } from 'moment';
import { SuccessMessage, WaringMessage } from '../environment/ToastMessage';

function TaskMessage({ show, handleClose, title, GetTodoByTask, GetcompleteByTask, GetcloseByTask, GetinprogressByTask, Tab, data }) {
    const { userDetails } = UserAuth();
    const { Logout, User } = UserAuth();
    const [fileCount, setFileCount] = useState(0);
    const [msg, setMsg] = useState([]);
    const [msgData, setMsgData] = useState({
        delegation_id: "",
        user_id: "",
        message: "",
        file: ""
    })


    const GetMessage = async () => {
        PrivateAxios.get(`task/get-message/${data.id}`)
            .then((res) => {
                setMsg(res.data.data);
                data.messages = res.data.data
            }).catch((err) => {
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }
    useEffect(() => {
        if (data) {
            GetMessage();
        }
        setMsgData({ ...msgData, delegation_id: data?.id, user_id: userDetails.id })
        // setMsg(data.messages)
    }, [data]);

    const FileUpload = (e) => {
        const file = e.target.files[0];
        let fileSize = file.size;
        setFileCount(e.target.files.length);
        if (Number(fileSize) >=  20 * 1024 * 1024) {
            WaringMessage("This image in getter than 20MB")
        } else {
            setMsgData({ ...msgData, file: e.target.files[0] });
        }
    }
    const scrollViewRef = useRef();
    const SubmitMsg = (e) => {
        e.preventDefault();
        if (msgData.message || msgData.file) {
            const formData = new FormData();
            formData.append("delegation_id", msgData.delegation_id)
            formData.append("user_id", msgData.user_id)
            formData.append("message", msgData.message)
            formData.append("file", msgData.file)
            PrivateAxiosFile.post("task/post-message", formData)
                .then((res) => {
                    GetMessage();
                    setMsgData({ ...msgData, message: "", file: "" })
                    if (Tab == "inprogress") {
                        GetinprogressByTask()
                    }
                    if (Tab == "complete") {
                        GetcompleteByTask()
                    }
                    if (Tab == "todo") {
                        GetTodoByTask()
                    }
                    if (Tab == "close") {
                        GetcloseByTask();
                    }
                    setFileCount(0)
                    // setTimeout(() => {
                    //     scrollViewRef.current?.scrollToEnd({ animated: true });
                    // }, 100);
                }).catch((err) => {
                    if (err.response.status == 401) {
                        Logout();
                    }
                })
        } else {
            WaringMessage("Please give some message")

        }

    }

    return (
        <Offcanvas id="TTChat" className="chat-canvas" show={show} onHide={handleClose} placement="end" backdrop="static" >
            <Offcanvas.Header closeButton className="d-block gth-blue-light-bg">
                <Offcanvas.Title className="gth-modal-title">{data?.name && splitText(data.name, 30)}</Offcanvas.Title>
                <div className="short-details-wrap mt-2">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label className="form-label">Assignee</label>

                                {data && User && User.map((item) => (
                                    item.id == data.assigned_by ?
                                        < div className="profile-wrap" key={item.id}>
                                            <div className="exp-avtar bg-exp-green">
                                                {item.user_photo != null ?
                                                    < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                                    <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                                }
                                            </div>
                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                                            </div>
                                        </div> : ""
                                ))
                                }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label className="form-label">Doer</label>
                                {data && User && User.map((item) => (
                                    item.id == data.assign_to ?
                                        < div className="profile-wrap" key={item.id}>
                                            <div className="exp-avtar bg-exp-yellow">
                                                {item.user_photo != null ?
                                                    < img className="prof-img" src={url + item.user_photo} alt="user" /> :
                                                    <span>{item.name != '' ? item.name.split(' ').length > 1 ? item.name.split(' ')[0].split('')[0] + item.name.split(' ')[1].split('')[0] : item.name.split('')[0] + item.name.split('')[1] : ""}</span>
                                                }
                                            </div>
                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                <h5 className="profile-name text-nowrap text-truncate">{item.name}</h5>
                                            </div>
                                        </div> : ""
                                ))
                                }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label className="form-label">Date</label>
                                <p className="mb-0 f-s-14">{data?.planned_date && format(data.planned_date, "d-MM-yyyy")}</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label className="form-label">Priority</label>
                                {data?.task_priority_id &&
                                    data?.task_priority_id == 1 ?
                                    <div className="priority-set urgent">
                                        <i className="fas fa-flag mr-2"></i>
                                        <span>Urgent</span>
                                    </div> : data?.task_priority_id == 2 ?
                                        <div className="priority-set high ">
                                            <i className="fas fa-flag mr-2"></i>
                                            <span>High</span>
                                        </div> : data?.task_priority_id == 3 ?
                                            <div className="priority-set normal" >
                                                <i className="fas fa-flag mr-2"></i>
                                                <span>Normal</span>
                                            </div> : data?.task_priority_id == 4 ?
                                                <div className="priority-set low" >
                                                    <i className="fas fa-flag mr-2"></i>
                                                    <span>Low</span>
                                                </div> : ""
                                }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label className="form-label">Status</label>
                                {data?.delegation_status_id && data?.delegation_status_id == 1 ?
                                    <div className="status-item todo status-list-item">
                                        <i className="far fa-dot-circle me-2"></i>
                                        <span>To Do</span>
                                    </div> : data?.delegation_status_id == 11 ?
                                        <div div className="status-item inprogress">
                                            <i className="fas fa-redo me-2"></i>
                                            <span>In Progress</span>
                                        </div> : data?.delegation_status_id == 8 ?
                                            <div className="status-item completed status-list-item">
                                                <i className="fas fa-check-circle me-2"></i>
                                                <span>Completed</span>
                                            </div> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Offcanvas.Header>
            {/* userDetails */}
            <Offcanvas.Body>
                <div className="chat-wrapper">
                    <div className="chat-conversation-wrap" ref={scrollViewRef}>
                        {data?.messages && data.messages.map((item) => (
                            item.user_id == userDetails.id ?
                                <div className="chat-msg">
                                    <div className="chat-msg-profile-image">
                                        {data && User && User.map((UserItem) => (
                                            UserItem.id == userDetails.id ?
                                                < div className="profile-wrap" key={UserItem.id}>
                                                    <div className="exp-avtar bg-exp-green">
                                                        {UserItem.user_photo != null ?
                                                            < img className="prof-img" src={url + UserItem.user_photo} alt="user" /> :
                                                            <span>{UserItem.name != '' ? UserItem.name.split(' ').length > 1 ? UserItem.name.split(' ')[0].split('')[0] + UserItem.name.split(' ')[1].split('')[0] : UserItem.name.split('')[0] + UserItem.name.split('')[1] : ""}</span>
                                                        }
                                                    </div>
                                                    {/* <div className="ps-2 profile-name-wrap text-truncate">
                                                        <h5 className="profile-name text-nowrap text-truncate">{UserItem.name}</h5>
                                                    </div> */}
                                                </div> : ""
                                        ))
                                        }
                                    </div>
                                    <div className="chat-msg-content">
                                        <div className="chat-profile-name text-truncate">
                                            {User.find(data => data.id == userDetails.id).name} {item.audit_msg == 1 ? "(Audit Message)" : item.audit_msg == 2 ? "(Switch Doer Remark)" : ""}
                                        </div>
                                        <div className="chat-msg-text">
                                            {item.message && item.message != null ?
                                                item.message && item.message : ""
                                            }
                                            {
                                                item.file && item.file != null ?
                                                    <div className="chat-attachment-item">
                                                        {item.file && item.file.split('.').pop() == "png" || item.file.split('.').pop() == "jpg" || item.file.split('.').pop() == "jpeg" ?
                                                            <div className="icon">
                                                                <i className="fas fa-image"></i>
                                                            </div> : item.file.split('.').pop() == "pdf" ?
                                                                <div className="icon">
                                                                    <i className="fas fa-file-pdf"></i>
                                                                </div> : item.file.split('.').pop() == "doc" ?
                                                                    <div className="icon">
                                                                        <i className="fas fa-file-word"></i>
                                                                    </div> : item.file.split('.').pop() == "xls" || item.file.split('.').pop() == "xlsx" ?
                                                                        <div className="icon">
                                                                            <i className="fas fa-file-excel"></i>
                                                                        </div> : ""
                                                        }
                                                        <p className="text-truncate filename">{item.file && item.file}</p>
                                                        <button className="attachment-download action-btn" onClick={() => {
                                                            const link = document.createElement('a');
                                                            link.href = url + item.file;
                                                            link.download = 'file.pdf';
                                                            link.target = "_blank";
                                                            document.body.appendChild(link);
                                                            link.click();
                                                            document.body.removeChild(link);
                                                        }}>
                                                            <i className="fas fa-download"></i>
                                                        </button>
                                                    </div> : ""
                                            }
                                        </div>
                                        <div className="chat-msg-date">
                                            {item.created_at && moment(item.created_at).format('DD-MM-YYYY  | hh:mm A')}
                                        </div>
                                    </div>
                                </div> :
                                <div className="chat-msg owner">
                                    <div className="chat-msg-profile-image">
                                        {data && User && User.map((userItem) => (
                                            userItem.id == item.user_id ?
                                                < div className="profile-wrap" key={userItem.id}>
                                                    <div className="exp-avtar bg-exp-green">
                                                        {userItem.user_photo != null ?
                                                            < img className="prof-img" src={url + userItem.user_photo} alt="user" /> :
                                                            <span>{userItem.name != '' ? userItem.name.split(' ').length > 1 ? userItem.name.split(' ')[0].split('')[0] + userItem.name.split(' ')[1].split('')[0] : userItem.name.split('')[0] + userItem.name.split('')[1] : ""}</span>
                                                        }
                                                    </div>
                                                    {/* <div className="ps-2 profile-name-wrap text-truncate">
                                                        <h5 className="profile-name text-nowrap text-truncate">{userItem.name}</h5>
                                                    </div> */}
                                                </div> : ""
                                        ))
                                        }
                                    </div>
                                    <div className="chat-msg-content">
                                        <div className="chat-profile-name text-truncate">
                                            {User.find(data => data.id == item.user_id).name}{item.audit_msg == 1 ? "(Audit Message)" : item.audit_msg == 2 ? "(Switch Doer Remark)" : ""}
                                        </div>
                                        <div className="chat-msg-text">
                                            {item.message && item.message != null ?
                                                item.message && item.message : ""
                                            }
                                            {
                                                item.file && item.file != null ?
                                                    <div className="chat-attachment-item">
                                                        {item.file && item.file.split('.').pop() == "png" || item.file.split('.').pop() == "jpg" || item.file.split('.').pop() == "jpeg" ?
                                                            <div className="icon">
                                                                <i className="fas fa-image"></i>
                                                            </div> : item.file.split('.').pop() == "pdf" ?
                                                                <div className="icon">
                                                                    <i className="fas fa-file-pdf"></i>
                                                                </div> : item.file.split('.').pop() == "doc" ?
                                                                    <div className="icon">
                                                                        <i className="fas fa-file-word"></i>
                                                                    </div> : item.file.split('.').pop() == "xls" || item.file.split('.').pop() == "xlsx" ?
                                                                        <div className="icon">
                                                                            <i className="fas fa-file-excel"></i>
                                                                        </div> : ""
                                                        }
                                                        <p className="text-truncate filename">{item.file && item.file}</p>
                                                        <button className="attachment-download action-btn" onClick={() => {
                                                            const link = document.createElement('a');
                                                            link.href = url + item.file;
                                                            link.download = 'file.pdf';
                                                            link.target = "_blank";
                                                            document.body.appendChild(link);
                                                            link.click();
                                                            document.body.removeChild(link);
                                                        }}>
                                                            <i className="fas fa-download"></i>
                                                        </button>
                                                    </div> : ""
                                            }
                                        </div>
                                        <div className="chat-msg-date">
                                            {item.created_at && moment(item.created_at).format('DD-MM-YYYY  | hh:mm A')}
                                        </div>
                                    </div>
                                </div>
                        ))
                        }
                    </div>
                    <form onSubmit={SubmitMsg} className="chat-area-footer">
                        <div className="chat-file-upload custom-file-upload">
                            <i className="fas fa-paperclip"></i>
                            <input
                                type="file"
                                accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc"
                                name="myfile"
                                className="input-upload"
                                onChange={FileUpload}
                            // multiple  // Allow multiple files if needed
                            />
                            {fileCount > 0 && (
                                <span className="file-badge">
                                    {fileCount}
                                </span>
                            )}
                        </div>
                        <input
                            type="text"
                            placeholder="Type something here..."
                            value={msgData.message}
                            className="chat-input"
                            onChange={(e) => setMsgData({ ...msgData, message: e.target.value })}
                        />
                        <button type="submit" className="send-btn">
                            <i className="fas fa-paper-plane"></i>
                        </button>
                    </form>
                </div>

            </Offcanvas.Body>
        </Offcanvas >
    )
}

export default memo(TaskMessage);