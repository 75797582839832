import React, { useEffect, useRef, useState } from 'react'
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { Link } from 'react-router-dom';
import { PrivateAxios } from '../../../environment/AxiosInstance';
import { useReactToPrint } from 'react-to-print';
import { useNavigate } from 'react-router-dom';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            labels: {
                font: {
                    size: 16,
                }
            }
        },
        title: {
            display: true,
            text: 'Sujit Paul',
            font: {
                size: 18,
            }
        },

    },
};





function BottleNeckReportV2() {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1); // Go back one page in history
    };

    const [fms, setFms] = useState([]);
    const GetFms = () => {
        PrivateAxios.get('report/all-fms')
            .then((res) => {
                setFms(res.data.data)
            }).catch((err) => {

            })
    }

    useEffect(() => {
        GetFms();
    }, [])
    const currentDate = new Date();
    const startDateInput = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    const endDateInput = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
    const [fmsData, setFmsData] = useState({
        "startData": startDateInput,
        "endDate": endDateInput,
        "fms_id": ""
    })

    const [subtask, setSubTask] = useState([]);
    const [reportItem, setReportItem] = useState([]);
    const submitData = (e) => {
        e.preventDefault();

        PrivateAxios.post('report/bottle-report', fmsData)
            .then((res) => {
                setSubTask(res.data.subTask);
                setReportItem(res.data.arrData);
            }).catch((err) => {

            })
    }
    const labels = subtask.map(data => data.name);

    const reportMap = reportItem.reduce((acc, item) => {
        acc[item.stepId] = item;
        return acc;
    }, {});

    const totalTasks = labels.map(label => reportMap[subtask.find(st => st.name === label)?.id]?.total || 0);
    const pendingTasks = labels.map(label => reportMap[subtask.find(st => st.name === label)?.id]?.pending || 0);
    const completedTasks = labels.map(label => reportMap[subtask.find(st => st.name === label)?.id]?.complete || 0);
    const delayedTasks = labels.map(label => reportMap[subtask.find(st => st.name === label)?.id]?.delay || 0);


    const data = {
        labels,
        datasets: [
            {
                label: 'Total Task',
                data: totalTasks,
                backgroundColor: 'rgb(97, 97, 255)',
            },
            {
                label: 'Pending Task',
                data: pendingTasks,
                backgroundColor: 'rgb(223, 47, 74)',
            },
            {
                label: 'Completed Task',
                data: completedTasks,
                backgroundColor: 'rgb(0, 200, 117)',
            },
            {
                label: 'Delayed Task',
                data: delayedTasks,
                backgroundColor: 'rgb(253, 171, 61)',
            },
        ],
    };

    const contentRef = useRef(null);
    const reactToPrintFn = useReactToPrint({ contentRef });
    //dark theme select
    const [isDarkTheme, setIsDarkTheme] = useState(false);

    // Check for the theme by looking at the body's class list
    useEffect(() => {
        const checkTheme = () => {
            setIsDarkTheme(document.body.classList.contains("dark-theme"));
        };

        // Initial check
        checkTheme();

        // MutationObserver to detect theme changes on body
        const observer = new MutationObserver(checkTheme);
        observer.observe(document.body, { attributes: true, attributeFilter: ["class"] });

        // Clean up observer on component unmount
        return () => observer.disconnect();
    }, []);
    //dark theme select end

    return (
        <React.Fragment>
            <div className="p-4" ref={contentRef}>
                <div className="mb-4 d-flex align-items-center justify-content-betweem">
                    <button className="text-dark link-btn back-btn me-3" onClick={handleBack}>
                        <i className="fas fa-long-arrow-alt-left me-1" />Back
                    </button>
                </div>
                {/* <form className='card mb-4' onSubmit={submitData}>
                    <div className="card-header">
                        <h3 className="card-title">Bottle-Neck Report</h3>
                    </div>
                    <div className='card-body pb-1'>
                        <div className='w-100'>
                            <div className='row'>
                                <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                    <div className='form-group'>
                                        <label className='form-label'>Form <span className='text-exp-red'>*</span></label>
                                        <div className="exp-datepicker-cont">
                                            <span className="cal-icon"><i class="fas fa-calendar-alt" /></span>
                                            <DatePicker
                                                required
                                                selected={fmsData.startData} onChange={(date) => setFmsData({ ...fmsData, startData: date })}
                                                dateFormat="dd/MM/YYYY"
                                                placeholderText='Select Date'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                    <div className='form-group'>
                                        <label className='form-label'>To <span className='text-exp-red'>*</span></label>
                                        <div className="exp-datepicker-cont">
                                            <span className="cal-icon"><i class="fas fa-calendar-alt" /></span>
                                            <DatePicker
                                                required
                                                selected={fmsData.endDate} onChange={(date) => setFmsData({ ...fmsData, endDate: date })}
                                                dateFormat="dd/MM/YYYY"
                                                placeholderText='Select Date'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                    <div className='form-group'>
                                        <label className='form-label'>Work Flow <span className='text-exp-red'>*</span></label>
                                        <div className='custom-select-wrap'>
                                            <Select
                                                required
                                                name='fms'
                                                options={fms}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#ddddff',
                                                        primary: '#6161ff',
                                                    },
                                                })}
                                                onChange={(data) => setFmsData({ ...fmsData, fms_id: data.id })}
                                                styles={{
                                                    menu: (base) => ({
                                                        ...base,
                                                        backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                    }),
                                                    singleValue: (base) => ({
                                                        ...base,
                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                    }),
                                                    option: (base, state) => ({
                                                        ...base,
                                                        backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                    }),
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end">
                        <button type="reset" name="reset_button" className="btn btn-exp-light me-2">Reset</button>
                        <button type="submit" name="submit_button" className="btn btn-exp-green">Generate Report</button>
                    </div>
                </form> */}
                <div className="row">
                    {/* no data found */}
                    {/* <div className="col-12">
                        <div className="card bg-warning-light mb-0">
                            <div className="card-body">
                                <div className="exp-no-data-found text-exp-red">
                                    <img className="task-img mb-3" src={process.env.PUBLIC_URL + 'assets/images/search-no-record-found.webp'} alt="No task" />
                                    <h6>No Record Found</h6>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className='col-12'>
                        <div className='d-flex justify-content-end mb-3'>
                            <button className='btn btn-secondary btn-sm' onClick={() => reactToPrintFn()}>
                                <i className="fas fa-print me-2"></i>
                                Print
                            </button>
                        </div>
                        <div className='card mb-0'>
                            <div className="card-header">
                                <h3 className="card-title">Detail Bottle-Neck Report of Order to Delivery</h3>
                            </div>
                            <div className='card-body'>
                                <div className='overflow-x-scroll'>
                                    <div className='bar-card-report'>
                                        <Bar data={data} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </React.Fragment>
    )
}

export default BottleNeckReportV2