import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { OverlayTrigger, Popover, PopoverBody, PopoverHeader } from 'react-bootstrap';
import { toDataSourceRequest } from '@progress/kendo-data-query';
import BottleNeckTableComponent from './BottleNeckTableComponent';
import BottleNeckCardComponent from './BottleNeckCardComponent';

function BottleNeckReportTable() {

    //custom range select
    const [selectedRange, setSelectedRange] = useState("3m"); // Default selection
    const [fmsData, setFmsData] = useState({ startDate: null, endDate: null });
    const handleRadioChange = (event) => {
        setSelectedRange(event.target.value);
    };

    //



    return (
        <>
            <div className="p-4">
                <div className="mb-4 d-flex align-items-center justify-content-betweem">
                    <Link className="text-dark back-btn me-3" to="/reports">
                        <i className="fas fa-long-arrow-alt-left me-1" />Back
                    </Link>
                </div>
                <form className='card mb-4'>
                    <div className="card-header">
                        <h3 className="card-title">Bottle-Neck Report</h3>
                    </div>
                    <div className='card-body pb-1'>
                        <div className='w-100'>
                            <div className='row'>
                                <div className='col-xl-6'>
                                    <div className='form-group mb-1'>
                                        <label className='form-label'>Quick Report <span className='text-exp-red'>*</span></label>
                                        <div className="d-flex flex-wrap">
                                            {["3m", "6m", "1y", "2y", "custom"].map((val, index) => (
                                                <label key={index} className="custom-radio btn-type-radio mb-2 me-3">
                                                    <input
                                                        type="radio"
                                                        name="reportRange"
                                                        value={val}
                                                        onChange={handleRadioChange}
                                                        checked={selectedRange === val}
                                                    />
                                                    <span className="checkmark" />
                                                    <span className="text-">
                                                        {val === "3m" ? "3 Months" :
                                                            val === "6m" ? "6 Months" :
                                                                val === "1y" ? "1 Year" :
                                                                    val === "2y" ? "2 Years" :
                                                                        "Custom Range"}
                                                    </span>
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                </div>


                                {selectedRange === "custom" && (
                                    <div className='col-xl-6'>
                                        <div className='row'>
                                            <div className='col-md-6 col-12'>
                                                <div className='form-group'>
                                                    <label className='form-label'>From <span className='text-exp-red'>*</span></label>
                                                    <div className="exp-datepicker-cont">
                                                        <span className="cal-icon"><i className="fas fa-calendar-alt" /></span>
                                                        <DatePicker
                                                            required
                                                            selected={fmsData.startDate}
                                                            onChange={(date) => setFmsData({ ...fmsData, startDate: date })}
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText='Select Date'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <div className='form-group'>
                                                    <label className='form-label'>To <span className='text-exp-red'>*</span></label>
                                                    <div className="exp-datepicker-cont">
                                                        <span className="cal-icon"><i className="fas fa-calendar-alt" /></span>
                                                        <DatePicker
                                                            required
                                                            selected={fmsData.endDate}
                                                            onChange={(date) => setFmsData({ ...fmsData, endDate: date })}
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText='Select Date'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end">
                        <button type="reset" name="reset_button" className="btn btn-exp-light me-2">Reset</button>
                        <button type="submit" name="submit_button" className="btn btn-exp-green">Generate Report</button>
                    </div>
                </form>

                <>                    
                    <BottleNeckCardComponent />
                </>

            </div>
        </>
    )
}

export default BottleNeckReportTable