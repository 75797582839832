import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { PrivateAxios } from '../../environment/AxiosInstance'
import Loader from '../../loader/Loader';
import { ErrorMessage, SuccessMessage, WaringMessage } from '../../environment/ToastMessage';
import moment from 'moment';
import { UserAuth } from '../auth/Auth';
import Select from 'react-select'

function Holiday() {
    const [loading, setLoading] = useState(false);
    const [holiday, setHoliday] = useState([]);
    const [update, setUpdate] = useState(false)
    const [create, setCreate] = useState(false)
    const [deleteShow, setDeleteShow] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [holidayValue, setHolidayValue] = useState('')
    const [holidayInputValue, setHolidayInputValue] = useState({
        "name": "",
        "date": "",
        "status": ""
    })
    const { Logout, holidayDates } = UserAuth();
    const holidayData = () => {
        setLoading(true)
        PrivateAxios.get('all-holiday-list')
            .then((res) => {
                setLoading(false)
                setHoliday(res.data.data);
            }).catch((err) => {
                setLoading(false)
                if (err.response.status == 401) {
                    Logout();
                }
            })
    }
    useEffect(() => {
        holidayData();
    }, [])

    const HolidayUpdateModelClose = () => {
        setUpdate(false);
        setHolidayValue('')
    }
    const HolidayCreateModelClose = () => {
        setCreate(false)
        setHolidayInputValue('')
    }
    const deleteModalClose = () => {
        setDeleteShow(false);
        setDeleteId('')
    }

    const createHoliday = () => {
        setLoading(true)
        if (holidayInputValue.name == '' || holidayInputValue.date == '') {
            WaringMessage('Input field can not be empty !')
            setLoading(false)
            return;
        }
        PrivateAxios.post("create-holiday", holidayInputValue)
            .then((res) => {
                holidayData();
                setLoading(false)
                SuccessMessage(res.data.data);
                holidayDates();
                HolidayCreateModelClose();
                setHolidayInputValue({ ...holidayInputValue, name: "", date: "" })
            }).catch((err) => {
                setLoading(false)
                ErrorMessage(err.response.data.msg)
            })
    }
    const UpdateHoliday = (e) => {
        e.preventDefault();
        setLoading(true)

        PrivateAxios.put(`update-holiday/${holidayValue.id}`, holidayValue)
            .then((res) => {
                const updateHoliday = holiday.map((item) =>
                    item.id === holidayValue.id ? { ...item, name: holidayValue.name, holiday_date: holidayValue.holiday_date, status: holidayValue.status } : item
                );
                setHoliday(updateHoliday)
                setLoading(false)
                holidayData();
                holidayDates();
                SuccessMessage(res.data.data);
                HolidayUpdateModelClose();

            }).catch((res) => {
                setLoading(false)
                if (res.response.status == 401) {
                    Logout();
                }
            })
    }
    const deleteHoliday = () => {
        setLoading(true)
        PrivateAxios.delete(`delete-holiday/${deleteId}`)
            .then((res) => {
                const deleteHoliday = holiday.filter((item) => item.id != deleteId);
                setHoliday(deleteHoliday)
                setLoading(false)
                holidayDates();
                SuccessMessage(res.data.data);
                deleteModalClose();
            }).catch((res) => {
                setLoading(false)
                if (res.response.status == 401) {
                    Logout();
                }
            })
    }

    const statusChange = (data, e) => {
        const newStatus = e.target.checked ? 1 : 0;
        setLoading(true)
        PrivateAxios.put(`update-holiday/${data.id}`, { "name": data.name, "holiday_date": data.holiday_date, "status": e.target.checked })
            .then((res) => {
                const updatedHoliday = holiday.map((item) =>
                    item.id === data.id ? { ...item, status: newStatus } : item
                );
                setHoliday(updatedHoliday)
                setLoading(false)
                SuccessMessage(res.data.data);
            }).catch((res) => {
                setLoading(false)
                if (res.response.status == 401) {
                    Logout();
                }
            })
    }
    function isDateExpired(expiryDate) {
        const now = new Date(); // Current date and time
        return new Date(expiryDate) < now;
    }
    // department start
    const [selectedDepartmentOptions, setSelectedDepartmentOptions] = useState([]);

    const handleChangeDepartment = (selected) => {
        if (selected.some(option => option.value === "All")) {
            setSelectedDepartmentOptions([{ value: "All", label: "All" }]); // Only allow "All"
        } else {
            setSelectedDepartmentOptions(selected); // Allow normal selection
        }
    };

    const departmentOptions = [
        { value: "All", label: "All" },
        { value: "Accounts", label: "Accounts" },
        { value: "HR", label: "HR" },
        { value: "Development", label: "Development" },
        { value: "Sales", label: "Sales" }
    ];
    // department end

    return (
        <>
            {loading ? <Loader /> :
                < div className='p-4'>
                    <div className='w-100 d-flex justify-content-end mb-3'>
                        <button onClick={() => setCreate(true)} className='btn btn-exp-green'>
                            <i className='fas fa-plus me-2'></i>
                            New
                        </button>
                    </div>
                    <div className='card'>
                        {/* <div className='d-flex justify-content-between flex-wrap align-items-center pt-3 px-3'>
                            <div className='table-button-group mb-3'>
                                <button className='btn table-export-btn'  >
                                    <img src={process.env.PUBLIC_URL + 'assets/images/file-csv.svg'} alt="icon" />
                                </button>
                                <button className='btn table-export-btn'>
                                    <img src={process.env.PUBLIC_URL + 'assets/images/file-pdf.svg'} alt="icon" />
                                </button>
                                <button className='btn table-export-btn' >
                                    <img src={process.env.PUBLIC_URL + 'assets/images/print.svg'} alt="icon" />
                                </button>
                            </div>
                            <div className='d-flex align-items-center mb-3 ms-auto'>
                                <label className='mr-2 mb-0'>Search: </label>
                                <input type='text' placeholder='Type here...' className='form-control form-control-sm' />
                            </div>
                        </div> */}
                        <div className='card-body p-0'>
                            <div className='table-responsive rounded-10'>
                                <table class="table table-striped border-top">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: 100 }}>SL.No.</th>
                                            {/* <th scope="col">Departments</th> */}
                                            <th scope="col">Holiday Name</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {holiday.map((data, i) => (
                                            <tr>
                                                <td scope="row" style={{ width: 100 }}>{i + 1}</td>
                                                {/* <td><span className='text-nowrap'>Department Name</span></td> */}
                                                <td><span className='text-nowrap'>{data.name}</span></td>
                                                <td><span className='text-nowrap'>{moment(data.holiday_date).format('DD-MM-YYYY | dddd')}</span></td>
                                                <td>
                                                    <h6 className='text-nowrap'>{isDateExpired(data.holiday_date) ? <span className='badge exp-badge-red-light rounded-pill'>Expired</span> : <span className='badge exp-badge-success-light rounded-pill'>Upcoming...</span>}</h6>
                                                </td>
                                                <td>
                                                    <div className="d-flex gap-2">
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip>
                                                                    Edit
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <button onClick={() => { setHolidayValue(data); setUpdate(true) }} to="#" className="table-action-btn">
                                                                <i className='fas fa-pen'></i>
                                                            </button>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip>
                                                                    Delete
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <button onClick={() => { setDeleteShow(true); setDeleteId(data.id) }} className="table-action-btn" >
                                                                <i className='fas fa-trash-alt text-exp-red'></i>
                                                            </button>
                                                        </OverlayTrigger>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div >
            }

            {/* create Department */}
            <Modal id="createHoliday" show={create} onHide={HolidayCreateModelClose} backdrop="static" centered>
                <Modal.Header closeButton className='gth-blue-light-bg'>
                    <Modal.Title className='gth-modal-title'>Create Holiday</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <div className='col-12'>
                        <div className='form-group'>
                            <label className='form-label'>Department</label>
                            <div className="custom-select-wrap">
                                <Select
                                    options={departmentOptions}
                                    isMulti
                                    value={selectedDepartmentOptions}
                                    onChange={handleChangeDepartment}
                                    closeMenuOnSelect={false}
                                />
                            </div>
                        </div>
                    </div> */}
                    <div className='col-12'>
                        <div className='form-group'>
                            <label className='form-label'>Name</label>
                            <input type='text' className={`form-control`} placeholder='Enter name' onChange={(e) => setHolidayInputValue({ ...holidayInputValue, name: e.target.value })} />
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label className='form-label'>Date</label>
                            <input type='date' className={`form-control`} onChange={(e) => setHolidayInputValue({ ...holidayInputValue, date: e.target.value })} />
                        </div>
                    </div>

                    {/* <div className='col-12'>
                        <div className='form-group'>
                            <label className='form-label'>Status</label>
                            <Form.Select aria-label="Default select example" onChange={(e) => setHolidayInputValue({ ...holidayInputValue, status: e.target.value })}>
                                <option value="1" selected>Active</option>
                                <option value="0">Inactive</option>
                            </Form.Select>
                        </div>
                    </div> */}
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" className='btn-sm' onClick={HolidayCreateModelClose}>
                        Close
                    </Button> */}
                    <button variant="primary" className='btn btn-exp-green' onClick={createHoliday}>
                        Create
                    </button>
                </Modal.Footer>
            </Modal>

            {/* Update Holiday */}
            <Modal id="updateHoliday" show={update} onHide={HolidayUpdateModelClose} backdrop="static" centered>
                <Modal.Header closeButton className='gth-blue-light-bg'>
                    <Modal.Title className='gth-modal-title'>Update Holiday</Modal.Title>
                </Modal.Header>
                <form onSubmit={UpdateHoliday}>
                    <Modal.Body>
                        {/* <div className='col-12'>
                            <div className='form-group'>
                                <label className='form-label'>Department</label>
                                <div className="custom-select-wrap">
                                    <Select
                                        options={departmentOptions}
                                        isMulti
                                        value={selectedDepartmentOptions}
                                        onChange={handleChangeDepartment}
                                        closeMenuOnSelect={false}
                                    />
                                </div>
                            </div>
                        </div> */}
                        <div className='col-12'>
                            <div className='form-group'>
                                <label className='form-label'>Title</label>
                                <input type='text' value={holidayValue.name} className={`form-control`} name='task_name' placeholder='Enter title' onChange={(e) => setHolidayValue({ ...holidayValue, name: e.target.value })} />
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label className='form-label'>Date</label>
                                <input type='date' className={`form-control`} value={holidayValue.holiday_date} onChange={(e) => setHolidayValue({ ...holidayValue, holiday_date: e.target.value })} />
                            </div>
                        </div>

                        {/* <div className='col-12'>
                        <div className='form-group'>
                            <label className='form-label'>Status</label>
                            <Form.Select aria-label="Default select example" value={holidayValue.status} onChange={(e) => setHolidayValue({ ...holidayValue, status: e.target.value })}>
                                <option value="1" >Active</option>
                                <option value="0">Inactive</option>
                            </Form.Select>
                        </div>
                    </div> */}
                    </Modal.Body>
                    <Modal.Footer>
                        {/* <Button variant="secondary" className='btn-sm' onClick={HolidayUpdateModelClose}>
                        Close
                    </Button> */}
                        <button variant="primary" type="submit" className='btn btn-exp-green' >
                            Update
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>

            {/* Delete department */}
            <Modal show={deleteShow} onHide={deleteModalClose} backdrop="static" keyboard={false} centered >
                <Modal.Header closeButton className="gth-light-red-bg">
                    <Modal.Title className="gth-text-danger">Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="delete-confirm-wrap text-center">
                        <div className="delete-confirm-icon mb-3 mt-2">
                            <img src={process.env.PUBLIC_URL + '/assets/images/delete-warning.svg'} alt="Warning" className="img-fluid" />
                        </div>
                        <h4 className="text-muted">Are you sure?</h4>
                        <p className="text-muted">
                            Do you really want to delete these record?
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <button className='btn btn-secondary' onClick={deleteModalClose}>
                        Cancel
                    </button>
                    <button className='btn btn-exp-red' onClick={deleteHoliday}>
                        Delete
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Holiday