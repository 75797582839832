import React from 'react'
import './bottleneck.min.css';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Tooltip } from 'antd';

function BottleNeckCardComponent() {
    return (
        <>
            <div className=' overflow-hidden'>
                <div className='mb-3 p-0'>
                    <div className='overflow-x-auto'>
                        <div className='bottle-neck-card-wrap'>
                            <div className='bottle_neck_item'>
                                <h5 className='mb-0'>Order to delivery</h5>
                            </div>
                            <div className='bottle_neck_item'>
                                <div>
                                    <OverlayTrigger
                                        trigger="click"
                                        rootClose
                                        placement="top"
                                        overlay={
                                            <Popover className="bottleneck_Short_desc">
                                                <div className="bottleneck_Short_desc_wrapper">
                                                    <div className='bottleneck_Short_desc_item'>
                                                        <div className="profile-wrap">
                                                            <div className="exp-avtar bg-exp-yellow">
                                                                <img
                                                                    className="prof-img"
                                                                    src="https://bms.growthh.com/api/uploads/1718707121.jpg"
                                                                    alt="user"
                                                                />
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-nowrap text-truncate">Subhadeep Chowdhury</h5>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-mobile-alt me-2"></i><span>+91 00000 00000</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-envelope me-2"></i><span>abc@example.com</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-comment-alt me-2"></i><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                                        </div>
                                                    </div>

                                                    <div className='bottleneck_Short_desc_item'>
                                                        <div className="profile-wrap">
                                                            <div className="exp-avtar bg-exp-yellow">
                                                                <img
                                                                    className="prof-img"
                                                                    src="https://bms.growthh.com/api/uploads/1718707121.jpg"
                                                                    alt="user"
                                                                />
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-nowrap text-truncate">Subhadeep Chowdhury</h5>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-mobile-alt me-2"></i><span>+91 00000 00000</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-envelope me-2"></i><span>abc@example.com</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-comment-alt me-2"></i><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                                        </div>
                                                    </div>

                                                    <div className='bottleneck_Short_desc_item'>
                                                        <div className="profile-wrap">
                                                            <div className="exp-avtar bg-exp-yellow">
                                                                <img
                                                                    className="prof-img"
                                                                    src="https://bms.growthh.com/api/uploads/1718707121.jpg"
                                                                    alt="user"
                                                                />
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-nowrap text-truncate">Subhadeep Chowdhury</h5>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-mobile-alt me-2"></i><span>+91 00000 00000</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-envelope me-2"></i><span>abc@example.com</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-comment-alt me-2"></i><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Popover>
                                        }
                                    >

                                        <div className='bottle_count big_count'>
                                            10
                                        </div>

                                    </OverlayTrigger>
                                </div>
                                <Tooltip title="Check Bottleneck Details">
                                    <Link to="/bottle-neck-chart-report">
                                        <span className='text-muted'>Step Name: Check Inventory, Is It Available?</span>
                                    </Link>
                                </Tooltip>
                            </div>
                            <div className='bottle_neck_item'>
                                <div>
                                    
                                </div>
                                <Tooltip title="Check Bottleneck Details">
                                    <Link to="/bottle-neck-chart-report">
                                        <span className='text-muted'>Step Name: Check Inventory, Is It Available?</span>
                                    </Link>
                                </Tooltip>
                            </div>
                            <div className='bottle_neck_item'>
                                <div>
                                    
                                </div>
                                <Tooltip title="Check Bottleneck Details">
                                    <Link to="/bottle-neck-chart-report">
                                        <span className='text-muted'>Step Name: Check Inventory, Is It Available?</span>
                                    </Link>
                                </Tooltip>
                            </div>
                            <div className='bottle_neck_item'>
                                <div>
                                    
                                </div>
                                <Tooltip title="Check Bottleneck Details">
                                    <Link to="/bottle-neck-chart-report">
                                        <span className='text-muted'>Step Name: Check Inventory, Is It Available?</span>
                                    </Link>
                                </Tooltip>
                            </div>
                            <div className='bottle_neck_item'>
                                <div>
                                    
                                </div>
                                <Tooltip title="Check Bottleneck Details">
                                    <Link to="/bottle-neck-chart-report">
                                        <span className='text-muted'>Step Name: Check Inventory, Is It Available?</span>
                                    </Link>
                                </Tooltip>
                            </div>
                            <div className='bottle_neck_item'>
                                <div>
                                    
                                </div>
                                <Tooltip title="Check Bottleneck Details">
                                    <Link to="/bottle-neck-chart-report">
                                        <span className='text-muted'>Step Name: Check Inventory, Is It Available?</span>
                                    </Link>
                                </Tooltip>
                            </div>
                            <div className='bottle_neck_item'>
                                <div>
                                    
                                </div>
                                <Tooltip title="Check Bottleneck Details">
                                    <Link to="/bottle-neck-chart-report">
                                        <span className='text-muted'>Step Name: Check Inventory, Is It Available?</span>
                                    </Link>
                                </Tooltip>
                            </div>
                            <div className='bottle_neck_item'>
                                <div>
                                    <OverlayTrigger
                                        trigger="click"
                                        rootClose
                                        placement="top"
                                        overlay={
                                            <Popover className="bottleneck_Short_desc">
                                                <div className="bottleneck_Short_desc_wrapper">
                                                    <div className='bottleneck_Short_desc_item'>
                                                        <div className="profile-wrap">
                                                            <div className="exp-avtar bg-exp-yellow">
                                                                <img
                                                                    className="prof-img"
                                                                    src="https://bms.growthh.com/api/uploads/1718707121.jpg"
                                                                    alt="user"
                                                                />
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-nowrap text-truncate">Subhadeep Chowdhury</h5>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-mobile-alt me-2"></i><span>+91 00000 00000</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-envelope me-2"></i><span>abc@example.com</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-comment-alt me-2"></i><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                                        </div>
                                                    </div>

                                                    <div className='bottleneck_Short_desc_item'>
                                                        <div className="profile-wrap">
                                                            <div className="exp-avtar bg-exp-yellow">
                                                                <img
                                                                    className="prof-img"
                                                                    src="https://bms.growthh.com/api/uploads/1718707121.jpg"
                                                                    alt="user"
                                                                />
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-nowrap text-truncate">Subhadeep Chowdhury</h5>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-mobile-alt me-2"></i><span>+91 00000 00000</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-envelope me-2"></i><span>abc@example.com</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-comment-alt me-2"></i><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                                        </div>
                                                    </div>

                                                    <div className='bottleneck_Short_desc_item'>
                                                        <div className="profile-wrap">
                                                            <div className="exp-avtar bg-exp-yellow">
                                                                <img
                                                                    className="prof-img"
                                                                    src="https://bms.growthh.com/api/uploads/1718707121.jpg"
                                                                    alt="user"
                                                                />
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-nowrap text-truncate">Subhadeep Chowdhury</h5>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-mobile-alt me-2"></i><span>+91 00000 00000</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-envelope me-2"></i><span>abc@example.com</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-comment-alt me-2"></i><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Popover>
                                        }
                                    >

                                        <div className='bottle_count'>
                                            5
                                        </div>

                                    </OverlayTrigger>
                                </div>
                                <Tooltip title="Check Bottleneck Details">
                                    <Link to="/bottle-neck-chart-report">
                                        <span className='text-muted'>Step Name: Check Inventory, Is It Available?</span>
                                    </Link>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=' overflow-hidden'>
                <div className='mb-3 p-0'>
                    <div className='overflow-x-auto'>
                        <div className='bottle-neck-card-wrap'>
                            <div className='bottle_neck_item'>
                                <h5 className='mb-0'>Employee Onboarding</h5>
                            </div>
                            
                            <div className='bottle_neck_item'>
                                <div>
                                    
                                </div>
                                <Tooltip title="Check Bottleneck Details">
                                    <Link to="/bottle-neck-chart-report">
                                        <span className='text-muted'>Step Name: Check Inventory, Is It Available?</span>
                                    </Link>
                                </Tooltip>
                            </div>
                            <div className='bottle_neck_item'>
                                <div>
                                    
                                </div>
                                <Tooltip title="Check Bottleneck Details">
                                    <Link to="/bottle-neck-chart-report">
                                        <span className='text-muted'>Step Name: Check Inventory, Is It Available?</span>
                                    </Link>
                                </Tooltip>
                            </div>
                            <div className='bottle_neck_item'>
                                <div>
                                    <OverlayTrigger
                                        trigger="click"
                                        rootClose
                                        placement="top"
                                        overlay={
                                            <Popover className="bottleneck_Short_desc">
                                                <div className="bottleneck_Short_desc_wrapper">
                                                    <div className='bottleneck_Short_desc_item'>
                                                        <div className="profile-wrap">
                                                            <div className="exp-avtar bg-exp-yellow">
                                                                <img
                                                                    className="prof-img"
                                                                    src="https://bms.growthh.com/api/uploads/1718707121.jpg"
                                                                    alt="user"
                                                                />
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-nowrap text-truncate">Subhadeep Chowdhury</h5>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-mobile-alt me-2"></i><span>+91 00000 00000</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-envelope me-2"></i><span>abc@example.com</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-comment-alt me-2"></i><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                                        </div>
                                                    </div>

                                                    <div className='bottleneck_Short_desc_item'>
                                                        <div className="profile-wrap">
                                                            <div className="exp-avtar bg-exp-yellow">
                                                                <img
                                                                    className="prof-img"
                                                                    src="https://bms.growthh.com/api/uploads/1718707121.jpg"
                                                                    alt="user"
                                                                />
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-nowrap text-truncate">Subhadeep Chowdhury</h5>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-mobile-alt me-2"></i><span>+91 00000 00000</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-envelope me-2"></i><span>abc@example.com</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-comment-alt me-2"></i><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                                        </div>
                                                    </div>

                                                    <div className='bottleneck_Short_desc_item'>
                                                        <div className="profile-wrap">
                                                            <div className="exp-avtar bg-exp-yellow">
                                                                <img
                                                                    className="prof-img"
                                                                    src="https://bms.growthh.com/api/uploads/1718707121.jpg"
                                                                    alt="user"
                                                                />
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-nowrap text-truncate">Subhadeep Chowdhury</h5>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-mobile-alt me-2"></i><span>+91 00000 00000</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-envelope me-2"></i><span>abc@example.com</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-comment-alt me-2"></i><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Popover>
                                        }
                                    >

                                        <div className='bottle_count big_count'>
                                            10
                                        </div>

                                    </OverlayTrigger>
                                </div>
                                <Tooltip title="Check Bottleneck Details">
                                    <Link to="/bottle-neck-chart-report">
                                        <span className='text-muted'>Step Name: Check Inventory, Is It Available?</span>
                                    </Link>
                                </Tooltip>
                            </div>
                            <div className='bottle_neck_item'>
                                <div>
                                    
                                </div>
                                <Tooltip title="Check Bottleneck Details">
                                    <Link to="/bottle-neck-chart-report">
                                        <span className='text-muted'>Step Name: Check Inventory, Is It Available?</span>
                                    </Link>
                                </Tooltip>
                            </div>
                            <div className='bottle_neck_item'>
                                <div>
                                    <OverlayTrigger
                                        trigger="click"
                                        rootClose
                                        placement="top"
                                        overlay={
                                            <Popover className="bottleneck_Short_desc">
                                                <div className="bottleneck_Short_desc_wrapper">
                                                    <div className='bottleneck_Short_desc_item'>
                                                        <div className="profile-wrap">
                                                            <div className="exp-avtar bg-exp-yellow">
                                                                <img
                                                                    className="prof-img"
                                                                    src="https://bms.growthh.com/api/uploads/1718707121.jpg"
                                                                    alt="user"
                                                                />
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-nowrap text-truncate">Subhadeep Chowdhury</h5>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-mobile-alt me-2"></i><span>+91 00000 00000</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-envelope me-2"></i><span>abc@example.com</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-comment-alt me-2"></i><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                                        </div>
                                                    </div>

                                                    <div className='bottleneck_Short_desc_item'>
                                                        <div className="profile-wrap">
                                                            <div className="exp-avtar bg-exp-yellow">
                                                                <img
                                                                    className="prof-img"
                                                                    src="https://bms.growthh.com/api/uploads/1718707121.jpg"
                                                                    alt="user"
                                                                />
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-nowrap text-truncate">Subhadeep Chowdhury</h5>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-mobile-alt me-2"></i><span>+91 00000 00000</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-envelope me-2"></i><span>abc@example.com</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-comment-alt me-2"></i><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                                        </div>
                                                    </div>

                                                    <div className='bottleneck_Short_desc_item'>
                                                        <div className="profile-wrap">
                                                            <div className="exp-avtar bg-exp-yellow">
                                                                <img
                                                                    className="prof-img"
                                                                    src="https://bms.growthh.com/api/uploads/1718707121.jpg"
                                                                    alt="user"
                                                                />
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-nowrap text-truncate">Subhadeep Chowdhury</h5>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-mobile-alt me-2"></i><span>+91 00000 00000</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-envelope me-2"></i><span>abc@example.com</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-comment-alt me-2"></i><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Popover>
                                        }
                                    >

                                        <div className='bottle_count'>
                                            5
                                        </div>

                                    </OverlayTrigger>
                                </div>
                                <Tooltip title="Check Bottleneck Details">
                                    <Link to="/bottle-neck-chart-report">
                                        <span className='text-muted'>Step Name: Check Inventory, Is It Available?</span>
                                    </Link>
                                </Tooltip>
                            </div>
                            <div className='bottle_neck_item'>
                                <div>
                                    
                                </div>
                                <Tooltip title="Check Bottleneck Details">
                                    <Link to="/bottle-neck-chart-report">
                                        <span className='text-muted'>Step Name: Check Inventory, Is It Available?</span>
                                    </Link>
                                </Tooltip>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className=' overflow-hidden'>
                <div className='mb-3 p-0'>
                    <div className='overflow-x-auto'>
                        <div className='bottle-neck-card-wrap'>
                            <div className='bottle_neck_item'>
                                <h5 className='mb-0'>Employee Onboarding 2</h5>
                            </div>
                            <div className='bottle_neck_item'>
                                <div>
                                    <OverlayTrigger
                                        trigger="click"
                                        rootClose
                                        placement="top"
                                        overlay={
                                            <Popover className="bottleneck_Short_desc">
                                                <div className="bottleneck_Short_desc_wrapper">
                                                    <div className='bottleneck_Short_desc_item'>
                                                        <div className="profile-wrap">
                                                            <div className="exp-avtar bg-exp-yellow">
                                                                <img
                                                                    className="prof-img"
                                                                    src="https://bms.growthh.com/api/uploads/1718707121.jpg"
                                                                    alt="user"
                                                                />
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-nowrap text-truncate">Subhadeep Chowdhury</h5>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-mobile-alt me-2"></i><span>+91 00000 00000</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-envelope me-2"></i><span>abc@example.com</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-comment-alt me-2"></i><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                                        </div>
                                                    </div>

                                                    <div className='bottleneck_Short_desc_item'>
                                                        <div className="profile-wrap">
                                                            <div className="exp-avtar bg-exp-yellow">
                                                                <img
                                                                    className="prof-img"
                                                                    src="https://bms.growthh.com/api/uploads/1718707121.jpg"
                                                                    alt="user"
                                                                />
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-nowrap text-truncate">Subhadeep Chowdhury</h5>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-mobile-alt me-2"></i><span>+91 00000 00000</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-envelope me-2"></i><span>abc@example.com</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-comment-alt me-2"></i><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                                        </div>
                                                    </div>

                                                    <div className='bottleneck_Short_desc_item'>
                                                        <div className="profile-wrap">
                                                            <div className="exp-avtar bg-exp-yellow">
                                                                <img
                                                                    className="prof-img"
                                                                    src="https://bms.growthh.com/api/uploads/1718707121.jpg"
                                                                    alt="user"
                                                                />
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-nowrap text-truncate">Subhadeep Chowdhury</h5>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-mobile-alt me-2"></i><span>+91 00000 00000</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-envelope me-2"></i><span>abc@example.com</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-comment-alt me-2"></i><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Popover>
                                        }
                                    >

                                        <div className='bottle_count big_count'>
                                            10
                                        </div>

                                    </OverlayTrigger>
                                </div>
                                <Tooltip title="Check Bottleneck Details">
                                    <Link to="/bottle-neck-chart-report">
                                        <span className='text-muted'>Step Name: Check Inventory, Is It Available?</span>
                                    </Link>
                                </Tooltip>
                            </div>
                            <div className='bottle_neck_item'>
                                <div>
                                    <OverlayTrigger
                                        trigger="click"
                                        rootClose
                                        placement="top"
                                        overlay={
                                            <Popover className="bottleneck_Short_desc">
                                                <div className="bottleneck_Short_desc_wrapper">
                                                    <div className='bottleneck_Short_desc_item'>
                                                        <div className="profile-wrap">
                                                            <div className="exp-avtar bg-exp-yellow">
                                                                <img
                                                                    className="prof-img"
                                                                    src="https://bms.growthh.com/api/uploads/1718707121.jpg"
                                                                    alt="user"
                                                                />
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-nowrap text-truncate">Subhadeep Chowdhury</h5>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-mobile-alt me-2"></i><span>+91 00000 00000</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-envelope me-2"></i><span>abc@example.com</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-comment-alt me-2"></i><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                                        </div>
                                                    </div>

                                                    <div className='bottleneck_Short_desc_item'>
                                                        <div className="profile-wrap">
                                                            <div className="exp-avtar bg-exp-yellow">
                                                                <img
                                                                    className="prof-img"
                                                                    src="https://bms.growthh.com/api/uploads/1718707121.jpg"
                                                                    alt="user"
                                                                />
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-nowrap text-truncate">Subhadeep Chowdhury</h5>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-mobile-alt me-2"></i><span>+91 00000 00000</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-envelope me-2"></i><span>abc@example.com</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-comment-alt me-2"></i><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                                        </div>
                                                    </div>

                                                    <div className='bottleneck_Short_desc_item'>
                                                        <div className="profile-wrap">
                                                            <div className="exp-avtar bg-exp-yellow">
                                                                <img
                                                                    className="prof-img"
                                                                    src="https://bms.growthh.com/api/uploads/1718707121.jpg"
                                                                    alt="user"
                                                                />
                                                            </div>
                                                            <div className="ps-2 profile-name-wrap text-truncate">
                                                                <h5 className="profile-name text-nowrap text-truncate">Subhadeep Chowdhury</h5>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-mobile-alt me-2"></i><span>+91 00000 00000</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-envelope me-2"></i><span>abc@example.com</span>
                                                        </div>
                                                        <div>
                                                            <i class="fas fa-comment-alt me-2"></i><span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Popover>
                                        }
                                    >

                                        <div className='bottle_count'>
                                            5
                                        </div>

                                    </OverlayTrigger>
                                </div>
                                <Tooltip title="Check Bottleneck Details">
                                    <Link to="/bottle-neck-chart-report">
                                        <span className='text-muted'>Step Name: Check Inventory, Is It Available?</span>
                                    </Link>
                                </Tooltip>
                            </div>
                            <div className='bottle_neck_item'>
                                <div>
                                    
                                </div>
                                <Tooltip title="Check Bottleneck Details">
                                    <Link to="/bottle-neck-chart-report">
                                        <span className='text-muted'>Step Name: Check Inventory, Is It Available?</span>
                                    </Link>
                                </Tooltip>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BottleNeckCardComponent