// import React, { useState, useCallback } from 'react';
// import ReactFlow, { MiniMap, Controls, Background, addEdge, applyNodeChanges, applyEdgeChanges, Handle, Position } from 'react-flow-renderer';

// // Custom Node Component with editable label
// const CustomNode = ({ data, id, removeNode, updateNodeLabel }) => {
//     return (
//         <div style={{ padding: 10, border: '1px solid #ddd', borderRadius: 5, background: 'white', position: 'relative' }}>
//             <Handle type="target" position={Position.Left} />
//             <input
//                 type="text"
//                 value={data.label}
//                 onChange={(e) => updateNodeLabel(id, e.target.value)}
//                 style={{ border: 'none', textAlign: 'center', width: '100%', outline: 'none' }}
//             />
//             <button
//                 onClick={() => removeNode(id)}
//                 style={{ position: 'absolute', top: -8, right: -8, background: 'red', color: 'white', border: 'none', borderRadius: '50%', width: 20, height: 20, cursor: 'pointer' }}
//             >
//                 X
//             </button>
//             <Handle type="source" position={Position.Right} />
//         </div>
//     );
// };

// // Define node types
// const nodeTypes = {
//     custom: (props) => <CustomNode {...props} removeNode={props.data.removeNode} updateNodeLabel={props.data.updateNodeLabel} />
// };

// function MyFlowDiagram() {
//     const [nodes, setNodes] = useState([]);
//     const [edges, setEdges] = useState([]);

//     // Handlers for node and edge changes
//     const onNodesChange = useCallback((changes) => setNodes((nds) => applyNodeChanges(changes, nds)), []);
//     const onEdgesChange = useCallback((changes) => setEdges((eds) => applyEdgeChanges(changes, eds)), []);

//     const onConnect = useCallback((connection) => {
//         setEdges((eds) => addEdge({ ...connection, markerEnd: { type: 'arrowclosed' } }, eds));
//     }, []);

//     // Function to add a new node
//     const addNode = () => {
//         const newNode = {
//             id: `${nodes.length + 1}`,
//             type: 'custom',
//             position: { x: Math.random() * 100, y: Math.random() * 100 },
//             data: {
//                 label: `Node ${nodes.length + 1}`,
//                 removeNode,
//                 updateNodeLabel
//             }
//         };
//         setNodes((nds) => [...nds, newNode]);
//     };

//     // Function to remove a node and its related edges
//     const removeNode = (nodeId) => {
//         setNodes((nds) => nds.filter((node) => node.id !== nodeId));
//         setEdges((eds) => eds.filter((edge) => edge.source !== nodeId && edge.target !== nodeId));
//     };

//     // Function to update node label
//     const updateNodeLabel = (nodeId, newLabel) => {
//         setNodes((nds) =>
//             nds.map((node) => (node.id === nodeId ? { ...node, data: { ...node.data, label: newLabel } } : node))
//         );
//     };

//     return (
//         <div className='p-4'>
//             <div className='card mb-0'>
//                 <div className='card-body'>
//                     <button onClick={addNode} className="btn btn-primary mb-3">Add Node</button>
//                     <div style={{ height: '700px', width: '100%' }}>
//                         <ReactFlow
//                             style={{ backgroundColor: "#F7F9FB" }}
//                             nodes={nodes}
//                             edges={edges}
//                             onNodesChange={onNodesChange}
//                             onEdgesChange={onEdgesChange}
//                             onConnect={onConnect}
//                             fitView
//                             nodeTypes={nodeTypes} // Using the custom node type
//                             minZoom={0.2}
//                             maxZoom={4}
//                         >
//                             <MiniMap />
//                             <Controls />
//                             <Background variant="dots" gap={12} size={0.5} />
//                         </ReactFlow>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default MyFlowDiagram;



import React, { useState, useCallback } from "react";
import ReactFlow, { MiniMap, Controls, Background, addEdge, applyNodeChanges, applyEdgeChanges } from "react-flow-renderer";
import CustomResizableNode from "./CustomResizableNode";

import "./reactwebflow.css";

const nodeTypes = {
    custom: (props) => (
        <CustomResizableNode
            {...props}
            removeNode={props.data.removeNode}
            updateNodeLabel={props.data.updateNodeLabel}
            updateNodeSize={props.data.updateNodeSize}
        />
    )
};

function MyFlowDiagram() {
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);

    const onNodesChange = useCallback((changes) => setNodes((nds) => applyNodeChanges(changes, nds)), []);
    const onEdgesChange = useCallback((changes) => setEdges((eds) => applyEdgeChanges(changes, eds)), []);

    const onConnect = useCallback((connection) => {
        setEdges((eds) => addEdge({ ...connection, animated: true, markerEnd: { type: "arrowclosed" } }, eds));
    }, []);

    const addNode = () => {
        const newNode = {
            id: `${nodes.length + 1}`,
            type: "custom",
            position: { x: Math.random() * 300, y: Math.random() * 300 },
            data: {
                label: `Node ${nodes.length + 1}`,
                width: 148, // Initialize width
                height: 40, // Initialize height
                removeNode,
                updateNodeLabel,
                updateNodeSize
            },
            style: { width: 150, height: 40 } // Initial style
        };
        setNodes((nds) => [...nds, newNode]);
    };

    const removeNode = (nodeId) => {
        setNodes((nds) => nds.filter((node) => node.id !== nodeId));
        setEdges((eds) => eds.filter((edge) => edge.source !== nodeId && edge.target !== nodeId));
    };

    const updateNodeLabel = (nodeId, newLabel) => {
        setNodes((nds) =>
            nds.map((node) => (node.id === nodeId ? { ...node, data: { ...node.data, label: newLabel } } : node))
        );
    };

    const updateNodeSize = (nodeId, newWidth, newHeight) => {
        setNodes((nds) =>
            nds.map((node) =>
                node.id === nodeId
                    ? {
                        ...node,
                        data: { ...node.data, width: newWidth, height: newHeight },
                        style: { width: newWidth, height: newHeight }
                    }
                    : node
            )
        );
    };

    return (
        <div className="p-4">
            <div className="card mb-0">
                <div className="card-body">
                    <button onClick={addNode} className="btn btn-primary mb-3">
                        Add Node
                    </button>
                    <div style={{ height: "700px", width: "100%" }}>
                        <ReactFlow
                            style={{ backgroundColor: "#F7F9FB" }}

                            fitView
                            nodeTypes={nodeTypes}
                            minZoom={0.2}
                            maxZoom={4}
                            nodes={nodes}
                            edges={edges}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            onConnect={onConnect}
                        >
                            <MiniMap />
                            <Controls />
                            <Background variant="dots" gap={12} size={0.5} />
                        </ReactFlow>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyFlowDiagram;