import React, { useEffect, useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { Doughnut } from 'react-chartjs-2';
import { PrivateAxios } from '../../../environment/AxiosInstance';
import Select from 'react-select'
import { Collapse } from 'react-bootstrap';
import { WaringMessage } from '../../../environment/ToastMessage';






function FmsDynamicReport({ layoutData1, fmsId, layout, setLayoutName, filterdata, setLayoutC, layoutC, setTable, setChat }) {

    const operation = [
        { 'label': 'Sum', 'value': 'Sum' },
        { 'label': 'average', 'value': 'average' },
        { 'label': 'min', 'value': 'min' },
        { 'label': 'max', 'value': 'max' },
        { 'label': 'count', 'value': 'count' },
    ]




    //==================All Layout Table=======================/
    //**************************************************/
    const [layout1, setLayout1] = useState({
        "row": "",
        "column": "",
        "filter": "",
        "value": "",
        "operation": "",
        "pie": 0,
        "bar": 0,
        "table": 0,
    })




    const [layoutAxis1, setLayoutAxis1] = useState({
        "row": "",
        "value": "",
        "operation": "",
    })
    const [layoutDisplayData1, setLayoutDisplayData1] = useState([])

    const submitData = (e) => {
        e.preventDefault();

        if (layout1.pie == 1 || layout1.bar == 1 && layout1.table == 0) {
            if (layoutAxis1.row != '' && layoutAxis1.value != '' && layoutAxis1.operation != '') {
                PrivateAxios.post('report/get-fms-chat-report', { ...layoutAxis1, fmsId })
                    .then((res) => {
                        setChat(res.data.data);
                       

                    }).catch((err) => {
                        console.log(err);

                    })
            } else {
                WaringMessage('Please fill all field')
            }
        } else {
            if (setLayout1.row != '' && setLayout1.column != '' && setLayout1.value != '' && setLayout1.operation != '') {
                PrivateAxios.post('report/get-fms-report', { ...layout1, fmsId })
                    .then((res) => {
                        setTable(res.data.data);
                        
                    }).catch((err) => {
                        console.log(err);

                    })
            } else {
                WaringMessage('Please fill all field')
            }
        }
    }

    // FilterCall = () => {
    //     PrivateAxios.post('report/get-fms-report', { ...layout1, fmsId, filterValue: filterdata })
    //         .then((res) => {
    //             setTable(res.data.data);
    //         }).catch((err) => {
    //             console.log(err);

    //         })
    // }


    // Get unique rows and columns





    //==================All Layout PIE & Column=======================/
    //**************************************************/


    const [layounameData, setLayoutNameData] = useState(layout)


    const clear = () => {
        // setLayoutAxis1({
        //     ...layoutAxis1,
        //     row: "",
        //     "value": "",
        //     "operation": "",
        // })

        // setLayout1({
        //     "row": "",
        //     "column": "",
        //     "filter": "",
        //     "value": "",
        //     "operation": "",
        // })
    }
    const [open, setOpen] = useState(false);
    //dark theme select
    const [isDarkTheme, setIsDarkTheme] = useState(false);

    // Check for the theme by looking at the body's class list
    useEffect(() => {
        const checkTheme = () => {
            setIsDarkTheme(document.body.classList.contains("dark-theme"));
        };

        // Initial check
        checkTheme();

        // MutationObserver to detect theme changes on body
        const observer = new MutationObserver(checkTheme);
        observer.observe(document.body, { attributes: true, attributeFilter: ["class"] });

        // Clean up observer on component unmount
        return () => observer.disconnect();
    }, []);
    //dark theme select end
    return (
        <React.Fragment>
            <div className='card mb-4 mt-3'>
                <div className='col-12'>
                    <div className=''>
                        <div className='card-body pb-1'>
                            {/* <h6 className='fw-bold gth-text-primary'>
                                 {layout} 
                            </h6>*/}
                            <div className="d-flex align-items-center mb-3">
                                <div className="input-edit-wrap position-relative me-3 w-100 border rounded">
                                    <input type="text" value={layounameData} className="form-control" onChange={(e) => { setLayoutName(e.target.value); setLayoutNameData(e.target.value) }} />
                                    <span className="input-edit"><i className="fas fa-pen"></i></span>
                                </div>
                                <button
                                    onClick={() => setOpen(!open)}
                                    aria-controls="example-collapse-text"
                                    aria-expanded={open}
                                    className="link-btn"
                                >
                                    <i className="fas fa-sort"></i>
                                </button>
                            </div>

                            <Collapse in={open}>
                                <div className='row align-items-end' id="example-collapse-text">
                                    {layout1.pie == 0 && layout1.bar == 0 && layout1.table == 0 ? "" :
                                        layout1.pie == 1 || layout1.bar == 1 && layout1.table == 0 ?
                                            <>
                                                <div className='col-lg-4 col-md-6 col-12'>
                                                    <div className='form-group'>
                                                        <label className='form-label'>X- Axis <span className='text-exp-red'>*</span></label>
                                                        <div className='custom-select-wrap multi-select'>
                                                            <Select
                                                                options={layoutData1}
                                                                getOptionLabel={(option) => option.label}
                                                                getOptionValue={(option) => option.id}
                                                                value={layoutAxis1.row ? layoutData1.find(item => item.id == layoutAxis1.row) : []}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#ddddff',
                                                                        primary: '#6161ff',
                                                                    },
                                                                })}
                                                                onChange={(data) => { setLayoutAxis1({ ...layoutAxis1, row: data.id });  }}
                                                                styles={{
                                                                    menu: (base) => ({
                                                                        ...base,
                                                                        backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                    singleValue: (base) => ({
                                                                        ...base,
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                    option: (base, state) => ({
                                                                        ...base,
                                                                        backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-6 col-12'>
                                                    <div className='form-group'>
                                                        <label className='form-label'>Value <span className='text-exp-red'>*</span></label>
                                                        <div className='custom-select-wrap multi-select'>
                                                            <Select
                                                                options={layoutData1}
                                                                value={layoutAxis1.value ? layoutData1.find(item => item.id == layoutAxis1.value) : []}
                                                                getOptionLabel={(option) => option.label}
                                                                getOptionValue={(option) => option.id}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#ddddff',
                                                                        primary: '#6161ff',
                                                                    },
                                                                })}
                                                                onChange={(data) => setLayoutAxis1({ ...layoutAxis1, value: data.id })}
                                                                styles={{
                                                                    menu: (base) => ({
                                                                        ...base,
                                                                        backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                    singleValue: (base) => ({
                                                                        ...base,
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                    option: (base, state) => ({
                                                                        ...base,
                                                                        backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-6 col-12'>
                                                    <div className='form-group'>
                                                        <label className='form-label'>Operation <span className='text-exp-red'>*</span></label>
                                                        <div className='custom-select-wrap multi-select'>
                                                            <Select
                                                                options={operation}
                                                                value={layoutAxis1.operation ? operation.find(item => item.value == layoutAxis1.operation) : []}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#ddddff',
                                                                        primary: '#6161ff',
                                                                    },
                                                                })}

                                                                onChange={(data) => { setLayoutAxis1({ ...layoutAxis1, operation: data.value }); setTable([]);setLayoutC({ ...layoutC, operation: data.value }); }}
                                                                styles={{
                                                                    menu: (base) => ({
                                                                        ...base,
                                                                        backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                    singleValue: (base) => ({
                                                                        ...base,
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                    option: (base, state) => ({
                                                                        ...base,
                                                                        backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className='col-lg-4 col-md-6 col-12'>
                                                    <div className='form-group'>
                                                        <label className='form-label'>Row <span className='text-exp-red'>*</span></label>
                                                        <div className='custom-select-wrap multi-select'>
                                                            <Select
                                                                options={layoutData1.filter(item => item.id != layout1.column)}
                                                                getOptionLabel={(option) => option.label}
                                                                getOptionValue={(option) => option.id}
                                                                value={layout1.row ? layoutData1.find(item => item.id == layout1.row) : []}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#ddddff',
                                                                        primary: '#6161ff',
                                                                    },
                                                                })}
                                                                onChange={(data) => setLayout1({ ...layout1, row: data.id })}
                                                                styles={{
                                                                    menu: (base) => ({
                                                                        ...base,
                                                                        backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                    singleValue: (base) => ({
                                                                        ...base,
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                    option: (base, state) => ({
                                                                        ...base,
                                                                        backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-6 col-12'>
                                                    <div className='form-group'>
                                                        <label className='form-label'>Column <span className='text-exp-red'>*</span></label>
                                                        <div className='custom-select-wrap multi-select'>
                                                            <Select
                                                                options={layoutData1.filter(item => item.id != layout1.row)}
                                                                getOptionLabel={(option) => option.label}
                                                                getOptionValue={(option) => option.id}
                                                                value={layout1.column ? layoutData1.find(item => item.id == layout1.column) : []}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#ddddff',
                                                                        primary: '#6161ff',
                                                                    },
                                                                })}
                                                                onChange={(data) => setLayout1({ ...layout1, column: data.id })}
                                                                styles={{
                                                                    menu: (base) => ({
                                                                        ...base,
                                                                        backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                    singleValue: (base) => ({
                                                                        ...base,
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                    option: (base, state) => ({
                                                                        ...base,
                                                                        backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* {layout1.pie == 0 && layout1.bar == 0 && layout1.table == 1 ? */}

                                                {/* <div className='col-lg-4 col-md-6 col-12'>
                                                <div className='form-group'>
                                                    <label className='form-label'>Filter <span className='text-exp-red'>*</span></label>
                                                    <div className='custom-select-wrap multi-select'>
                                                        <Select
                                                            options={layoutData1}
                                                            getOptionLabel={(option) => option.label}
                                                            getOptionValue={(option) => option.id}
                                                            value={layout1.filter ? layoutData1.find(item => item.id == layout1.filter) : []}
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#ddddff',
                                                                    primary: '#6161ff',
                                                                },
                                                            })}
                                                            onChange={(data) => setLayout1({ ...layout1, filter: data.id })}
                                                        />
                                                    </div>
                                                </div>
                                            </div> */}
                                                {/* } */}
                                                <div className='col-lg-4 col-md-6 col-12'>
                                                    <div className='form-group'>
                                                        <label className='form-label'>Value <span className='text-exp-red'>*</span></label>
                                                        <div className='custom-select-wrap multi-select'>
                                                            <Select
                                                                options={layoutData1}
                                                                getOptionLabel={(option) => option.label}
                                                                getOptionValue={(option) => option.id}
                                                                value={layout1.value ? layoutData1.find(item => item.id == layout1.value) : []}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#ddddff',
                                                                        primary: '#6161ff',
                                                                    },
                                                                })}
                                                                onChange={(data) => setLayout1({ ...layout1, value: data.id })}
                                                                styles={{
                                                                    menu: (base) => ({
                                                                        ...base,
                                                                        backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                    singleValue: (base) => ({
                                                                        ...base,
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                    option: (base, state) => ({
                                                                        ...base,
                                                                        backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-4 col-md-6 col-12'>
                                                    <div className='form-group'>
                                                        <label className='form-label'>Operation <span className='text-exp-red'>*</span></label>
                                                        <div className='custom-select-wrap multi-select'>
                                                            <Select
                                                                options={operation}
                                                                value={layout1.operation ? operation.find(item => item.value == layout1.operation) : []}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#ddddff',
                                                                        primary: '#6161ff',
                                                                    },
                                                                })}

                                                                onChange={(data) => { setLayout1({ ...layout1, operation: data.value }); setTable([]); setLayoutC({ ...layoutC, operation: data.value }); }}

                                                            />
                                                            {/* styles={{
                                                                    menu: (base) => ({
                                                                        ...base,
                                                                        backgroundColor: isDarkTheme ? "#06162f" : "#ffffff",
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                    singleValue: (base) => ({
                                                                        ...base,
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                    option: (base, state) => ({
                                                                        ...base,
                                                                        backgroundColor: state.isFocused ? (isDarkTheme ? "#112a46" : "#ddddff") : "transparent",
                                                                        color: isDarkTheme ? "#e0e0e0" : "#000000",
                                                                    }),
                                                                }} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                    <div className='col-lg-6 col-md-6 col-12'>
                                        <div className='form-group'>
                                            <div className='d-flex flex-wrap'>
                                                <label className="custom-checkbox me-4">
                                                    Pie Chart
                                                    <input type="checkbox" name='workflow' checked={layout1.pie == 1 ? true : false} onChange={(e) => { setLayout1({ ...layout1, pie: e.target.value ? 1 : 0, bar: 0, table: 0 }); setLayoutC({ ...layoutC, pie: e.target.value ? 1 : 0, bar: 0, table: 0 }); }} />
                                                    <span className="checkmark" />
                                                </label>
                                                <label className="custom-checkbox me-4">
                                                    Column Chart
                                                    <input type="checkbox" name='checksheet' checked={layout1.bar == 1 ? true : false} onChange={(e) => { setLayout1({ ...layout1, bar: e.target.value ? 1 : 0, pie: 0, table: 0 }); setLayoutC({ ...layoutC, bar: e.target.value ? 1 : 0, pie: 0, table: 0 }); }} />
                                                    <span className="checkmark" />
                                                </label>
                                                <label className="custom-checkbox me-4">
                                                    Table
                                                    <input type="checkbox" name='checksheet' checked={layout1.table == 1 ? true : false} onChange={(e) => { setLayout1({ ...layout1, table: e.target.value ? 1 : 0, bar: 0, pie: 0 }); setLayoutC({ ...layoutC, table: e.target.value ? 1 : 0, bar: 0, pie: 0 }); }} />
                                                    <span className="checkmark" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end">
                        <button type="reset" name="reset_button" className="btn btn-exp-light me-2">Reset</button>
                        <button type="submit" name="submit_button" onClick={submitData} className="btn btn-exp-green">Report Preview</button>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default FmsDynamicReport