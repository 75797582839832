import React, { useEffect, useState } from 'react'
import SOPBar from './SOPBar'
import { Modal } from 'react-bootstrap'
import AutoHeightTextarea from '../CommonComponent/AutoHeightTextarea';
import { Link } from 'react-router-dom';

const YourSOP = () => {

  const [addSopShow, setAddSopShow] = useState(false);
  const addSopModalClose = () => { setAddSopShow(false); }
  const addSopModalShow = () => { setAddSopShow(true) };

  const [viewSopShow, setViewSopShow] = useState(false);
  const viewSopModalClose = () => { setViewSopShow(false); }
  const viewSopModalShow = () => { setViewSopShow(true) };

  const [checkSheet, setCheckSheet] = useState({
    "attachment_file_link": "",
  });


  // edit view field

  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState("Organizational Performance");
  const [description, setDescription] = useState("It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.");
  const [links, setLinks] = useState([
    "https://www.growthh.in/",
    "https://www.growthh.in/",
    "https://www.growthh.in/",
  ]);
  const [hover, setHover] = useState(false);

  // Load from localStorage when component mounts
  useEffect(() => {
    const storedTitle = localStorage.getItem("cardTitle");
    const storedLinks = localStorage.getItem("cardLinks");
    const storedDescription = localStorage.getItem("cardDescription");

    if (storedTitle) setTitle(storedTitle);
    if (storedDescription) setDescription(storedDescription);
    if (storedLinks) setLinks(JSON.parse(storedLinks));
  }, []);

  // Save to localStorage when user clicks "Save"
  const handleSave = () => {
    localStorage.setItem("cardTitle", title);
    localStorage.setItem("cardDescription", description);
    localStorage.setItem("cardLinks", JSON.stringify(links));
    setIsEditing(false);
  };

  const handleEdit = () => setIsEditing(true);

  const handleChange = (index, newValue) => {
    const updatedLinks = [...links];
    updatedLinks[index] = newValue;
    setLinks(updatedLinks);
  };

  return (
    <>
      <div className="gthh-controller-bar">
        <ul className="gth-controller-view-block">
          <SOPBar />
        </ul>
      </div>
      <div className='p-4'>
        <div className='card'>
          <div className='card-body'>
            <div className=' d-flex justify-content-center align-content-center '>
              <div>
                <img src={process.env.PUBLIC_URL + '/assets/images/empty-box.png'} alt="no-data" className="img-fluid w-100" />
                <h5 className='f-s-18 text-center mt-2'>Nothing to Show</h5>
                <button class="btn btn-info btn-sm mt-3" type="button" onClick={addSopModalShow}><i class="fas fa-plus me-2"></i>Add New SOP</button>

              </div>
            </div>
          </div>
        </div>


        <div
          className="card position-relative"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {hover && !isEditing && (
            <>
              <button
                className=" table-action-btn position-absolute"
                style={{ top: "10px", right: "50px" }}
                onClick={handleEdit}
              >
                <i class="fas fa-pencil-alt"></i>
              </button>
              <button class="me-1 table-action-btn position-absolute" style={{ top: "10px", right: "10px" }}><i class="fas fa-trash-alt text-exp-red"></i></button>
            </>
          )}

          <div className="card-body">
            {isEditing ? (
              <>
                <div className='form-group'>
                  <label className='form-label'>Title</label>
                  <input
                    type="text"
                    className="form-control mb-2"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className='form-group'>
                  <label className='form-label'>Description</label>
                  <textarea class="form-control mb-2" id="exampleFormControlTextarea1" value={description} onChange={(e) => handleChange(e.target.value)}></textarea>
                </div>

                <div className='form-group'>
                  <label className='form-label'>Your links</label>
                  {links.map((link, index) => (
                    <>
                      <textarea key={index} class="form-control mb-2" id="exampleFormControlTextarea1" value={link} onChange={(e) => handleChange(index, e.target.value)}></textarea>
                      {/* <input
                      key={index}
                      type="text"
                      className="form-control mb-2"
                      value={link}
                      onChange={(e) => handleChange(index, e.target.value)}
                    /> */}
                    </>
                  ))}
                </div>
               
                <button className="btn btn-success btn-sm" onClick={handleSave}>
                  Save
                </button>
              </>
            ) : (
              <>
                <h5 className="mb-3">{title}</h5>
                <p className='f-s-16'>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                {links.map((link, index) => (
                  <p className="f-s-16 mb-2" key={index}>
                    <i className="fas fa-link me-2"></i>
                    <Link to={link} className="fw-semibold">
                      {link}
                    </Link>
                  </p>
                ))}
              </>
            )}
          </div>
        </div>
      </div>







      <Modal
        show={addSopShow}
        onHide={addSopModalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton className="gth-blue-light-bg">
          <Modal.Title className="gth-modal-title">Add New SOP </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className='w-100'>
            <div className='row align-items-center'>
              <div className='col-12'>
                <div className='form-group'>
                  <label className='form-label'>Title</label>
                  <input type='text' className="form-control" name='task_name' placeholder='Enter Task Name' />

                </div>
              </div>
              <div className='col-12'>
                <div className='form-group'>
                  <label className='form-label'>Description</label>
                  <textarea type='text' required className='form-control' placeholder='Write your note here...' rows={4} ></textarea>
                </div>
              </div>
              <div className='col-12'>
                <div className='form-group'>
                  <label className='form-label'>Your links</label>
                  <AutoHeightTextarea placeholder="Your links"
                    value={checkSheet.attachment_file_link}
                    onChange={(e) =>
                      setCheckSheet({
                        ...checkSheet,
                        attachment_file_link: e.target.value
                      })
                    } />
                  <p className='mb-0 mt-1 f-s-12'>

                    <i className='fas fa-info-circle me-1 text-info'></i>
                    Press enter for add more links
                  </p>
                </div>
              </div>


            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className=''>
          <button className='btn btn-exp-green' onClick={addSopModalClose}>
            Save
          </button>

        </Modal.Footer>
      </Modal>



    </>
  )
}

export default YourSOP